import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, message as Alert, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
import Helper from 'utils/helpers';
import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import {
  ProFormSelect,
} from '@ant-design/pro-form';
import { productList } from '../product/product.service';
import { bomList } from '../bom/bom.service';
import { MinusSquareOutlined, PlusOutlined } from '@ant-design/icons';
import { MODE } from 'utils/constants';

const DivisonEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [listbomProducts, setListbomProducts] = useState([]);
  const [masterProduct, setMasterProduct] = useState([]);

  const initValues = {
    masterProductId: item ? item?.id : undefined,
    bomProducts: item ? item?.masterProductsPacking?.map(bom => ({
      id: bom?.bomProduction.id,
      using: bom.using,
      type: bom.type,
    })) : [{ id: undefined, using: undefined, type: undefined }],
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    bomList({ warehouseId: currentWarehouse.id }).then(value => {
      if (value) {
        setListbomProducts(value.data);
      }
    });
    productList({ warehouseId: currentWarehouse.id }).then(value => {
      if (value) {
        setMasterProduct(value.data);
      }
    });
  }, [currentWarehouse]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateMasterproductPacking : lang.createMasterproductPacking}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={768}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            masterproductPackingCreate(values).then(() => {
              Alert.success(lang.createSuccessfully);
              onCallback(MODE.CREATE);
              setLoading(false);
            })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            // values.id = item?.id;
            masterproductPackingUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: !item ? 12 : 24 }} xs={{ span: 24 }}>
            <ProFormSelect
              name="masterProductId"
              label={lang.product}
              options={masterProduct?.map(product => ({
                value: product.id,
                label: `${product.nameEN}( ${product.shortName}) - ${product.code3A}`,
              }))}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              placeholder={lang.product}
            />
          </Col>
          {!item && <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <ProFormSelect
              name="type"
              label={lang.type}
              options={[
                {
                  value: 'DOUBLE',
                  label: lang.double,
                },
                {
                  value: 'SINGLE',
                  label: lang.single,
                },
                {
                  value: 'MANUAL',
                  label: lang.handWork,
                },
              ]}
              placeholder={lang.type}
            />
          </Col>}
          <Col span={24}>
            <Form.List name="bomProducts" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row gutter={[16, 16]} align="middle" key={name}>
                      <Col span={item ? 8 : 12}>
                        <ProFormSelect
                          name={[name, 'id']}
                          label={lang.bom}
                          options={listbomProducts?.map(bom => ({
                            value: bom.id,
                            label: `${bom.name} - ${bom.code}`,
                          }))}

                          placeholder={lang.bom}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            // return true;
                            return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                        />
                      </Col>
                      <Col span={item ? 7 : 9}>
                        <Form.Item
                          name={[name, 'using']}
                          wrapperCol={24}
                          label={lang.quantity}
                        >
                          <InputNumber style={{ width: '100%' }} placeholder={lang.quantity} />
                        </Form.Item>
                      </Col>
                      {item && <Col span={7}>
                        <ProFormSelect
                          name={[name, 'type']}
                          label={lang.type}
                          options={[
                            {
                              value: 'DOUBLE',
                              label: lang.double,
                            },
                            {
                              value: 'SINGLE',
                              label: lang.single,
                            },
                            {
                              value: 'MANUAL',
                              label: lang.handWork,
                            },
                          ]}
                          placeholder={lang.type}
                        />
                      </Col>}
                      <Col>
                        <Button
                          danger
                          type="text"
                          onClick={() => remove(name)}
                          icon={<MinusSquareOutlined />}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item wrapperCol={24}>
                    <Button
                      type="dashed"
                      onClick={() => add({ id: undefined, using: undefined })}
                      block
                      icon={<PlusOutlined />}
                    >
                      {lang.btnCreate}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

DivisonEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default DivisonEditForm;
