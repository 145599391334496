import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Form, Card, Row, Col, DatePicker, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';

import './style.less';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { bomGiftList } from './inventory-gift.service';
// import moment from 'moment-timezone';
// import BomEditForm from './inventory-gift-edit.form';
// import Helper from 'utils/helpers';
// import Modal from 'antd/lib/modal/Modal';
// import Dragger from 'antd/lib/upload/Dragger';
// import moment from 'moment-timezone';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const GiftInventory = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // const [isEditModal, setIsEditModal] = useState(false);

  // const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  // const [file, setFile] = useState();
  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  // const [loadingBtn, setLoadingBtn] = useState(false);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [uploadModal, setUploadModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    bomGiftList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id, isAvailable: true })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.giftCode,
      dataIndex: 'code',
      key: 'code',
      width: '10%',
    },
    {
      title: lang.giftName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: lang.UOM,
      dataIndex: 'UOM',
      key: 'UOM',
    },
    {
      title: lang.totalQuantity,
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: lang.PCSCase,
      dataIndex: 'PCSCase',
      key: 'PCSCase',
    },
    {
      title: lang.wastage,
      dataIndex: 'productionLoss',
      key: 'productionLoss',
    },
    // {
    //   title: lang.createDate,
    //   dataIndex: 'createDate',
    //   key: 'createDate',
    //   render: value => moment(value, formatDateFull).format(formatDatetime),
    // },
    // {
    //   title: lang.status,
    //   dataIndex: 'status',
    //   key: 'status',
    // },
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '10%',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           const itemDetail = items.find((item) => item.id === record.id);
    //           setItem(itemDetail);
    //           setIsEditModal(true);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const columnsExpand = [
    {
      title: lang.defaultCode,
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.productName,
      // dataIndex: 'name',
      key: 'name',
      render: record => record?.name,
    },
    {
      title: lang.locator,
      key: 'locators',
      dataIndex: 'locator',
      render: val => `${val?.locator} - ${val?.locatorName}`,
    },
    // {
    //   title: lang.lot,
    //   dataIndex: 'lot',
    //   key: 'lot',
    // },
    // {
    //   title: lang.expireDate,
    //   dataIndex: 'expireDate',
    //   key: 'expireDate',
    //   width: '10%',
    //   render: val => moment(val).format(formatDate),
    // },
    {
      title: lang.salesChannel,
      dataIndex: 'saleChannel',
      key: 'saleChannel',
    },
    // {
    //   title: 'ShelfLife',
    //   key: 'shelfLife',
    //   render: rec => parseFloat(moment(rec?.expireDate,formatDateFull).diff(moment().startOf('date'),'day')*100/(rec?.shelfLifeMonths *30)).toFixed(2),
    // },

    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.stockAvailableForSale,
      key: 'quantityForSale',
      dataIndex: 'quantityForSale',
    },
    {
      title: lang.quantityAwaitingDispatch,
      key: 'quantityForSale',
      render: record => record?.quantity - record?.quantityForSale,
    },
  ];

  const expandedRowRender = (rec) => {
    const { code, name, shortName } = rec;
    const dataSource = rec?.masterProductsGiftLocators?.map(locator => ({ ...locator, code, name, shortName }));
    return <TableCustom columns={columnsExpand} dataSource={[...dataSource]} pagination={false} />;
  };

  // const extraButton = [
  //   <Button
  //     key="btn-export"
  //     type="primary"
  //     loading={loadingBtn}
  //     onClick={async () => {
  //       try {
  //         setLoadingBtn(true);
  //         const response = await bomGiftList({ warehouseId: currentWarehouse.id });
  //         Helper.dowloadXLSX(response?.data, 'Bom-gift');
  //         setLoadingBtn(false);
  //       } catch (error) {
  //         setLoadingBtn(false);
  //         return false;
  //       }
  //     }}
  //   >
  //     {lang.exportCSV}
  //   </Button>,
  //   <Button
  //     key="btn-import"
  //     type="primary"
  //     onClick={() => setUploadModal(true)}
  //   >
  //     {lang.importCSV}
  //   </Button>,
  //   // <Button
  //   //   key="btn-complete"
  //   //   type="primary"
  //   //   onClick={() => {
  //   //     setIsEditModal(true);
  //   //     setItem();
  //   //   }}
  //   // >
  //   //   {lang.btnCreate}
  //   //   <PlusOutlined />
  //   // </Button>,
  // ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: lang.home,
    },
    {
      path: '/',
      breadcrumbName: lang.inventory,
    },
    {
      path: '/bom-gift',
      breadcrumbName: lang.giftInventory,
    },
  ];

  return (
    <Layout className="product-category-listing">
      <PageHeader
        ghost={false}
        title={lang.giftInventory}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content style={{ margin: ' 12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={20}>
              <Col col={16} style={{ flexDirection: 'row', display: 'flex' }}>
                <Form.Item label={lang.createDate} name="date">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
                <Form.Item name="productBOMCode" style={{ marginLeft: 20 }}>
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col col={8}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>

        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.bomGiftlist}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            pageSize: params.limit,
            current: params.page,
            showSizeChanger: true,
          }}
          expandable={{
            expandedRowRender,
          }}
          className="custom-table-row"
        />
      </Layout.Content>
      {/* <BomEditForm
        item={item}
        onCallback={(mode) => {
          mode === MODE.CREATE ? setParams({ ...defaultPage }) : setParams({ ...params });
          setIsEditModal(false);
        }}
        // onCancel={() => setIsEditModal(false)}
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(currentWarehouse.id, formData);
              setFile();
            }
            setParams({ ...defaultPage });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ name: 'Hàng mát' }], 'TemplateDivison')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal> */}
    </Layout>
  );
};

export default GiftInventory;
