import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message } from 'antd';
import './style.less';
import { materialManueuverCommandDetail, ordersMaterialUpdate, ordersMaterialProductUpdate } from './production-order.service';
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';//formatDatetime
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
// import SuggestLocationForm from './suggest-location.form';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons'; //, DeleteOutlined, ExclamationCircleOutlined
// import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';
// import { deleteProductOrder } from 'pages/dashboard/configuration/sale-order/sale-order.service';
import _get from 'lodash/get';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import logo from 'assets/logoAKAWMS/akawms.svg';
import MaterialProductEditForm from './material-production-order-create-product-edit.form';
// import ReceiptProductEditForm from './gift-production-order-create-product-edit.form';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const MaterialManueuverCommandDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExportPXK, setLoadingExportPXK] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  // const [deleteProduct, setDeleteProduct] = useState();
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isVasManager = _get(user, 'roles', '').includes('vasManager');
  // const isStocker = _get(user, 'roles', '').includes('stocker');
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: setSelectedRowKeys,
  // };

  useEffect(() => {
    setLoading(true);
    materialManueuverCommandDetail(parseInt(id))
      .then(async (result) => {
        setSaleorder(result);
        setLoading(false)
      })
      .catch((e) => setLoading(false));
      setLoading(false);
  }, [id]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['bomProduction', 'code'],
      key: 'code',
    },
    {
      title: lang.productName,
      dataIndex: ['bomProduction', 'name'],
      key: 'productName',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      render: rec => rec?.quantity,
    },
    // {
    //   title: 'Số lượng đã lấy',
    //   key: 'pickedQuantity',
    //   render: rec => rec?.pickedQuantity,
    // },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: saleorder?.status === 'EXPORTED',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            disabled={record?.pickedQuantity > 0}
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              setItem(record);
            }}
          />
          {/* <Button
            type="link"
            size="small"
            disabled={(record?.pickingQuantityCartton === record?.quantityInCarton && record?.pickingQuantityCartton > 0 && record?.quantityInCarton > 0) || 
              (record?.pickingQuantityTin === record?.quantityTin && record?.pickingQuantityTin > 0 && record?.quantityTin > 0)}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: lang.doYouWantToDeleteTheseItems,
                icon: <ExclamationCircleOutlined />,
                onOk: async () => {
                  try {
                    await deleteProductOrder(record.id);
                    message.success(lang.deleteSuccessfully);
                    setDeleteProduct(!deleteProduct);
                    return true;
                  } catch (error) {
                    message.error(error.message);
                    return false;
                  }
                },
                onCancel() {

                },
              });
            }}
          /> */}
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1)
      }}
    >
      {'Back'}
    </Button>,
  (isVasManager || isIdm) && <Button 
  key="btn-export-pxk"
  type="primary"
  ghost
  loading={loadingExportPXK}
  onClick={async () => {
    setLoadingExportPXK(true);
    const date = moment(saleorder?.createDate, formatDateFull).format(formatDate);
    const tottalQuantity = saleorder?.productMaterialOrders?.reduce((prev, curr) => { prev += curr.quantity; return prev; }, 0);
    const content = `
  <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
    <div style="display: flex; height: 120px;">
      <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
        <img src="${logo}" style=" height: 100%; width: 100%" />
      </div>
      <div style="width: 60%; text-align: center; padding-top: 7vh; ">
        <h1>{lang.warehouseExportSlip}</h1>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
    <div style="display: grid; line-height: 1;">
      <p> {lang.area}: <b>{lang.hcm}</b></p>
      <p> {lang.slipType}: <b>{lang.exportBranch}</b></p>
      <p> {lang.fromDate}: <b>${date}</b> </p>
      <p> {lang.vehicleNumber}: <b>59K1-00005</b> </p>
      <p> {lang.deliveryStaffCode}: <b>Nguyen Van A</b> </p>
      <p> {lang.deliveryRoute}: <b>{lang.hcm}</b> </p>
    </div>
    <div style="display: grid; line-height: 1;">
      <p> {lang.printedTime}: <b>${moment().format(formatDatetime)}</b></p>
      <p> {lang.toDate}: <b>${date}</b> </p>
      <p> {lang.totalCarton}: <b>${tottalQuantity ? tottalQuantity : ''}</b></p>
      <p> {lang.documentNo}: <b>${saleorder?.documentNo ? saleorder?.documentNo : ''}</b></p>
      <p> {lang.dailyTransferSequence}: <b>1</b> </p>
    </div>
  </div>
    <div style="min-height: 400px;">
      <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
        <thead>
          <tr>
            <th><div style="min-height: 50px; display: flex; align-items: center;">No.</div></th>
            <th><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
            <th><div style="min-height: 50px; display: flex; align-items: center;">Quantity</div></th>
            <th><div style="min-height: 50px; display: flex; align-items: center;">SO Doc No</div></th>
          </tr>
        </thead>
        <tbody>
        ${saleorder?.productMaterialOrders?.map((productOrder, index) => {
          return `<tr style="text-align: center;">
                    <td><div style="min-height: 30px; display: flex; align-items: center;">${index +1}</div></td>
                    <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.bomProduction.name}</div></td>
                    <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.quantity}</div></td>
                    <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.documentNo}</div></td>
                  </tr>`;
        }).join(' \n')}
        
          <tr>
            <td colSpan="2" style="text-align:center; padding-top: 10px;">
              <b style="min-height: 30px; display: block;">Total</b>
            </td>
            <td style="font-weight: bold;">${saleorder?.productMaterialOrders?.reduce((prev, curr) => { prev += curr.quantity; return prev; }, 0)}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
        <p> <b>{lang.checkerStaff}</b> </p>
      </div>
      <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
        <p> <b>{lang.securityGuard}</b> </p>
      </div>
      <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
        <p> <b>{lang.pickingStaff}</b> </p>
      </div>
      <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
        <p> <b>{lang.slipCreator}</b> </p>
      </div>
    </div>
  </div>`;
    var template = document.createElement('div');
    template.innerHTML = content;
    document.body.appendChild(template);
    await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      var imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPdf('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
      pdf.save(`ExportSlip${moment().format('DDMMYYYY')}.pdf`);
    });
    document.body.removeChild(template);
    setLoadingExportPXK(false);
  }}
>
  {lang.issueExportSlip}
</Button>,
    <Button
      key="btn-confirm"
      type="primary"
      disabled={!isVasManager || saleorder?.status === 'EXPORTED'}
      onClick={ async () => {
        try {
          setLoading(true)
          Modal.confirm({
            title: lang.areYouSureConfirmSlip,
            async onOk() {
              const formData = {...saleorder};
              formData.status = 'EXPORTED';
              formData.warehouseFrom = currentWarehouse?.name;
              const payload = saleorder?.productMaterialOrders.map(item => {
                return {
                  documentNo: formData?.documentNo,
                  quantity: item?.quantity,
                  codeMaterial: item?.codeMaterial,
                  warehouseId: item?.warehouseId
                }
              });
              delete formData.productMaterialOrders
              console.log(formData)
              await ordersMaterialUpdate({...formData, productMaterialOrders: payload});
              await materialManueuverCommandDetail(id)
                .then(result => {
                setLoading(true)
                setSaleorder(result);
                setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
              message.success(lang.confirmationSuccessful)
              setLoading(false);
            },
            onCancel() {}
          })
          setLoading(false);
        } catch (error) {
          message.error(error.message);
          setLoading(false);
        }
      }}
    >
      {lang.confirm}
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/manuever-command',
      breadcrumbName: lang.materialExport,
    },
    {
      path: '/',
      breadcrumbName: lang.materialExportSlipDetails,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.materialExportSlipDetails}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 2 }} >
            <Descriptions.Item label={lang.documentNo}>{saleorder?.documentNo}</Descriptions.Item>
            <Descriptions.Item label={lang.truckType}>{saleorder?.truckType}</Descriptions.Item>
            <Descriptions.Item label={lang.truckWeight}>{saleorder?.truckWeight}</Descriptions.Item>
            <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>{
              saleorder?.status === 'NEW' ? lang.newCreate
              : saleorder?.status === 'EXPORTED' ? lang.exported
              : lang.cancelled
            }</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={saleorder?.productMaterialOrders}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: saleorder?.productMaterialOrders?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
          // rowSelection = {{
          //   onChange: (_, selectedRows) => {
          //     setSelectedRowKeys(selectedRows)
          //   },
          //   getCheckboxProps: (record) => ({
          //     disabled: (record?.pickingQuantityCartton === record?.quantityInCarton && record?.pickingQuantityCartton > 0 && record?.quantityInCarton > 0) || 
          //     (record?.pickingQuantityTin === record?.quantityTin && record?.pickingQuantityTin > 0 && record?.quantityTin > 0)
          //   })
          // }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        {
          <MaterialProductEditForm
          item={item}
          onCallback={async (value) => {
            console.log(value)
            try {
              setLoading(true)
              await ordersMaterialProductUpdate({ id: value?.id, quantity: value?.quantity, warehouseId: currentWarehouse?.id});
              materialManueuverCommandDetail(id)
                .then(result => {
                setLoading(true)
                setSaleorder(result);
                setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
              message.success(lang.updateSuccessful)
              setLoading(false);
            } catch (error) {
              message.error(error.message);
              setLoading(false);
            }
          }}
          onCancel={() => setIsEditModal(false)}
          />
        }
      </Modal>      
    </Layout>
  );
};

export default MaterialManueuverCommandDetail;
