import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Form, Select, Card, Row, Col, DatePicker, message as Alert, Input, message } from 'antd';
import { CheckOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { receiptCreate } from './receipt.service';
import ReceiptCreateProductEditForm from './receipt-create-product-edit.form';
import { formatDate, formatDateFull, formatDatetime, INBOUND_KINDS } from 'utils/constants';
import './style.less';
import { productList } from '../../configuration/product/product.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { ProFormSelect } from '@ant-design/pro-form';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [isEditModal, setIsEditModal] = useState(false);

  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [productMasters, setProductMasters] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [kind, setKind] = useState();
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'nameEN',
      width: '15%',
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityCartton',
      key: 'carton',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityTin',
      key: 'quantityTin',
    },
    {
      title: lang.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: 'Số lượng thực nhận (Thùng/pallet)',
      key: 'actualQuantityReceivedCartonPallet',
      dataIndex: 'actualQuantityReceivedCartonPallet',
      hideInTable: kind !== 'BORROW',
    },
    {
      title: 'DO',
      key: 'DO',
      dataIndex: 'DO',
      hideInTable: kind !== 'RETURN',
    },
    {
      title: 'Line NO',
      key: 'lineNo',
      dataIndex: 'lineNo',
      hideInTable: kind !== 'RETURN',
    },
    {
      title: 'Tổng số lượng thực nhận (Thùng)',
      key: 'quantityCarttonReceived',
      dataIndex: 'quantityCarttonReceived',
      hideInTable: kind !== 'BORROW',
    },
    {
      title: 'Tổng số lượng thực nhận (Lon)',
      key: 'quantityTinReceived',
      dataIndex: 'quantityTinReceived',
      hideInTable: kind !== 'BORROW',
    },
    {
      title: 'Số lượng hỏng',
      key: 'actualQuantityErrorTin',
      dataIndex: 'actualQuantityErrorTin',
      hideInTable: kind !== 'BORROW',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: (record) => record !== '-' ? record?.format(formatDatetime) : undefined,
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (record) => record !== '-' ? record?.format(formatDatetime) : undefined,
    },
    //{
    //   title: lang.totalPallet,
    //   dataIndex: ['masterProduct', 'totalPallet'],
    //   key: 'totalPallet',
    //   hideInTable: kind === 'RETURN',
    // },
    {
      title: lang.vansCode,
      dataIndex: 'container No',
      key: 'container No',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipmentNo',
      key: 'shipmentNo',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contractNo',
      key: 'contractNo',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.invoiceNo,
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.categoryName,
      dataIndex: ['masterProduct', 'productCategory', 'name'],
      key: 'productCategory',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      hideInTable: kind === 'RETURN',
    },
    {
      title: lang.DVT,
      dataIndex: ['masterProduct', 'UOM_VN'],
      key: 'UOM_VN',
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => {
              // const detail = products.find((item) => item.masterProductId === record.masterProductId);
              setProduct(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/inbound/receipt');
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      disabled={products.length === 0}
      onClick={() => {
        form.submit();
      }}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.createReceipt,
    },
  ];

  useEffect(() => {
    setLoading(true);
    productList({ warehouseId: currentWarehouse.id })
      .then(result => {
        setProductMasters(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  return (
    <Layout className="receipt-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createReceipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form className="customFormSearch"
            colon={false}
            form={form}
            layout="vertical"
            name="receiptCreateForm"
            onFinish={async (values) => {
              try {
                // values.creatorId = user?.userInfo?.sub;
                // values.creatorName = user?.userInfo?.name;
                values.warehouseId = currentWarehouse?.id;
                values.receiptDate = values.receiptDate.format(formatDateFull);
                if (values?.rmaDate) {
                  values.rmaDate = values.rmaDate.format(formatDateFull);
                }
                if (values.inboundkind === 'RETURN') {
                  values.receiptProducts = products?.map(product => {
                    const { DO, expireDate, lot, masterProductId, lineNO, masterProduct, quantityTin } = product;
                    return {
                      DO,
                      masterProductId,
                      lineNO,
                      expireDate: expireDate ? expireDate.format(formatDateFull) : expireDate,
                      warehouseId: masterProduct.warehouseId,
                      lot,
                      quantityTin,
                      quantity: quantityTin,
                      quantityCartton: 0,
                    };
                  });
                }
                if (values.inboundkind === 'BORROW') {
                  values.receiptProducts = products?.map(product => {
                    const { actualQuantityReceivedCartonPallet, expireDate, lot, masterProductId, actualQuantityErrorTin, masterProduct, quantityCarttonReceived, quantityTinReceived } = product;
                    return {
                      actualQuantityReceivedCartonPallet,
                      actualQuantityErrorTin,
                      quantityTinReceived,
                      masterProductId,
                      quantityCarttonReceived,
                      expireDate: expireDate ? expireDate.format(formatDateFull) : expireDate,
                      warehouseId: masterProduct.warehouseId,
                      lot,
                      quantityTin: quantityTinReceived,
                      quantityCartton: quantityCarttonReceived,
                    };
                  });
                }
                setLoading(true);
                receiptCreate(values)
                  .then(result => {
                    setLoading(false);
                    Modal.success({
                      title: lang.createReceiptSuccessfully,
                      content: `${lang.receiptCode}: ${result?.code}`,
                      icon: <CheckOutlined />,
                      onOk() {
                        navigate('/dashboard/inbound/receipt');
                      },
                    });
                  })
                  .catch((error) => {
                    Alert.error(error?.message);
                    setLoading(false);
                  });
                return true;
              } catch (error) {
                Alert.error(error?.message);
                setLoading(false);
              }
            }}
            initialValues={{}}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="inboundkind"
                  label={lang.inboundKind}
                  rules={[
                    {
                      required: true,
                      message: lang.pleaseSelectAInboundKind,
                    },
                  ]}
                >
                  <Select placeholder={lang.inboundKind} onChange={value => { setKind(value); setProducts([]); }}>
                    {INBOUND_KINDS().map(item => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                <Form.Item
                  name="receiptDate"
                  label={lang.receiveDate}
                  rules={[
                    {
                      required: true,
                      message: lang.messageReceiveDate,
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              {kind === 'RETURN' && <>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="documentNo"
                    label={'Mã phiếu nhận hàng thu hồi'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Mã phiếu nhận hàng thu hồi'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="orderReference"
                    label={lang.orderReference}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.orderReference} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="description"
                    label={lang.description}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.description} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="customerName"
                    label={'Tên khách hàng'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Tên khách hàng'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="partnerLocation"
                    label={lang.address}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.address} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <ProFormSelect name="locatorCode" label={lang.locator} placeholder={lang.locator}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredTotalshelf,
                    //   },
                    // ]}
                                 params={{ warehouseId: currentWarehouse?.id }}
                                 request={async ({ warehouseId }) => {
                      const locators = await locatorList({ warehouseId });
                      return locators?.data?.map(locator => ({
                        value: locator.locator,
                        label: `${locator.locatorName} - ${locator.locator}`,
                      }));
                    }}
                                 showSearch
                  />
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="salesPerson"
                    label={'Người bán'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Người bán'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="rma"
                    label={'RMA'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'RMA'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="rmaDate"
                    label={lang.returnDate}
                  >
                    <DatePicker style={{ width: '100%' }} format={formatDate} />
                  </Form.Item>
                </Col>
              </>}
              {kind === 'IMPORT' && <>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="boothCode"
                    label={lang.vansCode}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.vansCode} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="shipment"
                    label={lang.shipmentNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.shipment} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="order"
                    label={lang.orderNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.orderNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="contract"
                    label={lang.contractNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.contractNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 8 }}>
                  <Form.Item
                    name="invoice"
                    label={lang.invoiceNo}
                  >
                    <Input style={{ width: '100%' }} placeholder={lang.invoiceNo} />
                  </Form.Item>
                </Col>
              </>}
              {kind === 'BORROW' && <>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="codeReceiptBorrow"
                    label={'Mã phiếu nhập mượn'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Mã phiếu nhập mượn'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="groupReturn"
                    label={'Nhóm trả hàng'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Nhóm trả hàng'} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 6 }}>
                  <Form.Item
                    name="orderNumber"
                    label={'Số đơn hàng'}
                  >
                    <Input style={{ width: '100%' }} placeholder={'Số đơn hàng'} />
                  </Form.Item>
                </Col>
              </>}
            </Row>
          </Form>
        </Card>
        <ProTableCusTom
          title={() => <Row>
            <Col sm={12} xs={10}><h3>{lang.productAdded}</h3></Col>
            <Col sm={12} xs={14} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setProduct();
                }}
                disabled={!kind}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => setUploadModal(true)}
                style={{ marginLeft: 8 }}
                disabled={!kind}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setProducts(products.filter(item => !selectedRowKeys.includes(item.key)));
                        setSelectedRowKeys([]);
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="key"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={products}
          scroll={{ x: 2600 }}
          search={false}
          tableAlertRender={false}
          options={{
            search: false,
            reload: false,
            density: false,
          }}
        // pagination={false}
        />
      </Layout.Content>
      <ReceiptCreateProductEditForm
        item={product}
        products={productMasters}
        productsSelected={products}
        warehouseId={currentWarehouse?.id}
        onCallback={(values) => {
          const productsfind = products.find(item => item?.key === values?.key);
          if (productsfind) {
            Object.assign(productsfind, values);
            setProducts([...products]);
          } else {
            setProducts([...products, { ...values, key: products.length }]);
          }
          // const productUpdate = products?.find(product => product.key === values[0].key);
          // if (productUpdate) {
          //   productUpdate.expectedQuantity = values[0].expectedQuantity;
          //   productUpdate.supplier = values[0].supplier;
          //   productUpdate.supplierId = values[0].supplierId;
          //   setProducts([...products]);
          //   setIsEditModal(false);
          //   return true;
          // }
          setIsEditModal(false);
        }}
        setIsEditModal={setIsEditModal}
        isEditModal={isEditModal}
        kind={kind}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file?.originFileObj);
              fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'binary' });
                const sheetName = wb.SheetNames[0];
                const sheet = wb.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (data) {
                  const result = data?.reduce((prev, curr, index) => {
                    if (index > 0 && !!curr) {
                      const finded = productMasters?.find(master => master.barCode === curr[0]?.toString());
                      if (finded) {
                        return [...prev, {
                          key: index - 1,
                          barCode: finded.barCode,
                          productName: finded?.name,
                          productCategory: finded?.productCategory?.name,
                          expectedQuantity: curr[1],
                          supplier: finded?.suppliers?.find(supp => supp?.id === curr[2])?.name,
                          purchasePrice: finded?.purchasePrice,
                          salePrice: finded?.salePrice,
                          retailPrice: finded?.retailPrice,
                          expireDate: curr[3] && moment(curr[3], formatDateFull),
                          masterProductId: finded?.id,
                          productCategoryId: finded?.productCategory?.id,
                          productCode: finded?.code,
                          warehouseId: finded?.warehouseId,
                          DVT: finded?.DVT,
                        }];
                      }
                      return prev;
                    }
                    return prev;
                  }, []);
                  setProducts([...products, ...result]);
                }
              };
              setFile();
            }
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ barCode: '', expectedQuantity: 0, supplierId: 0, expireDate: moment().format(formatDate) }], 'TemplateDataPO')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
