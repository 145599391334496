import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu, Dropdown, Avatar, Affix, Modal } from 'antd';

import {
  LogoutOutlined,
  UserOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  EditOutlined
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import logoCollapse from 'assets/logoAKAWMS/akawms_2.svg';
import logo from 'assets/logoAKAWMS/akawms_5.svg';

import ConfigurationIcon from 'assets/icons/icon-Configuration.svg';

import './style.less';
import environment from 'environments/environment';

const { Sider, Header } = Layout;
const { SubMenu } = Menu;

const AdminLayout = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
  }, []);

  const handleLogout = () => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmLogout,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        localStorage.clear();
        navigate('/');
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        window.location.replace(environment.urlChangePassWord);
      }}>
        <EditOutlined /> {lang.changePassword}
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        <LogoutOutlined /> {lang.logout}
      </Menu.Item>
    </Menu>
  );

  //menuActive
  let defaultOpenKeys = [];
  //admin
  if (
    [
      '/admin',
      '/admin/user',
      '/admin/warehouse',
    ].includes(pathname)
  ) {
    defaultOpenKeys = ['/admin'];
  }

  //subMenuActive
  const selectedKeys = [pathname];

  return (
    <Layout className="admin-layout">
      <Affix offsetTop={0}>
        <Sider className="sider" collapsible theme={'light'} breakpoint="lg" collapsedWidth='64px' onCollapse={()=>setCollapse(!collapse)} width={215}>
          <div className="sider-left">
            <div>
              {/* Logo */}
              {collapse === true ? <div className="logo-wrapper">
                <img style={{ cursor: 'pointer' }} src={logo} className="logo" alt="akaWMS" onClick={() => navigate('/admin')} />
              </div>
              : <div className="logo-wrapper">
                <img style={{ cursor: 'pointer' }} src={logoCollapse} className="logoCollapse" alt="akaWMS" onClick={() => navigate('/admin')} />
              </div>}
              <Menu
                className="menu-list"
                mode="inline"
                selectedKeys={selectedKeys}
                defaultOpenKeys={defaultOpenKeys}
                style={{
                  height: '88vh',
                  overflowY: 'scroll',
                  paddingBottom: 40,
                  overflowX: 'hidden'
                }}
              >
                <SubMenu key="/admin" icon={<img alt="" src={ConfigurationIcon} />} title={lang.admin}>
                  <Menu.Item
                    onClick={() => navigate('/admin/user')}
                    key="/admin/user">{lang.user}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/admin/warehouse')}
                    key="/admin/warehouse"
                  >{lang.warehouse}
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </div>
        </Sider>
      </Affix>
      <Layout className="site-layout">
        <Affix offsetTop={0} style={{ zIndex: 30 }}>
          <Header className="header">
            <div className="header-container">
              <div className="profile-container">
                <Dropdown overlay={menu} placement="bottomRight">
                  <div className="profile">
                    <Avatar
                      //src={}
                      icon={<UserOutlined />}
                      style={{ marginRight: '8px' }}
                    />
                    <div style={{ color: 'white', fontWeight: 600, marginRight: 10 }}>{user?.userInfo?.name}</div>
                    <DownOutlined
                      style={{ fontSize: '14px', color: 'white' }}
                      theme="outlined"
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Header>
        </Affix>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
