import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Form, Card, Row, Col, DatePicker, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { defaultPage, formatDate, formatDateFull } from 'utils/constants';

import './style.less';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { bomList } from './bom.service';
// import BomEditForm from './bom-edit.form';
// import Helper from 'utils/helpers';
// import Modal from 'antd/lib/modal/Modal';
// import Dragger from 'antd/lib/upload/Dragger';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const BomList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  // const [isEditModal, setIsEditModal] = useState(false);

  // const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  // const [file, setFile] = useState();
  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  // const [loadingBtn, setLoadingBtn] = useState(false);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const [uploadModal, setUploadModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    bomList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id, isAvailable: true })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    {
      title: lang.codeNpl,
      dataIndex: 'code',
      key: 'code',
      width: '5%',
    },
    {
      title: lang.caseUOM,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: lang.DVT,
      dataIndex: 'DVT',
      key: 'DVT',
      width: '8%',
    },
    // {
    //   title: lang.totalQuantity,
    //   dataIndex: 'stock',
    //   key: 'stock',
    //   width: '7%',
    // },
    {
      title: lang.producerRule,
      dataIndex: 'producerRule',
      key: 'producerRule',
      width: '10%',
    },
    {
      title: lang.cartonRule,
      dataIndex: 'cartonRule',
      key: 'cartonRule',
      width: '10%',
    },
    {
      title: lang.grossWeightPerCarton,
      dataIndex: 'grossWeightPerCarton',
      key: 'grossWeightPerCarton',
      width: '12%',
    },
    {
      title: lang.cartonPerPallet,
      dataIndex: 'cartonPerPallet',
      key: 'cartonPerPallet',
      width: '10%',
    },
    {
      title: lang.wastage,
      dataIndex: 'wastage',
      key: 'wastage',
      width: '8%',
    },
    {
      title: lang.totalQuantity,
      align: 'center',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: lang.stockAvailableForSale,
      align: 'center',
      dataIndex: 'stockAvailableForSale',
      key: 'stockAvailableForSale',
    },
  ];

  // const extraButton = [
  //   <Button
  //     key="btn-export"
  //     type="primary"
  //     loading={loadingBtn}
  //     onClick={async () => {
  //       try {
  //         setLoadingBtn(true);
  //         const response = await bomList({ warehouseId: currentWarehouse.id });
  //         Helper.dowloadXLSX(response?.data, 'Bom-production');
  //         setLoadingBtn(false);
  //       } catch (error) {
  //         setLoadingBtn(false);
  //         return false;
  //       }
  //     }}
  //   >
  //     {lang.exportCSV}
  //   </Button>,
  //   <Button
  //     key="btn-import"
  //     type="primary"
  //     onClick={() => setUploadModal(true)}
  //   >
  //     {lang.importCSV}
  //   </Button>,
  //   <Button
  //     key="btn-complete"
  //     type="primary"
  //     onClick={() => {
  //       setIsEditModal(true);
  //       setItem();
  //     }}
  //   >
  //     {lang.btnCreate}
  //     <PlusOutlined />
  //   </Button>,
  // ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: lang.home,
    },
    {
      path: '/',
      breadcrumbName: lang.inventory,
    },
    {
      path: '/bom',
      breadcrumbName: lang.inventoryMaterial,
    },
  ];

  return (
    <Layout className="product-category-listing">
      <PageHeader
        ghost={false}
        title={lang.inventoryMaterial}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content style={{ margin: ' 12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={20}>
              <Col col={16} style={{ flexDirection: 'row', display: 'flex' }}>
                <Form.Item label={lang.createDate} name="date">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
                <Form.Item name="BOMProductionName" style={{ marginLeft: 20 }}>
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col col={8}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>

        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.bomList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            pageSize: params.limit,
            current: params.page,
            showSizeChanger: true,
          }}
          className="custom-table-row"
        />
      </Layout.Content>
    </Layout>
  );
};

export default BomList;
