import React, { useEffect } from 'react';
import { Modal, Form, Col, Row, Button, InputNumber, Input, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
// import Helper from 'utils/helpers';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
// import { ProFormText } from '@ant-design/pro-form';

const ProducttionPackInternal = ({ item, onCallBack, setIsEditModal, isEditModal, typeProductionOrder }) => {
  // console.log(item)
  const [formParent] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    internalProductPacking: item?.productionProductPack?.map(product => ({
      name: `${product?.code3A} - ${product?.shortName} - ${product?.nameEN}`,
      totalQuantity: product?.totalQuantity,
    })),
    internalProductChildPacking: item?.productionProductLine?.map(productLine => ({
      productChilPackName: `${productLine?.name}`,
      locator: `${productLine?.locator?.locator} -  ${productLine?.locator?.locatorName}`,
      quantity: productLine?.movementQuantity,
    })),
    // internalProductChildPackingByLotExpireDate: item?.productionProductLine?.map(productLotExpireDate => ({
    //   lot: productLotExpireDate?.lot ? productLotExpireDate?.lot : '',
    //   expireDate: productLotExpireDate?.expireDate ? productLotExpireDate?.expireDate : '',
    // })), 
  };
  useEffect(() => {
    return () => {
      formParent.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={typeProductionOrder === 'PACKING' ? `${lang.create} Production` : `${lang.create} UNPACK`}
      onCancel={onCancel}
      afterClose={() => {
        formParent.resetFields();
        formParent.setFieldsValue({ internalProductPacking: undefined, internalProductChildPacking: undefined });
      }}
      width={1200}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={formParent}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={(values) => {
          onCallBack({...values, ...item});
          setIsEditModal(false);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col span={24}>
            <Form.List name="internalProductPacking" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[24, 24]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={24}>
                          <Form.Item
                            name={[name, 'name']}
                            wrapperCol={24}
                            label={typeProductionOrder === 'PACKING' ? lang.packingProduction : lang.unpackProduction}
                          >
                            <Input style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'totalQuantity']}
                            wrapperCol={24}
                            label={lang.totalQuantity}
                          >
                            <InputNumber style={{ width: '100%' }} min={0}  />
                          </Form.Item>
                        </Col>
                        <Col lg={{span: 24}}  xs={{ span: 24 }}>
                          <h3>{lang.giftCombo}</h3>
                        </Col> 
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List>

            <Form.List name="internalProductChildPacking" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[24, 24]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'productChilPackName']}
                            label={lang.giftName}
                            // initialValue={cartons}
                          >
                            <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'locator']}
                            wrapperCol={24}
                            label={'Locator'}
                            // initialValue={tins}
                          >
                            <InputNumber style={{ width: '100%' }} disabled/>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'quantity']}
                            wrapperCol={24}
                            label={lang.quantity}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.totalQuantityTin} disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List>

            {/* <Form.List name="internalProductChildPackingByLotExpireDate" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <>
                      <Row gutter={[24, 24]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'lot']}
                            wrapperCol={24}
                            label={'Lot'}
                          >
                            <Input style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'expireDate']}
                            wrapperCol={24}
                            label={'Hạn Sử Dụng'}
                          >
                            <Input style={{ width: '100%' }} disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List> */}

          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

ProducttionPackInternal.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
  typeProductionOrder: PropTypes.string,
};

export default ProducttionPackInternal;
