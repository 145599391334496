
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Form } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { productList } from './product.service';

import './style.less';
import { useSelector } from 'react-redux';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ProductList = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const { currentWarehouse } = useSelector((state) => state.auth);
  //
  const [form] = Form.useForm();
  const fetchProduct = (params, sortedInfo, currentWarehouse) => {
    setLoading(true);
    productList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id, isAvailableQauntity: true })
      .then(result => {
        const bigData = result.data;
        bigData.map(item => {
          let availableQuantity = 0;
          let availableQuantityCarton = 0;
          let availableQuantityCartonForSale = 0;
          let availableQuantityForSale = 0;
          let availableQuantityTin = 0;
          let availableQuantityTinForSale = 0;
          const dataPro = item?.masterProductsLocators;
          dataPro.map(proLocator => {
            availableQuantityCarton += proLocator?.totalQuantityCartton;
            availableQuantityTin += proLocator?.totalQuantityTin;
            availableQuantity += proLocator?.totalQuantity;
            availableQuantityCartonForSale += proLocator?.totalQuantityCarttonForSale;
            availableQuantityTinForSale += proLocator?.totalQuantityTinForSale;
            availableQuantityForSale += proLocator?.totalQuantityForSale;
            return true;
          });
          item.availableQuantity = availableQuantity;
          item.availableQuantityCarton = availableQuantityCarton;
          item.availableQuantityCartonForSale = availableQuantityCartonForSale;
          item.availableQuantityForSale = availableQuantityForSale;
          item.availableQuantityTin = availableQuantityTin;
          item.availableQuantityTinForSale = availableQuantityTinForSale;
          return true;
        },

        );
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
        // console.log(listProducts);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    fetchProduct(params, sortedInfo, currentWarehouse);
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    {
      title: lang.defaultCode,
      key: 'code',
      dataIndex: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: lang.productName,
      // dataIndex: 'name',
      key: 'name',
      render: record => record?.nameEN,
    },
    {
      title: lang.size,
      dataIndex: 'netWeightGramPerTins',
      key: 'netWeightGramPerTins',
    },
    {
      title: lang.totalQuantityCartonLabel,
      key: 'totalQuantityCartton',
      dataIndex: 'availableQuantityCarton',
      // render: rec => rec?.products?.reduce((prev,curr)=>prev+ curr.quantityCartton,0),
    },
    {
      title: lang.totalQuantityTinOdd,
      key: 'totalQuantityTin',
      dataIndex: 'availableQuantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'availableQuantity',
    },
    {
      title: lang.totalAvailableCartonQuantity,
      dataIndex: 'availableQuantityCartonForSale',
      key: 'availableQuantityCartonForSale',
    },
    {
      title: lang.totalUsableSeparatedTinQuantity,
      dataIndex: 'availableQuantityTinForSale',
      key: 'availableQuantityTinForSale',
    },
    {
      title: lang.totalAvailableTinQuantity,
      dataIndex: 'availableQuantityForSale',
      key: 'availableQuantityForSale',
    },
    {
      title: lang.totalQuantityCartonsAwait,
      key: 'availableQuantityAwait',
      render: (record) => {
        return record?.availableQuantityCarton - record?.availableQuantityCartonForSale >= 0 ? record?.availableQuantityCarton - record?.availableQuantityCartonForSale : 0;
      },
    },
    {
      title: lang.totalQuantityTinsAwait,
      key: 'availableQuantityAwait',
      render: (record) => {
        return record?.availableQuantity - record?.availableQuantityForSale >= 0 ? record?.availableQuantity - record?.availableQuantityForSale : 0;
      },
    },
    {
      title: lang.totalQuantityOddTinsAwait,
      key: 'quantityTinOddAwait',
      render: (record) => {
        return record?.availableQuantityTin - record?.availableQuantityTinForSale >= 0 ? record?.availableQuantityTin - record?.availableQuantityTinForSale : 0;
      },
    },
    {
      title: lang.importedDate,
      dataIndex: 'createDate',
      sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'createDate',
      render: value => moment(value, formatDateFull).format(formatDate),
    },
    // {
    //   title: lang.status,
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (record) => {
    //     return (
    //       <Tag>{lang[record]}</Tag>
    //     );
    //   },
    // },
  ];

  const columnsExpand = [
    {
      title: lang.defaultCode,
      key: 'code',
      dataIndex: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: ['shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      // dataIndex: 'name',
      key: 'name',
      render: record => record?.nameEN,
    },
    {
      title: lang.locator,
      key: 'locators',
      dataIndex: 'locators',
      render: val => `${val?.locator} - ${val?.locatorName}`,
    },
    {
      title: lang.lot,
      dataIndex: 'lot',
      key: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: '10%',
      render: val => moment(val).format(formatDate),
    },
    {
      title: lang.salesChannel,
      dataIndex: ['saleChannel', 'channelName'],
      key: 'saleChannel',
    },
    {
      title: lang.shelfLife,
      key: 'shelfLife',
      render: rec => parseFloat(moment(rec?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (rec?.shelfLifeMonths * 30)).toFixed(2),
    },

    {
      title: lang.totalQuantityCartonLabel,
      key: 'totalQuantityCartton',
      dataIndex: 'totalQuantityCartton',
    },
    {
      title: lang.totalQuantityTinOdd,
      key: 'totalQuantityTin',
      dataIndex: 'totalQuantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
    },
    {
      title: lang.totalAvailableCartonQuantity,
      dataIndex: 'totalQuantityCarttonForSale',
      key: 'totalQuantityCarttonForSale',
    },
    {
      title: lang.totalUsableSeparatedTinQuantity,
      dataIndex: 'totalQuantityTinForSale',
      key: 'totalQuantityTinForSale',
    },
    {
      title: lang.totalAvailableTinQuantity,
      dataIndex: 'totalQuantityForSale',
      key: 'totalQuantityForSale',
      render: (val, rec) => rec?.locators?.locatorName.indexOf('Transit') > -1 ? 0 : val,
    },
  ];

  const expandedRowRender = (rec) => {
    const { code3A, nameEN, shortName, shelfLifeMonths } = rec;
    // const proRemp = products?.reduce((prev,curr)=>{
    //   const finded = prev?.find(pro=>pro?.lot === curr?.lot && pro?.expireDate === curr?.expireDate && pro?.locators?.locator === curr?.locator?.locator);
    //   if(finded){
    //     finded.totalQuantityCartton += curr.quantityCartton;
    //     finded.totalQuantityTin += curr.quantityTin;
    //     finded.totalQuantity += curr.totalQuantity;
    //     return prev;
    //   }
    //   return [...prev, {code3A, nameEN, UOM_VN,totalQuantityCartton: curr.quantityCartton, totalQuantityTin: curr.quantityTin, totalQuantity: curr.totalQuantity, locators: curr?.locator, lot: curr.lot, expireDate: curr.expireDate}]
    // },[])
    const dataSource = rec?.masterProductsLocators?.map(locator => ({ ...locator, code3A, nameEN, shortName, shelfLifeMonths }));
    return <TableCustom columns={columnsExpand} dataSource={[...dataSource]} pagination={false} />;
  };

  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: lang.home,
    },
    {
      path: '/dashboard',
      breadcrumbName: lang.inventory,
  },
    {
      path: '/inventory/inventorymanagement',
      breadcrumbName: lang.inventoryManagement,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.product}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} layout='horizontal' className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    format={formatDate}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="masterProductName">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 1600 }}
          expandable={{
            expandedRowRender,
          }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ProductList;
