import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
// import { locatorList } from '../../configuration/locator/locator.service';
import { bomGiftList } from 'pages/dashboard/inventory/giftinventory/inventory-gift.service';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, onCancel, onCallback, items, type }) => {
  // console.log(item)
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [dataLocatorFinded, setDataLocatorFinded] = useState([]);
  const [productFind, setProductFind] = useState();
  // const [locators, setLocators] = useState();
  // const [changeCarton, setChangeCarton] = useState(0);
  const [maxInput, setmaxInput] = useState(0);
  const inputRef = useRef(null)

  useEffect(() => {
    setLoading(true);
    bomGiftList({ page: 1, litmit: 10, isAvailable: true, warehouseId: currentWarehouse?.id })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
    // locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true }).then(val => {
    //   setLocators(val.data);
    //   setLoading(false);
    // }).catch(e => setLoading(false));
  }, [currentWarehouse, item]);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => { 
        try {
          const quantity = values?.quantity;
          if (quantity === 0) {
            throw new Error(lang.quantityCannotBeZero)
          } else if (quantity === undefined) {
            throw new Error(lang.pleaseEnterQuantity)
          } else {
            values.maxInput = item ? item?.maxInput : maxInput
            values.locatorCode = item ? item?.masterProductsGiftLocators?.find(itemLocator => itemLocator?.id === values?.locator).locator?.locator : 
            dataLocatorFinded?.find(itemLoca => itemLoca?.id === values?.locator)?.locator?.locator
            onCallback({
              ...item,
              ...productFind,
              ...values,
              type,
              indx: item?.indx || items?.length + 1,
              productOrderId: item?.id
            });
          } 
        } catch (error) {
          message.error(error.message);
        } 
      }}
      initialValues={{
        productCode: item ? item?.id : undefined,
        quantity: item?.quantity ? item?.quantity : 0,
        locator: item?.locator ? item?.locator : undefined
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              // disabled={item}
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                const listProductLocator = itemFind?.masterProductsGiftLocators;
                if (item) {
                  item.masterProductsGiftLocators = listProductLocator
                  item.maxInput = listProductLocator.reduce((prev, curr) => prev + curr?.quantityForSale, 0);
                }
                setDataLocatorFinded(listProductLocator)
                setmaxInput(listProductLocator.reduce((prev, curr) => prev + curr?.quantityForSale, 0));
                setProductFind(itemFind)
                form.setFieldsValue({locator: undefined})
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              } >
              {products?.map(item => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.code} - {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
          label="Locator" 
          name="locator"
          rules={[
            {
              required: true,
              message: lang.locatorIsRequired
            }
          ]}
          >
            <Select
              // disabled={item}
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = item ? item?.masterProductsGiftLocators.find(product => product.id === value) : dataLocatorFinded.find(product => product.id === value);
                if (item) {
                  item.maxInput = itemFind?.quantityForSale
                }
                setmaxInput(itemFind?.quantityForSale);
                inputRef.current.focus();
                form.setFieldsValue({quantity: undefined})
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }} 
              >
              {
              item ? item?.masterProductsGiftLocators?.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.locator.locator} - {item.locator.locatorName}
                </Option>
              )) :
              dataLocatorFinded?.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.locator.locator} - {item.locator.locatorName}
                </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>

        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Form.Item 
          name="quantity"
          label={`${lang.quantity} (${lang.maxQuantity}: ${item ? item?.maxInput : maxInput})` } >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={item ? item?.maxInput : maxInput}
            defaultValue={0}
            ref={inputRef}
            // onChange={(value) => setChangeCarton(value)}
            // value  = {changeCarton}
            placeholder={lang.placeholderQuantity}
          />
        </Form.Item>
      </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  items: PropTypes.array,
  type: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
