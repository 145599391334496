import React, { useEffect } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { ProFormSelect } from '@ant-design/pro-form';
import './style.less';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const ReceiptProductEditForm = ({ item, onCancel, onCallback, warehouseId, onPrint, locatorsList, onSplit, isEditModal }) => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      expectedQuantityCarton: item ? item.expectedQuantityCarton : undefined,
      expectedQuantityTin: item ? item.expectedQuantityTin : undefined,
      quantityCartton: item ? item.quantityCartton : undefined,
      quantityTin: item ? item.quantityTin : undefined,
      locatorId: item?.locator ? item.locator.id : undefined,
      expireDate: item?.expireDate ? moment(item.expireDate, formatDateFull) : undefined,
      note: item ? item.note : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        onCallback({
          ...values,
          status: item.status,
          id: item.id,
        });
      }}
    // initialValues={{
    //   expectedQuantityCarton: item ? item?.expectedQuantityCarton : undefined,
    //   expectedQuantityTin: item ? item?.expectedQuantityTin : undefined,
    //   quantityCartton: item ? item.quantityCartton : undefined,
    //   quantityTin: item ? item.quantityTin : undefined,
    //   locatorId: item ? item?.locator?.id : undefined,
    //   expireDate: item?.expireDate ? moment(item.expireDate, formatDateFull) : undefined,
    //   note: item ? item?.note : undefined,
    // }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.defaultCode}>
            <Input
              disabled style={{ width: '100%' }}
              value={item?.masterProduct?.code3A}
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.shortname}>
            <Input
              disabled style={{ width: '100%' }}
              value={item?.masterProduct?.shortName}
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={item?.masterProduct?.nameEN} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.productCode}>
            <Input disabled value={item?.code} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="expectedQuantityCarton"
            label={lang.expectedQuantityCarton}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="expectedQuantityTin"
            label={lang.expectedQuantityTin}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='quantityCartton' label={lang.quantityCarttonReceived}>
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='quantityTin' label={lang.quantityTinReceived}>
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name="locatorId" label={lang.locator}
            disabled
            placeholder={lang.locator}
            options={locatorsList.filter(item => item?.area?.warehouseID !== 'ITT').map(locator => {
              return {
                value: locator.id,
                label: `${locator.locatorName} - ${locator.locator}`,
              };
            })}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            }
          />
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="expireDate" label={lang.expireDate}>
            <DatePicker
              style={{ width: '100%' }}
              format={formatDate}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item name="note" label={lang.note}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            <Button key='split' type="primary" onClick={() => onSplit({ id: item?.id, ...form?.getFieldsValue() })} style={{ fontWeight: 'bold' }}>
              {lang.split}
            </Button>
            <Button key='print' type="primary" onClick={onPrint} style={{ fontWeight: 'bold' }}>
              {lang.print}
            </Button>
            <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
              {lang.btnSave}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  onPrint: PropTypes.func,
  locatorsList: PropTypes.array,
  onSplit: PropTypes.func,
  form: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default ReceiptProductEditForm;
