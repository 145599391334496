import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, message as Alert, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import Helper from 'utils/helpers';
import { locatorCreate, locatorUpdate } from './locator.service';
import { areaList } from '../area/area.service';
import { ProFormSelect } from '@ant-design/pro-form';
import { warehouseList } from 'pages/admin/warehouse/warehouse.service';
import { MODE } from 'utils/constants';

const { Option } = Select;

const AreaEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [listArea, setListArea] = useState([]);

  const initValues = {
    locator: item ? item?.locator : undefined,
    locatorName: item ? item?.locatorName : undefined,
    areaId: item ? item?.area?.id : undefined,
    warehouseId: item ? item.warehouseId : undefined,
    type: item?.type ? item.type : undefined,
    warehouseToId: item?.warehouseToId ? item.warehouseToId : undefined,
    status: item ? item?.status : undefined,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  useEffect(() => {
    areaList({ warehouseId: 1 }).then(result => {
      setListArea(result?.data);
    });
  }, [currentWarehouse]);

  const onCancel = () => {
    const valueForm = form.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setIsEditModal(false);
      return true;
    }
    Helper.conFirm(setIsEditModal);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.locatorUpdate : lang.locatorCreate}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={756}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          if (!values?.warehouseId) {
            values.warehouseId = currentWarehouse?.id;
          }
          if (!item) { // Create
            locatorCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            locatorUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 8 }} xs={{ span: 12 }}>
            <Form.Item
              name="locator"
              label={lang.locatorCode}
            rules={[
              {
                required: true,
                message: `${lang.pleaseInput} ${lang.locatorCode}`,
              },
              {
                max: 100,
                message: lang.messageLong,
              },
            ]}
            >
              <Input placeholder={lang.locatorCode} disabled={!!item} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 12 }}>
            <Form.Item
              name="locatorName"
              label={lang.locatorName}
            rules={[
              {
                required: true,
                message: `${lang.pleaseInput} ${lang.locatorName}`,
              },
            ]}
            >
              <Input placeholder={lang.locatorName} disabled={!!item} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 12 }}>
            <Form.Item
              name="areaId"
              label={lang.area}
            rules={[
              {
                required: true,
                message: `${lang.pleaseInput} ${lang.area}`,
              },
            ]}
            >
              <Select placeholder={lang.area} disabled={!!item}>
                {listArea?.map(area => <Option value={area.id} key={area.id}>{area.warehouseName}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 12 }}>
            <ProFormSelect
              name='warehouseId'
              disabled={!!item}
              label={lang.warehouse}
              request={async () => {
                const response = await warehouseList();
                const warehouseDisplay = response?.data?.filter((value) => {
                  return value?.id > 1;
                });
                return warehouseDisplay?.map(warehouse => ({
                  value: warehouse.id,
                  label: warehouse.name,
                  key: warehouse.id,
                }));
              }}
              placeholder={lang.warehouse}
              rules={[
                {
                  required: true,
                  message: lang.requiredWarehouse,
                },
              ]}
            />
          </Col>
          <Col span={8}>
            <Form.Item name="type" label={lang.type}>
              <Select placeholder={lang.type}>
                <Option value="HOLD">{lang.HOLD}</Option>
                <Option value="AV">{lang.AV}</Option>
              </Select>
            </Form.Item>
          </Col>
          {item?.locatorName?.indexOf('Transit') > -1 && <Col lg={{ span: 8 }} xs={{ span: 12 }}>
            <ProFormSelect
              name='warehouseToId'
              label={`${lang.warehouse} (${lang.warehouseTo})`}
              request={async () => {
                const response = await warehouseList();
                return response?.data?.filter(warehouse => warehouse.id !== currentWarehouse?.id)?.map(warehouse => ({
                  value: warehouse.id,
                  label: warehouse.name,
                  key: warehouse.id,
                }));
              }}
              placeholder={lang.warehouse}
              rules={[
                {
                  required: true,
                  message: lang.requiredWarehouse,
                },
              ]}
            />
          </Col>}
          {item && <Col span={8}>
            <Form.Item name="status" label={lang.status}>
              <Select placeholder={lang.status} disabled>
                <Option value="ENABLE">{lang.ENABLE}</Option>
                <Option value="DISABLE">{lang.DISABLE}</Option>
              </Select>
            </Form.Item>
          </Col>}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

AreaEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default AreaEditForm;
