import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Tag } from 'antd';
import './style.less';
import { createPalletVasProduct, deleteProductsPallet, productionOrderCreate, productionOrderUnpack, productOrderUpdateVasBaseProduct, saleorderCreate, saleorderDetail } from './production-order.service';
import { formatDate, formatDateFull, STATUS_PRODUCTION_ORDER } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import { useSelector } from 'react-redux';
import { EditOutlined, CheckOutlined, TableOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'; //DeleteOutlined, ExclamationCircleOutlined
import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';
import ProductionProductEditForm from './production-detail-product-edit.form';
import ProductDetailEditForm from './production-order-create-product-edit.form';
import ProducttionInternal from './producttion-order-create-internal.edit';
import _get from 'lodash/get';
import { productUpdate, productUpdateStatus} from 'pages/dashboard/inventory/product-management/product-management.service';
import { productPicking } from 'pages/dashboard/outbound/packaging/packaging.service';
import VasProductCreatePallet from './vasProduct-createPallet';
import { productUpdateLocation, productSplit } from '../product.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import ReceiptProductPrint from '../receipt/receipt-products-print';
import Helper from 'utils/helpers';
import { productUpdateLocator } from 'pages/dashboard/inventory/product-management/product-management.service';
import { bomList } from 'pages/dashboard/configuration/masster-products-bom-gift/master-products-bom-gift.service';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';
import ProducttionPackInternal from './producttion-order-pack-create-internal.edit';
import { productList } from 'pages/dashboard/configuration/product/product.service';
import { getAllBomGift } from 'pages/dashboard/inventory/physical-inventory-gift/physical-inventory-gift.service';


const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};
const ProductTionOrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const isVasManger = _get(user, 'roles', '').includes('vasManager');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const [modalCreateInternal, setModalCreateInternal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [productLock, setProductLock] = useState([]);
  const [isEditModalVasProduct, setIsEditModalVasProduct] = useState(false);
  const [listProductCreatePallet, setListProductCreatePallet] = useState([]);
  const [productVas, setProductVas] = useState();
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [isclose, setIsClose] = useState(false);
  const [comboData, setComboData] = useState([]);
  const [locatorsList, setLocatorList] = useState([]);
  const [dataCreateProduction, setDataCreateProduction] = useState([]);
  const [modalCreateProduction, setModalCreateProduction] = useState(false);
  const [itemProductPallet, setItemProductPallet] = useState();
  const [isEditModalProductPallet, setIsEditModalProductPallet] = useState(false);
  const [proPrinQRCode, setProPrinQRCode] = useState([]);
  const [listMasterProduct, setListMasterProduct] = useState([]);
  const [isPrintModalOneQRCode, setIsPrintModalOneQRCode] = useState(false);
  const [listProductDisabled, setListProductDisabled] = useState([]);
  // const [proCreateInternal, setProCreateInternal] = useState([]);
  const [sortedInfo] = useState(defaultSort);

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: setSelectedRowKeys, khi tạo nội bộ sẽ lấy sp hàng Base
  // };

  useEffect(() => {
    setLoading(true);
    saleorderDetail(parseInt(id))
      .then(async result => {
        setSaleorder(result);
        setLoading(false)
        if (['IMPORTLABEL', 'PASTCARD'].indexOf(result?.typeProductionOrder) > -1) {
          return result.group && productPicking({ warehouseId: result.warehouseId, group: result.group })
        } else {
          result.group && productPicking({ warehouseId: result.warehouseId, group: result.group }) 
        }
      })
    .then(val => { 
      setProductLock(val?.data); 
      const groupOfOrder = val?.data[0].group;
      // setGroupOrder(groupOfOrder)
      productPicking({ warehouseId: currentWarehouse.id})
      .then(rs => {
        // B1: Lấy ra những product theo OrderId
        const listProdutByOrderId = rs?.data?.filter(item => (item?.orderId === parseInt(id)));
        // B2: Lấy ra những product có group !== group của Order
        const findProductByGroup = listProdutByOrderId?.filter(item => (item?.orderId === parseInt(id) && ['TEMPORARY', 'STORED', 'VAS', 'PICKING', 'NEW'].indexOf(item?.status) > -1) && (item?.group !== groupOfOrder));
        const findProductByGroupdisabled = listProdutByOrderId?.filter(item => (item?.orderId === parseInt(id) && ['TEMPORARY', 'STORED', 'VAS', 'PICKING', 'NEW'].indexOf(item?.status) > -1) && (item?.group !== null) && (item?.group !== groupOfOrder));
        const arrayIdDisabled = findProductByGroupdisabled.map(itemId => itemId?.id)
        // console.log(findProductByGroup)
        // console.log(findProductByGroupdisabled)
        setListProductCreatePallet(findProductByGroup);
        setListProductDisabled(arrayIdDisabled);
        setLoading(false)
        setCheckLoading(false)
      })
      .catch((e) => setLoading(false));
      setLoading(false); 
    
    })
    .catch((e) => {
      setLoading(false)
    });
  }, [id, currentWarehouse.id, checkLoading]);
// console.log(listProductDisabled)
  useEffect(() => {
    setLoading(true);
    bomList({warehouseId: currentWarehouse?.id})
    .then(result => {
      setComboData(result?.data);
      setLoading(false);
    })
    .catch((e) => setLoading(false));

    locatorList({ warehouseId: currentWarehouse?.id }).then(value => setLocatorList(value?.data));

    productList({ ...sortedInfo, warehouseId: currentWarehouse?.id })
    .then(result => {
      setListMasterProduct([...result.data]);
      setLoading(false);
    })
    .catch((e) => setLoading(false));
    
  }, [id, currentWarehouse.id, sortedInfo]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => val && `${val?.locatorName} - ${val?.locator}`,
      hideInTable: saleorder?.typeProductionOrder === 'PACKING' || saleorder?.typeProductionOrder === 'UNPACK',
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.shelfLifeMonths,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: 'Shelf life',
      key: 'Shelf life',
      hideInTable: saleorder?.typeProductionOrder === 'PACKING' || saleorder?.typeProductionOrder === 'UNPACK',
      render: (record) => {
        const shelfLife = moment(record?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (record?.masterProduct?.shelfLifeMonths * 30);
        return shelfLife.toFixed(2);
      },
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => rec?.quantityCartton || rec?.quantityInCarton || 0,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => rec?.quantityTin,
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      render: rec => (rec?.quantityInCarton * rec?.masterProduct?.tinPerCarton) + rec?.quantityTin,
    },
    {
      title: `${lang.quantityHasCreatedProduction} (${lang.tin})`,
      key: 'pickingPackQuantity',
      dataIndex: 'pickingPackQuantity',
      // render: rec => rec?.quantityTin,
      hideInTable: ['IMPORTLABLE', 'PASTCARD', 'UNPACK'].indexOf( saleorder?.typeProductionOrder) > -1,
    },
    {
      title: `${lang.quantityHasCreatedUnpack} (${lang.tin})`,
      key: 'pickingPackQuantity',
      dataIndex: 'pickingPackQuantity',
      // render: rec => rec?.quantityTin,
      hideInTable: ['IMPORTLABLE', 'PASTCARD', 'PACKING'].indexOf( saleorder?.typeProductionOrder) > -1,
    },
    {
      title: lang.quantityCarttonTaken,
      key: 'pickingQuantityCartton',
      hideInTable: saleorder?.typeProductionOrder === 'PACKING' || saleorder?.typeProductionOrder === 'UNPACK',
      render: rec => rec?.pickingQuantityCartton,
    },
    {
      title: lang.quantityTaken,
      key: 'pickingQuantityTin',
      render: rec => rec?.pickingQuantityTin,
      hideInTable: saleorder?.typeProductionOrder === 'PACKING' || saleorder?.typeProductionOrder === 'UNPACK',
    },

    // Action dùng để Cập nhật lại SP khi nó ở status là NEW và chỉ hiển thị khi nó là VAS
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: (['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) === -1),
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            disabled={saleorder?.status === 'CONFIRM'}
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              setItem(record);
            }}
          />
          {/* Button VAS để chia pllet tạo QR code mới  */}
          <Button
            type="link"
            size="small"
            disabled={!isVasManger || (record?.total === listProductCreatePallet.filter(itemCode3A => itemCode3A?.masterProduct?.code3A === record?.code3A)?.reduce((prev, curr) => prev + curr?.totalQuantity, 0))}
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setIsEditModalVasProduct(true);
              setProductVas(record);
            }}
          />
        </div>
      ),
    },


    // Action VAS để chia pllet tạo QR code mới <PlusCircleOutlined />
    // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '8%',
    //   fixed: 'right',
    //   hideInTable: (!(saleorder?.status === 'PICKING') || isPicker || saleorder?.typeProductionOrder === 'PACKING'), //(['CONFIRM', 'REJECT'].indexOf(saleorder?.status) > -1 && !isVas) || 
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           setIsEditModalVasProduct(true);
    //           setProductVas(record);
    //         }}
    //       />
    //     </div>
    //   ),
    // },

    // Action PACKING, UNPACK
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: (['PACKING'].indexOf(saleorder?.typeProductionOrder) === -1 || isPicker || !isVasManger), //(['CONFIRM', 'REJECT'].indexOf(saleorder?.status) > -1 && !isVas) || 
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            disabled={!isVasManger || (record?.total === listProductCreatePallet.filter(itemCode3A => itemCode3A?.masterProduct?.code3A === record?.code3A)?.reduce((prev, curr) => prev + curr?.totalQuantity, 0))}
            onClick={() => {
              setIsEditModalVasProduct(true);
              // console.log(record)
              record.typeProductionOrder = 'PACKING'
              setProductVas(record);
            }}
          />
        </div>
      ),
    },
  ];
    
  const columnsExpandProductLock = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      // dataIndex: 'quantityCartton',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityCartonSubmitArr?.[finded];
        return record?.quantityCartton
      }
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityTinSubmitArr?.[finded];
        return record?.quantityTin
      }
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      // dataIndex: 'totalQuantity',
      render: rec => {
        const finded = rec?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return rec?.quantityCartonSubmitArr?.[finded] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[finded];
        return rec?.totalQuantity;
      },
    },
    {
      title: `${lang.takeQuantity} (${lang.carton})`,
      key: 'quantityCarttonTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.takeQuantity} (${lang.tin})`,
      key: 'quantityTinTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.sum} ${lang.takeQuantity} (${lang.tin})`,
      key: 'totalQuantityTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: 'User Picked',
      key: 'userPicked',
      dataIndex: 'userName',
    },
  ];

  const columsVasProPallet = [
    {
      title: lang.defaultCode,
      key: 'barcode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.masterProduct?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      render: (value) => value?.nameEN,
      width: '15%',
    },
    {
      title: 'QR code',
      key: 'code',
      // dataIndex: 'masterProduct',
      render: (value) => value?.code,
    },
    {
      title: lang.note,
      key: 'node',
      dataIndex: 'note',
      // render: (value) => value?.code,
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.shelfLifeMonths,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : '',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      render: (value) => value?.productCategory?.name,
    },
    {
      title: lang.DVT,
      key: 'DVT',
      dataIndex: 'masterProduct',
      render: (value) => value?.UOM_VN,
    },
    {
      title: lang.locator,
      key: 'locator',
      // dataIndex: 'locator',
      render: rec =>  rec?.locatorFrom && rec?.inboundkind === 'NEW' ? `${rec?.locatorFrom?.locatorName} - ${rec?.locatorFrom?.locator}` : `${rec?.locator?.locatorName} - ${rec?.locator?.locator}`,
    },
    {
      title: lang.block,
      key: 'block',
      render: record => {
        if (record?.status === 'NEW') {
          if ( record?.block) {
            return   record?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.block) {
            return   record?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.block?.name
        }

      }
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.rack?.shelf) {
            return   record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.rack?.shelf?.name
        }
        
      }
    },
    {
      title: lang.rack,
      key: 'rack',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.code + ` (${lang.suggest})`
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if (record?.rack) {
            return  record?.rack?.code + ` (${lang.suggest})`
          } else {
            return ''
          }
        } else {
          return  record?.rack?.code
        }
        
      }
    },
    {
      title: 'Status QR Code',
      key: 'statusQA',
      dataIndex: 'status',
      render: (value) => {
        if (value === 'NEW') {
          return lang.new
        } else if (value === 'TEMPORARY'){
          return lang.temporaryInbound
        } else if (value === 'VAS') {
          return lang.VAS
        } else if (value === 'STORED') {
          return lang.inStock
        }  else if (value === 'PICKING') {
          return lang.PICKING
        }
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            disabled={(isStocker || isPicker)}
            icon={<EditOutlined />}
            onClick={() => {
              setItemProductPallet(record);
              setIsEditModalProductPallet(true);
            }}
          />
        </div>
      ),
    },
    
  ];
                  
  const expandedRowRender = (rec) => {
    // console.log(rec)
    if ((['PICKING', 'CONFIRM'].indexOf(saleorder?.status) > -1 && saleorder?.typeProductionOrder === 'IMPORTLABEL') || (['PICKING', 'CONFIRM'].indexOf(saleorder?.status) > -1 && saleorder?.typeProductionOrder === 'PASTCARD')) {
      const proRemp = productLock?.filter(item => item?.masterProduct?.code3A === rec?.code3A );
      return <TableCustom columns={columnsExpandProductLock} dataSource={proRemp} pagination={false} scroll={{ x: 2600 }} />;
    } else if (saleorder?.typeProductionOrder === 'PACKING' || saleorder?.typeProductionOrder === 'UNPACK') {
      const proRemp = comboData?.find(item => item?.code3A === rec?.code3A );
      // console.log(rec)
      let listComboData = [];
      proRemp.comboData.bomGift.forEach((itemGift, index) => {
        itemGift.movementQuantity = rec?.bundledGift[index]?.quantity;
        // itemGift.quantityTin = rec?.quantity;
        return listComboData.push(itemGift);
      });
      proRemp.comboData.masterProduct.forEach((itemMaster, indexProMaster) => {
        itemMaster.movementQuantity = rec?.bundledProduct[indexProMaster]?.quantity;
        itemMaster.lot = rec?.bundledProduct[indexProMaster]?.lot;
        itemMaster.expireDate = rec?.bundledProduct[indexProMaster]?.expireDate;
        itemMaster.quantityInCarton = 0;
        itemMaster.quantityTin = rec?.bundledProduct[indexProMaster]?.quantity;
        itemMaster.totalQuantity = rec?.bundledProduct[indexProMaster]?.quantity;
        return listComboData.push(itemMaster)
      });
      
      const columnsPack = [
        {
          title: lang.productName,
          key: 'name',
          render: val => val?.nameEN || val?.name,
        },
        {
          title: ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1 ? lang.bomCode : lang.defaultCode,
          key: 'code',
          render: record => record?.nameEN ? record.code3A : record?.code,
        },
        {
          title: lang.lot,
          dataIndex: 'lot',
          key: 'lot',
        },
        {
          title: lang.expireDate,
          dataIndex: 'expireDate',
          key: 'expireDate',
        },
        {
          title: lang.quantity,
          dataIndex: 'movementQuantity',
          key: 'quantity',
        },
        {
          title: lang.action,
          align: 'center',
          width: '8%',
          fixed: 'right',
          // hideInTable: (['PACKING'].indexOf(saleorder?.typeProductionOrder) === -1 || isPicker || !isVasManger),
          render: (record) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button
                type="link"
                size="small"
                icon={<EditOutlined />}
                disabled={!isVasManger || saleorder?.typeProductionOrder === 'PACKING' || (record?.totalQuantity === listProductCreatePallet.filter(itemCode3A => itemCode3A?.masterProduct?.code3A === record?.code3A)?.reduce((prev, curr) => prev + curr?.totalQuantity, 0))}
                onClick={() => {
                  // console.log(record)
                  setIsEditModalVasProduct(true);
                  const masterProductFinded = listMasterProduct.find(item => item?.code3A === record?.code3A);
                  record.masterProduct = masterProductFinded
                  record.lot = record?.lot
                  record.expireDate = record?.expireDate
                  record.quantityInCartton = record?.quantityInCartton
                  record.quantityTin = record?.quantityTin
                  record.totalQuantity = record?.totalQuantity
                  record.locator = saleorder?.locator
                  setProductVas(record);
                }}
              />
            </div>
          ),
        },
      ];
      return <TableCustom columns={columnsPack} dataSource={listComboData} pagination={false} />;
    }
  };
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      onClick={async () => {
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,

  //IDM (ban đầu) kiểm tra và tiến hành tạo đơn xuất nội bộ khi đóng VAS - PACK và VAS - UNPACK
    ( ['PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && isIdm && saleorder?.status === 'NEW') && <Button
      key="btn-create-interal"
      type="primary"
      disabled={saleorder?.status === 'COMPLETED_INTERNAL'}
      onClick={async () => {
        Modal.confirm({
          title: `${lang.doYouWant} ${lang.create} ${lang.EXPORT_INTERNAL}?`,
          async onOk() {
            try {
              const formData = { ...saleorder };
              formData.orderType = 'EXPORT_INTERNAL';
              let payload = '';
              // PACKING: khi tạo nội bộ sẽ lấy sp hàng Base
              if (saleorder?.typeProductionOrder === 'PACKING') {
                saleorder.productOrders.forEach(item => {
                  const dataUsing = item?.bundledProduct?.map(result => {
                    const dataLocatorFind = locatorsList.find(item => item?.id === result?.masterProductLocatorId)?.locator
                    return {
                      quantityInCarton: 0,
                      quantityTin: result.quantity,
                      code3A: result?.code3APack,
                      locatorCode: dataLocatorFind,
                      locatorCodeTo: item?.locatorCodeTo,
                      total: result?.quantity,
                      warehouseId: result?.warehouseId,
                      lot: result?.lot,
                      expireDate: result?.expireDate ? new Date(result?.expireDate) : '',
                      isPackagedSku: item?.masterProduct?.isPackagedSku
                    };
                  })
                  payload = dataUsing;
                  return true
                });
              } 
              
              // UNPACK:  khi tạo nội bộ sẽ lấy sp thằng Pack
              else {
                payload = saleorder.productOrders?.map(item => {
                  return {
                    quantityInCarton: item?.quantityInCarton,
                    quantityTin: item.quantityTin,
                    code3A: item?.code3A,
                    locatorCode: item?.locatorCode,
                    locatorCodeTo: item?.locatorCodeTo,
                    total: item?.quantityInCarton * item?.tinPerCarton + item?.quantity,
                    warehouseId: item?.warehouseId,
                    lot: item?.lot,
                    expireDate: item?.expireDate ? new Date(item?.expireDate) : '',
                    isPackagedSku: item?.masterProduct?.isPackagedSku
                  };
                });
              }
              if (formData.channel) {
                formData.subChannel = formData.channel?.subChannel;
                formData.channel = formData.channel.channel;
              }
              formData.internalTranferCode = formData.code;
              formData.locatorCode = formData?.locatorCode
              formData.locator =  formData?.locatorCode
              delete formData.locatorToId;
              delete formData.id;
              delete formData.code;
              delete formData.productOrders;
              // console.log(payload)
              // console.log(formData)
              await orderUpdateStatus({ ids: [saleorder?.id], status: 'COMPLETED_INTERNAL' });
              await saleorderCreate({ ...formData, productOrders: payload, warehouseId: currentWarehouse?.id});
              setModalCreateInternal(false);
              message.success(`${lang.create} ${lang.EXPORT_INTERNAL} ${lang.successfully}`);
              navigate(-1)
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
          onCancel() { },
        });
      }}
    >
      {`${lang.create} ${lang.EXPORT_INTERNAL}`}
    </Button>,

     // Thủ kho vào xem hàng hóa và xác nhận chuyển sang Status LOCK cho PICKER đi lấy hàng chuyển đến khu VAS để dán nhãn hoặc thẻ cào.
    isStocker && ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1 && <Button
    key="btn-lock"
    type="primary"
    onClick={async () => {
      Modal.confirm({
        title: `${lang.doYouWant} ${lang.confirm}?`,
        async onOk() {
          try {
            await orderUpdateStatus({ ids: [saleorder?.id], status: 'LOCK' });
            message.success(lang.updateSuccessfully);
            navigate(-1);
            return true;
          } catch (error) {
            message.error(error.message);
            return false;
          }
        },
        onCancel() { },
      });
    }}
  >
    {`${lang.confirm}`}
  </Button>,

    // VasManager vào xem hàng hóa và xác nhận sẽ trừ tồn kho material cho VAS For Base Product.
    (isVasManger && listProductCreatePallet?.length > 0 && ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1) && <Button
      key="btn-complete"
      type="primary"
      disabled={saleorder?.status === 'CONFIRM'}
      onClick={async () => {
        Modal.confirm({
          title: `${lang.doYouWant} ${lang.confirm}?`,
          async onOk() {
            try {
              await orderUpdateStatus({ ids: [saleorder?.id], status: 'CONFIRM' });
              message.success(lang.updateSuccessfully);
              navigate(-1);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
          onCancel() { },
        });
      }}
    >
      {`${lang.confirm} VAS`}
    </Button>,

    // VasManager vào xem hàng hóa và xác nhận sẽ trừ tồn kho material cho VAS - PACKING, UNPACK.
    // Nếu là PACKING khi xác nhận sẽ TRỪ tồn material
    // Nếu là UNPACK khi xác nhận sẽ CỘNG tồn material
    (['PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && isVasManger && listProductCreatePallet?.length > 0) && <Button
      key="btn-complete"
      type="primary"
      onClick={async () => {
        Modal.confirm({
          title: `${lang.doYouWant} ${lang.confirm}?`,
          async onOk() {
            try {
              await orderUpdateStatus({ ids: [saleorder?.id], status: 'CONFIRM' });
              message.success(lang.updateSuccessfully);
              navigate(-1);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
          onCancel() { },
        });
      }}
    >
      {`${lang.confirm}`}
    </Button>,

  // Idm bước cuối tạo production đối với VAS - PACKING và VAS - UNPACK
    (isIdm && ['PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && isIdm && ['CONFIRM', 'PACKINPROGRESS'].indexOf(saleorder?.status) > -1) && <Button
    key="btn-createProduction"
    type="primary"
    disabled={saleorder?.status === 'COMPLETED'}
    onClick={async () => {
      try {
        const listProductionProductLine = [];
        saleorder.productOrders.forEach(proOrder => {
          const proRemp = comboData?.find(item => item?.code3A === proOrder?.code3A );
          proRemp.comboData.bomGift.forEach((itemGift, index) => {
            const locatorIdGift = proOrder?.bundledGift[index]?.locatorId;
            const locatorCode = locatorsList.find(itemLocator => itemLocator?.id === locatorIdGift);
            // console.log(locatorCode)
            itemGift.locatorCode = locatorCode?.locator;
            itemGift.locator = locatorCode;
            itemGift.name = itemGift?.name;
            itemGift.codeProductPack = proOrder?.code3A;
            itemGift.codeProduct = itemGift?.code;
            itemGift.movementQuantity = proOrder?.bundledGift[index]?.quantity;
            itemGift.isGift = 'Y';
            itemGift.warehouseId = currentWarehouse?.id;
            itemGift.productionOrderId = parseInt(id);
             listProductionProductLine.push(itemGift);
          });
          proRemp.comboData.masterProduct.forEach((itemMaster, indexProMaster) => {
            // const locatorIdMaster = proOrder?.bundledProduct[indexProMaster]?.masterProductLocatorId;
            // const locator = locatorsList.find(itemLocator => itemLocator?.id === locatorIdMaster);
            itemMaster.codeProductPack = proOrder?.code3A;
            itemMaster.codeProduct = itemMaster?.code3A;
            itemMaster.name = itemMaster?.nameEN;
            itemMaster.locator = saleorder?.locator;
            itemMaster.locatorCode = saleorder?.locator?.locator;
            itemMaster.lot = proOrder?.bundledProduct[indexProMaster]?.lot;
            itemMaster.expireDate = proOrder?.bundledProduct[indexProMaster]?.expireDate ? new Date(proOrder?.bundledProduct[indexProMaster]?.expireDate) : '';
            itemMaster.movementQuantity = proOrder?.bundledProduct[indexProMaster]?.quantity - proOrder?.pickingPackQuantity;
            itemMaster.isGift = 'N';
            itemMaster.warehouseId = currentWarehouse?.id;
            itemMaster.productionOrderId = id;
             listProductionProductLine.push(itemMaster)
          });
        })

        const payload = {
          description: saleorder?.orderReference,
          movementDate: saleorder?.movementDate,
          docType: saleorder?.typeProductionOrder,
          creatorId: user?.userInfo?.sub,
          warehouseId: currentWarehouse?.id,
          codeLSX: saleorder?.code,
          productionProductPack: saleorder?.productOrders?.map(itemProduction => ({
            code3A: itemProduction?.code3A,
            totalQuantity: itemProduction?.total - itemProduction?.pickingPackQuantity,
            shortName: itemProduction?.masterProduct?.shortName,
            nameEN: itemProduction?.masterProduct?.nameEN,
            locatorCode: itemProduction?.locatorCodeTo,
            productionQuantity: itemProduction?.total,
            warehouseId: currentWarehouse?.id,
            lot: itemProduction?.lot,
            expireDate: itemProduction?.expireDate,
            productOrderPackId: itemProduction?.id
          })),
          productionProductLine: listProductionProductLine
        }
        setDataCreateProduction(payload)
        setModalCreateProduction(true)
        return true;
      } catch (error) {
        message.error(error.message);
        return false;
      }
    }}
  >
    {saleorder?.typeProductionOrder === 'PACKING' ? `${lang.create} Production` : `${lang.create} UNPACK`}
  </Button>,

     //IDM bước cuối kiểm tra và tiến hành tạo đơn xuất nội bộ khi đóng VAS
    isIdm &&  ['IMPORTLABEL', 'PASTCARD', 'PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && saleorder?.status === 'CONFIRM' && <Button
      key="btn-create-internal"
      type="primary"
      disabled={selectedRowKeys?.length === 0}
      onClick={() => {
        setModalCreateInternal(true);
      }}
    >
      {`${lang.btnCreate} ${lang.EXPORT_INTERNAL}`}
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/production-order',
      breadcrumbName: lang.productionOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.productionOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.productionOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 2 }} >
            <Descriptions.Item label={'Mã lệnh sản xuất'}>{saleorder?.code}</Descriptions.Item>
            { ['PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && <Descriptions.Item label={lang.locatorTo}>{`${saleorder?.locator?.locatorName} - ${saleorder?.locator?.locator}`}</Descriptions.Item>}
            <Descriptions.Item label={lang.salesChannel}>{`${saleorder?.channel ? saleorder?.channel?.channelName : ''} - ${saleorder?.channel ? saleorder?.channel?.channel : ''}`}</Descriptions.Item>
            <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>
              {saleorder?.typeProductionOrder === 'PACKING' && ['EXPORTED', 'PACKINPROGRESS'].indexOf(saleorder?.status) !== -1 ? 'Đã tạo Production' :
              saleorder?.typeProductionOrder === 'UNPACK' && ['EXPORTED', 'PACKINPROGRESS'].indexOf(saleorder?.status) !== -1 ? 'Đã tạo UNPACK' :
              STATUS_PRODUCTION_ORDER[saleorder?.status]}
              </Descriptions.Item>
            <Descriptions.Item label={'Loại LSX'}>{saleorder?.typeProductionOrder === 'PACKING' ? 'Đóng Pack' : saleorder?.typeProductionOrder === 'IMPORTLABEL' ? 'Dán nhãn nhập khẩu' : saleorder?.typeProductionOrder === 'PASTCARD' ? 'Dán thẻ cào' : 'UnPack'}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns} //saleorder?.typeProductionOrder === 'PACKING' ? columsPack : columns
          expandable={{expandedRowRender}}
          dataSource={saleorder?.productOrders}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: saleorder?.productOrders?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
          // rowSelection={{
          //   onChange: (_, selectedRows) => {
          //     setSelectedRowKeys(selectedRows);
          //   },
          // }}
        />
        {((saleorder?.status === 'PICKING' && saleorder?.typeProductionOrder === 'IMPORTLABEL') || 
          (saleorder?.status === 'PICKING' && saleorder?.typeProductionOrder === 'PASTCARD') || 
          (saleorder?.status === 'CONFIRM' && saleorder?.typeProductionOrder === 'PASTCARD') || 
          (saleorder?.status === 'CONFIRM' && saleorder?.typeProductionOrder === 'IMPORTLABEL') || 
          (saleorder?.typeProductionOrder === 'PACKING') || 
          (saleorder?.typeProductionOrder === 'UNPACK')
          ) && <ProTableCusTom
          headerTitle={lang.productListDivided}
          toolBarRender={() => [
            (isVasManger) && <Button
              key="btn-delete"
              type="primary"
              ghost
              onClick={async () => {
                Modal.confirm({
                  title: `${lang.doYouWant} ${lang.btnDelete} ${lang.product}?`,
                  icon: <ExclamationCircleOutlined />,
                  async onOk() {
                    try {
                      setLoading(true);
                      // const totalProductSelected = listProductCreatePallet?.length
                      await deleteProductsPallet({ ids: selectedRowKeys?.map(itemId => itemId?.id)});
                      await productPicking({ warehouseId: currentWarehouse.id})
                      .then(result => {
                        const findProduct = result?.data?.filter(item => (item?.orderId === parseInt(id) && item?.status !== 'PICKING'));
                        setListProductCreatePallet(findProduct);
                        setLoading(false);
                      })
                      .catch((e) => setLoading(false));
                      setSelectedRowKeys([]);
                      message.success(lang.deleteSuccessfully);
                      return true;
                    } catch (error) {
                      message.error(error.message);
                      return false;
                    }
                  },
                  onCancel() { },
                });
              }}
              disabled={selectedRowKeys.length === 0}
            >
              {lang.btnDelete}
            </Button>,
            (isVasManger) && <Button
              key="btn-print"
              type="primary"
              disabled={listProductCreatePallet?.length === 0}
              ghost
              onClick={() => {
                // setPrintProducts(listProductCreatePallet);
                setIsPrintModal(true);
              }}
            >
              {lang.print}
            </Button>,
            (isVasManger) && <Button
              key="btn-updatelocation"
              type="primary"
              onClick={() => {
                const productIds = listProductCreatePallet?.filter(item => !item?.rack).map(item => item.id);
                productUpdateLocation({ productIds })
                  .then(result => {
                    Modal.success({
                      title: lang.updateLocationSuccessfully,
                      icon: <CheckOutlined />,
                      onOk() {
                        setLoading(true);
                        productPicking({ warehouseId: currentWarehouse.id})
                          .then(result => {
                            const findProduct = result?.data?.filter(item => (item?.orderId === parseInt(id) && item?.status !== 'PICKING'));
                            setListProductCreatePallet(findProduct?.map(product => {
                              // console.log(product)
                              if (listProductCreatePallet.map(item => item.id).includes(product.id)) {
                                const find = listProductCreatePallet.find(x => x.id === product.id); 
                                return {
                                  ...product,
                                  totalQuantity: find?.totalQuantity,
                                };
                              } else {
                                return product;
                              }
                            }))
                            setLoading(false);
                          })
                          .catch((e) => setLoading(false));
                      },
                    });
                  })
                  .catch((e) => {
                    message.error(e.message);
                    productPicking({ warehouseId: currentWarehouse.id})
                    .then(result => {
                      const findProduct = result?.data?.filter(item => (item?.orderId === parseInt(id) && item?.status !== 'PICKING'));
                      setListProductCreatePallet(findProduct);
                    })
                    .catch((e) => setLoading(false));
                  },
                  );
              }}
              disabled={listProductCreatePallet.length === 0}
            >
              {lang.updateLocation}
              <TableOutlined />
            </Button>
          ]}
          rowKey="id"
          loading={loading}
          bordered
          columns={columsVasProPallet}
          dataSource={listProductCreatePallet}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) => `${range.join('-')} ${lang.on} ${total} pallet`
          }}
          scroll={{ x: 2600 }}
          rowSelection={(isVasManger || isIdm) && {
            onChange: (_, selectedRows) => {
            setSelectedRowKeys(selectedRows);
          },
          getCheckboxProps: (record) => ({
            disabled: (listProductDisabled.indexOf(record?.id) > -1),
          }),
          selectedRowKeys: selectedRowKeys?.map(itemId => itemId?.id)
          }}
        />
        }
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProductDetailEditForm
          itemVas={item}
          onCallback={async (value) => {
            // console.log(value) 
            const payload = {
              id: item?.id,
              quantityCarton: value?.quantityInCarton,
              quantityTin: value?.quantity,
              totalQuantity: (value?.quantityInCarton * value.tinPerCarton) + value?.quantity,
              productOrderMaterial: value?.bomProductionIds
            }
            // console.log(payload)
            try {
              await productOrderUpdateVasBaseProduct(payload);
              saleorderDetail(id)
                .then(result => {
                  setSaleorder(result);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
              message.success(`${lang.update} ${lang.successfully}!`);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
      <Modal
        visible={isEditModalVasProduct}
        title={lang.updateProduct}
        onCancel={() => setIsEditModalVasProduct(false)}
        destroyOnClose={true}
        width={992}
        centered
        footer={null}
        wrapClassName="receipt-product-pallet-edit-modal"
      >
        <VasProductCreatePallet
          item={productVas}
          onCallback={async (value) => {
            // console.log(value)
            try {
              setLoading(true);
              if (value.amountPallet > 0) {
                await createPalletVasProduct({
                  warehouseId: currentWarehouse.id,
                  balletNumber: value.amountPallet,
                  masterProductId: productVas?.masterProduct?.id,
                  name: productVas?.masterProduct?.nameEN,
                  quantityCartton: value.quantityCarttonPallet,
                  quantityTin: value.quantityTinPallet,
                  expireDate: new Date(value.expireDate),
                  lot: value.lot,
                  productOrderId: productVas?.id,
                  mfgDate: productVas?.mfgDate,
                  locatorId: value?.locator,
                  orderId: parseInt(id),
                  totalQuantity: value.quantityCarttonPallet * parseInt(productVas?.masterProduct?.tinPerCarton) + value.quantityTinPallet,
                });
              }
          
              // await updateProductOrder({ ...value, receiptId, code3A: productVas?.masterProduct?.code3A, locator: value?.locator});
              
              //     receiptDetail({ receiptId: parseInt(receiptId) })
              //       .then(result => {
              //         setReceipt(result);
              //         setItems(result?.products);
              //         setItemsReceiptProduct(result?.receiptProduct);
              //         setLoading(false);
              //       })
              //       .catch((e) => setLoading(false));
              await productPicking({ warehouseId: currentWarehouse.id})
                  .then(result => {
                    const findProduct = result?.data?.filter(item => (item?.orderId === parseInt(id) && item?.status !== 'PICKING'));
                    setListProductCreatePallet(findProduct);
                    
                  })
                  .catch((e) => setLoading(false));
                  message.success(lang.updateSuccessfully);
                  setLoading(false);
                  setIsEditModalVasProduct(false);
                  return true;
              
            } catch (error) {
              message.error(error.message);
              setLoading(false);
              return false;
            }
          }}
          onCancel={() => setIsEditModalVasProduct(false)}
          // divePallet={items?.filter(item => item?.receiptProductId === receiptProduct?.id)?.length > 0 ||
          //   (receiptProduct?.quantityCarttonReceived === receiptProduct?.quantityCartton && receiptProduct?.quantityTinReceived === receiptProduct?.quantityTin)}
          // quantityCarttonItems={items?.reduce((prev, curr) => {
          //   if (receiptProduct?.id === curr.receiptProductId && curr.status === 'NEW') {
          //     return prev + curr.quantityCartton;
          //   }
          //   return prev;
          // }, 0)}
          // quantityTinItems={items?.reduce((prev, curr) => {
          //   if (receiptProduct?.id === curr.receiptProductId && curr.status === 'NEW') {
          //     return prev + curr.quantityTin;
          //   }
          //   return prev;
          // }, 0)}
        />
      </Modal>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductPrint 
          items={listProductCreatePallet}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.rack.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
          onCancel={() => {
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
        />
      </Modal>
      <Modal
        visible={isPrintModalOneQRCode}
        title={lang.print}
        onCancel={() => setIsPrintModalOneQRCode(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductPrint 
          items={proPrinQRCode}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.rack.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
          onCancel={() => {
            setIsPrintModalOneQRCode(false);
            if (isclose) {
              navigate('/dashboard/inbound/production-order');
              setIsClose(false);
            }
          }}
        />
      </Modal>
      <Modal
        visible={isEditModalProductPallet}
        title={`${itemProductPallet ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModalProductPallet(false)}
        // afterClose={() => {
        //   formEdit.resetFields();
        // }}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProductionProductEditForm
          item={itemProductPallet}
          onCallback={async (value) => {
            setCheckLoading(true)
            try {
              // const rackId = itemProductPallet?.id === value?.id ? itemProductPallet?.rack?.id : ''
              // setLoading(true);
              await productUpdate({ id: value.id, expireDate: value.expireDate, note: value.note, quantityCartton: value.quantityCartton, quantityTin: value.quantityTin, locatorId: value.locatorId }); 
              setIsEditModalProductPallet(false);
              message.success(`${lang.update} ${lang.successfully}`)
            } catch (error) {
              setLoading(false);
              message.error(error.message);
            }
          }}
          onCancel={() => { setIsEditModalProductPallet(false); }}
          onPrint={async(values) => {
            const res = await productSplit({ id: itemProductPallet.id, quantityTin: values.quantityTin, quantityCartton: values.quantityCartton, locatorId: values.locatorId });
            setProPrinQRCode([res]);
            setIsPrintModalOneQRCode(true);
          }}
          onSplit={async (values) => {
            try {
              setLoading(true);
              const res = await productSplit({ id: itemProductPallet.id, quantityTin: values.quantityTin, quantityCartton: values.quantityCartton, locatorId: values.locatorId });
              // setListProductCreatePallet([res]);
              setCheckLoading(true)
              setProPrinQRCode([res])
              setIsPrintModalOneQRCode(true);
              setIsEditModalProductPallet(false);
              message.success(`${lang.splitproduct} ${lang.successfully}`)
            } catch (error) {
              setLoading(false);
              message.error(error.message);
            }
          }}
          warehouseId={currentWarehouse.id}
          locatorsList={locatorsList}
          isEditModal={isEditModalProductPallet}
        />
      </Modal>
      {
         <ProducttionPackInternal
         item={dataCreateProduction}
         typeProductionOrder={saleorder?.typeProductionOrder}
         onCallBack={async (value) => {
          // console.log(value)
           try {
            const arrayProductionLine = [];
            const dataProductionLine = value?.productionProductLine
            value.internalProductPacking.forEach((pro) => {
              //Nếu có nhiều SP cần kiểm tra code 3A tương ứng cho SP combo của nó
              dataProductionLine.forEach(itemLine => {
                itemLine.movementQuantity = pro?.totalQuantity;
                arrayProductionLine.push(itemLine)
              })
            })
            
            // console.log(arrayProductionLine)
            if (saleorder?.typeProductionOrder === 'PACKING') {
              const payload = {
                description: saleorder?.orderReference,
                movementDate: saleorder?.movementDate,
                docType: saleorder?.typeProductionOrder,
                creatorName: user?.userInfo?.preferred_username,
                creatorId: user?.userInfo?.sub,
                warehouseId: currentWarehouse?.id,
                codeLSX: saleorder?.code,
                productionProductPack: saleorder?.productOrders?.map((itemProduction, index) => ({
                  code3A: itemProduction?.code3A,
                  totalQuantity: itemProduction?.quantityTin,
                  shortName: itemProduction?.masterProduct?.shortName,
                  nameEN: itemProduction?.masterProduct?.nameEN,
                  locatorCode: itemProduction?.locatorCodeTo,
                  productOrderId: saleorder.id,
                  productionQuantity: value?.internalProductPacking[index]?.totalQuantity,
                  warehouseId: currentWarehouse?.id,
                  lot: itemProduction?.lot,
                  expireDate: itemProduction?.expireDate,
                  productOrderPackId: itemProduction?.id
                })),
                productionProductLine: value?.productionProductLine
              }
              // console.log(payload)
              await productionOrderCreate(payload);
              setModalCreateProduction(false);
              message.success(`${lang.create} Production ${lang.successfully}!`)
            } else {
              const arrayProductGift = [];
              const arrayProductMaster = [];

              value.productionProductLine.forEach(item => {
                if (item?.isGift === 'Y') {
                  arrayProductGift.push(item);
                }

                if (item?.isGift === 'N') {
                  arrayProductMaster.push(item);
                }
              });
              // console.log(value)
              const payload = value?.productionProductPack?.map((productPack, index) => ({
                code3A: productPack?.code3A,
                locatorCode: productPack?.locatorCode,
                totalQuantity: value?.internalProductPacking[index]?.totalQuantity,
                productOrderPackId: productPack?.productOrderPackId,
                warehouseId: currentWarehouse?.id,
                giftUnPacking: arrayProductGift?.map(r => ({
                  bomGiftId: r?.id,
                  locatorCode: r?.locatorCode,
                  quantity: r?.movementQuantity
                })),
                productUnPacking: arrayProductMaster?.map(s => ({
                  masterProductId: s?.id,
                  locatorCode: s?.locatorCode,
                  code3A: s?.code3A,
                  lot: s?.lot,
                  expireDate: s?.expireDate,
                  quantity: s?.movementQuantity
                })),
                codeLSX: saleorder?.code,
                lot: productPack?.lot,
                expireDate: productPack?.expireDate ? new Date(productPack?.expireDate) : ''

              }))
              // console.log(payload)
              await productionOrderUnpack(payload);
              await saleorderDetail(parseInt(id))
                .then(async result => {
                  setSaleorder(result);
                  setLoading(false)
                })
                .catch(e => {
                  message.error(e);
                  setLoading(false)
                })
              setModalCreateProduction(false);
              message.success(`${lang.create} Unpack ${lang.successfully}!`)
            }
           } catch (error) {
             message.error(error.message);
           }
         }}
        setIsEditModal={setModalCreateProduction}
        isEditModal={modalCreateProduction}
       />
      }
      
     {
      <ProducttionInternal
        item={selectedRowKeys}
        onCallBack={async (values) => {
          setCheckLoading(true)
          try {
            const formData = { ...saleorder };
            formData.orderType = 'EXPORT_INTERNAL';
            delete formData.locatorCode
            delete formData.locator
            delete formData.group

            const listProductSelectedMulti = []; 
              for (let i = 0; i < selectedRowKeys.length; i++) { 
                let obj = selectedRowKeys[i];
                const { code3A, expireDate, lot, quantityCartton, quantityTin, totalQuantity } = obj;
                const foundObj = listProductSelectedMulti.find((item) => ( item.code3A === code3A && item.expireDate === expireDate && item.lot === lot ));
                 
                if (foundObj) {
                  foundObj.quantityCartton += quantityCartton; 
                  foundObj.quantityTin += quantityTin;
                  foundObj.totalQuantity += totalQuantity;
                } else { 
                  listProductSelectedMulti.push(selectedRowKeys[i]);
                }
              }

            const payload =  ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1 ? listProductSelectedMulti?.map(item => {
              return {
                quantityInCarton: item?.quantityCartton,
                quantityTin: item?.quantityTin,
                code3A: item?.masterProduct?.code3A,
                locatorCode: item?.locator?.locator,
                locatorCodeTo: values?.locatorCodeTo,
                total: item?.totalQuantity,
                warehouseId: item?.warehouseId,
                lot: item?.lot,
                expireDate: item?.expireDate ? item?.expireDate : '',
                isPackagedSku: item?.masterProduct?.isPackagedSku
              };
            }) : saleorder?.productOrders?.map(pro => {
              return {
                quantityInCarton: pro?.quantityInCarton,
                quantityTin: pro?.quantityTin,
                code3A: pro?.code3A,
                locatorCode: pro?.locator?.locator,
                locatorCodeTo: values?.locatorCodeTo,
                total: pro?.total,
                warehouseId: pro?.warehouseId,
                lot: pro?.lot,
                expireDate: pro?.expireDate ? new Date(pro?.expireDate) : '',
                isPackagedSku: pro?.masterProduct?.isPackagedSku
              };
            });
          
            // console.log(payload)
            if (formData.channel) {
              formData.subChannel = formData.channel?.subChannel;
              formData.channel = formData.channel.channel;
            }
            formData.internalTranferCode = formData.code;
            formData.locatorCode = values?.locatorCodeTo;
            formData.locator = values?.locatorCodeTo;
            formData.status = 'EXPORTED';
            delete formData.locatorToId;
            delete formData.id;
            delete formData.code;
            delete formData.productOrders;

           
              const dataTime = new Date().getTime() + parseInt(id)
              formData.group = dataTime;
              const dataUpdateStatusProducts = {
                productStatus: selectedRowKeys?.map(proId => {
                  return {
                    id: proId?.id,
                    status: proId?.status,
                    group: dataTime
                  }
                })
              }
              // console.log(formData)
              await saleorderCreate({ ...formData, productOrders: payload, warehouseId: currentWarehouse?.id});
              await productUpdateLocator({ productIds: selectedRowKeys?.map(proId => proId?.id), locatorCode: values?.locatorCodeTo });
              await productUpdateStatus(dataUpdateStatusProducts);
              setSelectedRowKeys([])

         
           productPicking({ warehouseId: currentWarehouse.id})
            .then(result => {
              const groupOfOrder = productLock[0].group;
            // B1: Lấy ra những product theo OrderId
            const listProdutByOrderId = result?.data?.filter(item => (item?.orderId === parseInt(id)));
            // B2: Lấy ra những product có group !== group của Order
            const findProductByGroup = listProdutByOrderId?.filter(item => (item?.orderId === parseInt(id) && ['TEMPORARY', 'STORED', 'VAS', 'PICKING', 'NEW'].indexOf(item?.status) > -1) && (item?.group !== groupOfOrder));
            setListProductCreatePallet(findProductByGroup);  
            setLoading(false);
            })
            .catch((e) => setLoading(false));
            setModalCreateInternal(false);
            setSelectedRowKeys([])
            message.success(`${lang.create} ${lang.EXPORT_INTERNAL} ${lang.successfully}`);
            // navigate(-1)
          } catch (error) {
            message.error(error.message);
            return false;
          }
        }}
        setIsEditModal={setModalCreateInternal}
        isEditModal={modalCreateInternal}
      /> 
    }
    </Layout>
  );
};

export default ProductTionOrderDetail;
