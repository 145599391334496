import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import exportFromJSON from 'export-from-json';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Tag, Input } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import { saleorderDetail, deleteProductOrder, updateProductOrder, saleorderUpdate, getAllProudctLocator, saleorderList } from './sale-order.service';
import { defaultPage, formatDate, formatDateFull, formatDatetime, STATUS_INTERNAL_TRANFER, STATUS_OUTBOUND, TYPE_PRODUCTION_ORDER } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import logo from 'assets/logoAKAWMS/akawms.svg';
import { productPicking } from 'pages/dashboard/outbound/packaging/packaging.service';
import ReceiptCreateProductEditForm from './sale-order-create-product-edit.form';
// import { deliveryOrderCreate } from 'pages/dashboard/outbound/delivery-order/delivery-order.service';
import { orderUpdate, orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';
// import { productUpdates } from 'pages/dashboard/inbound/product.service';
import {ProForm, ModalForm, ProFormSelect, ProFormDatePicker } from '@ant-design/pro-form'; //ProFormText
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { productOrderUpdate } from 'pages/dashboard/inbound/production-order/production-order.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};
const ReceiptDetail = () => {
  const { saleorderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingVas, setLoadingVas] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [item, setItem] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [params] = useState({ ...defaultPage });
  const [productLock, setProductLock] = useState([]);
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isOutboundStaff = _get(user, 'roles', '').includes('outboundStaff');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const formRef = useRef();
  const [description, setDescription] = useState();
  const [sortedInfo] = useState(defaultSort);
  const [listProductCreatePallet, setListProductCreatePallet] = useState([]);

  useEffect(() => {
    setLoading(true);
    saleorderDetail(saleorderID)
      .then(result => {
        setSaleorder(result);
        setDescription(result?.description);
        setProductOrder(result?.productOrders)
        // call method to get orderID
        saleorderList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id, orderType: 'COMMAND_MANUFACTURING'  })
          .then(dataList => { 
          const findLSX = dataList.data.find(item => item?.code === result?.internalTranferCode)?.id;
            if (findLSX) {
              productPicking({ warehouseId: currentWarehouse.id})
              .then(rs => {
                const findProduct = rs?.data?.filter(item => (item?.orderId === parseInt(findLSX) && (['STORED', 'VAS', 'NEW', 'TEMPORARY'].indexOf(item?.status) > -1)));
                const findProductByGroup = findProduct?.filter(proByGroup => proByGroup?.group === result?.group); 
                setListProductCreatePallet(findProductByGroup);
                setLoading(false);
              })
            }
            setLoading(false);
          })
          .catch((e) => setLoading(false));
        return result.group && productPicking({ warehouseId: result.warehouseId, group: result.group })
        
      })
      .then(val => { setProductLock(val?.data); setLoading(false); })
      .catch((e) => setLoading(false));
      }, [saleorderID, deleteProduct, sortedInfo, params, currentWarehouse]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => val && `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.shelfLifeMonths,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => rec?.quantityCartton || rec?.quantityInCarton || 0,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => rec?.quantityTin,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: saleorder?.status !== 'NEW' || saleorder.orderType === 'EXPORT_INTERNAL',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {saleorder.orderType !== 'EXPORT_SALE' && <>
            <Button
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                setIsEditModal(true);
                setItem(record);
              }}
            />
            <Button
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: lang.doYouWantToDeleteTheseItems,
                  icon: <ExclamationCircleOutlined />,
                  onOk: async () => {
                    try {
                      await deleteProductOrder(record.id);
                      message.success(lang.deleteSuccessfully);
                      setDeleteProduct(!deleteProduct);
                      return true;
                    } catch (error) {
                      message.error(error.message);
                      return false;
                    }
                  },
                  onCancel() {

                  },
                });
              }}
            />
          </>}
          {saleorder.orderType === 'EXPORT_SALE' && <ModalForm
            title={lang.updateProduct}
            formRef={formRef}
            onFinish={async (value) => {
              try {
                await productOrderUpdate({ id: record?.id, locatorCode: value?.locatorCode, lot: value?.lot?.value, expireDate: value?.expireDate });
                await saleorderUpdate({ id: saleorder?.id, locator: value?.locatorCode});
                setDeleteProduct(!deleteProduct);
                message.success(lang.updateSuccessfully);
                return true;
              } catch (error) {
                message.err(error.message);
                return false;
              }
            }}
            trigger={
              <Button
                type="link"
                size="small"
                icon={<EditOutlined />}
              />
            }
            requiredMark={false}
            initialValues={{
              locatorCode: record?.locator ? `${record?.locator?.locatorName} - ${record?.locator?.locator}` : '',
              lot: record?.lot,
              expireDate: record?.expireDate
            }}
            // submitter={{
            //   render: ({ form }) => {
            //     return [
            //       <Button
            //         style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
            //         key="extra-reset"
            //         onClick={onCancel}
            //       >
            //         {lang.btnCancel}
            //       </Button>,
            //       <Button
            //         style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
            //         type="primary"
            //         key="extra-submit"
            //         onClick={() => form?.submit?.()}
            //       >
            //         {lang.btnSave} <CheckCircleOutlined />
            //       </Button>,
            //     ];
            //   },
            // }}
            modalProps={{
              closable: false,
              centered: true,
              destroyOnClose: true,
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <ProFormSelect name="locatorCode" label={lang.locator} placeholder={lang.locator}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: lang.requiredTotalshelf,
                  //   },
                  // ]}
                               params={{ warehouseId: record?.warehouseId, masterProductId: record?.masterProduct.id }}
                               request={async ({ warehouseId, masterProductId }) => {
                    const locators = await getAllProudctLocator({ masterProductId });
                    return locators?.data?.filter(masterLo => masterLo?.locators.warehouseId === warehouseId && !masterLo?.locators.warehouseToId &&
                      masterLo?.totalQuantityForSale >= record.quantity)?.map(masterLo => ({
                        label: `${masterLo?.locators?.locatorName} - ${masterLo?.locators?.locator}`,
                        value: masterLo?.locators?.locator,
                      }))
                  }}
                               fieldProps={{
                  onChange: ()=>{
                    formRef.current.setFieldsValue({lot:undefined, expireDate: undefined})
                  },
                }}
                />
              </Col>
              <Col span={8}>
                <ProForm.Item noStyle shouldUpdate >
                  {(form) =>
                    <ProFormSelect name="lot" label={lang.lot} placeholder={lang.lot}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: lang.requiredTotalshelf,
                      //   },
                      // ]}
                      params={{ locatorCode: form?.getFieldValue('locatorCode'), masterProductId: record?.masterProduct.id }}
                      request={async ({ locatorCode, masterProductId }) => {
                        const locator = await getAllProudctLocator({ masterProductId, locatorCode });
                        return locator?.data?.reduce((prev, curr) => {
                          if (!prev.some(lot => lot === curr.lot)) {
                            return [...prev, {lot: curr.lot, expireDate: curr.expireDate}];
                          }
                          return prev;
                        }, [])?.map(item => (
                          {
                            label: item?.lot,
                            value: item?.lot,
                            expireDate: item?.expireDate,
                          }
                        ))
                      }}
                      fieldProps={{
                        labelInValue: true,
                        onChange: (val) => {
                          form.setFieldsValue({ expireDate: val?.expireDate });
                        }
                      }}
                    />
                  }
                </ProForm.Item>
                {/* <ProFormText name="lot" label={lang.lot} placeholder={lang.lot}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập lot',
                    },
                  ]} /> */}

              </Col>
              <Col span={8}>
              <ProFormDatePicker name='expireDate' label={lang.expireDate} placeholder={lang.expireDate}
                rules={[
                  {
                    required: true,
                    message: lang.expiryDateRequired,
                  },
                ]}
                fieldProps={{
                  style: { width: '100%' },
                  format: formatDate,
                  disabledDate: (date) => date < moment(),
                }} />
              </Col>
            </Row>
          </ModalForm>}
        </div>
      ),
    },
  ];
  const columnsProduct = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      // dataIndex: 'quantityCartton',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityCartonSubmitArr?.[finded];
        return record?.quantityCartton
      }
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: record =>{
        const finded = record?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return record?.quantityTinSubmitArr?.[finded];
        return record?.quantityTin
      }
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      // dataIndex: 'totalQuantity',
      render: rec => {
        const finded = rec?.orderSubmitId?.indexOf(saleorder?.id);
        if(finded > -1)
          return rec?.quantityCartonSubmitArr?.[finded] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[finded];
        return rec?.totalQuantity;
      },
    },
    {
      title: `${lang.takeQuantity} (${lang.carton})`,
      key: 'quantityCarttonTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.takeQuantity} (${lang.tin})`,
      key: 'quantityTinTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: `${lang.sum} ${lang.takeQuantity} (${lang.tin})`,
      key: 'totalQuantityTake',
      render: rec => {
        const index = rec?.orderSubmitId?.indexOf(saleorder?.id);
        return rec?.quantityCartonSubmitArr?.[index] * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTinSubmitArr?.[index];
      },
      hideInTable: saleorder?.status !== 'LOCK',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
      // hideInTable: listProductCreatePallet
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
      // hideInTable: listProductCreatePallet
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
      // hideInTable: listProductCreatePallet
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      // dataIndex: 'rack',
      // width: '6%',
      render: record => {
        if (record?.status === 'STORED') {
          return record?.rack?.code
        } if (['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1 && record?.status === 'PICKING') {
          return record?.rack?.code
        } else {
          return `${record?.rack?.code} (${lang.suggestion})`
        }
      },
    },
    {
      title: lang.status + ' QR Code',
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (value) => {
        if (['IMPORTLABEL', 'PASTCARD', 'PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1) {
          if (value === 'NEW') {
            return (
              <Tag>{lang.newCreate}</Tag>
            );
          } else if (value === 'VAS') {
            return (
              <Tag>{'VAS'}</Tag>
            );
          } else if (value === 'TEMPORARY') {
            return (
              <Tag>{lang.temporaryInbound}</Tag>
            );
          } else if (value === 'STORED') {
            return (
              <Tag>{lang.inStock}</Tag>
            );
          }
          
        } else {
          return (
            <Tag>{lang[value]}</Tag>
          );
        }
      },
    },
    {
      title: 'User Picked',
      key: 'userPicked',
      dataIndex: 'userName',
      hideInTable: ['IMPORTLABEL', 'PASTCARD'].indexOf(saleorder?.typeProductionOrder) > -1
    },
  ];
  const extraButton = [
    <Button
      key="btn-back"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1)
      }}
    >
      {'Back'}
    </Button>,
    ['NEW', 'CONFIRM'].indexOf(saleorder?.status) > -1 && saleorder?.orderType !== 'EXPORT_SALE' && <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        Modal.confirm({
          title: lang.areYouSureCancelProductionOrder,
          async onOk() {
            try {
              if (saleorder?.status !== 'NEW')
                await orderUpdate({ id: saleorder?.id, status: 'NEW' });
              else await orderUpdate({ id: saleorder?.id, status: 'CANCELLED', internalTranferCode: saleorder?.internalTranferCode });
              message.success(lang.updateSuccessfully);
              navigate(-1);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          },
        });
      }}
    >
      {lang.btnCancel}
    </Button>,
    !isPicker && <Button
      key="btn-save"
      type="primary"
      disabled={!description}
      onClick={async () => {
        try {
          setLoading(true);
          await saleorderUpdate({ id: saleorder?.id, description });
          saleorderDetail(saleorderID)
            .then(result => {
              setSaleorder(result);
              setDescription(result?.description);
              if (!result.isConfirm) {
                productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
              } else if (result.orderType === 'EXPORT_INTERNAL') {
                productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
              }
              if (result.status !== 'EXPORT') {
                setProductOrder(result?.productOrders);
                setLoading(false);
              } else {
                return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
              }
            })
            .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
            .catch((e) => setLoading(false));
          message.success(lang.updateSuccessfully);
          return true;
        } catch (error) {
          message.error(error.message);
          setLoading(false);
          return false;
        }
      }}
    >
      {lang.btnSave}
    </Button>,
    ['PACKING', 'UNPACK'].indexOf(saleorder?.typeProductionOrder) > -1 && (isIdm || isStocker) && <Button
    key="btn-internaltranfer-vas"
    type="primary"
    // ghost
    loading={loadingVas}
    onClick={async () => {
      setLoadingVas(true);
      let content = `
      <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
    
        <div style="display: flex; height: 120px;">
          <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
            <img src="${logo}" style=" height: 100%; width: 100%" />
          </div>
          <div style="width: 60%; text-align: center; padding-top: 7vh; ">
            <h1>INTERNAL WAREHOUSE TRANSFER FORM</h1>
          </div>
        </div>
        
        <div>
          <div style="text-align: left; marginBottom: 5px">
            <div style="font-size: 16px;">April 4, 2023</div>
          </div>
          <div style="display: flex;">
            <div style="width: 10%"></div>
            <div style="width: 40%">
              <div>{lang.number}: - <span>{lang.locatorFrom}: ${saleorder?.productOrders[0]?.locatorCode ? saleorder?.productOrders[0]?.locatorCode : '-'} </span> - <span>To Locator: ${saleorder?.productOrders[0]?.locatorCodeTo ? saleorder?.productOrders[0]?.locatorCodeTo : '-'} </span></div>
            </div>
            <div style="width: 25%">
              <div>Label</div>
              <div>Storage</div>
              <div>Transit</div>
              <div>Other....</div>
            </div>
            <div style="width: 25%; border: 1px solid #333; padding: 8px; text-align: center;">
              <div>Code: P-85-03-F5</div>
              <div>Issue date: 02/04/2023</div>
              <div style="font-weight: bold">No: 0355</div>
            </div>
          </div>
        </div>

        <table style="border: 1px solid #333; width: 100%; border-collapse: collapse; margin-top: 10px;">
          <thead>
            <tr style="text-align:center; font-weight: bold;">
              <td style=" border: 1px solid #333; padding: 5px;" rowspan="2">No.</td>
              <td style=" border: 1px solid #333; padding: 5px;" rowspan="2">PRODUCT CODE</td>
              <td style=" border: 1px solid #333; padding: 5px;" rowspan="2">LOT</td>
              <td style=" border: 1px solid #333; padding: 5px;" rowspan="2">EXP.DATE</td>
              <td style=" border: 1px solid #333; padding: 5px;" colspan="2">AVI/ATR/AVC QUANTITY</td>
              <td style=" border: 1px solid #333; padding: 5px;" colspan="2">DAMAGED QUANTITY</td>
              <td style=" border: 1px solid #333; padding: 5px;" colspan="2">TOTAL</td>
              <td style=" border: 1px solid #333; padding: 5px;" rowspan="2">NOTE - CDC</td>
            </tr>
            <tr style="text-align:center; font-weight: bold;">
              <td style=" border: 1px solid #333; padding: 5px;">Carton</td>
              <td style=" border: 1px solid #333; padding: 5px;">Tin</td>
              <td style=" border: 1px solid #333; padding: 5px;">Carton</td>
              <td style=" border: 1px solid #333; padding: 5px;">Tin</td>
              <td style=" border: 1px solid #333; padding: 5px;">Carton</td>
              <td style=" border: 1px solid #333; padding: 5px;">Tin</td>
            </tr>
          </thead>
          <tbody>`;
          // let listShortName = [];
              listProductCreatePallet.forEach((proVas, index) => {
                // listShortName.push(proVas?.masterProduct?.shortName);
                return content += `
                <tr>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${index + 1}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${proVas?.masterProduct?.shortName ? proVas?.masterProduct?.shortName : ''}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${proVas?.lot ? proVas?.lot : ''}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${proVas?.expireDate ? moment(proVas?.expireDate, formatDateFull).format(formatDate) : ''}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${proVas?.quantityCartton >= 0 ? proVas?.quantityCartton : ''}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${proVas?.quantityTin >= 0 ? proVas?.quantityTin : ''}</td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;"></td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;"></td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;"></td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;"></td>
                  <td style=" border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">
                  ${`${saleorder?.productOrders[0]?.masterProduct?.shortName} (${proVas?.masterProduct?.shortName}) (${saleorder?.productOrders[0]?.masterProduct?.code3A})`}
                  </td>
              </tr>`;
              });
        content += `</tbody>
        </table>

        <div style="display: flex; padding-top: 20px">
          <div style="width: 20%">
            <p>Department Manager</p>
          </div>
          <div style="width: 20%">
            <p>Warehouse Keeper (ATR)</p>
          </div>
          <div style="width: 20%">
            <p>QA</p>
          </div>
          <div style="width: 20%">
            <p>VAS Group Leader</p>
          </div>
          <div style="width: 20%">
            <p>Creator</p>
          </div>
        </div>
          
      </div>
      `;
      var template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        console.log(canvas)
        const imgWidth = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width ;
        var imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('l', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`InternalTranfer${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingVas(false);
    }}
  // disabled={saleorder?.orderType !== 'EXPORT_INTERNAL'}
  >
    {lang.exportInternal}
  </Button>,
    saleorder?.orderType === 'EXPORT_SALE' && <Button
      key="btn-spicking-slip"
      type="primary"
      // ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
        <div style="display: grid ;line-height: 1.5;width: 950px; font-size: 18px; font-family: 'Times New Roman', Times, serif;">
        <!-- <div style="text-align:right; width: 100%; padding: 10px">
          <p><b style="padding: 10px">Print date: 22/08/2022 </b></p>
        </div> -->
        <div style="display: flex; height: 120px;">
          <div style="width: 80%; text-align: center; ">
            <h1>Picking Slip</h1>
          </div>
        </div>
        <div style="display: flex; width: 100%;">
          <div style="width: 25%;">
            <p><b>Order code:</b> </p>
            <p><b>Date</b>: </p>
            <p><b>Customer name</b>: </p>
            <p><b>Saler name</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.documentNo ? saleorder?.documentNo : ''}</p>
            <p>${saleorder?.dateOrdered ? moment(saleorder?.dateOrdered, formatDateFull).format(formatDate) : ''}</p>
            <p>${saleorder?.nameCustomer ? saleorder?.nameCustomer : ''}</p>
            <p>${saleorder?.userContact ? saleorder?.userContact : ''}</p>
          </div>
          <div style="width: 25%;">
            <p><b>Delivery Note No:</b> </p>
            <p><b>Order Type</b>: </p>
            <p><b>Order Reference</b>: </p>
            <p><b>Locator</b>: </p>
          </div>
          <div style="width: 25%;">
            <p>${saleorder?.code ? saleorder?.code : ''}</p>
            <p>${saleorder?.orderType ? saleorder?.orderType : ''}</p>
            <p>${saleorder?.orderReference ? saleorder?.orderReference : ''}</p>
            <p>${saleorder?.locator?.locatorName ? saleorder?.locator?.locatorName : ''} - ${saleorder?.locator?.locator ? saleorder?.locator?.locator : ''}</p>
          </div>
        </div>
        <div>
          <p> <b>{lang.address}: ${saleorder?.shipAddress}</b> </p>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #f5f5f5; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">No</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Code</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Short</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInCart</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInTin</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lot & Exp Date</div></th>
              </tr>
            </thead>
            <tbody>

            ${productOrder?.map((product, index) => {
          return `<tr style="text-align: center;">
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.code3A}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton ? product?.quantityInCarton : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityTin ? product?.quantityTin : ''}</div></td>
                        <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product?.lot ? product?.lot : ''}&gt;_${product?.expireDate ? moment(product?.expireDate, formatDateFull).format(formatDate) : ''}</div></td>
                      </tr>`;
        }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuPickingSlip${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    // disabled={saleorder?.orderType !== 'EXPORT_INTERNAL'}
    >
      {lang.exportPickingSlip}
    </Button>,
    (saleorder?.orderType === 'EXPORT_SALE') && <Button
      key="btn-confirm"
      type="primary"
      onClick={async () => {
        Modal.confirm({
          title: lang.confirmProceedSure,
          async onOk() {
            try {
              setLoading(true);
              await orderUpdateStatus({ ids: [saleorder?.id], status: saleorder?.status === 'NEW' ? 'LOCK' : 'CONFIRM' });
              saleorderDetail(saleorderID)
                .then(result => {
                  setSaleorder(result);
                  if (!result.isConfirm) {
                    productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
                  } else if (result.orderType === 'EXPORT_SALE') {
                    productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
                  }
                  if (result.status !== 'EXPORT') {
                    setProductOrder(result?.productOrders);
                    setLoading(false);
                  } else {
                    return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
                  }
                })
                .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
                .catch((e) => setLoading(false));
            } catch (err) {
              message.error(err.message);
            }
          },
          onCancel() { },

        });
      }}
    // disabled={['NEW', 'CONFIRM'].indexOf(saleorder?.status) === -1 || (isIdm && saleorder?.status !== 'NEW') || (isThukho && saleorder?.status !== 'CONFIRM1')}
    >
      {lang.btnConfirm}
    </Button>,
    // saleorder?.orderType === 'EXPORT_INTERNAL' && saleorder?.status === 'EXPORTED' && <Button
    //   key="btn-export"
    //   type="primary"
    //   onClick={() => {
    //     const dataXml = [
    //       {
    //         Invoices: {
    //           Inv: {
    //             key: `${saleorder?.code}`,
    //             Invoice: {
    //               DelieriName: 'Ho Chi Minh',
    //               IRNUMBER: '503/22-XM',
    //               DeliverDate: `${moment(saleorder?.movementDate, formatDateFull).format(formatDate)}`,
    //               DeliverContent: `${saleorder?.description ? saleorder?.description : ''}`,
    //               NoilapYC: 'GIÁM ĐỐC CÔNG TY TNHH DINH DƯỠNG 3A(VIỆT NAM)',
    //               OrgNameOut: 'CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)',
    //               StoreOut: `${saleorder?.locator?.locatorName} - ${saleorder?.locator?.locator}`,
    //               FromAddress: 'Lô 6-1A, Cụm 6, Đường M1, Khu CN Tân Bình Mở Rộng, P. Bình Hưng Hòa, Q. Bình Tân, Tp.HCM',
    //               OrgNameIn: 'CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)',
    //               ToAddress: 'Lô 6-1A, Cụm 6, Đường M1, Khu CN Tân Bình Mở Rộng, P. Bình Hưng Hòa, Q. Bình Tân, Tp.HCM',
    //               CusCode: '',
    //               CusName: '',
    //               Buyer: '',
    //               CusAddress: '',
    //               DVQLKHO: '',
    //               DeliverAddress: '',
    //               DeliverTaxCode: '',
    //               DeliverPhone: '',
    //               ComFax: '',
    //               ComBankNo: '',
    //               ComBankName: '',
    //               CurrencyCode: '',
    //               TyGia: '',
    //               VNMInvoiceType: '',
    //               OrderNoOrderNo: '',
    //               DeliverCom: '',
    //               ContractNo: '',
    //               DeliverTruck: `${saleorder?.truckType ? saleorder?.truckType : ''}`,
    //               TripNumber: '',
    //               DeliverNumber: '',
    //               CusTaxCode: '',
    //               PaymentMethod: '',
    //               CusBankNo: '',
    //               BookingNo: '',
    //               Extra: '',
    //               InvoiceDate: `${moment(saleorder?.createDate, formatDateFull).format(formatDate)}`,
    //               TransNumber: '',
    //               PrintDate: '',
    //               Printer: '',
    //               Products: {
    //                 Product: saleorder?.productOrders?.map(val => ({
    //                   ProductName: `${val?.masterProduct?.nameEN}`,
    //                   Code: `${val?.masterProduct?.code3A}`,
    //                   ProdUnit: `${val?.masterProduct?.UOM_VN}`,
    //                   ProdQuantity: `${val?.quantityInCarton}T - ${val?.quantityTin}L`,
    //                   Remarks: '',
    //                   ProdPrice: '',
    //                   Total: '',
    //                   Extra1: '1178413',
    //                   Extra2: `${moment(val?.expireDate, formatDateFull).format(formatDate)}`,
    //                   VATRate: '',
    //                   VATAmount: '',
    //                   Amount: '',
    //                 })),
    //               },
    //               CountTHU: '',
    //               WeightTON: '',
    //               CountItem: '',
    //               SoldVia: '',
    //               InvoiceSigner: '',
    //               Total: '',
    //               DiscountAmount: '',
    //               VATRate: '',
    //               VATAmount: '',
    //               Amount: '',
    //               AmountInWords: '',
    //               PaymentStatus: '',
    //               ResourceCode: '',
    //               GrossValueNon: '',
    //               GrossValue0: '',
    //               GrossValue5: '',
    //               GrossValue10: '',
    //               VatAmountNon: '',
    //               VatAmount0: '',
    //               VatAmount5: '',
    //               VatAmount10: '',
    //               AmountNon: '',
    //               Amount0: '',
    //               Amount5: '',
    //               Amount10: '',
    //             },
    //           },
    //         },
    //       },
    //     ];

    //     const fileName = 'ExportXML';
    //     const fields = [
    //       'Invoices',
    //       'Inv',
    //       'key',
    //       'Invoice',
    //       'DelieriName',
    //       'IRNUMBER',
    //       'DeliverDate',
    //       'DeliverContent',
    //       'NoilapYC',
    //       'OrgNameOut',
    //       'StoreOut',
    //       'FromAddress',
    //       'OrgNameIn',
    //       'ToAddress',
    //       'CusCode',
    //       'CusName',
    //       'Buyer',
    //       'CusAddress',
    //       'DVQLKHO',
    //       'DeliverAddress',
    //       'DeliverTaxCode',
    //       'DeliverPhone',
    //       'ComFax',
    //       'DeliverPhone',
    //       'ComBankNo',
    //       'ComBankName',
    //       'CurrencyCode',
    //       'TyGia',
    //       'VNMInvoiceType',
    //       'OrderNo',
    //       'DeliverCom',
    //       'ContractNo',
    //       'DeliverTruck',
    //       'TripNumber',
    //       'DeliverNumber',
    //       'TripNumber',
    //       'CusTaxCode',
    //       'PaymentMethod',
    //       'CusBankNo',
    //       'BookingNo',
    //       'Extra',
    //       'InvoiceDate',
    //       'TransNumber',
    //       'PrintDate',
    //       'Printer',
    //       'Products',
    //       'Product',
    //       'ProdName',
    //       'Code',
    //       'ProdUnit',
    //       'ProdQuantity',
    //       'Remarks',
    //       'ProdPrice',
    //       'Total',
    //       'Extra1',
    //       'Extra2',
    //       'VATRate',
    //       'Extra2',
    //       'VATAmount',
    //       'Amount',
    //       'CountTHU',
    //       'WeightTON',
    //       'CountItem',
    //       'SoldVia',
    //       'InvoiceSigner',
    //       'Total',
    //       'DiscountAmount',
    //       'VATRate',
    //       'VATAmount',
    //       'Amount',
    //       'AmountInWords',
    //       'PaymentStatus',
    //       'ResourceCode',
    //       'GrossValueNon',
    //       'GrossValue0',
    //       'GrossValue5',
    //       'GrossValue10',
    //       'VatAmountNon',
    //       'VatAmount0',
    //       'VatAmount5',
    //       'VatAmount10',
    //       'AmountNon',
    //       'Amount0',
    //       'Amount5',
    //       'Amount10',
    //     ]; //empty list means "use all"
    //     const exportType = 'xml';
    //     exportFromJSON({ data: dataXml, fileName, fields, exportType });
    //   }}
    // >
    //   {'Xuất XML'}
    //   <VerticalAlignBottomOutlined />
    // </Button>,
    saleorder?.orderType === 'EXPORT_INTERNAL' && (isStocker || isIdm) && saleorder?.internalTranferCode === null && <Button
      // disabled={saleorder?.status !== 'EXPORTED'}
      key="btn-export"
      type="primary"
      // ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
      <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;" margin: 0px auto>
        <div style="display: flex; height: 120px;">
          <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
            <img src="${logo}" style=" height: 100%; width: 100%" />
          </div>
          <div style="width: 60%; text-align: center; padding-top: 7vh; ">
            <h1>INTERNAL WAREHOUSE TRANSFER FORM</h1>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid;">
            <p> <b>Internal tranfer code: ${saleorder?.internalTranferCode ? saleorder?.internalTranferCode : ''} </b> </p>
            <p> <b>Số PXKKVCNB: </b></p>
            <p> <b>Diễn dãi:${saleorder?.description ? saleorder?.description : ''}</b></p>
          </div>
          <div style="display: grid;">
            <p> <b>{lang.printDate}: ${moment().format(formatDatetime)} </b> </p>
            <p> <b>{lang.transferDate}: ${moment().format(formatDate)}</b> </p>
            <p> <b>{lang.completionDate}:</b> </p>
          </div>
        </div>
        <div style="min-height: 200px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #009ada; ">
                <th rowspan="2"><div style=" display: flex; align-items: center;">Abbott code</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">3A code</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">ShortName</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Product name</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Lot & Exp date</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">From warehouse</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">To warehouse</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Carton</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Lon</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Damaged</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Deformed</div></th> 
                <th rowspan="2"><div style=" display: flex; align-items: center;">Defect</div></th>
                <th rowspan="2"><div style=" display: flex; align-items: center;">Missing</div></th>
              </tr>
            </thead>
            <tbody>
            ${saleorder?.productOrders.map((product) => {
          return `<tr style="text-align: center;">
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product?.masterProduct?.abbottCode16 ? product?.masterProduct?.abbottCode16 : ''}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product?.masterProduct?.code3A ? product?.masterProduct?.code3A : ''}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">&lt;${product?.lot}&gt;_${moment(product?.expireDate, formatDateFull).format(formatDate)}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${`${product?.locator ? product?.locator.locatorName : ''} - ${product?.locator ? product?.locator.locator : ''}`}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${`${saleorder?.locator ? saleorder?.locator.locatorName : ''} - ${saleorder?.locator ? saleorder?.locator.locator : ''}`}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product?.quantityInCarton ? product?.quantityInCarton : '' || product.quantityCartton ? product.quantityCartton : ''}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;">${product.quantityTin ? product.quantityTin : ''}</div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;"></div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;"></div></td>
                          <td><div style=" padding: 5px; display: flex; align-items: center;"></div></td> 
                          <td><div style=" padding: 5px; display: flex; align-items: center;"></div></td>
                      </tr>`;
        }).join(' \n')}
              <tr>
                <td colSpan="6">
                  <b style="min-height: 30px; display: flex; align-items: center;">Total</b>
                </td>
                <td><div style="min-height: 30px;padding: 5px; display: flex; align-items: center;"></div></td>
                <td style="font-weight: bold;">${saleorder?.productOrders ? saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0) : ''}</td>
                <td style="font-weight: bold;">${saleorder?.productOrders ? saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0) : ''}</td>
                <td style="font-weight: bold;"></td>
                <td style="font-weight: bold;"></td>
                <td style="font-weight: bold;"></td>
                <td style="font-weight: bold;"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>Picking Staff</b> </p>
            <p> <b>(Sign, full name)</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.warehouseKeeper}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.tableCreator}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 297;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('l', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuNoiBo${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportPdf}
    </Button>,
    // saleorder?.orderType === 'EXPORT_SALE' && <Button
    //   key="btn-create"
    //   type="primary"
    //   ghost
    //   onClick={async () => {
    //     try {
    //       const payload = Object.assign({}, saleorder);
    //       payload.orderId = saleorder?.id;
    //       payload.orderCode = saleorder?.code;
    //       payload.productDeliverys = saleorder?.productOrders?.map(product => {
    //         return {
    //           lineNo: product?.lineNo,
    //           code3A: product?.code3A,
    //           branch: product?.branch,
    //           description: product?.description,
    //           quantityInCarton: product?.quantityInCarton,
    //           quantityInTin: product?.quantityTin,
    //           quantity: product?.quantity,
    //           lot: product?.lot,
    //           expireDate: product?.expireDate,
    //           warehouseId: product?.warehouseId,
    //         };
    //       });
    //       delete payload.id;
    //       delete payload.createDate;
    //       delete payload.productOrders;
    //       await deliveryOrderCreate(payload);
    //       setDeleteProduct(!deleteProduct);
    //       message.success(lang.createSuccessfully);
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       return false;
    //     }
    //   }}
    //   disabled={saleorder?.deliveryOrder}
    // >
    //   {'Tạo Delivery Order'}
    // </Button>,
    // saleorder?.status === 'LOCK' && saleorder?.orderType === !('EXPORT_SALE') && <Button
    //   key="btn-unlock"
    //   type="primary"
    //   onClick={async () => {
    //     try {
    //       setLoading(true);
    //       await Promise.all([(await suggestUnLock({ orderId: saleorder?.id, warehouseId: saleorder.warehouseId, subChannel: saleorder?.subChannel })),
    //       orderUpdateStatus({ ids: [saleorder?.id], status: 'NEW' })]);
    //       saleorderDetail(saleorderID)
    //         .then(result => {
    //           setSaleorder(result);
    //           if (result.status === 'LOCK') {
    //             productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
    //           }
    //           if (result.status !== 'EXPORT') {
    //             setProductOrder(result?.productOrders);
    //             setLoading(false);
    //           } else {
    //             return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
    //           }
    //         })
    //         .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
    //         .catch((e) => setLoading(false));
    //       message.success('Unlock thành công');
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       setLoading(false);
    //       return false;
    //     }
    //   }}
    // >
    //   {'Unlock'}
    // </Button>,
    //   saleorder?.status === 'PICKING' && saleorder?.orderType === 'EXPORT_SALE' && <Button
    //   key="btn-unlock"
    //   type="primary"
    //   onClick={async () => {
    //     try {
    //       setLoading(true);
    //       await Promise.all([(await suggestUnLock({ orderId: saleorder?.id, warehouseId: saleorder.warehouseId, subChannel: saleorder?.subChannel })),
    //       orderUpdateStatus({ ids: [saleorder?.id], status: 'NEW' })]);
    //       saleorderDetail(saleorderID)
    //         .then(result => {
    //           setSaleorder(result);
    //           if (result.status === 'LOCK') {
    //             productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
    //           }
    //           if (result.status !== 'EXPORT') {
    //             setProductOrder(result?.productOrders);
    //             setLoading(false);
    //           } else {
    //             return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
    //           }
    //         })
    //         .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
    //         .catch((e) => setLoading(false));
    //       message.success('Unlock thành công');
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       setLoading(false);
    //       return false;
    //     }
    //   }}
    // >
    //   {'Unlock'}
    // </Button>,
    saleorder?.orderType === 'EXPORT_INTERNAL' && (isStocker) && saleorder?.status === 'NEW' && <Button
      key="btn-confirm"
      type="primary"
      onClick={async () => {
        Modal.confirm({
          title: lang.confirmProceedSure,
          async onOk() {
            try {
              setLoading(true);
              await orderUpdateStatus({ ids: [saleorder?.id], status: saleorder?.status === 'NEW' ? 'LOCK' : 'CONFIRM1', assignAll: true });
              message.success(lang.confirmationSuccessful);
              navigate(-1)
              saleorderDetail(saleorderID)
                .then(result => {
                  setSaleorder(result);
                  if (result?.status === 'LOCK') {
                    productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
                  } else if (result.orderType === 'EXPORT_INTERNAL') {
                    productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
                  }
                  if (result.status !== 'EXPORT') {
                    setProductOrder(result?.productOrders);
                    setLoading(false);
                  } else {
                    return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
                  }
                })
                .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
                .catch((e) => setLoading(false));
            } catch (err) {
              message.error(err.message);
            }
          },
          onCancel() { },

        });
      }}
      disabled={['CONFIRM1'].indexOf(saleorder?.status) !== -1 || (isIdm && saleorder?.status === 'NEW') || (isOutboundStaff && saleorder?.status !== 'NEW') || (isManager && saleorder?.status !== 'PICKING') || (isIdm && saleorder?.status === 'PICKING') || (isStocker && saleorder?.status === 'CONFIRM')}
    >
      {lang.btnConfirm}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('internal-tranfer') > -1 ? {
      path: '/internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL,
    } : {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: document.location.pathname.indexOf('internal-tranfer') > -1 ? `${lang.details} ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('internal-tranfer') > -1 ? `${lang.details} ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.code}</Descriptions.Item>
            {saleorder?.orderType === 'EXPORT_SALE' && <>
              <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
              <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
              <Descriptions.Item label={lang.orderReference}>{saleorder?.orderReference}</Descriptions.Item>
              <Descriptions.Item label={lang.businessLine}>{saleorder?.bussinessLine}</Descriptions.Item>
              <Descriptions.Item label={lang.abbottPOReference}>{saleorder?.abbottPOReference}</Descriptions.Item>
              <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
              <Descriptions.Item label={lang.dateOrdered}>{moment(saleorder?.dateOrdere).format(formatDate)}</Descriptions.Item>
              <Descriptions.Item label={lang.giftWarehouse}>{saleorder?.giftWarehouse}</Descriptions.Item>
              <Descriptions.Item label={lang.customerProgram}>{saleorder?.customerProgram}</Descriptions.Item>
              <Descriptions.Item label={lang.subChannel}>{saleorder?.subChannel}</Descriptions.Item>
            </>
            }
            {saleorder?.orderType !== 'EXPORT_SALE' && <>
              <Descriptions.Item span={saleorder?.orderType === 'EXPORT_BRANCH' ? 2 : 1} label={lang.locatorTo}>{saleorder?.orderType === 'EXPORT_BRANCH' ? `${saleorder?.locatorTransit?.locatorName} - ${saleorder?.locatorTransit?.locator}` : `${saleorder?.locator?.locatorName} - ${saleorder?.locator.locator}`}</Descriptions.Item>
              {saleorder?.orderType === 'EXPORT_BRANCH' && <>
                <Descriptions.Item label={lang.dispatchOrder}>{saleorder?.lDDNo}</Descriptions.Item>
                <Descriptions.Item label={lang.truckType}>{saleorder?.truckType}</Descriptions.Item>
                <Descriptions.Item label={lang.truckWeight}>{saleorder?.truckWeight}</Descriptions.Item>
              </>}
              <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate ? saleorder?.movementDate : moment()).format(formatDate)}</Descriptions.Item>
              <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
              {saleorder?.orderType === 'EXPORT_BORROW' && <Descriptions.Item label={lang.consignmentOrder}>{saleorder?.typeBorrow}</Descriptions.Item>}
            </>}
            {saleorder?.orderType === 'EXPORT_INTERNAL' &&
              <>
                <Descriptions.Item label={lang.salesChannel}>{saleorder?.channel ? `${saleorder?.channel?.channelName} - ${saleorder?.channel?.channel}` : ''}</Descriptions.Item>
                <Descriptions.Item label={lang.productionOrder}>{saleorder?.internalTranferCode ? saleorder?.internalTranferCode : ''}</Descriptions.Item>
                <Descriptions.Item label={lang.typeProductionOrder}>{saleorder?.typeProductionOrder ? TYPE_PRODUCTION_ORDER?.find(val => val.value === saleorder?.typeProductionOrder)?.label : ''}</Descriptions.Item>
              </>}
            <Descriptions.Item label={lang.status}>
              {saleorder?.orderType === 'EXPORT_INTERNAL' ? STATUS_INTERNAL_TRANFER[saleorder?.status] : STATUS_OUTBOUND[saleorder?.status]}
            </Descriptions.Item>
            <Descriptions.Item label={lang.description}>
              <Input onChange={(val) => setDescription(val.target.value)} value={description}></Input>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          // columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          columns={columns}
          // expandable={saleorder?.typeProductionOrder === 'PACKING' && {
          //   expandedRowRender,
          // }}
          scroll={{ x: 467 }}
        />
        {(!saleorder?.isConfirm || saleorder?.orderType === 'EXPORT_INTERNAL' || saleorder?.orderType === 'EXPORT_SALE') && <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{
            listProductCreatePallet?.length > 0 ? `${lang.productList} VAS` :
            saleorder?.isConfirm && saleorder?.typeProductionOrder === 'PACKING' ? `${lang.productList} ${lang.picked1}` :
            !saleorder?.isConfirm ? `${lang.productList} ${lang.picked1}` :
            lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsProduct}
          dataSource={listProductCreatePallet?.length > 0 ? listProductCreatePallet : productLock}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 2600 }}
        />}
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={lang.editProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await updateProductOrder({ id: value?.id, quantityInCarton: value?.quantityInCarton, quantityTin: value?.quantity });
              message.success(lang.updateSuccessfully);
              setDeleteProduct(!deleteProduct);
              setIsEditModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          }}
          onCancel={() => setIsEditModal(false)}
        // type={type}
        // locators={locators}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptDetail;
