export default {
  login: 'LOGIN',
  password: 'Password',
  slogan: 'See your growth and get consulting support!',
  akaName: 'OPTIMIZED WAREHOUSE MANAGEMENT',
  akaDescription: 'By Digital Transformation',
  intro: 'The akaWMS system is a solution developed with digital transformation in mind, providing flexible system integration alongside numerous outstanding process and technical advantages. It helps optimize traditional warehouse management methods, thereby enhancing the efficiency of warehouse operations and elevating the level of specialization.',
  copyright: 'The product belongs to the Logistics Suite developed by FPT Software Corporation.',
  placeholderPassword: 'Password',
  messagePassword: 'Please input your Password!',
  placeholderUsername: 'Username',
  messageUsername: 'Please enter your Username',
  loading: 'Loading',
  inbound: 'Inbound',
  receipt: 'PO',
  temporaryInbound: 'Temporary Inbound',
  ntaPurchaseOrder: 'NTA Purchase Order',
  giftReceipt: 'Gift Receipt',
  materialReceipt: 'Material Receipt',
  storing: 'Storing',
  maneuveringCommand: 'Maneuvering Command',
  maneuveringCommandGift: 'Maneuvering Command Gift',
  production: 'Production',
  unpack: 'UnPack',
  inboundControl: 'Storing',
  inventory: 'Inventory',
  warehouseState: 'Warehouse State',
  reallocation: 'Reallocation',
  productManagement: 'Product Management',
  outbound: 'Outbound',
  pickingOrder: 'Sale order',
  packaging: 'Packaging',
  shippingOutboundControl: 'Delivery order',
  configuration: 'Configuration',
  supplier: 'Supplier',
  product: 'Product',
  productCategory: 'Product category',
  location: 'Location',
  replenishment: 'Replenishment',
  saleOrder: 'Sale Order',
  reporting: 'Reporting',
  auditOrder: 'Audit Order',
  inventoryWarning: 'Inventory Warning',
  performance: 'Performance',
  changePassword: 'Change Password',
  logout: 'Logout',
  confirm: 'Confirm',
  confirmCancelDO: 'Confirm Cancel DO',
  cancelDO: 'Cancel DO',
  confirmLogout: 'Do you want to log out?',
  warehouse: 'Warehouse',
  user: 'User',
  admin: 'Admin',
  receiptId: 'Receipt Id',
  receiptCode: 'Receipt code',
  receiptStatus: 'Receipt status',
  exportCode: 'Export code',
  inboundKind: 'Inbound kind',
  status: 'Status',
  confirmed: 'Confirmed',
  receiptDate: 'Receipt date',
  receiveDate: 'Receive date',
  inboundType: 'Inbound type',
  new: 'New',
  importFromOtherBranches: 'Import from other branches',
  internalImport: 'Internal import',
  idmConfirmed: 'IDM Confirmed',
  stockerConfirmed: 'Stocker confirmed',
  inboundStatus: 'Inbound status',
  stockReceiving: 'Stock receiving',
  stockReceived: 'Stock received',
  receiptGiftList: 'Gift Receipt List',
  erpIntegration: 'ERP Integration',
  containerNo: 'Container No.',
  from: 'From',
  to: 'To',
  creator: 'Creator',
  keyword: 'Keyword',
  search: 'Search',
  receiptList: 'Receipt List',
  doYouWantToDeleteTheseItems: 'Do you want to delete these items?',
  deleteReceiptsSuccessfully: 'Delete receipts successfully',
  receiptIdsHasBeenDeleted: 'Receipt IDs has been deleted:',
  productCode: 'Product code',
  productName: 'Product name',
  expectedQuantity: 'Expected quantity',
  cost: 'Purchase price',
  salePrice: 'Sale price',
  expireDate: 'Expiration date',
  createReceipt: 'Create Receipt',
  createReceiptSuccessfully: 'Create receipt successfully',
  pleaseSelectAInboundKind: 'Please select a inbound kind',
  messageReceiptDate: 'Please enter your Receipt date',
  messageReceiveDate: 'Please enter Receive date',
  productAdded: 'Product added',
  importCSV: 'Import Excel',
  pleaseSelecteAProduct: 'Please select a product',
  messageExpectedQuantity: 'Please input a expected quantity',
  messageCost: 'Please input a purchase price',
  totalQuantity: 'Total quantity',
  stockAvailableForSale: 'Available quantity',
  quantityAwaitingDispatch: 'Export pending quantity',
  totalQuantityTins: 'Total tin quantity',
  totalQuantityTin: 'Total tin quantity',
  totalQuantityTinErp: 'Total tin quantity (ERP)',
  totalQuantityErp: 'Total quantity (ERP)',
  totalAvailableCartonQuantity: 'Total available carton quantity',
  totalUsableSeparatedTinQuantity: 'Total usable separated tin quantity',
  totalAvailableTinQuantity: 'Total available tin quantity',
  zone: 'Zone',
  block: 'Block',
  shelf: 'Shelf',
  rack: 'Rack',
  suggestion: 'Suggested',
  confirmReceiptSuccessfully: 'Confirm receipt successfully',
  receiptDetail: 'Receipt detail',
  productList: 'Product list',
  updateLocationSuccessfully: 'Update location successfully',
  updateLocation: 'Update location',
  print: 'Print',
  messageTotalQuantity: 'Please input a total quantity',
  importDate: 'Import date',
  report: 'Report',
  scanReturnProduct: 'Scan return product',
  reportErrorProduct: 'Report error product',
  reportSuccessfully: 'Report successfully',
  reportContent: 'Report content',
  messageReportContent: 'Please input a report content',
  btnSubmit: 'Submit',
  inputCode: 'Input code',
  packageCode: 'Package code',
  locationCode: 'Location code',
  completeScanReturnProductSuccessfully: 'Complete scan return product successfully',
  scanReturnProductComplete: 'Scan return product complete',
  scanMore: 'Scan more',
  scan: 'Scan',
  storingDate: 'Storing date',
  scanProduct: 'Scan product',
  storingScanProduct: 'Storing scan product',
  completeScanStoringSuccessfully: 'Complete scan storing successfully',
  storingScanProductComplete: 'Scan product list',
  inboundControlDate: 'Inbound control date',
  inboundControlScanProduct: 'Inbound control scan product',
  completeInboundControlScanSuccessfully: 'Complete inbound control scan successfully',
  inboundControlScanProductComplete: 'Scan product list',
  username: 'Username',
  name: 'Name',
  role: 'Role',
  users: 'Users',
  requiredUsername: 'Username is required',
  requiredPassword: 'Password is required',
  requiredName: 'Name is required',
  requiredRole: 'Role is required',
  requiredWarehouse: 'Warehouse is required',
  warehouseCode: 'Warehouse code',
  warehouseName: 'Warehouse name',
  address: 'Address',
  warehouses: 'Warehouses',
  acreage: 'Acreage',
  btnCancel: 'Cancel',
  btnComplete: 'Complete',
  btnUpdate: 'Update',
  btnCreate: 'Create',
  btnSearch: 'Search',
  btnAdd: 'Add',
  btnEdit: 'Edit',
  btnConfirm: 'Confirm',
  btnDelete: 'Delete',
  btnSave: 'Save',
  confirmQuestion: 'The content has been modified, your changes will be discarded. Do you want to proceed?',
  confirmContent: 'If you cancel, the edited contents will be discarded.',
  yes: 'Yes',
  addZone: 'Add Zone',
  addShelf: 'Add Shelf',
  addBlock: 'Add Block',
  zoneDetail: 'Zone Detail',
  zoneCreate: 'Create Zone',
  zoneName: 'Zone name',
  zoneAreDeletedSuccessfully: 'Zone are deleted successfully',
  blockAreDeletedSuccessfully: 'Block are deleted successfully',
  shelfAreDeletedSuccessfully: 'Shelf is deleted successfully',
  areYouSureToDeleteThisZone: 'Are you sure to delete this zone?',
  areYouSureToDeleteThisBlock: 'Are you sure to delete this block?',
  areYouSureToDeleteThisShelf: 'Are you sure to delete this shelf?',
  placeholderName: 'Please input name',
  placeholderSelectMethod: 'Please select method',
  requiredCategoryName: 'Product category name is required',
  requiredMethod: 'Method export is required',
  placeholderSelectProductCate: 'Please select product category',
  placeholderAddress: 'Please input address',
  placeholderPhoneNumber: 'Please input phone number',
  requiredCategory: 'Product category is required',
  requiredSupplierName: 'Supplier name is required',
  requiredEmail: 'Email is required',
  requiredAddress: 'Address is required',
  requiredPhoneNumber: 'Phone number is required',
  placeholderSelectSupplier: 'Please select supplier',
  placeholderCapacity: 'Please input capacity',
  requiredImage: 'Image is required',
  requiredProductName: 'Product name is required',
  requiredSupplier: 'Supplier is required',
  requiredCapacity: 'Capacity is required',
  requiredStoreTime: 'Store time in inventory is required',
  placeholderMin: 'Please input min',
  placeHolderMax: 'Please input max',
  requiredMin: 'Min quantity is required',
  requiredMax: 'Max quantity is required',
  placeholderSelectProduct: 'Please select product',
  requiredProduct: 'Product is required',
  requiredZoneName: 'Zone name is required',
  requiredBlockName: 'Block name is required',
  placeholderTotalshelf: 'Please input total shelf',
  requiredTotalshelf: 'Total shelf is required',
  requiredShelfName: 'Shelf name is required',
  placeholderTotalRack: 'Please input total rack',
  requiredTotalRack: 'Total rack is required',
  placeholderLevelPercent: 'Please input level percent',
  requiredLevelPercent: 'Level percent is required',
  placeholderQuantity: 'Please input quantity',
  requiredQuantity: 'Quantity is required',
  requiredDocumentType: 'Document type is required',
  quantityBook: 'Quantity Book',
  quantityBookIsNegative: 'Quantity book is negative',
  capacity: 'Capacity',
  success: 'Success',
  blockDetail: 'Block Detail',
  blockCreate: 'Create Block',
  blockName: 'Block name',
  totalShelf: 'Total shelf',
  updateShelf: 'Update Shelf',
  createShelf: 'Create Shelf',
  complete: 'Complete',
  shelfName: 'Shelf name',
  capacityRack: 'Capacity rack',
  levelPercent: 'Level percent',
  medium: 'medium',
  high: 'high',
  code: 'Code',
  createSuccessfully: 'Create successfully',
  updateSuccessfully: 'Update successfully',
  deleteSuccessfully: 'Delete successfully',
  idsHasBeenDeleted: 'IDs has been deleted:',
  email: 'Email',
  orderCode: 'Order code',
  userName: 'User name',
  orderDate: 'Order date',
  orderQuantity: 'Order quantity',
  pickingquantity: 'Picking quantity',
  placeholderSelectOrderCode: 'Select order code',
  titlePicking: 'Order and picking information',
  suggestLocation: 'Suggest location',
  quantity: 'Quantity',
  createPicking: 'Create pick up list',
  labelBooking: 'Booking order',
  placeholderSelectOrder: 'Select order',
  okText: 'Ok',
  cancelText: 'Cancel',
  titleModalBooking: 'Select booking order item',
  scanCode: 'Scan code',
  confirmPickupList: 'Confirm pick up list',
  titleModalPslitProduct: 'Split Product',
  placeholderSelectPackageCode: 'Select package code',
  btnDone: 'Done',
  btnClose: 'Close',
  titleModalSuccessSuggestLocation: 'Suggest location are printed successfully',
  pickupList: 'Pick up list',
  generatePackageCode: 'Generate package code',
  orderNumber: 'Order number',
  productOrderCode: 'Product order code',
  pickingList: 'Picking list',
  packagingCode: 'Package code',
  tempOutLot: 'Temp out lot',
  orderandpackagingInfo: 'Order and packaging information',
  packagingDetail: 'Package detail',
  orderandshippingInfo: 'Order and shipping information',
  confirmPackageList: 'Confirm package list',
  outQuantity: 'Out quantity',
  startDate: 'Start date',
  endDate: 'End date',
  orderInfo: 'Order information',
  inStock: 'In stock',
  outOfStock: 'Out of stock',
  selectProduct: 'Select product',
  expirationDate: 'Expiration date',
  storageExpirationDate: 'Storage expiration date',
  exportCSV: 'Export Excel',
  agingStockAlert: 'Aging Stock Alert',
  dotRestock: 'Please restock for the next delivery',
  productDetail: 'Product Detail',
  total: 'Total',
  inventorySummary: 'Inventory Summary',
  inboundsummary: 'Inbound Summary',
  productSummary: 'Product Summary',
  action: 'Action',
  warehouseBlockDetail: 'Warehouse Block Detail',
  requiredReportContent: 'Report content is required',
  editLocation: 'Edit location',
  dotEditLocation: 'Please select the products that you want to reallocate to edit',
  onlyEditLocation: 'Only one line can be edited at a time',
  pendingState: 'Pending state',
  inboundState: 'Inbound state',
  lostState: 'Lost state',
  updateProduct: 'Update product',
  requiredZone: 'Zone is required',
  requiredLocator: 'Locator is required',
  requiredBlock: 'Block is required',
  requiredShelf: 'Shelf is required',
  requiredRack: 'Rack is required',
  requiredExpirationDate: 'Expiration date is required',
  requiredTotalQauntity: 'Total quantity is required',
  requiredCost: 'Purchase price is required',
  requiredSalePrice: 'Sale price is required',
  requiredStatus: 'Status is required',
  requiredLostDate: 'Lost date is required',

  totalPerPallet: 'Number product per pallet',
  sepratePallet: 'Separate pallet',
  totalPallet: 'Number of pallet',
  totalRackbetween: 'Total rack',
  backtoProductList: 'Back to Product List',
  back: 'Back',
  printQrCode: 'Print QR code',
  stocktaking: 'Stocktaking',
  productInfo: 'Product Info',
  storageTime: 'Storage time in inventory (day)',
  productImage: 'Product Image',
  imageExtra: 'You can upload up to an image of 5MB',
  upload: 'Upload',
  methodExport: 'Method export product',
  createDate: 'Create date',
  createProduct: 'Create product',
  productCategoryName: 'Product category name',
  productCategoryCode: 'Product category code',
  productCategoryList: 'Product category list',
  updateProductCategoty: 'Update product category',
  createProductCategoty: 'Create product category',
  minQuantity: 'Min quantity',
  maxQuantity: 'Max quantity',
  notification: 'Notification',
  onHand: 'Onhand',
  toOrder: 'To order',
  replenishmentList: 'Replenishment list',
  createReplenishment: 'Create replenishment',
  updateReplenishment: 'Update replenishment',
  backtoSupplierList: 'Back to Supplier List',
  supplierInfo: 'Supplier Info',
  phone: 'Phone number',
  cooperationDate: 'Cooperation date',
  supplierName: 'Supplier name',
  requiredCooperationDate: 'Cooperation date is required',
  supplierCode: 'Supplier code',
  supplierList: 'Supplier list',
  createSupplier: 'Create supplier',
  updateSupplier: 'Update supplier',
  createSaleOrder: 'Create Sale Order',
  addProduct: 'Add product',
  editProduct: 'Edit product',
  saleOrderDetail: 'Sale Order detail',
  orderList: 'Order list',
  reportDate: 'Report date',
  firstName: 'First name',
  lastName: 'Last name',
  lostDate: 'Lost date',
  phoneLengthMessage: 'Phone number cannot be longer than 11 characters',
  phoneMessage: 'Phone number must be numbers',
  full: 'Full',
  empty: 'Empty',
  Medium: 'Medium',
  resetPassword: 'Reset password',
  passwordNew: 'New password',
  currentPassword: 'Current password',
  passwordConfirm: 'Confirm password',
  zoneNameExist: 'Zone name is existed',
  blockNameExist: 'Block name is existed',
  shelfNameExist: 'Shelf name is existed',
  subTitleInboundControl: 'Please scan product location before placing on the shelf',
  subTitleOutboundCreatePickUpNotify: 'Please select booking order item',
  subTitleStoringNotify: 'Please scan temporary inbound QR code before moving',
  subTitleInboundTemporaryNotify: 'Please scan Temporary inbound QR code before moving',
  no: 'No',
  passwordRegex: 'Password must be at least 8 characters',
  updateImageLess: 'Please upload photos from 5MB or less',
  creatorName: 'Creator',
  invalidCode: 'Invalid code',
  tryAgain: 'Please try again',
  orderStatus: 'Order status',
  packageStatus: 'Package status',
  noRecordsFound: 'No records found!',
  emailRequirePlaceholder: 'Please input email',
  messeageExpiration: 'Expiration date is required',
  unlockAccount: 'Unlock account successfully',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  splitproduct: 'Split Product',
  split: 'Split',
  splitProductList: 'Split product list',
  vans: 'Vans',
  addVans: 'Add Vans',
  createVans: 'Create vans',
  updateVans: 'Update vans',
  vansName: 'Vans name',
  vansLicensePlate: 'License plate',
  placeholderLicensePlate: 'Please input license plate',
  requiredLicensePlate: 'License plate is required',
  requiredVansName: 'Vans name is required',
  vansState: 'Vans State',
  vansDetail: 'Vans Detail',
  defaultCode: 'Barcode',
  requiredDefaultCode: 'Default code is required',
  placeHolderDefaultCode: 'Please input default code',
  placeholderSalePrice: 'Please input sale price',
  retailPrice: 'Retail price',
  placeholderRetailPrice: 'Please input sale price',
  lot: 'Lot',
  placeholderLot: 'Please input Lot',
  requiredLot: 'Lot is required',
  vat: 'vat',
  divison: 'Divison',
  category: 'Category',
  divisonCode: 'Divison code',
  divisonName: 'Divison name',
  createDivison: 'Create Divison',
  updateDivison: 'Update divison',
  placeholderDivisonName: 'Please input divison name',
  requiredDivisonName: 'Divison name is required',
  categoryCode: 'Category code',
  categoryName: 'Category name',
  createCategory: 'Create category',
  updateCategory: 'Update category',
  placeholderCategoryName: 'Please input category name',
  requiredParentCategoryName: 'Category name is requried',
  requiredDivison: 'Divison is requried',
  requiredParentCategory: 'Category is requried',
  categoryList: 'Category list',
  divisonList: 'Divison list',
  DVT: 'Unit',
  isActive: 'Is active',
  contractNumber: 'Contract number',
  packingRule: 'Packing rule',
  NEW: 'NEW',
  ERROR: 'ERROR',
  TEMPORARY: 'TEMPORARY',
  STORED: 'STORED',
  MOVING: 'MOVING',
  TEMPORARY_OUT: 'TEMPORARY_OUT',
  LOST: 'LOST',
  REALLOCATE: 'REALLOCATE',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  requestReceipt: 'Ap Invoice',
  note: 'Note',
  itemCode: 'Item code',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  RECEIVE: 'RECEIVE',
  dowloadTemplate: 'Dowload template',
  importSuccess: 'Import Successfully',
  exportPdf: 'Export PDF',
  discount: 'Discount',
  isResources: 'Resources',
  vansCode: 'Vans Code',
  createPo: 'Create Purchase Order',
  taxCode: 'Tax code',
  requiredPackingRule: 'Packing rule is required',
  CANCELLED: 'CANCELLED',
  PICKING: 'PICKING',
  PACKAGED: 'PACKAGED',
  deliveryTime: 'Delivery time',
  messeagePicking: 'Orders are picking by another user',
  bom: 'BOM',
  bomList: 'BOM list',
  caseUOM: 'BOM / case UOM',
  average: 'Average',
  wastage: 'Wastage',
  createBom: 'Create BOM',
  updateBom: 'Update BOM',
  bomCode: 'BOM code',
  codeNpl: 'Code',
  producerRule: 'Producer rule',
  cartonRule: 'Carton rule',
  grossWeightPerCarton: 'Gross weight/ carton',
  cartonPerPallet: 'Carton / pallet',
  abbottCode16: 'Abbott Code',
  threeACode: '3A Code',
  shortname: 'Short Name',
  nameEn: 'Name - English',
  UOM_EN: 'Unit - English',
  nameVn: 'Name - Vietnamese',
  UOM_VN: 'Unit - Vietnamese',
  flavor: 'Flavor',
  tinPerCarton: 'Tin/ Carton',
  brand: 'Brand',
  skuGroup: 'SKU Group',
  fiveKeybrand: 'Keybrand',
  countryOfOrigin: 'Country Of Orgin',
  size: 'Size',
  shelfLifeMonths: 'Shelf Life',
  typeOfProduct: 'Product Type',
  ctnsPerLayer3A: '3A - Carton/Layer ',
  layersPerPallet3A: '3A - Layer/ Pallet',
  ctmsPerPalletStorage3A: '3A - Carton/ Pallet ',
  ctnsPerLayer3AFloor: '3A - Carton/ Layer - Floor',
  layersPerPallet3AFloor3: '3A - Layer/ Pallet - Floor',
  ctnsPerPalletStorage3AFloor4: '3A - Carton/ Pallet - Floor',
  ctnsPerLayer2Import: 'IMPORT - Carton/ Layer - Floor',
  layersPerPallet3Import: 'IMPORT - Layer/ Pallet - Floor',
  ctnsPerPalletImport: 'IMPORT - Carton/ Pallet - Floor',
  isPackagedSku: 'Package?',
  milkCode: 'Milk code?',
  subBrandFollowAds: 'Sub brand - ADS',
  typeOfProduct2: 'Type of product',
  grossWeightKgPerCase: 'GROSS weight (kg)/ Case',
  netWeightGramPerTins: 'NET weight (gr)/ Tins',
  netWeightKgPerCase: 'NET weight (kg)/ Case',
  volumeCm3: 'Volumn (cm3)',
  grossWeightKgPerPallet: 'GROSS weight (kg)/ Pallet',
  netWeightKgPerPallet: 'NET weight (kg)/ Pallet',
  lengthCartonCm: 'Lenght carton (cm)',
  widthCartonCm: 'Width carton (cm)',
  heightCartonCm: 'Height carton (cm)',
  volumeCartonCm3: 'Volume caton (cm3)',
  BARCODE: 'Barcode',
  bomGift: 'BOM gift',
  bomGiftlist: 'BOM gift list',
  createBomGift: 'Create BOM gift',
  updateBomGift: 'Update BOM gift',
  productBomCode: 'Product BOM code',
  umIsFreeItem: 'Is free?',
  masterproductPacking: 'Packing product',
  masterproductPackingList: 'Packing product list',
  createMasterproductPacking: 'Create packing product',
  updateMasterproductPacking: 'Update packing product',
  type: 'Type',
  double: 'Double wrapping machine',
  single: 'Single machine',
  area: 'Area',
  areaList: 'Area list',
  areaName: 'Area name',
  areaCode: 'Area code',
  areaCreate: 'Create area',
  areaUpdate: 'Update area',
  locator: 'Locator',
  locatorList: 'Locator list',
  locatorName: 'Locator name',
  locatorCode: 'Locator code',
  locatorCreate: 'Create locator',
  locatorUpdate: 'Update locator',
  apInvoiceList: 'Ap Invoice list',
  apInvoiceCode: 'Ap Invoice code',
  updateApInvoice: 'Update Ap Invoice',
  apInvoiceDetail: 'APInvoice detail',
  productListDivided: 'Product list divied',
  giftCode: 'Gift code',
  giftName: 'Gift name',
  UOM: 'UOM',
  PCSCase: 'PCS/CASE',
  specification: 'Specification',
  lengthPcs: 'Lenght PCS',
  widthPcs: 'Width PCS',
  heightPcs: 'Height PCS',
  grossWeightPcs: 'Gross weight (PCS)',
  volumePcs: 'Volume PCS',
  grossWeight3A: 'Gross weight (3A)',
  ctnsPerLayer2Vender: 'Ctns/Layer2(Vender)',
  layersPerPallet3Vender: 'Layers /Pallet3(Vender)',
  ctnsPerPalletVender: 'Ctns/palleVender)',
  grossWeightVender: 'Gross weight (Vender)',
  isRack: 'Is rack',
  salesChannel: 'Sales channel',
  saleChannelList: 'Sales channel list',
  salesChannelCode: 'Sales channel code',
  salesChannelName: 'Sales channel name',
  quota: 'Quota',
  shelfLife: 'Shelf life',
  createSalesChannel: 'Create sales channel',
  updateSalesChannel: 'Update sales channel',
  shelftype: 'Shelf type',
  quantityCartton: 'Quantity carton',
  quantityTin: 'Quantity tin',
  quantityCarttonTaken: 'Quantity carton taken',
  quantityCarttonLeft: 'Quantity carton leave',
  quantityTaken: 'Quantity tin taken',
  quantityLeft: 'Quantity tin left',
  quantityCarttonReceived: 'Quantity carton received',
  quantityTinReceived: 'Quantity tin received',
  company: 'Company',
  branch: 'Branch',
  orderReference: 'Order reference',
  businessLine: 'Business line',
  abbottPOReference: 'Abbott PO reference',
  description: 'Description',
  dateOrdered: 'Date ordered',
  giftWarehouse: 'Gift warehouse',
  customerProgram: 'Customer program',
  subChannel: 'Sub channel',
  RETURN: 'RETURN',
  INTERNAL: 'INTERNAL',
  BORROW: 'BORROW',
  IMPORT: 'IMPORT',
  returnDate: 'Return date',
  movementDate: 'Movement date',
  tranferReason: 'Tranfer reason',
  locatorTo: 'Locator to',
  movementQuantity: 'Movement quantity',
  documentNo: 'Document no',
  documentType: 'Document type',
  perpetualInventory: 'Perpetual inventory',
  orderType: 'Order type',
  consignmentOrder: 'Consignment order',
  borrowed: 'Borrowed',
  enterBorrow: 'Enter borrow',
  EXPORT_INTERNAL: 'Export internal',
  exportInternalGift: 'Export internal Gift',
  EXPORT_SALE: 'Export sale',
  EXPORT_BORROW: 'Export borrow',
  masterproductsbomgift: 'Master products bom gift',
  createMasterproductsbomgift: 'Create master products bom gift',
  updateMasterproductsbomgift: 'Update master products bom gift',
  masterproductsbomgiftList: 'Master products bom gift list',
  Vas: 'Vas',
  completeVasScanSuccessfully: 'Complete vas scan successfully',
  VAS: 'VAS',
  floor: 'Floor',
  productionOrder: 'Production order',
  productionOrderList: 'Production order list',
  createProductionOrder: 'Create production order',
  productionOrderDetail: 'Production order detail',
  confirmProductionOrderProduct: 'Production order product confirm',
  createComboProduct: 'Create combo product',
  invoiceNo: 'Invoice No',
  orderNo: 'Order No',
  contractNo: 'Contract No',
  shipment: 'Shipment No',
  shipmentNo: 'Shipment No',
  mfgDate: 'MFG date',
  btnlock: 'Lock',
  lockproductSuccessfully: 'Lock products successfully',
  picking: 'Picking',
  expectedQuantityCarton: 'Expected quantity carton',
  expectedQuantityTin: 'Expected quantity tin',
  requiredContainerNo: 'Container no is required',
  locatorFrom: 'Locator from',
  receivedRemainingQuantityCarton: 'Received remaining carton quantity',
  receivedRemainingQuantityTin: 'Received remaining tin quantity',
  orderConfirm: 'Order confirm',
  orderConfirmCreate: 'Create order confirm',
  inventoryManagement: 'Inventory Management',
  inventoryProductBySalesChannel: 'Inventory by Sales Channel',
  inventoryProductByChannel: 'Inventory Channel',
  lDDNo: 'Dispatch Order Number',
  truckType: 'Truck type',
  noPallet: 'No pallet',
  quantityCarttonRemainder: 'Quantity cartton remainer',
  quantityTinRemainder: 'Quantity tin remainer',
  warehouseZoneDetail: 'Zone detail',
  customerReturn: 'Customer RMA',
  customerReturnList: 'Customer RMA list',
  customerReturnCreate: 'Create Customer Return',
  customerReturnDetail: 'Customer Return Detail',
  targetDocType: 'Target doc type',
  rmaCategory: 'RMA category',
  origSalesOrder: 'Original Sales Order',
  origDO: 'Orig DO',
  DODate: 'DO date',
  businessPartner: 'Business partner',
  billTo: 'Bill to',
  partnerLocation: 'Partner location',
  billToLocation: 'Bill To Location',
  representative: 'Representative',
  campaign: 'Campaign',
  department: 'Department',
  origDOLine: 'Orig DO line',
  attributeSetInstance: 'Attribute Set Instance',
  unitPrice: 'Unit Price',
  createReceiptReturn: 'Create Receipt Return',
  rmaDate: 'RMA Date',
  availableProduct: 'Available Product',
  exportBranch: 'Export Branch',
  BRANCH: 'BRANCH',
  HOLD: 'HOLD',
  AV: 'AV',
  rcnew: 'IMPORT',
  EXPORT: 'EXPORT',
  enablehd: 'Active',
  COMPLETED: 'COMPLETED',
  NORMAL: 'NORMAL',
  GIFT: 'GIFT',
  giftInventory: 'Inventory Gift',
  inventoryMaterial: 'Inventory Material',
  physicalInventory: 'Physical Inventory',
  physicalInventoryGiftDetail: 'Physical Inventory Gift Detail',
  phyAdj: 'Phy Adj',
  home: 'Home',
  createPhysicalInventory: 'Create Physical Inventory',
  giftPhysicalInventory: 'Physical Inventory Gift',
  stocktakeAdjustment: 'Stocktake Adjustment',
  stockAdjustment: 'Stock Adjustment',
  exportPickingSlip: 'In Picking Slip',
  origDoLine: 'Original DO Line',
  exportBranchGift: 'Export Branch Gift',
  exportStocktakeAdjustment: 'Export Stocktake Adjustment',
  rcBranch: 'RC Branch',
  totalQuantityTinOdd: 'Total separated tin quantity',
  totalQuantityCartonsAwait: 'Export pending carton quantity',
  totalQuantityTinsAwait: 'Export pending tin quantity',
  totalQuantityOddTinsAwait: 'Export pending separated tin quantity',
  exportWarehouse: 'Warehouse Export Slip and Order',
  createExportWarehouse: 'Create Warehouse Export Slip',
  confirmSuccessfully: 'Confirmation Successful',
  takeQuantity: 'Quantity to Take',
  warehouseProductDetail: 'Warehouse Product Details',
  inboundStateGift: 'Inbound gift state',

  confirmOrderDetail: 'Confirm order detail',
  confirmOrder: 'Confirm order',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  CONFIRM1: 'CONFIRM1',
  CONFIRM2: 'CONFIRM2',
  TEMPORARYCONFIRM: 'Temporary confirm',
  INPROGRESS: 'In progress',
  EXPORTED: 'Exported',
  barcodeTins: 'Barcode tins',
  barcodeCarton: 'Barcode carton',
  itemGroupName: 'Item group name',
  handWork: 'Hand work',
  totalQuantityCartton: 'Total quantity carton',
  totalQuantityCartonLabel: 'Total carton quantity',
  quantityCarttonAtLocation: 'Quantity carton at location',
  quantityTinAtLocation: 'Quantity tin at location',
  rcreturn: 'RC return',
  rcinternal: 'RC internal',
  rcborrow: 'RC borrow',
  rcimport: 'RC import',
  chooseFile: 'Choose File!',
  truckWeight: 'Vehicle load capacity (tons)',
  iddNo: 'Dispatch order',
  listARInvoices: 'List of AR-invoices',
  internalTransferNote: 'INTERNAL STOCK TRANSFER SLIP',
  internalStockTransferNumber: 'Internal stock transfer slip number',
  internalUseExportNumber: 'Export Slip Number for Internal Use Materials',
  printDate: 'Print Date',
  transferDate: 'Transfer Date',
  completionDate: 'Completion Date',
  abbottCode: 'Abbott Code',
  code3A: '3A Code',
  lotNumberExpiryDate: 'Batch Number & Expiry Date',
  lotNumber: 'Batch Number',
  expiryDate: 'Expiry Date',
  fromWarehouse: 'From warehouse',
  toWarehouse: 'To Warehouse',
  carton: 'Carton',
  tin: 'Tin',
  damaged: 'Damaged',
  dented: 'Dented',
  missing: 'Missing',
  sum: 'Sum',
  pickingStaff: 'Picking Staff',
  signatureFullName: '(Signature, Full Name)',
  warehouseKeeper: 'Warehouse keeper',
  tableCreator: 'Table Creator',
  vehicleLoadCapacityTons: 'Vehicle Load Capacity (tons)',
  transit: 'Transit',
  totalExportSlips: 'Total number of export slips',
  threeANutritionLLCVietnam: '3A Nutrition Limited Liability Company (Vietnam)',
  date: 'Date',
  month: 'month',
  year: 'year',
  pickedProductList: 'List of Picked Products',
  itemNo: 'No.',
  productBatchCode: 'Product batch code',
  productGroup: 'Product Group',
  pickedProducts: 'Picked Products',
  entryDate: 'Entry Date',
  importedDate: 'Imported Date',
  canBeTaken: 'can be taken',
  lessThanCurrentCanQuantity: 'Less than the current quantity of cans',
  lessThanCurrentBoxQuantity: 'Less than the current quantity of boxes',
  serialNumber: 'Serial number',
  goodsReceiptPaymentAdjustmentSlip: 'Goods Receipt and Payment Adjustment Slip for the Program',
  salesUnit3ANutritionAbbottVietnam: 'Sales Unit: 3A Nutrition Abbott Co., Ltd. (Vietnam)',
  shippingAddressBinhTan: 'Shipping Address: Lot 6-1A, Cluster 6, M1 Street, Binh Tan Expansion Industrial Zone, Binh Hung Hoa Ward, Binh Tan District, Ho Chi Minh City',
  poNumber401550: 'PO Number: 401550',
  purchasingUnit: 'Purchasing unit',
  information: 'Information',
  fullName: 'Full Name',
  deliveryAddress: 'Delivery Address',
  billingAddress: 'Billing address',
  route: 'Route',
  customerType: 'Customer Type',
  salesStaff: 'Sales staff',
  payment: 'Payment',
  bankTransfer: 'Bank transfer',
  deliverySlipNumber: 'Delivery Slip Number',
  purchaseOrderNumber: 'Purchase Order Number',
  orderPlacementDate: 'Order Placement Date',
  deliveryDueDate: 'Delivery Due Date',
  purchaseOrderType: 'Purchase Order Type',
  purchaseOrderReferenceNumber: 'Purchase Order Reference Number',
  systemCode: 'System Code',
  quantityCansPackagesPack: 'Quantity (Cans/Packages/Pack)',
  batchNumberExpiryDate: 'Batch Number_Expiry Date',
  invoiceAmountBeforeDiscountInvoiceNumber: 'Invoice Amount Before Discount for Invoice Number',
  tradeDiscountInvoiceNumber: 'Trade Discount for Invoice Number',
  cumulativeTradeDiscountPreviousOrdersInvoiceNumber: 'Cumulative Trade Discount from Previous Orders for Invoice Number',
  programAmountOffsetPayables: 'Program Amount Offset Against Payables',
  remainingAmount: 'Remaining Amount',
  deliveryConfirmationOriginalVATInvoice: 'This slip confirms the delivery of goods and the provision of the original VAT invoice',
  copy2GivenToBuyerNoPaymentConfirmation: ' Copy 2: Given to the buyer, not valid as a confirmation of payment receipt',
  customerReceivedFullOrder: 'Customer has received the full order',
  customerReceivedFullOrderAndProgramPayment: 'Customer has received the full order and program payment',
  deliveryStaff: 'Delivery Staff',
  issueGoodsDeliveryNote: ' Issue Goods Delivery Note',
  confirmationSuccessful: 'Confirmation Successful!',
  warehouseExportSlip: 'WAREHOUSE EXPORT SLIP',
  fromDate: 'From date',
  deliveryStaffCode: 'Delivery Staff Code',
  vehicleNumber: 'Vehicle Number',
  doCreated: 'DO Created',
  doNotCreated: 'DO Not Created',
  materialExport: 'Material export',
  newCreate: 'New',
  exported: 'Exported',
  cancelled: 'Cancelled',
  slipDeletedSuccessfully: 'Slip deleted successfully!',
  materialExportSlipDetails: 'Material Export Slip Details',
  warning: 'Warning!',
  deliveryRoute: 'Delivery Route',
  printedTime: 'Printed Time',
  toDate: 'To Date',
  totalCarton: 'Total carton',
  dailyTransferSequence: 'Transfer Sequence of the Day',
  checkerStaff: 'Checker Staff',
  securityGuard: 'Security Guard',
  slipCreator: 'Slip Creator',
  issueExportSlip: 'Issue Export Slip',
  confirmProceedSure: 'Are you sure you want to confirm?',
  DOCancelledSuccessfully: 'DO canceled successfully!',
  waitingForPicking: 'Waiting for Picking',
  inPicking: 'In Picking',
  picked: 'Picked',
  pendingCancellation: 'Pending Cancellation',
  completedShipment: 'Completed Shipment',
  areYouSureGroup: 'Are you sure you want to group?',
  ordersGroupedSuccessfully: 'Orders grouped successfully!',
  groupPick: 'Group pick',
  areYouSureUngroup: ' Are you sure you want to ungroup?',
  ungroupSuccessful: 'Ungroup successful!',
  areYouSureAssign: 'Are you sure you want to assign?',
  assignSuccessful: 'Assign successful!',
  selectEmployee: 'Select employee',
  deliveryOrderList: 'Delivery order list',
  areYouSureCancelExportBranch: 'Are you sure you want to cancel ExportBranch?',
  cancelSuccessful: 'Cancel successful!',
  cancelExportBranch: 'Cancel Export Branch',
  exportXML: 'Export XML',
  pleaseSelectZone: 'Please select Zone',
  areYouSureConfirmSlip: 'Are you sure you want to confirm the slip?',
  slipType: 'Slip type',
  quantityCannotBeZero: 'Quantity cannot be zero',
  pleaseEnterQuantity: 'Please enter quantity',
  actualExportCans: 'Actual export (Cans)',
  actualImportCans: 'Actual import (Cans)',
  soDocNo: 'SO Document Number',
  doYouWantToContinue: 'Do you want to continue',
  itemQuantityShort: 'Item quantity is insufficient. Do you want to complete?',
  createMaterialExportSlip: 'Create Material Export Slip',
  dateFormat: 'Date___Month_____Year______',
  licensePlate: 'License plate',
  driverEmployee: 'Driver employee',
  exportIWS: 'Export IWS',
  internalTransferSlipWithInternalTransport: 'INTERNAL TRANSFER SLIP WITH INTERNAL TRANSPORT',
  productSuggestionList: 'PRODUCT SUGGESTION LIST',
  createDeliveryOrder: 'Create Delivery Order',
  createDeliveryOrderFailed: 'Create Delivery Order failed!',
  orderWithCode: 'Order with code',
  deliveryOrderCreated: 'Delivery Order created!',
  productNoExpiryDate: 'Product does not have an expiry date, please check again!',
  productNoLocator: 'Product does not have a locator, please check again!',
  productNoLot: 'Product does not have a lot, please check again!',
  deliveryOrderCreatedContinue: 'Delivery Order created! Do you want to continue?',
  list: 'List',
  areYouSureDeleteSlip: 'Are you sure you want to delete the slip?',
  orderProduct: 'Order product',
  lessThanStockQuantity: 'Less than stock quantity',
  quantityIsRequired: 'Quantity is required',
  invalidQuantity: 'Invalid quantity',
  quantityAvailableToPick: 'Quantity available to pick',
  order: 'order',
  materialExportSlipList: 'Material export slip list',
  all: 'All',
  exportBranch1: 'Export branch',
  internalExport: 'Internal export',
  exportSlipType: 'Export slip type',
  updateSuccessful: 'Update successful!',
  selectReceivingWarehouse: 'Select receiving warehouse',
  receivingWarehouse: 'Receiving warehouse',
  exportWSlipWithITransport: 'Export warehouse slip with internal transport',
  details: 'Details',
  picked1: 'Picked',
  companyDirector3AVietnam: 'DIRECTOR OF 3A NUTRITION CO., LTD (VIETNAM)',
  company3AVietnam: '3A NUTRITION CO., LTD (VIETNAM)',
  address3AVietnam: 'Lot 6-1A, Cluster 6, M1 Street, Tan Binh Extended Industrial Park, Binh Hung Hoa Ward, Binh Tan District, Ho Chi Minh City',
  hcm: 'Ho Chi Minh',
  centecTowerAddress: 'Centec Tower, 72-74, Nguyen Thi Minh Khai Street, Vo Thi Sau Ward, District 3, Ho Chi Minh City, Vietnam.',
  symbol: 'Symbol',
  number: 'Number',
  warehouseExportSlipConvertedFromEWarehouseSlip: 'WAREHOUSE EXPORT SLIP CONVERTED FROM ELECTRONIC WAREHOUSE EXPORT SLIP',
  basedOnDispatchOrderNo: 'Based on dispatch order number',
  onlyOneSelectionAllowed: 'Only one selection allowed',
  areYouSureCreateDOForSelectedOrders: 'Are you sure you want to create a Delivery Order for the selected orders?',
  pleaseSelectVehicleType: 'Please select vehicle type!',
  areYouSureCancelProductionOrder: 'Are you sure you want to cancel the production order?',
  locatorIsRequired: 'Locator is required',
  customerFullName: 'Customer full name',
  transporterFullName: 'Transporter full name',
  transportationMode: 'Transportation mode',
  exportAtWarehouse: 'Export at warehouse',
  importAtWarehouse: 'Import at warehouse',
  totalAmount: 'Total amount',
  export: 'Export',
  import: 'Import',
  slipCreatorOrConverter: 'Slip creator/Converter',
  warehouseReceivingUnit: 'Warehouse receiving unit',
  transportationUnit: 'Transportation unit',
  warehouseExportUnit: 'Warehouse export unit',
  signedBy: 'Signed by',
  signedDate: 'Signed date',
  orderedProductList: 'Ordered product list',
  suggestedProductList: 'Suggested product list',

  //configuration bom-list import template
  bomListTemplateColName: 'BOM case',
  bomListTemplateColProducerRule: '36 stamps/ sheet',
  bomListTemplateColCartonRule: '36 stamps/ sheet',
  bomListTemplateColGrossWeightPerCarton: '1000 sheets/ package',
  bomListTemplateColCartonPerPallet: '2160000 stamps/pallet',

  //configuration bom-gift edit form
  vietNameseName: 'VietNamese Name',
  active: 'Active',
  taxCategory: 'TaxCategory',
  lengthPcsUnit: 'L(cm) (PCS)',
  widthPcsUnit: 'W(cm) (PCS)',
  heightPcsUnit: 'H(cm) (PCS)',
  volumePcsUnit: 'Volume (PCS)',
  lengthCartonUnit: 'L(cm) (Carton)',
  widthCartonUnit: 'W(cm) (Carton)',
  heightCartonUnit: 'H(cm) (Carton)',
  grossWeight3AUnit: 'Gross Weight (3A QA)',
  volumeCartonUnit: 'Volume (Carton)',
  ctnsLayer3AUnit: 'Ctns/Layer (3A)',
  layerPallet3AUnit: 'Layers /Pallet (3A)',
  ctnsPallet3AStorageUnit: 'Ctns/pallet (Storage) (3A)',
  ctnsLayer3AFloorUnit: 'Ctns/Layer (3A/Floor)',
  ctnsLayer2VendorUnit: 'Ctns/Layer2 (Vendor)',
  layerPallet3VendorUnit: 'Layers /Pallet3 (Vendor)',
  ctnsPalletVendorUnit: 'Ctns/pallet (Vendor)',
  grossWeightVendorUnit: 'Gross Weight (Vendor)',

  //configuration location-list container
  divived: '(Drive-In)',
  shelfTemplateColShelfName: 'Shelf name',
  shelfTemplateColPosition: 'Position (Above, below)',
  shelfTemplateColTotalBin: 'Number of bins',
  shelfTemplateColAverageRate: 'Average rate(%)',
  shelfTemplateColHighRate: 'High rate(%)',
  shelfTemplateColVolume: 'Volume (cm)',
  shelfTemplateColBlockCode: 'Block code',
  shelfTemplateColTierNumber: 'Tier number',

  updateRackTitle: 'Update rack',

  bin: 'Bin',
  MATERIAL: 'MATERIAL',
  driveIn: 'Drive-in',

  //configuration zone modal
  zoneCapacity: 'Max number of pallets',
  zoneTempImport: 'TEMPORARY IMPORT',
  zoneTempExport: 'TEMPORARY EXPORT',

  //configuration locator
  pleaseInput: 'Vui lòng nhập',
  warehouseTo: 'Đến',

  //configuration sale order create product edit
  requireQuantityInCartonAndTin: 'Quantity in carton and tin required',
  totalQuantityInCarton: 'Total Quantity In cartons',
  lessThanStockCarton: 'Less than stocked cartons',
  quantityInCarton: 'Quantity in carton',
  totalQuantityInTin: 'Total Quantity In Tin',
  lessThanOneCarton: 'Less than 1 carton',
  lessThanStockTin: 'Less than stocked tins',
  quantityInTin: 'Quantity in tin',

  //configuration sale-order-create container
  pleaseSelectLocator: 'Please select locator!',
  expiryDateRequired: 'Expiry date is required!',
  distributorCode: 'Distributor code',
  distributorName: 'Distributor name',
  customerCode: 'Customer code',
  storeName: 'Store name',
  businessRegistrationName: 'Business registration name',
  customerTypeCode: 'Customer type code',
  customerTypeName: 'Customer type name',
  street: 'Street',
  ward: 'Ward',
  wardCode: 'WardCode',
  district: 'District',
  province: 'Province',
  homeInvoiceNo: 'Home Invoice No',
  streetNameInvoice: 'Street Name Invoice',
  provinceInvoice: 'Province Invoice',
  districtInvoice: 'District Invoice',
  wardCodeInvoice: 'Ward Code Invoice',
  country: 'Country',
  dateOfBirth: 'Date Of Birth',
  channelName: 'Channel Name',
  subChannelName: 'SubChannel Name',
  nielsenChannelName: 'Nielsen Channel Name',
  basedOnInventory: 'Based on inventory',
  exportInternal: 'Export Internal',

  //inventory invetory by sales channel
  available: 'available',

  adjustExpiredItems: 'Adjust Expired Items',
  qtyScrapped: 'Qty Scrapped',
  exportPhysicalInventory: 'Export Physical Inventory',

  //inbound
  customerReturnCode: 'Customer RMA Code',
  giftProductCode: 'Gift product code',
  giftProductName: 'Gift product name',
  selectVehicleNotice: 'Please select vehicle type!',
  selectLocatorTransitNotice: 'Please select transit locator!',
  dispatchOrder: 'Dispatch Order',
  dispatchOrderCode: 'Dispatch Order Code',
  createDispatchOrder: 'Create dispatch Order',
  dispatchOrderDetail:'Dispatch Order Detail',
  dispatchGiftOrder: 'Dispatch Gift Order',
  tons: 'tons',
  exportDispatchOrder: 'Export DO',
  exportDeliveryNotice: 'Export Delivery Notice',
  update:'Update',
  createNew: 'Create new',
  deleted: 'Deleted',
  canceled: 'Canceled',
  operationCreated: 'Operation created',
  cancelOrderSuccess: 'Cancel order successfully',
  updateOrderSuccess: 'Update order successfully',
  doYouWant: 'Do you want',
  deleteDispatchOrder: 'Delete dispatch order',
  successfully: 'successfully',
  quantityIsRequire: 'Quantity is required',
  availableQuantity: 'Available quantity',
  availableQuantityCarton: 'Available quantity carton',
  lessThanInventoryQuantity: 'Less than inventory quantity',
  branchShipmentOrder: 'Branch Shipment Order',

  //inbound
  poReference: 'PO reference',
  totalTinQuantityErp: 'Total Tin Quantity (ERP)',
  conversedBoxQuantityErp: 'Conversed box quantity (ERP)',
  separatedTinQuantityAfterConversionToBoxErp: 'Separated tin quantity after conversion to box (ERP)',
  pickedCanQuantity: 'Quantity Available for Picking',
  pickedCan: 'can pick',
  pickedNeed: 'need pick',
  inventoryItems: 'Inventory Items',
  inventoryGifts: 'Inventory Gifts',
  temporaryImportGiftList: 'Temporary Import Gift List',
  toCoordination: 'to coordination',
  coordinationPending: 'COORDINATION PENDING',
  coordinated: 'COORDINATED',
  partiallyReceived: 'PARTIALLY RECEIVED',
  fullyReceived: 'FULLY RECEIVED',
  notReceived: 'NOT RECEIVED',
  createExport: 'create export',
  create: 'Create',
  quantityInCartonLessThanTotal: 'The quantity taken must be less than the quantity in stock',
  quantityCartonAndTin: 'Quantity carton and tin',
  isRequire: 'is require',
  suggest: 'Suggest',
  temporaryImport: 'Temporary import',
  temporaryGift: 'Temporary gift',
  temporaryGiftList: 'Temporary gift list',
  unpackCode: 'Unpack Code',
  unpackDetail: 'Unpack detail',
  unpackProductList: 'Unpack product list',
  materialCode: 'Material Code',
  materialName: 'material name',
  cancelMaterial: 'Cancel material',
  cancelMaterialSuccessfully: 'Material cancel successfully',
  form: 'form',
  exportPGH: 'export delivery note',
  giftReceiptNoteDetail: 'Gift receipt note detail',
  materialReceiptDetail: 'Material receipt detail',
  standardImport: 'Standard import',
  branchImport: 'Branch import',
  warehouseReceiptConfirm: 'Warehouse receipt confirm',
  warehouseFrom: 'Warehouse From',
  receiptMaterial: 'Material receipt',
  receiptMaterialList: 'Material receipt list',
  formHaveCode: 'form have code',
  chooseRack: 'Choose Rack',
  pleaseChooseRack: 'Please choose rack',
  productionCode: 'Production Code',
  productionDetail: 'Production Detail',
  productionList: 'Production List',
  typeProductionOrder: 'Production Order type',
  requiredTypeProductionOrder: 'Production Order type required',
  importLabel: 'Import label',
  pastCard: 'Attach the scratch card',
  packing: 'Packing',
  deletedFormError: 'Cannot delete the document with a confirmed status',
  selectedItem: 'Selected Item',
  deleteProduction: 'Delete Production',
  rawMaterialsAndSuppliesInfo: 'Information on raw materials and supplies',
  quantityNeeded: 'Quantity needed',
  productNotConfiguredYet: 'The product is not configured yet',
  packingOrderFrom: 'From Packing Order',
  packingOrder: 'Packing Order',
  packingOrderRequired: 'Please select the Packing order',
  toLocator: 'To locatior',
  pleaseChooseProductionOrder: 'Please choose prodution order type',
  receiptProduct: 'Receipt product',
  quantityHasCreatedProduction: 'Quantity has created Prodution',
  quantityHasCreatedUnpack: 'Quantity has created UnPack',
  on: 'on',
  created: 'Created',
  btnPicking: 'picking',
  giftCombo: 'Combo gift',
  productionGift: 'Gift list',
  quantityConfigured: 'Configured quantity',
  quantityPicking: 'Quantity picking',
  returnPicking: 'Return picking',
  baseProduction: 'Base production',
  notEnough: 'not enough',
  packingProduction: 'Packing Production',
  unpackProduction: 'Unpack Production',
  CTMSPerPalletStorage3AMsg: 'No 3A standard - Number of cartons/pallet - Below floor level.',
  remainingQuantity: 'Remaining quantity',
  ERP: 'ERP',
  tongSL: 'Total Supply',
  phieuNhapKho: 'WarehouseReceipt',
  pleaseChooseStore: 'Please choose warehouse!',
  ntaPurchaseOrderDetail: 'NTA Purchase Order Detail',
  ntaPurchaseOrderList: 'NTA Purchase Order List',
  poCode: 'PO Code',
  notYetReceived: 'Not Yet Received',
  notYetCompleted: 'Not Yet Completed',
  completedEntry: 'Completed Entry',
  notYetAllocated: 'Not yet allocated',
  allocated: 'Allocated',
  apInvoiceCompleteMsg: 'The goods have been fully received, please check again!',
  productWithCode: 'Product with code',
  fullyTakenMsg: 'has been fully taken',
  giftInventoryReceipt: 'Gift Inventory Receipt',
  validatorTotalQuantityMsg: 'Incompatible quantity, please check again!',
  needToBeEntered: 'needs to be entered',
  invoiceType: 'Invoice Type',
  seller: 'Seller',
  partnerAddress: 'Partner Address',
  choosePackError: 'Please select the type of packaging',
  productCondition: 'Product Condition',
  chooseProductCondition: 'Choose Product Condition',
  packVAS: 'pack VAS',
  searchByStatus: 'Search by Status',
  packItems: 'Pack Items',

};
