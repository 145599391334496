import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import './style.less';
import moment from 'moment-timezone';
import { formatDate, formatDateAPi, formatDateFull } from 'utils/constants';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';

const ReceiptProductDetail = ({ item, onCancel, onCallback, divePallet, inboundKind, quantityCarttonItems, quantityTinItems }) => {
  // console.log(item)
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [change, setChange] = useState(false);
  const [locatorsList, setLocatorList] = useState([]);
  const [palletAmount, setPalletAmount] = useState();
  useEffect(() => {
    if (item) {
      const cartonPerPallet = inboundKind === 'BRANCH' ? item?.masterProduct?.CTMSPerPalletStorage3A : item?.masterProduct?.CTNSPerPalletImport;
      const cartonReceived = form.getFieldValue('quantityCarttonReceived');
      const tinReceived = form.getFieldValue('quantityTinReceived');
      let pallet = parseInt(cartonReceived / cartonPerPallet);
      const carttonRemainder = cartonReceived % cartonPerPallet;
      let quantityCarttonFromTin = parseInt(tinReceived / parseInt(item?.masterProduct?.tinPerCarton));
      if (tinReceived % parseInt(item?.masterProduct?.tinPerCarton) > 0) quantityCarttonFromTin += 1;
      const palletAdd = (carttonRemainder + quantityCarttonFromTin) % cartonPerPallet;
      if (palletAdd > 0) {
        pallet++;
      }
      pallet += parseInt((carttonRemainder + quantityCarttonFromTin) / cartonPerPallet);
      setPalletAmount(pallet);
      return form?.setFieldsValue({
        amountPallet: pallet,
        carttonRemainder: parseInt((carttonRemainder + quantityCarttonFromTin) / cartonPerPallet) === 0 ? carttonRemainder : 0,
        tinRemainder: parseInt((carttonRemainder + quantityCarttonFromTin) / cartonPerPallet) === 0 ? tinReceived : palletAdd === 0 ? 0 : tinReceived % parseInt(item?.masterProduct?.tinPerCarton) > 0 ? (palletAdd - 1) * parseInt(item?.masterProduct?.tinPerCarton) + tinReceived % parseInt(item?.masterProduct?.tinPerCarton) : palletAdd * parseInt(item?.masterProduct?.tinPerCarton) + tinReceived % parseInt(item?.masterProduct?.tinPerCarton),
      });
    }
  }, [item, form, change, inboundKind]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id }).then(value => setLocatorList(value?.data));
  }, [currentWarehouse]);

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        setLoading(true);
        // if (divePallet) {
        //   values.quantityCarttonPallet = values.quantityCarttonReceived - item.quantityCarttonReceived;
        //   values.quantityTinPallet = values.quantityTinReceived - item.quantityTinReceived;
        // } else {
        values.quantityCarttonPallet = values.quantityCarttonReceived;
        values.quantityTinPallet = values.quantityTinReceived;
        values.code3A = item?.masterProduct.code3A;
        // }
        if (palletAmount !== values.amountPallet) {
          values.autoDive = false;
        } else values.autoDive = true;
        if (values?.expireDate) {
          values.expireDate = values?.expireDate.format(formatDateAPi);
        }
        delete values.tinPerCarton;
        delete values.tinRemainder;
        delete values.carttonRemainder;
        delete values.CTNSPerPalletImport;
        delete values.quantityCarttonReceived;
        delete values.quantityTinReceived;
        await onCallback({
          ...values,
          id: item.id,
        });
        setLoading(false);
      }}
      initialValues={{
        quantityCarttonReceived: item ? (item?.quantityTin - item?.quantityTinReceived - quantityTinItems) < 0 ? item?.quantityCartton - item?.quantityCarttonReceived - quantityCarttonItems - Math.floor(Math.abs(item?.quantityTin - item?.quantityTinReceived - quantityTinItems) /
          parseInt(item?.masterProduct?.tinPerCarton)) : item?.quantityCartton - item?.quantityCarttonReceived - quantityCarttonItems : undefined,
        quantityTinReceived: item ? (item?.quantityTin - item?.quantityTinReceived - quantityTinItems) < 0 ? Math.abs(item?.quantityTin - item?.quantityTinReceived - quantityTinItems) % parseInt(item?.masterProduct?.tinPerCarton) : item?.quantityTin - item?.quantityTinReceived - quantityTinItems : undefined,
        expireDate: item?.expireDate ? moment(item.expireDate, formatDateFull) : undefined,
        lot: item?.lot ? item?.lot : undefined,
        quantityCartton: item?.quantityCartton ? item?.quantityCartton : 0,
        quantityTin: item?.quantityTin ? item?.quantityTin : 0,
        quantity: parseInt(item?.masterProduct?.tinPerCarton) * item?.quantityCartton + item?.quantityTin || 0,
        CTNSPerPalletImport: item ? item?.masterProduct?.CTNSPerPalletImport : undefined,
        CTMSPerPalletStorage3A: item ? item?.masterProduct?.CTMSPerPalletStorage3A : undefined,
        tinPerCarton: item ? item?.masterProduct?.tinPerCarton : undefined,
        // container: item ? item?.container : undefined,
        locator: item?.locator?.id,
        inboundKind: inboundKind,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item label={lang.defaultCode}>
            <Input disabled value={item?.masterProduct.code3A} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.shortname}
          >
            <Input disabled value={item?.masterProduct?.shortName} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={item?.masterProduct?.nameEN} />
          </Form.Item>
        </Col>
        {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name='container'
            label={lang.vansCode}
          // rules={[
          //   {
          //     required: true,
          //     message: lang.requiredContainerNo,
          //   },
          // ]}
          >
            <Input placeholder='Nhập số Con' />
          </Form.Item>
        </Col> */}
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantityCarttonReceived"
            label={lang.quantityCarttonReceived}
            rules={[
              {
                required: true,
                message: lang.quantityCarttonReceived,
              },
            ]}
          >
            <InputNumber disabled style={{ width: '100%' }} min={0} onChange={() => setChange(!change)} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantityTinReceived"
            label={lang.quantityTinReceived}
            rules={[
              {
                required: true,
                message: lang.quantityTinReceived,
              },
            ]}
          >
            <InputNumber disabled style={{ width: '100%' }} min={0} onChange={() => setChange(!change)} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lot"
            label={'Lot'}
            rules={[
              {
                required: true,
                message: 'Lot là bắt buộc',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="expireDate" label={lang.expireDate}
            rules={[
              {
                required: true,
                message: lang.requiredExpirationDate,
              },
            ]}>
            <DatePicker
              style={{ width: '100%' }}
              format={formatDate}
            />
          </Form.Item>
        </Col>
        {
          inboundKind === 'BRANCH' && 
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <ProFormSelect style={{ fontWeight: 600, width: '80%' }}
              name="locator" label={lang.locator}
              placeholder={lang.locator}
              rules={[
                {
                  required: true,
                  message: lang.requiredLocator,
                },
              ]}
              
              options={
              locatorsList.filter(item => item?.area?.warehouseID !== 'ITT').map(locator => {
                return {
                  value: locator.id,
                  label: `${locator.locatorName} - ${locator.locator}`,
                };
                })
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            />
        </Col>
        }
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="amountPallet"
            label={lang.totalPallet}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="carttonRemainder"
            label={lang.quantityCarttonRemainder}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="tinRemainder"
            label={lang.quantityTinRemainder}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantityCartton"
            label={lang.quantityCartton}
          >
            <InputNumber style={{ width: '100%' }} onChange={() => {
              form.setFieldsValue({ quantityCarttonReceived: 0, quantityTinReceived: 0 });
              setChange(!change);
            }
            } />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantityTin"
            label={lang.quantityTin}
          >
            <InputNumber style={{ width: '100%' }} onChange={() => {
              form.setFieldsValue({ quantityCarttonReceived: 0, quantityTinReceived: 0 });
              setChange(!change);
            }
            } />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="quantity"
            label={lang.totalQuantityTin}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item
            name="tinPerCarton"
            label={lang.tinPerCarton}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        {
          inboundKind === 'BRANCH'
          ? <Col lg={{ span: 10 }} xs={{ span: 24 }}>
            <Form.Item
              name="CTMSPerPalletStorage3A"
              label={lang.ctmsPerPalletStorage3A}
              rules={[
                {
                  required: true,
                  message: 'Không có tiêu chuẩn 3A - Số thùng/ pallet - Dưới sàn',
                },
              ]}
            >
              <InputNumber disabled style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        : <Col lg={{ span: 10 }} xs={{ span: 24 }}>
          <Form.Item
            name="CTNSPerPalletImport"
            label={lang.ctnsPerPalletImport}
            rules={[
              {
                required: true,
                message: 'Không có tiêu chuẩn nhập khẩu - Số thùng/Pallet - Dưới sàn',
              },
            ]}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        }
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductDetail.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  divePallet: PropTypes.bool,
  inboundKind: PropTypes.string,
  quantityCarttonItems: PropTypes.number,
  quantityTinItems: PropTypes.number,
};

export default ReceiptProductDetail;
