import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message, DatePicker, Card, Form, Input } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import { createExportInternalGift } from './picking.service';
import ReceiptCreateProductEditForm from './internal-export-create-product-edit.form';

import './style.less';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import moment from 'moment-timezone';
import { locatorList } from '../../configuration/locator/locator.service.js';
const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState(document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? 'EXPORT_INTERNAL' : undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [locators, setLocators] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
   locatorList({warehouseId: currentWarehouse.id}).then(async results => {
    const dataLocator = [];
      await results.data.forEach(locatorGift => {
        if (locatorGift?.locator.includes('Gift') && locatorGift?.area?.warehouseID !== 'ITT') {
          dataLocator.push(locatorGift)
        }
      });
      setLocators(dataLocator)
    });
  }, [currentWarehouse.id]);

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: lang.productName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: lang.locator,
      key: 'locator',
      render: record => {
        const locatorFinded = record?.masterProductsGiftLocators.find(proLocator => proLocator?.id === record?.locator)
        return `${locatorFinded?.locator?.locator} - ${locatorFinded?.locator?.locatorName}` 
      }
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              setItem(record);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          const formData = { ...form.getFieldsValue(true) };
          const dataFieldForm = form.getFieldsValue();
          setLoadingBtn(true);
          const productGiftOrders = items.map(item => {
            return {
              codeGift: item.code,
              bomGiftId: item.id,
              quantity: item.quantity,
              locatorCode: item.locatorCode,
              locatorCodeTo:formData?.locatorToId?.value,
              warehouseId: currentWarehouse.id,
            };
          });
          let payload =
          {
            description: formData?.description,
            creatorId: user?.userInfo?.name,
            warehouseId: currentWarehouse.id,
            locatorCodeTo: formData?.locatorToId?.value,
            orderGiftType: "EXPORT_INTERNAL",
            productGiftOrders:productGiftOrders,
          }
          if (dataFieldForm?.locatorToId) {
            // console.log(payload)
            await createExportInternalGift(payload);
            message.success(lang.createSuccessfully);
            setLoadingBtn(false);
            navigate(-1);
          } else {
            Modal.warning({title: lang.pleaseSelectLocator})
            setLoadingBtn(false);
          }
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }
      }
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? {
      path: '/gift-internal-tranfer',
      breadcrumbName: lang.exportInternalGift,
    }
      : {
        path: '/sale-order',
        breadcrumbName: lang.exportWarehouse,
      },
    {
      path: '/',
      breadcrumbName: document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? `${lang.create} ${lang.exportInternalGift}` : lang.createExportWarehouse,
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? lang.exportInternalGift : lang.createExportWarehouse}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card bodyStyle={{ padding: 12 }}>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{
              orderType: document.location.pathname.indexOf('gift-internal-tranfer') > -1 ? 'EXPORT_INTERNAL' : undefined,
              movementDate: moment(),
            }}
            form={form}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="orderType" label={lang.orderType}
                  placeholder={lang.orderType}
                  params={{ warehouseId: currentWarehouse.id }}
                  options={[
                    {
                      value: 'EXPORT_SALE',
                      label: lang.EXPORT_SALE,
                    },
                    {
                      value: 'EXPORT_BORROW',
                      label: lang.EXPORT_BORROW,
                    },
                  ]}
                  onChange={(value) => setType(value)}
                  disabled={document.location.pathname.indexOf('gift-internal-tranfer') > -1}
                />
              </Col>
       

              {(type === 'EXPORT_INTERNAL' || type === 'EXPORT_BORROW' || type === 'EXPORT_BRANCH') && <>
                {/* <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="internalTranferCode"
                    label={lang.documentNo}
                  >
                    <Input placeholder={lang.documentNo} />
                  </Form.Item>
                </Col> */}
                {type === 'EXPORT_BRANCH' ? <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="locatorTransit" label={`${lang.locator} (Transit)`}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.locator}
                    options={locators?.filter(locator => locator.warehouseId === currentWarehouse?.id && locator.warehouseToId)?.map(locator => ({
                      label: `${locator.locatorName} - ${locator.locator}`,
                      value: locator.locator,
                      warehouseToId: locator.warehouseToId,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                    fieldProps={{
                      labelInValue: true,
                    }}
                  />
                </Col> : <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="locatorToId" label={lang.locatorTo}
                    rules={[
                      {
                        required: true,
                        message: lang.requiredLocator,
                      },
                    ]}
                    placeholder={lang.locator}
                    options={locators?.map(locator => ({
                      label: `${locator.locatorName} - ${locator.locator}`,
                      value: locator.locator,
                      warehouseId: locator.warehouseId,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                    fieldProps={{
                      labelInValue: true,
                    }}
                  />
                </Col>}
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="movementDate"
                    label={lang.movementDate}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="tranferReason"
                    label={lang.tranferReason}
                  >
                    <Input placeholder={lang.tranferReason} />
                  </Form.Item>
                </Col>
                {type === 'EXPORT_BORROW' && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="typeBorrow" label={lang.consignmentOrder}
                    placeholder={lang.consignmentOrder}
                    options={[
                      {
                        label: lang.borrowed,
                        value: 'EXPORT_BORROW',
                      },
                      {
                        label: lang.enterBorrow,
                        value: 'IMPORT_BORROW',
                      },
                    ]}
                  />
                </Col>}
              </>}
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productAdded}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setItem();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.indx) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="indx"
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={item ? lang.editProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          items={items}
          onCallback={(value) => {
            console.log(value)
            const filters = items?.filter(item=>item.indx !== value.indx);
            setItems([...filters, value]);
             setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
          type={type}
        //  channel={saleChannels?.find(saleChannel => saleChannel.id === form?.getFieldValue('channel'))}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
