import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message } from 'antd';
import { PlusOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { customerReturnDelete, customerReturnList } from './receipt.service';
import _get from 'lodash/get';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull, formatDateAPi } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');

  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);

  const [params, setParams] = useState({ ...defaultPage, status: 'ENABLE' });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  //
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getReceipts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    customerReturnList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        result.data.map((value) => {
          value.shipmentNo = value.apInvoice?.shipmentNo;
          return value;
        });
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    getReceipts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    {
      title: lang.customerReturnCode,
      render: (record) => {
        if (isStaff) {
          return (
            <div>{record?.documentNo}</div>
          );
        } else {
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record?.id}`)}>{record?.documentNo}</Button>
          );
        }
      },
    },
    {
      title: lang.company,
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: lang.branch,
      dataIndex: 'branch',
      key: 'branch',
    },
    // {
    //   title: lang.documentNo,
    //   dataIndex: 'documentNo',
    //   key: 'documentNo',
    // },
    {
      title: lang.orderReference,
      dataIndex: 'orderReference',
      key: 'orderReference',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      //sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order,
      key: 'createDate',
      render: value => moment(value, formatDateFull).format(formatDateAPi),
      // sorter: true,
      // sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      disabled={isStaff}
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/customer-return',
      breadcrumbName: lang.customerReturn,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.customerReturn}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>

            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }

                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.receiptList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        customerReturnDelete({
                          ids: selectedRowKeys,
                        })
                          .then(result => {
                            params.page = 1;
                            setParams({ ...defaultPage });
                            setSelectedRowKeys([]);
                            Modal.success({
                              title: lang.deleteReceiptsSuccessfully,
                              content: `${lang.receiptIdsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                            });
                          })
                          .catch((e) => message.error(e.message));
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="id"
          rowSelection={!isStaff && rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default ReceiptList;
