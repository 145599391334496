import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, message as Alert, Modal, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import Helper from 'utils/helpers';
import { bomGiftCreate, bomGiftUpdate } from './bom-gift.service';
import { Option } from 'antd/lib/mentions';
import { MODE } from 'utils/constants';

const BomEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    ...item,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    // const valueForm = form.getFieldsValue();
    // const isDefined = Object.values(valueForm).some(value => value !== undefined);
    // const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    // if (!isDefined || isUnChange) {
    //   setIsEditModal(false);
    //   return true;
    // }
    // Helper.conFirm(setIsEditModal);
    setIsEditModal(false);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateBomGift : lang.createBomGift}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={1200}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            bomGiftCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            bomGiftUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={24}>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="company"
              label={lang.company}
            >
              <Input style={{ width: '100%' }} placeholder={lang.company} />
            </Form.Item>
          </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="branch"
              label={lang.branch}
            >
              <Input style={{ width: '100%' }} placeholder={lang.branch} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="code"
              label={lang.giftCode}
            >
              <Input style={{ width: '100%' }} placeholder={lang.giftCode} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.giftName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.giftName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="vietnameseName"
              label={lang.vietNameseName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.vietNameseName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="active"
              label={lang.active}
            >
              <Input style={{ width: '100%' }} placeholder={lang.active} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="description"
              label={lang.description}
            >
              <Input style={{ width: '100%' }} placeholder={lang.description} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="taxCategory"
              label={lang.taxCategory}
            >
              <Input style={{ width: '100%' }} placeholder={lang.taxCategory} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="UOM"
              label={lang.UOM}
            >
              <Input style={{ width: '100%' }} placeholder={lang.UOM} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="PCSCase"
              label={lang.PCSCase}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.PCSCase} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="specification"
              label={lang.specification}
            >
              <Input style={{ width: '100%' }} placeholder={lang.specification} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="lengthPCS"
              label={'L(cm) (PCS)'}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.lengthPcs} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="widthPCS"
              label={lang.widthPcsUnit}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.widthPcsUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="heightPCS"
              label={lang.heightPcsUnit}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.heightPcsUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="grossWeightPCS"
              label={lang.grossWeightPcs}
            >
              <Input style={{ width: '100%' }} placeholder={lang.grossWeightPcs} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="volumePCS"
              label={lang.volumePcsUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.volumePcsUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="packingRule"
              label={lang.packingRule}
            >
              <Input style={{ width: '100%' }} placeholder={lang.packingRule} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="isRack"
              label={lang.isRack}
            >
              <Select placeholder={lang.isRack}>
                <Option key="1" value={true}>{lang.yes}</Option>
                <Option key="2" value={false}>{lang.no}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="lengthCarton"
              label={lang.lengthCartonUnit}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.lengthCartonUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="widthCarton"
              label={lang.widthCartonUnit}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.widthCartonUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="heightCarton"
              label={lang.heightCartonUnit}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.heightCartonUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="grossWeight3A"
              label={lang.grossWeight3AUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.grossWeight3AUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="volumeCarton"
              label={lang.volumeCartonUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.volumeCartonUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="CTNSLayer3A"
              label={lang.ctnsLayer3AUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ctnsLayer3AUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="layerPallet3A"
              label={lang.layerPallet3AUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.layerPallet3AUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="CTNSPallet3A"
              label={lang.ctnsPallet3AStorageUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ctnsPallet3AStorageUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="CTNSLayer3AFloor"
              label={lang.ctnsLayer3AFloorUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ctnsLayer3AFloorUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="CTNSLayer2Vendor"
              label={lang.ctnsLayer2VendorUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ctnsLayer2VendorUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="layerPallet3Vendor"
              label={lang.layerPallet3VendorUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.layerPallet3VendorUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="CTNSPalletVendor"
              label={lang.ctnsPalletVendorUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ctnsPalletVendorUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="grossWeightVendor"
              label={lang.grossWeightVendorUnit}
            >
              <Input style={{ width: '100%' }} placeholder={lang.grossWeightVendorUnit} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }}>
            <Form.Item
              name="productionLoss"
              label={lang.wastage}
            >
              <Input style={{ width: '100%' }} placeholder={lang.wastage} />
            </Form.Item>
          </Col>
          {/* <Col sm={{ span: 6 }}>
            <Form.Item
              name="stock"
              label={lang.totalQuantity}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.messageReportContent,
            //   },
            // ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.totalQuantity} />
            </Form.Item>
          </Col> */}
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

BomEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default BomEditForm;
