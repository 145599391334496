import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Select, Input, Tag, Form, message, Modal, DatePicker } from 'antd';
import { SearchOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { bookingList, importExel, orderCheck, orderUpdateStatus, suggestLock } from './picking.service';
import { useSelector } from 'react-redux'; //useDispatch useDispatch,,
// import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import './style.less';
import moment from 'moment-timezone';
import { defaultPage, formatDate, formatDateFull, STATUS_OUTBOUND } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import SuggestLocationForm from './suggest-location.form';
import { updateStatusCreateDO } from 'pages/dashboard/configuration/sale-order/sale-order.service';
import { deliveryOrderCreate, deliveryOrderList } from '../delivery-order/delivery-order.service';
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingList = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [items, setItems] = useState([]);
  //Pagination
  const [params, setParams] = useState({ status: 'NEW', orderType: 'EXPORT_SALE', ...defaultPage });
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  // const [pageSize, SetPageSize] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [searchCode, setSearchCode] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [form] = Form.useForm();
  const [uploadModal, setUploadModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deliveryOrder, setDeliveryOrder] = useState([]);
  const [file, setFile] = useState();

  // useEffect(() => {
  //   setLoading(true);
  //   bookingList({ status: 'NEW', warehouseId: currentWarehouse?.id })
  //     .then(result => {
  //       // setSearchCode(result.data);
  //       setLoading(false);
  //     })
  //     .catch((e) => setLoading(false));
  // }, [currentWarehouse]);

  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));

      deliveryOrderList({ warehouseId: currentWarehouse?.id })
      .then(result => {
        setDeliveryOrder(result.data)
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      // dataIndex: 'code',
      render: rec => <Button
        key="btn-detail"
        type="link"
        onClick={() => navigate(`/dashboard/outbound/sale-order/${rec.id}`)}
        size='small'
      >
        {rec.code}
      </Button>,
      filters: [
        {
          text: lang.doCreated,
          value: true,
        },
        {
          text: lang.doNotCreated,
          value: false,
        },
      ],
      onFilter: (value, record) => {
        const takedDO = deliveryOrder?.map(item => item.orderCode)
        const checkCode = takedDO.includes(record?.code)
        return checkCode === value
        
      }
    },
    {
      title: lang.company,
      key: 'company',
      dataIndex: 'company',
    },
    {
      title: lang.branch,
      key: 'branch',
      // width: '30%',
      dataIndex: 'branch',
    },
    {
      title: lang.orderReference,
      dataIndex: 'orderReference',
      key: 'orderReference',
      // width: '20%',
    },
    {
      title: lang.businessLine,
      dataIndex: 'bussinessLine',
      key: 'bussinessLine',
      // width: '20%',
    },
    {
      title: lang.orderDate,
      dataIndex: 'dateOrdered',
      key: 'dateOrdered',
      // width: '20%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
      sorter: true,
    },
    {
      title: lang.orderType,
      dataIndex: 'orderType',
      key: 'orderType',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
      // width: '20%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '15%',
      render: (record) => {
        return (
          <Tag>{STATUS_OUTBOUND[record]}</Tag>
        );
      },
    },
  ];
  const extraButton = [
    // <Button
    //   key="btn-detail"
    //   type="primary"
    //   onClick={() => navigate(`/dashboard/outbound/sale-order/${selectedRowKeys[0].id}`)}
    //   disabled={!(selectedRowKeys.length === 1)}
    // >
    //   {lang.saleOrderDetail}
    // </Button>,
    <Button
      key="btn-createDO"
      type="primary"
      disabled = {selectedRowKeys?.length === 0}
      onClick={() => {
        Modal.confirm({
          title: lang.areYouSureCreateDOForSelectedOrders,
          async onOk() {
            try {
              setLoading(true)

              //Check xem có lot hay chưa?
              const dataCodeCheckLot = [];
              let checkLot = true
              await selectedRowKeys?.map(item => {
                const checkLot1 = item?.productOrders.some(lot => lot?.lot);
                if (checkLot1 === false) {
                  dataCodeCheckLot.push(item.code);
                  checkLot = checkLot1;
                }
                return true
              });

               //Check xem có date hay chưa?
              const dataCheckDate = [];
              let checkDate = true;
              await selectedRowKeys?.map(item1 => {
                const checkDate1 = item1?.productOrders.some(date => date?.expireDate);
                if (checkDate1 === false) {
                  dataCheckDate.push(item1.code);
                  checkDate = checkDate1;
                }
                return true
              });


              //Check xem có locator hay chưa?
              const dataCheckLocator = [];
              let checkLocator = true;
              await selectedRowKeys?.map(item2 => {
                const checkLocator1 = item2?.productOrders.some(locator => locator?.locator);
                if (checkLocator1 === false) {
                  dataCheckLocator.push(item2.code);
                  checkLocator = checkLocator1;
                }
                return true
              });
              
              //Xử lý logic
              if (checkLot === false) {
                Modal.confirm({
                  title: lang.warning,
                  content: `${lang.orderWithCode} ${dataCodeCheckLot.toString()}, ${lang.productNoLot}`,
                  onOk() {},
                  onCancel() {}
                })
                setLoading(false)
              } 
              else if (checkDate === false) {
                Modal.confirm({
                  title: lang.warning,
                  content: `${lang.orderWithCode} ${dataCheckDate.toString()}, ${lang.productNoExpiryDate}`,
                  onOk() {},
                  onCancel() {}
                })
                setLoading(false)
              } else if (checkLocator === false) {
                Modal.confirm({
                  title: lang.warning,
                  content: `${lang.orderWithCode} ${dataCheckLocator.toString()}, ${lang.productNoLocator}`,
                  onOk() {},
                  onCancel() {}
                })
                setLoading(false)
              } 
              else {
                //Lấy ra danh sách những ông dã tạo DO
                const listDOs = deliveryOrder?.filter((litsDo) => litsDo?.status !== 'CANCELLED')
                              .map((litsDo) => litsDo?.orderCode);
                // const listDOs = deliveryOrder?.map(litsDo => litsDo?.orderCode);
                // Lấy ra những ông check được
                const checked = selectedRowKeys?.map(check => check.code);
                // Lấy ra những ông CHƯA tạo DO từ trong danh sách check 
                const data = selectedRowKeys?.filter(doCode => !(listDOs.includes(doCode?.code)));
                let takedDo = [];
                for (const order of selectedRowKeys){
                  if(!order.createdDO && !data.includes(order)){//kiểm tra field dưới DB nếu chưa tạo thì cho phép tạo
                    data.push(order);
                  }
                }

                for (const codeDO of listDOs) {
                  if (checked.includes(codeDO)) {
                  takedDo.push(codeDO);
                  }
                }
                // TH chọn có ông đã tạo DO và có ông chưa tạo DO
                if (takedDo?.length > 0) {
                  if (takedDo?.length < checked?.length) {
                    Modal.confirm({
                      title: lang.warning,
                      content: `${lang.orderWithCode} ${takedDo} ${lang.deliveryOrderCreatedContinue}`,
                      async onOk() {
                        try {
                          await data?.map(item => {
                            const payload = Object.assign({}, item);
                            payload.orderId = item?.id;
                            payload.orderCode = item?.code;
                            payload.subChannel = item?.subChannel
                            payload.warehouseType = item?.warehouseType
                            payload.productDeliverys = item?.productOrders?.map(product => {
                              return {
                                lineNo: product?.lineNo,
                                code3A: product?.code3A,
                                branch: product?.branch,
                                description: product?.description,
                                quantityInCarton: product?.quantityInCarton,
                                quantityInTin: product?.quantityTin,
                                quantity: product?.quantity,
                                lot: product?.lot,
                                expireDate: product?.expireDate,
                                warehouseId: product?.warehouseId,
                                locator: product?.locator?.locator,
                                subChannel: item?.subChannel
                              };
                            });
                            delete payload.id;
                            delete payload.createDate;
                            delete payload.productOrders;
                            // console.log(payload)
                            deliveryOrderCreate(payload);
                            deliveryOrderList({ warehouseId: currentWarehouse?.id })
                            .then(result => {
                              setDeliveryOrder(result.data)
                              setLoading(false);
                            })
                            .catch((e) => setLoading(false));
                            setSelectedRowKeys([])
                            setLoading(false)
                            return true
                          }) 
                          message.success(lang.createSuccessfully);
                          setLoading(false)
                        } catch (error) {
                          message.error(error.message);
                          setLoading(false)
                          return false;
                        }
                      },
                      onCancel(){}
                    });
                  } else {
                    // TH chọn tất cả những ông đã tạo DO rồi
                    Modal.warning({
                      title: lang.createDeliveryOrderFailed,
                      content: `${lang.orderWithCode} ${JSON.stringify(selectedRowKeys?.filter(doCode => (listDOs.includes(doCode?.code)))?.map(item => item?.code))} ${lang.deliveryOrderCreated}`,
                    });
                    setLoading(false)
                    }
                  
                } 
                // TH chọn tất cả là những ông chưa tạo DO
                else if (data.length > 0) {
                  setLoading(true)
                  await  Promise.all( data?.map(async item =>  {
                    const payload = Object.assign({}, item);
                    payload.orderId = item?.id;
                    payload.orderCode = item?.code;
                    payload.subChannel = item?.subChannel
                    payload.warehouseType = item?.warehouseType
                    payload.productDeliverys = item?.productOrders?.map(product => {
                      return {
                        lineNo: product?.lineNo,
                        code3A: product?.code3A,
                        branch: product?.branch,
                        description: product?.description,
                        quantityInCarton: product?.quantityInCarton,
                        quantityInTin: product?.quantityTin,
                        quantity: product?.quantity,
                        lot: product?.lot,
                        expireDate: product?.expireDate,
                        warehouseId: product?.warehouseId,
                        locator: product?.locator?.locator,
                        subChannel: item?.subChannel
                      };
                    });
                    delete payload.id;
                    delete payload.createDate;
                    delete payload.productOrders;
                   await deliveryOrderCreate(payload);
                    let dataOrder = {};
                        if (item && item.id) {
                          dataOrder.ids = [item.id];
                          dataOrder.status = true;
                        }
                    updateStatusCreateDO(dataOrder);

                    deliveryOrderList({ warehouseId: currentWarehouse?.id })
                    .then(result => {
                      setDeliveryOrder(result.data)
                      setLoading(false);
                    })
                    .catch((e) => setLoading(false));
                    setSelectedRowKeys([])
                    setLoading(false)
                    return true
                  })) 
                  message.success(lang.createSuccessfully);
                  setLoading(false)
                } 
              }
                    
            } catch (error) {
              message.error(error.message);
              setLoading(false)
              return false;
            }
          },
          onCancel() {}
        })
      }}
    >
      {lang.createDeliveryOrder}
      <PlusOutlined />
    </Button>,
    // <Button
    //   key="btn-import"
    //   type="primary"
    //   onClick={() => setUploadModal(true)}
    // >
    //   {lang.importCSV}
    // </Button>,
  //   (params?.status === 'LOCK' || params?.status === 'WAITING') && <Button
  //   key="btn-complete"
  //   disabled={selectedRowKeys.length >= 2 || selectedRowKeys.length === 0}
  //   type="primary"
  //   onClick={async () => {
  //     try {
  //       if (selectedRowKeys.length > 0) {
  //         await orderCheck({ ids: selectedRowKeys?.map(order => order.id), userId: `${user?.userInfo?.sub} - ${user?.userInfo?.name}` });
  //         dispatch(bookingOrderSet({
  //           pickingOrder: selectedRowKeys,
  //           pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
  //             const productOrders = curr?.productOrders?.map(product => {
  //               product.orderCode = curr.code;
  //               product.orderId = curr.id;
  //               product.pickedQuantityCartton = 0;
  //               product.pickedQuantityTin = 0;
  //               product.groupOrder = curr.group;
  //               product.saleChannel = curr?.channel?.channel;
  //               return product;
  //             });
  //             return [...prev, ...productOrders];
  //           }, []),
  //         }));
          
  //         navigate('picking/scan');
  //         return true;
  //       }
  //     } catch (error) {
  //       const splitMessage = error?.message?.split(/\s(.*)/s);
  //       message.error(splitMessage[0] + ' ' + lang.messeagePicking);
  //     }
  //   }}
  // >
  //   {lang.picking}
  // </Button>,
    // <Button
    //   key="btn-complete"
    //   disabled={selectedRowKeys.length === 0}
    //   type="primary"
    //   onClick={async () => {
    //     try {
    //       if (selectedRowKeys.length > 0) {
    //         if (selectedRowKeys[0]?.status === 'LOCK') {
    //           dispatch(bookingOrderSet({
    //             pickingOrder: selectedRowKeys,
    //             pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
    //               const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
    //                 product.orderCode = curr.code;
    //                 product.orderId = curr.id;
    //                 product.pickedQuantityCartton = 0;
    //                 product.pickedQuantityTin = 0;
    //                 product.groupOrder = curr.group;
    //                 return product;
    //               });
    //               return [...prev, ...productOrders];
    //             }, []),
    //           }));
    //           navigate('picking/scan');
    //           return true;
    //         }
    //         if (selectedRowKeys[0]?.group) {
    //           const selected = initDataOrders?.filter(order => order.group === selectedRowKeys[0]?.group);
    //           await orderCheck({ ids: selected?.map(order => order.id), userId: user?.userInfo?.sub });
    //           dispatch(bookingOrderSet({
    //             pickingOrder: selected,
    //             pickingProducts: selected?.reduce((prev, curr) => {
    //               const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
    //                 product.orderCode = curr.code;
    //                 product.orderId = curr.id;
    //                 product.groupOrder = curr.group;
    //                 return product;
    //               });
    //               return [...prev, ...productOrders];
    //             }, []),
    //           }));
    //         } else {
    //           await orderCheck({ ids: selectedRowKeys?.map(order => order.id), userId: user?.userInfo?.sub });
    //           dispatch(bookingOrderSet({
    //             pickingOrder: selectedRowKeys,
    //             pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
    //               const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
    //                 product.orderCode = curr.code;
    //                 product.orderId = curr.id;
    //                 product.groupOrder = curr.group;
    //                 return product;
    //               });
    //               return [...prev, ...productOrders];
    //             }, []),
    //           }));
    //         }
    //       }
    //       // dispatch(pickingOrderCreate());
    //       // navigate('picking');
    //       setIsSuggestLocationModal(true);
    //     } catch (error) {
    //       const splitMessage = error?.message?.split(/\s(.*)/s);
    //       message.error(splitMessage[0] + ' ' + lang.messeagePicking);
    //     }
    //   }}
    // >
    //   {items[0]?.status === 'NEW' ? lang.btnlock : lang.picking}
    // </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/outbound',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.exportWarehouse,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.exportWarehouse}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (!cleanValue?.orderCode && !cleanValue?.status) {
              cleanValue.status = 'NEW';
            }
            if (cleanValue?.orderCode) {
              cleanValue.status = undefined;
            }
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.status} name="status" initialValue={'NEW'}>
                  <Select placeholder={lang.status} showSearch allowClear>
                    <Option key={'NEW'} value={'NEW'}>{STATUS_OUTBOUND.NEW}</Option>
                    <Option key={'LOCK'} value={'LOCK'}>{STATUS_OUTBOUND.LOCK}</Option>
                    <Option key={'WAITING'} value={'WAITING'}>{STATUS_OUTBOUND.WAITING}</Option>
                    <Option key={'PICKING'} value={'PICKING'}>{STATUS_OUTBOUND.PICKING}</Option>
                    <Option key={'CONFIRM'} value={'CONFIRM'}>{STATUS_OUTBOUND.CONFIRM}</Option>
                  </Select>
                </Form.Item></Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.orderType} name="orderType" initialValue={'EXPORT_SALE'}>
                  <Select placeholder={lang.orderType} showSearch allowClear>
                    <Option key={'EXPORT_SALE'} value={'EXPORT_SALE'}>{lang.EXPORT_SALE}</Option>
                    <Option key={'EXPORT_BORROW'} value={'EXPORT_BORROW'}>{lang.EXPORT_BORROW}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.search}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card size="small" className="cardSearch">
          <Row style={{ marginBottom: 0 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <h3>{lang.titlePicking}</h3>
              <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`${lang.totalExportSlips}: ${totalItems}`}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                // rowSelection={!isStaff && rowSelection}
                rowSelection={(['NEW', 'LOCK', 'WAITING'].indexOf(params?.status) !== -1) && {
                  onChange: (_, selectedRows) => {
                    setSelectedRowKeys(selectedRows);
                  },
                }}
                className="tableCustom"
                loading={loading}
                bordered
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  if (sorter) {
                    setSortedInfo({ sortDirection: sorter.order, sortBy: sorter.field });
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: 600 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(formData, currentWarehouse.id);
              setFile();
            }
            setParams({ ...params });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ 'Order Code': '', Group: '' }], 'TemplateOrders')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={selectedRowKeys}
          totalItems={totalItems}
          productOrders={selectedRowKeys?.reduce((prev, curr) => {
            const productOrders = curr?.productOrders?.filter(product => product.status === 'ENABLE' || product.status === 'PENDING')?.map(product => {
              product.orderCode = curr.code;
              product.orderId = curr.id;
              product.pickedQuantityCartton = 0;
              product.pickedQuantityTin = 0;
              product.groupOrder = curr.group;
              return product;
            });
            return [...prev, ...productOrders];
          }, [])}
          onCallback={async (values) => {
            const content = `
        <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <h3>Công ty TNHH PinNow Việt Nam</h3>
            <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
            <p>0316954732</p>
          </div>
          <div style="text-align: center;">
            <h3>Mẫu số: 01-VT*</h3>
            <p>Ban hành theo thông tư</p>
            <p>số 200/2014/TT-BTC</p>
            <p>ngày 22/12/2014 của Bộ</p>
          </div>
        </div>
        <div style="text-align: center;">
          <h1>{lang.productSuggestionList}</h1>
          <p>{lang.date} ${moment().format('DD')} {lang.month} ${moment().format('MM')} {lang.year} ${moment().format('YYYY')}</p>
        </div>
        <div>
          <table border="1" style="width: 100%; border-collapse: collapse;margin-top: 10px; line-height: 1;">
            <thead>
              <tr style="height: 40px;">
                <th>{lang.itemNo}</th>
                <th>{lang.itemCode}</th>
                <th>{lang.productBatchCode}</th>
                <th>{lang.productGroup}</th>
                <th>{lang.productName}</th>
                <th>{lang.block}</th>
                <th>{lang.shelf}</th>
                <th>{lang.rack}</th>
                <th>{lang.quantity}</th>
              </tr>
            </thead>
            <tbody>
              ${values?.map((product, index) => {
              return `<tr style="text-align: center; height: 40px;">
                    <td>${index + 1}</td>
                    <td>${product.masterProduct.barCode}</td>
                    <td>${product.code}</td>
                    <td>${product.masterProduct.productCategory?.name}</td>
                    <td>${product.masterProduct.name}</td>
                    <td>${product?.block?.name}</td>
                    <td>${product?.rack?.shelf?.name}</td>
                    <td>${product?.rack?.code}</td>
                    <td>${product?.totalQuantity}</td>
                </tr>`;
            }).join(' \n')}
            </tbody>
          </table>
        </div>
      </div>`;
            var template = document.createElement('div');
            template.innerHTML = content;
            document.body.appendChild(template);
            await html2canvas(template, { width: 1000, scale: 3 }).then((canvas) => {
              const imgWidth = 208;
              const imgHeight = canvas.height * imgWidth / canvas.width;
              var imgData = canvas.toDataURL('image/png');
              // eslint-disable-next-line new-cap
              const pdf = new jsPdf('p', 'mm', 'a4');
              pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
              pdf.save(`SuggestProducts${moment().format('DDMMYYYY')}.pdf`);
            });
            document.body.removeChild(template);
            setIsSuggestLocationModal(false);
          }}
          onCancel={async () => {
            await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
            setIsSuggestLocationModal(false);
          }}
          onLock={async (values) => {
            try {
              await Promise.all(values?.map(value => suggestLock(value)), orderUpdateStatus({ ids: selectedRowKeys?.map(order => order?.id), status: 'LOCK' }));
              message.success(lang.lockproductSuccessfully);
              await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
              setParams({ ...params });
              setSelectedRowKeys([]);
              setIsSuggestLocationModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
              return false;
            }
          }}
          productPicked={[]}
        />
      </Modal>
    </Layout>
  );
};

export default PickingList;
