export default {
  login: 'ĐĂNG NHẬP',
  password: 'Mật khẩu',
  slogan: 'Luôn luôn dõi theo và tư vấn hỗ trợ doanh nghiệp!',
  akaName: 'HỆ THỐNG QUẢN LÝ TỐI ƯU KHO ',
  akaDescription: 'Chuyển Đổi Số (DX)',
  placeholderPassword: 'Mật khẩu',
  messagePassword: 'Vui lòng điền mật khẩu!',
  placeholderUsername: 'Tên đăng nhập',
  messageUsername: 'Vui lòng điền Tên đăng nhập',
  loading: 'Đang tải',
  inbound: 'Nhập hàng',
  receipt: 'Phiếu nhập kho',
  temporaryInbound: 'Tạm Nhập',
  storing: 'Lưu trữ',
  inboundControl: 'Lưu trữ',
  inventory: 'Kiểm kê',
  warehouseState: 'Trạng thái Kho',
  reallocation: 'Chuyển vị trí',
  productManagement: 'Quản lý Sản phẩm',
  outbound: 'Xuất hàng',
  pickingOrder: 'Đơn hàng',
  packaging: 'Đóng gói',
  shippingOutboundControl: 'Quản lý xuất kho và đơn hàng',
  configuration: 'Tùy chỉnh',
  supplier: 'Nhà cung cấp',
  product: 'Sản phẩm',
  productCategory: 'Nhóm hàng',
  location: 'Vị trí',
  replenishment: 'Bổ sung Hàng',
  saleOrder: 'Đơn hàng',
  exportWarehouse: 'Phiếu Xuất kho và đơn hàng',
  createExportWarehouse: 'Tạo phiếu xuất kho',
  reporting: 'Báo cáo',
  auditOrder: 'Kiểm toán Đơn hàng',
  inventoryWarning: 'Cảnh báo Kiểm kê',
  performance: 'Năng suất',
  changePassword: 'Đổi Mật khẩu',
  logout: 'Đăng xuất',
  confirm: 'Xác nhận',
  confirmCancelDO: 'Xác nhận hủy DO',
  cancelDO: 'Hủy DO',
  confirmLogout: 'Bạn muốn đăng xuất?',
  warehouse: 'Nhà kho',
  user: 'Người dùng',
  admin: 'Admin',
  receiptId: 'ID Phiếu nhập kho',
  receiptCode: 'Mã Phiếu nhập kho',
  exportCode: 'Mã phiếu xuất kho',
  inboundKind: 'Loại Nhập Kho',
  status: 'Trạng thái',
  receiptDate: 'Ngày tạo phiếu',
  receiveDate: 'Ngày Nhận',
  from: 'Từ',
  to: 'Tới',
  creator: 'Người tạo',
  keyword: 'Từ khóa',
  search: 'Tìm kiếm',
  receiptList: 'Danh sách Phiếu nhập kho',
  doYouWantToDeleteTheseItems: 'Bạn chắc chắn muốn xóa?',
  deleteReceiptsSuccessfully: 'Đã xóa Phiếu nhập kho thành công',
  receiptIdsHasBeenDeleted: 'Đã xóa thành công các mã phiếu:',
  productCode: 'QR code',
  productName: 'Tên sản phẩm',
  expectedQuantity: 'Số lượng mong muốn',
  cost: 'Giá mua',
  salePrice: 'Giá bán sỉ',
  expireDate: 'Hạn sử dụng',
  createReceipt: 'Tạo Phiếu nhập kho',
  createReceiptSuccessfully: 'Tạo Phiếu nhập kho thành công',
  pleaseSelectAInboundKind: 'Vui lòng chọn loại Nhập hàng',
  messageReceiptDate: 'Vui lòng điền ngày Biên nhận',
  messageReceiveDate: 'Vui lòng điền ngày Nhận',
  productAdded: 'Sản phẩm đã được thêm vào',
  importCSV: 'Thêm Excel',
  pleaseSelecteAProduct: 'Vui lòng chọn sản phẩm',
  messageExpectedQuantity: 'Vui lòng điền Số lượng mong muốn',
  messageCost: 'Vui lòng điền Giá mua',
  totalQuantity: 'Tổng số lượng',
  totalQuantityTins: 'Tổng số lượng(lon)',
  zone: 'Khu vực',
  block: 'Khối',
  shelf: 'Kệ',
  rack: 'Giá',
  confirmReceiptSuccessfully: 'Xác nhận Phiếu nhập kho thành công',
  receiptDetail: 'Chi tiết Phiếu nhập kho',
  productList: 'Danh sách Sản phẩm',
  updateLocationSuccessfully: 'Cập nhật vị trí thành công',
  updateLocation: 'Cập nhật vị trí',
  print: 'In',
  messageTotalQuantity: 'Vui lòng điền Tổng số lượng',
  importDate: 'Ngày nhập nội dung',
  report: 'Báo cáo',
  scanReturnProduct: 'Scan hàng trả về',
  reportErrorProduct: 'Báo cáo sản phẩm lỗi',
  reportSuccessfully: 'Báo cáo thành công',
  reportContent: 'Nội dung báo cáo',
  messageReportContent: 'Vui lòng nhập nội dung báo cáo',
  btnSubmit: 'Gửi đi',
  inputCode: 'Nhập mã',
  packageCode: 'Mã Đóng gói',
  locationCode: 'Mã Vị trí',
  completeScanReturnProductSuccessfully: 'Hoàn thành scan sản phẩm',
  scanReturnProductComplete: 'Hoàn thành scan sản phẩm trả về',
  scanMore: 'Scan thêm',
  scan: 'Scan',
  storingDate: 'Ngày lưu trữ',
  scanProduct: 'Scan sản phẩm',
  storingScanProduct: 'Scan sản phẩm lưu trữ',
  completeScanStoringSuccessfully: 'Scan sản phẩm lưu trữ thành công',
  storingScanProductComplete: 'Danh sách sản phẩm đã scan',
  inboundControlDate: 'Ngày nhập hàng',
  inboundControlScanProduct: 'Scan nhập hàng',
  completeInboundControlScanSuccessfully: 'Scan nhập hàng thành công',
  inboundControlScanProductComplete: 'Danh sách sản phẩm đã scan',
  username: 'Tên đăng nhập',
  name: 'Tên',
  role: 'Vai trò',
  users: 'Người dùng',
  requiredUsername: 'Tên đăng nhập bắt buộc có',
  requiredPassword: 'Mật khẩu bắt buộc có',
  requiredName: 'Tên bắt buộc có',
  requiredRole: 'Vai trò bắt buộc có',
  requiredWarehouse: 'Nhà kho bắt buộc có',
  warehouseCode: 'Mã Nhà kho',
  warehouseName: 'Tên Nhà kho',
  address: 'Địa chỉ',
  warehouses: 'Nhà kho',
  acreage: 'Diện tích(m2)',
  btnCancel: 'Hủy',
  btnComplete: 'Hoàn tất',
  btnUpdate: 'Cập nhật',
  btnCreate: 'Tạo mới',
  btnSearch: 'Tìm kiếm',
  btnAdd: 'Thêm',
  btnEdit: 'Sửa',
  btnConfirm: 'Xác nhận',
  btnDelete: 'Xóa',
  btnSave: 'Lưu',
  confirmQuestion: 'Nội dung đã được sửa đổi, các thay đổi sẽ bị loại bỏ. Bạn có muốn tiếp tục?',
  confirmContent: 'Nếu bạn hủy, thông tin vừa sửa sẽ bị hủy bỏ.',
  yes: 'Có',
  addZone: 'Thêm Zone',
  addShelf: 'Thêm Shelf',
  addBlock: 'Thêm Block',
  zoneDetail: 'Chi tiết Zone',
  zoneCreate: 'Tạo Zone',
  zoneName: 'Tên Zone',
  zoneAreDeletedSuccessfully: 'Zone đã được xóa thành công',
  blockAreDeletedSuccessfully: 'Block đã được xóa thành công',
  shelfAreDeletedSuccessfully: 'Shelf đã được xóa thành công',
  areYouSureToDeleteThisZone: 'Bạn có chắc muốn xóa zone?',
  areYouSureToDeleteThisBlock: 'Bạn có chắc muốn xóa block?',
  areYouSureToDeleteThisShelf: 'Bạn có chắc muốn xóa shelf?',
  placeholderName: 'Vui lòng điền Tên',
  placeholderSelectMethod: 'Vui lòng chọn Phương thức xuất hàng',
  requiredCategoryName: 'Tên nhóm hàng bắt buộc có',
  requiredMethod: 'Phương thức xuất hàng bắt buộc có',
  placeholderSelectProductCate: 'Vui lòng chọn Nhóm hàng',
  placeholderAddress: 'Vui lòng điền Địa chỉ',
  placeholderPhoneNumber: 'Vui lòng điền Số điện thoại',
  requiredCategory: 'Nhóm hàng bắt buộc có',
  requiredSupplierName: 'Tên Nhà cung cấp bắt buộc có',
  requiredEmail: 'Email bắt buộc có',
  requiredAddress: 'Địa chỉ bắt buộc có',
  requiredPhoneNumber: 'Số điện thoại bắt buộc có',
  placeholderSelectSupplier: 'Vui lòng chọn Nhà cung cấp',
  placeholderCapacity: 'Vui lòng điền Sức chứa',
  requiredImage: 'Hình ảnh bắt buộc có',
  requiredProductName: 'Tên sản phẩm bắt buộc có',
  requiredSupplier: 'Nhà cung cấp bắt buộc có',
  requiredCapacity: 'Sức chứa bắt buộc có',
  requiredStoreTime: 'Thời gian lưu kho bắt buộc có',
  placeholderMin: 'Vui lòng điền Giá trị bé nhất',
  placeHolderMax: 'Vui lòng điền Giá trị lớn nhất',
  requiredMin: 'Giá trị bé nhất bắt buộc có',
  requiredMax: 'Giá trị lớn nhất bắt buộc có',
  placeholderSelectProduct: 'Vui lòng chọn Sản phẩm',
  requiredProduct: 'Sản phẩm bắt buộc có',
  requiredZoneName: 'Tên Zone bắt buộc có',
  requiredBlockName: 'Tên Block bắt buộc có',
  placeholderTotalshelf: 'Vui lòng điền Tổng số shelf',
  requiredTotalshelf: 'Tổng số Shelf bắt buộc có',
  requiredShelfName: 'Tên Shelf bắt buộc có',
  placeholderTotalRack: 'Vui lòng điền Tổng số rack',
  requiredTotalRack: 'Tổng số Rack bắt buộc có',
  placeholderLevelPercent: 'Vui lòng điền mức %',
  requiredLevelPercent: 'Mức % bắt buộc có',
  placeholderQuantity: 'Điền số lượng',
  requiredQuantity: 'Số lượng bắt buộc có',
  capacity: 'Sức chứa',
  success: 'Thành công',
  blockDetail: 'Chi tiết Block',
  blockCreate: 'Tạo Block',
  blockName: 'Tên Block',
  totalShelf: 'Tổng số Shelf',
  updateShelf: 'Cập nhật Shelf',
  createShelf: 'Tạo Shelf',
  complete: 'Hoàn tất',
  shelfName: 'Tên Shelf',
  capacityRack: 'Sức chứa của Rack/Pallet',
  levelPercent: 'Mức %',
  medium: 'trung bình',
  high: 'cao',
  code: 'Mã',
  createSuccessfully: 'Tạo thành công',
  updateSuccessfully: 'Cập nhật thành công',
  confirmSuccessfully: 'Xác nhận thành công',
  deleteSuccessfully: 'Xóa thành công',
  idsHasBeenDeleted: 'ID đã bị xóa:',
  email: 'Email',
  orderCode: 'Mã phiếu xuất',
  userName: 'Tên đăng nhập',
  orderDate: 'Ngày xuất hàng',
  orderQuantity: 'Số lượng Đặt hàng',
  takeQuantity: 'Số lượng Cần lấy',
  pickingquantity: 'Số lượng đã lấy',
  remainingQuantity: 'Số lượng còn lại',
  placeholderSelectOrderCode: 'Chọn mã phiếu xuất',
  titlePicking: 'Danh sách phiếu xuất kho',
  suggestLocation: 'Gợi ý vị trí',
  quantity: 'Số lượng',
  createPicking: 'Tạo danh sách lấy hàng',
  labelBooking: 'Đặt hàng',
  placeholderSelectOrder: 'Chọn mã phiếu xuất',
  okText: 'Đồng ý',
  cancelText: 'Hủy',
  titleModalBooking: 'Chọn Đặt hàng',
  scanCode: 'Scan mã',
  confirmPickupList: 'Xác nhận Danh sách lấy hàng',
  titleModalPslitProduct: 'Phân chia Sản phẩm',
  placeholderSelectPackageCode: 'Chọn Mã đóng gói',
  btnDone: 'Xong',
  btnClose: 'Đóng',
  titleModalSuccessSuggestLocation: 'Vị trí gợi ý được in thành công',
  pickupList: 'Danh sách Lấy hàng',
  generatePackageCode: 'Phát hành Mã đóng gói',
  orderNumber: 'Số Đặt hàng',
  productOrderCode: 'Mã sản phẩm đặt hàng',
  pickingList: 'Danh sách lấy hàng',
  packagingCode: 'Mã đóng gói',
  tempOutLot: 'Khu vực tạm xuất',
  orderandpackagingInfo: 'Thông tin Đặt hàng và Đóng gói',
  packagingDetail: 'Chi tiết đóng gói',
  orderandshippingInfo: 'Chi tiết phiếu xuất kho',
  confirmPackageList: 'Xác nhận Danh sách đóng gói',
  outQuantity: 'Số lượng đã hết',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  orderInfo: 'Thông tin Đặt hàng',
  inStock: 'Trong kho',
  expirationDate: 'Ngày hết hạn',
  storageExpirationDate: 'Ngày hết hạn lưu kho',
  exportCSV: 'Xuất Excel',
  agingStockAlert: 'Cảnh báo Quá ngày lưu kho',
  dotRestock: 'Vui lòng bổ sung sản phẩm cho đợt hàng tiếp theo',
  productDetail: 'Chi tiết Sản phẩm',
  total: 'Tổng số',
  inventorySummary: 'Tổng kết Lưu kho',
  inboundsummary: 'Tổng kết Nhập hàng',
  productSummary: 'Tổng kết Xuất hàng',
  action: 'Hành động',
  warehouseProductDetail: 'Chi tiết sản phẩm trong kho',
  requiredReportContent: 'Nội dung báo cáo bắt buộc có',
  editLocation: 'Thay đổi vị trí',
  dotEditLocation: 'Vui lòng chọn sản phẩm bạn muốn thay đổi vị trí',
  onlyEditLocation: 'Chỉ được phép thay đổi 1 dòng mỗi thời điểm',
  pendingState: 'Trạng thái Chờ',
  inboundState: 'Trạng thái Nhập hàng',
  inboundStateGift: 'Trạng thái Nhập hàng Gift',
  lostState: 'Trạng thái Mất',
  updateProduct: 'Cập nhật sản phẩm',
  requiredZone: 'Zone bắt buộc có',
  requiredLocator: 'Locator bắt buộc có',
  requiredBlock: 'Block bắt buộc có',
  requiredShelf: 'Shelf bắt buộc có',
  requiredRack: 'Rack bắt buộc có',
  requiredExpirationDate: 'Ngày hết hạn bắt buộc có',
  requiredTotalQauntity: 'Tổng số lượng bắt buộc có',
  requiredCost: 'Giá mua bắt buộc có',
  requiredSalePrice: 'Giá bán bắt buộc có',
  requiredStatus: 'Trạng thái bắt buộc có',
  requiredLostDate: 'Ngày mất bắt buộc có',

  totalPerPallet: 'Số lượng sản phẩm mỗi pallet',
  sepratePallet: 'Chia pallet',
  totalPallet: 'Số lượng pallet',
  totalRackbetween: 'Tổng số rack',
  backtoProductList: 'Quay về Danh sách Sản phẩm',
  productInfo: 'Thông tin sản phẩm',
  storageTime: 'Thời gian lưu kho còn lại (ngày)',
  productImage: 'Hình ảnh Sản phẩm',
  imageExtra: 'Bạn có thể tải lên hình ảnh dung lượng tối đa 5MB',
  upload: 'Tải lên',
  methodExport: 'Chiến lược xuất hàng',
  createDate: 'Ngày tạo',
  createProduct: 'Tạo sản phẩm',
  productCategoryName: 'Tên Nhóm hàng',
  productCategoryCode: 'Mã Nhóm hàng',
  productCategoryList: 'Danh sách Nhóm hàng',
  updateProductCategoty: 'Cập nhật Nhóm hàng',
  createProductCategoty: 'Tạo Nhóm hàng',
  minQuantity: 'Số lượng tối thiểu',
  maxQuantity: 'Số lượng tối đa',
  notification: 'Thông báo',
  onHand: 'Đang có',
  toOrder: 'Đặt hàng',
  replenishmentList: 'Danh sách Bổ sung hàng',
  createReplenishment: 'Tạo Bổ sung hàng',
  updateReplenishment: 'Cập nhật Bổ sung hàng',
  backtoSupplierList: 'Quay về Danh sách Nhà cung cấp',
  supplierInfo: 'Thông tin Nhà cung cấp',
  phone: 'Số điện thoại',
  cooperationDate: 'Ngày hợp tác',
  supplierName: 'Tên Nhà cung cấp',
  requiredCooperationDate: 'Ngày hợp tác bắt buộc có',
  supplierCode: 'Mã Nhà cung cấp',
  supplierList: 'Danh sách Nhà cung cấp',
  createSupplier: 'Tạo Nhà cung cấp',
  updateSupplier: 'Cập nhật Nhà cung cấp',
  createSaleOrder: 'Tạo Đơn hàng',
  addProduct: 'Thêm Sản phẩm',
  editProduct: 'Sửa Sản phẩm',
  saleOrderDetail: 'Chi tiết Phiếu xuất kho',
  confirmOrderDetail: 'Chi tiết xuất hàng',
  confirmOrder: 'Chi tiết phiếu xác nhận xuất hàng',
  orderList: 'Danh sách Đặt hàng',
  reportDate: 'Ngày báo cáo',
  firstName: 'Họ',
  lastName: 'Tên',
  lostDate: 'Ngày mất',
  phoneLengthMessage: 'Số điện thoại không được dài hơn 11 ký tự',
  phoneMessage: 'Số điện thoại phải là số',
  full: 'Đầy',
  empty: 'Còn trống',
  Medium: 'Trung bình',
  resetPassword: 'Khôi phục mật khẩu',
  passwordNew: 'Mật khẩu mới',
  currentPassword: 'Mật khẩu hiện tại',
  passwordConfirm: 'Xác nhận mật khẩu',
  zoneNameExist: 'Tên zone đã tồn tại',
  blockNameExist: 'Tên block đã tồn tại',
  shelfNameExist: 'Tên shelf đã tồn tại',
  no: 'Không',
  subTitleInboundControl: 'Vui lòng quét vị trí trước khi đặt sản phẩm lên kệ',
  subTitleOutboundCreatePickUpNotify: 'Vui lòng chọn đơn đặt hàng',
  subTitleStoringNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  subTitleInboundTemporaryNotify: 'Vui lòng quét mã QR của sản phẩm trước khi vận chuyển',
  passwordRegex: 'Mật khẩu phải có tối thiểu 8 ký tự',
  updateImageLess: 'Vui lòng tải ảnh nhỏ hơn 5MB',
  creatorName: 'Người tạo',
  invalidCode: 'Mã không tồn tại',
  tryAgain: 'Vui lòng thử lại',
  noRecordsFound: 'Không có kết quả tìm kiếm!',
  emailRequirePlaceholder: 'Vui lòng điền email',
  messeageExpiration: 'Ngày hết hạn là bắt buộc',
  unlockAccount: 'Mở khóa tài khoản thành công',
  january: 'Tháng 1',
  February: 'Tháng 2',
  March: 'Tháng 3',
  April: 'Tháng 4',
  May: 'Tháng 5',
  June: 'Tháng 6',
  July: 'Tháng 7',
  august: 'Tháng 8',
  september: 'Tháng 9',
  october: 'Tháng 10',
  november: 'Tháng 11',
  December: 'Tháng 12',
  orderStatus: 'Trạng thái đặt hàng',
  packageStatus: 'Trạng thái đóng gói',
  splitproduct: 'Tách sản phẩm',
  split: 'Tách',
  splitProductList: 'Danh sách tách sản phẩm',
  defaultCode: 'Mã sản phẩm',
  requiredDefaultCode: 'Mã mặc định là bắt buộc có',
  placeHolderDefaultCode: 'Vui lòng nhập mã mặc định',
  placeholderSalePrice: 'Vui lòng nhập giá bán',
  retailPrice: 'Giá bán lẻ',
  placeholderRetailPrice: 'Vui lòng nhập giá bán lẻ',
  lot: 'Lô',
  requiredLot: 'Số lượng lô là bắt buộc có',
  vat: 'Thuế (%)',
  divison: 'Khối hàng',
  category: 'Ngành hàng',
  divisonCode: 'Mã khối hàng',
  divisonName: 'Tên khối hàng',
  createDivison: 'Tạo khối hàng',
  updateDivison: 'Cập nhật khối hàng',
  placeholderDivisonName: 'Vui lòng nhập tên khối hàng',
  requiredDivisonName: 'Tên khối hàng là bắt buộc',
  categoryCode: 'Mã ngành hàng',
  categoryName: 'Tên ngành hàng',
  createCategory: 'Tạo ngành hàng',
  updateCategory: 'Cập nhật ngành hàng',
  placeholderCategoryName: 'Vui lòng nhập tên ngành hàng',
  requiredParentCategoryName: 'Tên ngành hàng là bắt buộc',
  requiredDivison: 'Khối hàng là bắt buộc',
  requiredParentCategory: 'Ngành hàng là bắt buộc',
  categoryList: 'Danh sách ngành hành',
  divisonList: 'Danh sách khối hàng',
  DVT: 'Đơn vị tính',
  isActive: 'Kích hoạt',
  contractNumber: 'Số hợp đồng',
  packingRule: 'Quy cách đóng gói',
  NEW: 'Bản nháp',
  INPROGRESS: 'Đang nhập kho',
  TEMPORARYCONFIRM: 'Chờ xác nhận',
  CONFIRM1: 'Thủ kho xác nhận',
  CONFIRM2: 'IDM xác nhận',
  ERROR: 'LỖI',
  TEMPORARY: 'TẠM NHẬP',
  STORED: 'TRONG KHO',
  MOVING: 'DI CHUYỂN',
  TEMPORARY_OUT: 'XUẤT',
  LOST: 'MẤT',
  REALLOCATE: 'ĐỔI LẠI',
  ENABLE: 'CHO PHÉP',
  DISABLE: 'VÔ HIỆU HÓA',
  requestReceipt: 'Hóa đơn AP',
  note: 'Ghi chú',
  itemCode: 'Mã item',
  length: 'Dài',
  width: 'Rộng',
  height: 'Cao',
  RECEIVE: 'Completed',
  dowloadTemplate: 'Tải mẫu',
  importSuccess: 'Tải lên thành công',
  exportPdf: 'Xuất PDF',
  discount: 'Chiết khấu',
  isResources: 'Nguồn hàng',
  vansCode: 'Số container',
  createPo: 'Phiếu nhập kho',
  taxCode: 'Mã số thuế',
  requiredPackingRule: 'Quy cách đóng gói là bắt buộc',
  CANCELLED: 'HỦY',
  PICKING: 'ĐÃ LẤY',
  EXPORTED: 'ĐÃ XUẤT',
  PACKAGED: 'ĐÓNG GÓI',
  deliveryTime: 'Thời gian giao hàng',
  messeagePicking: 'Đơn hàng đang được chọn bởi 1 người dùng khác',
  bom: 'Nguyên liệu sản phẩm',
  bomList: 'Danh sách nguyên liệu sản phẩm',
  caseUOM: 'Tên nguyên liệu',
  average: 'Số lượng sử dụng/ 1 thùng thành phẩm',
  wastage: 'Hao hụt sản xuất',
  createBom: 'Tạo mới nguyên liệu sản phẩm',
  updateBom: 'Cập nhật nguyên liệu sản phẩm',
  bomCode: 'Mã nguyên liệu',
  codeNpl: 'Mã NPL',
  producerRule: 'Quy cách nhà sản xuất',
  cartonRule: 'Quy cách thùng chứa',
  grossWeightPerCarton: 'Trọng lượng tổng/ thùng',
  cartonPerPallet: 'Số lượng thùng/ pallet',
  abbottCode16: 'Mã sản phẩm -nhà máy',
  threeACode: 'Mã sản phẩm - ERP',
  shortname: 'Tên sản phẩm - Viết tắt',
  nameEn: 'Tên sản phẩm - Tiếng Anh',
  UOM_EN: 'Đơn vị - Tiếng Anh',
  nameVn: 'Tên sản phẩm - Tiếng Việt',
  UOM_VN: 'Đơn vị - Tiếng Việt',
  flavor: 'Hương sản phẩm',
  tinPerCarton: 'Số lượng lon/ thùng',
  brand: 'Nhãn hàng',
  skuGroup: 'Nhóm sản phẩm',
  fiveKeybrand: 'Nhóm nhãn hàng',
  countryOfOrigin: 'Nơi sản xuất',
  size: 'Trọng lượng (Gram/lon)',
  shelfLifeMonths: 'Hạn sử dụng',
  typeOfProduct: 'Loại sản phẩm',
  ctnsPerLayer3A: 'Tiêu chuẩn 3A - Số thùng/lớp - Trên kệ',
  layersPerPallet3A: 'Tiêu chuẩn 3A - Số lớp/ pallet',
  ctmsPerPalletStorage3A: 'Tiêu chuẩn 3A - Số thùng/pallet - Trên kệ',
  ctnsPerLayer3AFloor: 'Tiêu chuẩn 3A - Số thùng/ lớp - Dưới sàn',
  layersPerPallet3AFloor3: 'Tiêu chuẩn 3A - Số lớp/ pallet - Dưới sàn',
  ctnsPerPalletStorage3AFloor4: 'Tiêu chuẩn 3A - Số thùng/ pallet - Dưới sàn',
  ctnsPerLayer2Import: 'Tiêu chuẩn nhập khẩu - Số thùng/ lớp - Dưới sàn',
  layersPerPallet3Import: 'Tiêu chuẩn nhập khẩu - Số lớp/ pallet - Dưới sàn',
  ctnsPerPalletImport: 'Tiêu chuẩn nhập khẩu - Số thùng/ pallet - Dưới sàn',
  isPackagedSku: 'Sản phẩm đóng gói?',
  milkCode: 'Sản phẩm sữa cho trẻ dưới 2 tuổi?',
  subBrandFollowAds: 'Nhãn phụ - ADS',
  typeOfProduct2: 'Ngành sản phẩm con',
  grossWeightKgPerCase: 'Tổng trọng lượng/ thùng sản phẩm',
  netWeightGramPerTins: 'Tổng trọng lượng sữa (NET)/ 1 đơn vị sản phẩm',
  netWeightKgPerCase: 'Tổng trọng lượng sữa (NET)/ 1 thùng sản phẩm',
  volumeCm3: 'Thể tích/ đơn vị sản phẩm',
  grossWeightKgPerPallet: 'Tổng trọng lượng/ 1 pallet',
  netWeightKgPerPallet: 'Tổng trọng lượng NET/ 1 pallet',
  lengthCartonCm: 'Chiều dài thùng sản phẩm',
  widthCartonCm: 'Chiều rộng thùng sản phẩm',
  heightCartonCm: 'Chiều cao thùng sản phẩm',
  volumeCartonCm3: 'Thể tích thùng sản phẩm',
  barcodeTins: 'Mã vạch theo sản phẩm(lon)',
  barcodeCarton: 'Mã vạch theo sản phẩm(thùng)',
  itemGroupName: 'Nhóm hàng',
  bomGift: 'Sản phẩm tặng kèm',
  bomGiftlist: 'Quà tặng sản phẩm',
  createBomGift: 'Tạo sản phẩm tặng kèm',
  updateBomGift: 'Cập nhật sản phẩm tặng kèm',
  productBomCode: 'Mã BOM sản phẩm',
  umIsFreeItem: 'Hàng miễn phí',
  masterproductPacking: 'Quy cách đóng gói NPL',
  masterproductPackingList: 'Danh sách quy cách đóng gói nguyên phụ liệu',
  createMasterproductPacking: 'Tạo mới quy cách đóng gói nguyên phụ liệu',
  updateMasterproductPacking: 'Cập nhật quy cách đóng gói nguyên phụ liệu',
  type: 'Loại',
  double: 'Máy co đôi',
  single: 'Máy đơn',
  handWork: 'Thủ công',
  area: 'Khu vực',
  areaList: 'Danh sách khu vực',
  areaName: 'Tên khu vực',
  areaCode: 'Mã khu vực',
  areaCreate: 'Tạo mới khu vực',
  areaUpdate: 'Cập nhật khu vực',
  locator: 'Vị trí',
  locatorList: 'Danh sách locator',
  locatorName: 'Tên locator',
  locatorCode: 'Mã locator',
  locatorCreate: 'Tạo mới locator',
  locatorUpdate: 'Cập nhật locator',
  apInvoiceList: 'Danh sách Ap Invoice',
  updateApInvoice: 'Cập nhật Ap Invoice',
  apInvoiceDetail: 'Chi tiết APInvoice',
  productListDivided: 'Danh sách sản phẩm đã chia',
  giftCode: 'Mã quà tặng',
  giftName: 'Tên quà tặng',
  UOM: 'Đơn vị',
  PCSCase: 'Số lượng mỗi thùng',
  specification: 'Thông số kỹ thuật',
  lengthPcs: 'Độ dài PCS',
  widthPcs: 'Chiều rộng PCS',
  heightPcs: 'Chiều cao PCS',
  grossWeightPcs: 'Tổng trọng lượng (PCS)',
  volumePcs: 'Thể tích PCS',
  grossWeight3A: 'Tổng trọng lượng (3A)',
  ctnsPerLayer2Vender: 'Số thùng mỗi lớp (Nhà cung cấp)',
  layersPerPallet3Vender: 'Số lớp trên pallet (Nhà cung cấp)',
  ctnsPerPalletVender: 'Số thùng trên pallet (Nhà cung cấp)',
  grossWeightVender: 'Tổng trọng lượng (Vender)',
  isRack: 'Kệ',
  salesChannel: 'Kênh bán hàng',
  saleChannelList: 'Danh sách kênh bán hàng',
  salesChannelCode: 'Mã kênh bán hàng',
  salesChannelName: 'Tên kênh bán hàng',
  quota: 'Số lượng giới hạn',
  shelfLife: 'Thời gian sử dụng còn lại (%)',
  createSalesChannel: 'Tạo kênh bán hàng',
  updateSalesChannel: 'Cập nhật kênh bán hàng',
  shelftype: 'Loại kệ',
  quantityCartton: 'Số lượng thùng',
  quantityTin: 'Số lượng lon',
  totalQuantityCartton: 'Tổng số lượng thùng',
  totalQuantityTin: 'Tổng số lượng lon',
  totalQuantityTinOdd: 'Tổng số lượng lon lẻ',
  quantityCarttonAtLocation: 'Số lượng thùng tại vị trí',
  quantityTinAtLocation: 'Số lượng lon tại vị trí',
  quantityCarttonTaken: 'Số lượng thùng đã lấy',
  quantityCarttonLeft: 'Số lượng thùng còn lại',
  quantityTaken: 'Số lượng lon đã lấy',
  quantityLeft: 'Số lượng lon còn lại',
  quantityCarttonReceived: 'Số lượng thùng thực nhận',
  quantityTinReceived: 'Số lượng lon thực nhận',
  company: 'Công ty',
  branch: 'Chi nhánh',
  orderReference: 'Order tham chiếu',
  businessLine: 'Kênh khách hàng',
  abbottPOReference: 'PO tham chiếu',
  description: 'Diễn giải',
  dateOrdered: 'Ngày xuất hàng',
  giftWarehouse: 'Kho hàng tặng',
  customerProgram: 'Chương trình khách hàng',
  subChannel: 'Kênh phụ',
  rcreturn: 'TRẢ VỀ',
  rcinternal: 'NỘI BỘ',
  rcborrow: 'NHẬP MƯỢN',
  rcimport: 'NHẬP HÀNG GIFT',
  returnDate: 'Ngày trả về',
  movementDate: 'Ngày chuyển',
  tranferReason: 'Lý do chuyển',
  locatorTo: 'Locator nhận',
  movementQuantity: 'Số lượng chuyển',
  documentNo: 'Mã phiếu xuất',
  documentType: 'Document Type',
  orderType: 'Loại xuất kho',
  consignmentOrder: 'Loại nhập',
  borrowed: 'Xuất mượn',
  enterBorrow: 'Nhập mượn',
  EXPORT_INTERNAL: ' Đơn xuất nội bộ',
  exportInternalGift: ' Đơn xuất nội bộ quà tặng',
  EXPORT_SALE: 'Đơn xuất bán',
  EXPORT_BORROW: 'Đơn xuất mượn',
  masterproductsbomgift: 'Quy cách đóng gói SPTK',
  createMasterproductsbomgift: 'Tạo mới quy cách đóng gói sản phẩm tặng kèm',
  updateMasterproductsbomgift: 'Cập nhật quy cách đóng gói sản phẩm tặng kèm',
  masterproductsbomgiftList: 'Danh sách quy cách đóng gói sản phẩm tặng kèm',
  Vas: 'Vas',
  completeVasScanSuccessfully: 'Scan Vas thành công',
  VAS: 'VAS',
  floor: 'Số tầng',
  productionOrder: 'Lệnh sản xuất',
  productionOrderList: 'Danh sách lệnh sản xuất',
  createProductionOrder: 'Tạo lệnh sản xuất',
  productionOrderDetail: 'Chi tiết lệnh sản xuất',
  confirmProductionOrderProduct: 'Xác nhận danh sách sản phẩm lệnh sản xuất',
  createComboProduct: 'Tạo combo sản phẩm',
  invoiceNo: 'Số hóa đơn',
  orderNo: 'Số đơn hàng',
  contractNo: 'Số hợp đồng',
  shipment: 'Lô hàng',
  shipmentNo: 'Số vận chuyển',
  mfgDate: 'Ngày sản xuất',
  btnlock: 'Khóa',
  lockproductSuccessfully: 'Khóa sản phẩm thành công',
  picking: 'Lấy hàng',
  expectedQuantityCarton: 'Số lượng thùng mong muốn',
  expectedQuantityTin: 'Số lượng lon mong muốn',
  requiredContainerNo: 'Container no là bắt buộc',
  locatorFrom: 'Từ Locator',
  receivedRemainingQuantityCarton: 'Số lượng thùng còn lại phải nhận',
  receivedRemainingQuantityTin: 'Số lượng lon còn lại phải nhận',
  orderConfirm: 'Xác nhận xuất hàng',
  orderConfirmCreate: 'Tạo mới xác nhận đơn hàng',
  inventoryManagement: 'Kiểm tồn',
  lDDNo: 'Số lệnh điều động',
  truckType: 'Loại xe',
  noPallet: 'Không có pallet',
  quantityCarttonRemainder: 'Số lượng thùng lẻ/Pallet',
  quantityTinRemainder: 'Số lượng lon lẻ',
  warehouseZoneDetail: 'Chi tiết khu vực',
  customerReturn: 'Yêu cầu trả hàng của khách hàng',
  customerReturnList: 'Danh sách yêu cầu trả hàng của khách hàng',
  customerReturnCreate: 'Tạo trả hàng khách hàng',
  customerReturnDetail: 'Chi tiết trả hàng khách hàng',
  targetDocType: 'Loại tài liệu mục tiêu',
  rmaCategory: 'Danh mục yêu cầu trả hàng',
  origSalesOrder: 'Đơn Đặt Hàng Gốc',
  origDO: 'Đơn Giao Hàng Gốc',
  DODate: 'Ngày Giao Hàng',
  businessPartner: 'Đối tác kinh doanh',
  billTo: 'Đơn vị thanh toán',
  partnerLocation: 'Địa điểm đối tác',
  billToLocation: 'Địa điểm thanh toán',
  representative: 'Người đại diện',
  campaign: 'Chiến dịch',
  department: 'Phòng ban',
  origDOLine: 'Orig DO line',
  attributeSetInstance: 'Thuộc tính bộ dữ liệu',
  unitPrice: 'Đơn giá',
  createReceiptReturn: 'Tạo phiếu trả hàng',
  rmaDate: 'Ngày RMA',
  availableProduct: 'Sản phẩm có sẵn',
  exportBranch: 'Chi nhánh xuất khẩu',
  exportBranchGift: 'Chi nhánh quà tặng',
  rcBranch: 'Chi nhánh RC',
  HOLD: 'GIỮ',
  AV: 'AV',
  rcnew: 'NHẬP KHẨU',
  EXPORT: 'XUẤT HÀNG',
  enablehd: 'Hoạt động',
  COMPLETED: 'HOÀN THÀNH',
  NORMAL: 'BÌNH THƯỜNG',
  GIFT: 'QUÀ TẶNG',
  giftInventory: 'Kiểm tồn quà tặng',
  inventoryMaterial: 'Kiểm tồn NLSP',
  exportPickingSlip: 'Trong phiếu lấy hàng',
  origDoLine: 'Dòng DO gốc',

  february: 'Tháng hai',
  march: 'Tháng ba',
  april: 'Tháng tư',
  may: 'Tháng năm',
  june: 'Tháng sáu',
  july: 'Tháng bảy',
  december: 'Tháng mười hai',
  addVans: 'Thêm xe tải',
  createVans: 'Tạo xe tải',
  updateVans: 'Cập nhật xe tải',
  vansName: 'Tên xe tải',
  vansLicensePlate: 'Biển số xe tải',
  placeholderLicensePlate: 'Biển số xe tải mẫu',
  requiredLicensePlate: 'Biển số xe tải bắt buộc',
  requiredVansName: 'Tên xe tải bắt buộc',
  vansState: 'Trạng thái xe tải',
  vansDetail: 'Chi tiết xe tải',
  chooseFile: 'Vui lòng chọn file!',
  truckWeight: 'Trọng tải xe(tấn)',
  iddNo: 'Lệnh điều động',
  listARInvoices: 'Danh sách Ar-invoice',
  internalTransferNote: 'PHIẾU CHUYỂN KHO NỘI BỘ',
  internalStockTransferNumber: 'Số phiếu chuyển kho nội bộ',
  internalUseExportNumber: 'Số PXKKVCNB',
  printDate: 'Ngày in',
  transferDate: 'Ngày chuyển',
  completionDate: 'Ngày hoàn thành',
  abbottCode: 'Mã Abbott',
  code3A: 'Mã 3A',
  lotNumberExpiryDate: 'Số lô & Hạn dùng',
  lotNumber: 'Số lô',
  expiryDate: 'Hạn dùng',
  fromWarehouse: 'Từ kho',
  toWarehouse: 'Đến kho',
  carton: 'Thùng',
  tin: 'Lon',
  damaged: 'Hỏng',
  dented: 'Móp',
  missing: 'Thiếu',
  sum: 'Tổng',
  pickingStaff: 'Nhân viên soạn hàng',
  signatureFullName: '(Ký, Họ tên)',
  warehouseKeeper: 'Thủ kho',
  tableCreator: 'Người lập bảng',
  vehicleLoadCapacityTons: 'Tải trọng xe (tấn)',
  transit: 'Trung chuyển',
  totalExportSlips: 'Tổng số phiếu xuất',
  threeANutritionLLCVietnam: 'CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)',
  date: 'Ngày',
  month: 'tháng',
  year: 'năm',
  pickedProductList: 'Danh Sách Sản Phẩm Đã Lấy',
  itemNo: 'STT',
  productBatchCode: 'Mã lô SP',
  productGroup: 'Nhóm hàng',
  pickedProducts: 'Sản phẩm đã lấy',
  entryDate: 'Ngày nhập',
  canBeTaken: 'có thể lấy',
  lessThanCurrentCanQuantity: 'Nhỏ hơn số lượng lon hiện có',
  lessThanCurrentBoxQuantity: 'Nhỏ hơn số lượng thùng hiện có',
  serialNumber: 'Mẫu số',
  goodsReceiptPaymentAdjustmentSlip: 'Phiếu giao nhận hàng và cấn trừ tiền chương trình',
  salesUnit3ANutritionAbbottVietnam: 'Đơn vị bán hàng: CÔNG TY TNHH DINH DƯỠNG 3A ABBOTT (Việt Nam)',
  shippingAddressBinhTan: 'Địa chỉ xuất hàng: Lô 6-1A, cụm 6, đường M1, KCN Bình Tân mở rộng, P.Bình Hưng Hòa, Q.Bình Tân, TP.HCM',
  poNumber401550: 'Số PO: 401550',
  purchasingUnit: 'Đơn vị mua hàng',
  information: 'Thông tin',
  fullName: 'Họ và tên',
  deliveryAddress: 'Địa chỉ giao hàng',
  billingAddress: 'Địa chỉ hóa đơn',
  route: 'Tuyến đường',
  customerType: 'Loại khách hàng',
  salesStaff: 'Nhân viên bán hàng',
  payment: 'Thanh toán',
  bankTransfer: 'Chuyển khoản',
  deliverySlipNumber: 'Số phiếu giao hàng',
  purchaseOrderNumber: 'Số đơn đặt hàng',
  orderPlacementDate: 'Ngày đơn hàng',
  deliveryDueDate: 'Ngày đến hạn giao hàng',
  purchaseOrderType: 'Loại đơn đặt hàng',
  purchaseOrderReferenceNumber: 'Số tham chiếu đơn đặt hàng',
  systemCode: 'Mã hệ',
  quantityCansPackagesPack: 'SL lon/Gói/Pack',
  batchNumberExpiryDate: 'Số lô_Hạn sử dụng',
  invoiceAmountBeforeDiscountInvoiceNumber: 'Tiền hàng chưa chiết khấu theo hóa đơn số',
  tradeDiscountInvoiceNumber: 'Chiết khấu thương mại theo đơn hàng hóa đơn số',
  cumulativeTradeDiscountPreviousOrdersInvoiceNumber: 'Chiết khấu thương mại tích lũy từ đơn hàng trước hóa đơn số',
  programAmountOffsetPayables: 'Tiền chương trình được cấn trừ công nợ',
  remainingAmount: 'Số tiền còn lại',
  deliveryConfirmationOriginalVATInvoice: 'Phiếu này có giá trị xác nhận việc đã giao hàng, đã giao hóa đơn GTGT bản gốc',
  copy2GivenToBuyerNoPaymentConfirmation: 'Liên 2: Giao cho người mua, không có giá trị xác nhận vệc thu tiền',
  customerReceivedFullOrder: 'Khách hàng đã nhận đủ hàng',
  customerReceivedFullOrderAndProgramPayment: 'Khách hàng đã nhận đủ hàng và tiền chương trình',
  deliveryStaff: 'Nhân viên giao hàng',
  issueGoodsDeliveryNote: 'Xuất PGNH',
  confirmationSuccessful: 'Xác nhận thành công!',
  warehouseExportSlip: 'PHIẾU XUẤT KHO',
  fromDate: 'Từ ngày',
  deliveryStaffCode: 'NVGH',
  vehicleNumber: 'Số xe',
  doCreated: 'Đã tạo DO',
  doNotCreated: 'Chưa tạo DO',
  materialExport: 'Xuất Material',
  newCreate: 'Mới tạo',
  exported: 'Đã xác nhận',
  cancelled: 'Đã hủy',
  slipDeletedSuccessfully: 'Xóa phiếu thành công!',
  materialExportSlipDetails: 'Chi tiết phiếu xuất Material',
  warning: 'Cảnh báo!',
  deliveryRoute: 'Tuyến giao hàng',
  printedTime: 'Thời gian in',
  toDate: 'Đến ngày',
  totalCarton: 'Tổng số thùng',
  dailyTransferSequence: 'Thứ tự chuyển trong ngày',
  checkerStaff: 'Nhân viên kiểm tra',
  securityGuard: 'Bảo vệ',
  slipCreator: 'Người lập phiếu',
  issueExportSlip: 'Xuất PXK',
  confirmProceedSure: 'Bạn có chắc chắn muốn xác nhận không?',
  DOCancelledSuccessfully: 'Hủy DO thành công!',
  waitingForPicking: 'Chờ soạn',
  inPicking: 'Đang soạn',
  picked: 'Đã lấy hàng',
  pendingCancellation: 'Đang Chờ Hủy',
  completedShipment: 'Hoàn tất xuất hàng',
  areYouSureGroup: 'Bạn chắc chắn muốn gom nhóm?',
  ordersGroupedSuccessfully: 'Gom đơn thành công!',
  groupPick: 'Gom đơn',
  areYouSureUngroup: 'Bạn chắc chắn muốn UnGroup?',
  ungroupSuccessful: 'UnGroup thành công!',
  areYouSureAssign: 'Bạn có chắc chắn muốn Assign?',
  assignSuccessful: 'Assign thành công!',
  selectEmployee: 'Chọn nhân viên',
  deliveryOrderList: 'Danh sách Delivery order',
  areYouSureCancelExportBranch: 'Bạn có chắc chắn muốn hủy ExportBranch không?',
  cancelSuccessful: 'Hủy thành công!',
  cancelExportBranch: 'Hủy Export Branch',
  exportXML: 'Xuất XML',
  pleaseSelectZone: 'Vui lòng chọn Zone',
  areYouSureConfirmSlip: 'Bạn chắc chắn muốn xác nhận phiếu?',
  slipType: 'Loại phiếu',
  quantityCannotBeZero: 'Số lượng không thể bằng 0',
  pleaseEnterQuantity: 'Vui lòng nhập số lượng',
  actualExportCans: 'Thực xuất (Lon)',
  actualImportCans: 'Thực nhập (Lon)',
  soDocNo: 'Số tài liệu SO',
  doYouWantToContinue: 'Bạn có muốn tiếp tục',
  itemQuantityShort: 'Mặt hàng số lượng đang lấy thiếu. Bạn có muốn hoàn tất không?',
  createMaterialExportSlip: 'Tạo phiếu xuất Material',
  dateFormat: 'Ngày___tháng_____năm______',
  licensePlate: 'Biển số xe',
  driverEmployee: 'Nhân viên lái xe',
  exportIWS: 'Xuất PCKNB',
  internalTransferSlipWithInternalTransport: 'PHIẾU CHUYỂN KHO NỘI BỘ KIÊM VẬN CHUYỂN NỘI BỘ',
  productSuggestionList: 'DANH SÁCH GỢI Ý SẢN PHẨM',
  createDeliveryOrder: 'Tạo DeliveryOrder',
  createDeliveryOrderFailed: 'Tạo DeliveryOrder không thành công!',
  orderWithCode: 'Đơn có mã',
  deliveryOrderCreated: 'đã tạo DeliveryOrder!',
  productNoExpiryDate: 'sản phẩm chưa có HSD, vui lòng kiểm tra lại!',
  productNoLocator: 'sản phẩm chưa có Locator, vui lòng kiểm tra lại!',
  productNoLot: 'Product does not have a lot, please check again!',
  deliveryOrderCreatedContinue: 'đã tạo DeliveryOrder!, Bạn có muốn tiếp tục?',
  list: 'Danh sách',
  areYouSureDeleteSlip: 'Bạn chắc chắn muốn xóa phiếu?',
  availableQuantity: 'Số lượng khả dụng',
  orderProduct: 'Sản phẩm đơn hàng',
  lessThanStockQuantity: 'Nhỏ hơn số lượng tồn kho',
  quantityIsRequired: 'Số lượng là bắt buộc',
  invalidQuantity: 'Sai số lượng',
  quantityAvailableToPick: 'SL có thể lấy',
  order: 'đơn',
  materialExportSlipList: 'Danh sách phiếu xuất Material',
  all: 'Tất cả',
  exportBranch1: 'Xuất chi nhánh',
  internalExport: 'Xuất nội bộ',
  exportSlipType: 'Loại phiếu xuất',
  updateSuccessful: 'Cập nhật thành công!',
  selectReceivingWarehouse: 'Chọn Kho nhận',
  receivingWarehouse: 'Kho nhận',
  exportWSlipWithITransport: 'Xuất PXKKVCNB',
  details: 'Chi tiết',
  picked1: 'đã lấy',
  companyDirector3AVietnam: 'GIÁM ĐỐC CÔNG TY TNHH DINH DƯỠNG 3A(VIỆT NAM)',
  company3AVietnam: 'CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)',
  address3AVietnam: 'Lô 6-1A, Cụm 6, Đường M1, Khu CN Tân Bình Mở Rộng, P. Bình Hưng Hòa, Q. Bình Tân, Tp.HCM',
  hcm: 'Hồ Chí Minh',
  centecTowerAddress: 'Centec Towe, 72-74, Đường Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh, Việt Nam.',
  symbol: 'Ký hiệu',
  number: 'Số',
  warehouseExportSlipConvertedFromEWarehouseSlip: 'PHIẾU XUẤT KHO CHUYỂN ĐỔI TỪ PHIẾU XUÂT KHO ĐIỆN TỬ',
  basedOnDispatchOrderNo: 'Căn cứ lệnh điều động số',
  onlyOneSelectionAllowed: 'Chỉ được chọn 1',
  suggestion: 'Gợi ý',
  areYouSureCreateDOForSelectedOrders: 'Bạn chắc chắn muốn tạo DeliveryOrder cho các đơn đã chọn?',
  pleaseSelectVehicleType: 'Vui lòng chọn loại xe!',
  areYouSureCancelProductionOrder: 'Bạn có chắc chắn muốn hủy lệnh sản xuất?',
  locatorIsRequired: 'Locator là bắt buộc',
  customerFullName: 'Họ tên khách hàng',
  transporterFullName: 'Họ và tên người vận chuyển',
  transportationMode: 'Phương tiện vận chuyển',
  exportAtWarehouse: 'Xuất tại kho',
  importAtWarehouse: 'Nhập tại kho',
  totalAmount: 'Thành tiền',
  export: 'Xuất',
  import: 'Nhập',
  slipCreatorOrConverter: 'Người lập phiếu/chuyển đổi',
  warehouseReceivingUnit: 'Đơn vị nhập kho',
  transportationUnit: 'Đơn vị vận chuyển',
  warehouseExportUnit: 'Đơn vị xuất kho',
  signedBy: 'Ký bởi',
  signedDate: 'Ký ngày',
  orderedProductList: 'Danh sách sản phẩm đặt hàng',
  suggestedProductList: 'Danh sách sản phẩm gợi ý',

  //configuration bom-list import template
  bomListTemplateColName: 'Vỏ thùng',
  bomListTemplateColProducerRule: '36 tem/ tờ',
  bomListTemplateColCartonRule: '36 tem/ tờ',
  bomListTemplateColGrossWeightPerCarton: '1000 tờ/ kiện',
  bomListTemplateColCartonPerPallet: '2160000tem/pallet',

  //configuration bom-gift edit form
  vietNameseName: 'Tên tiếng việt',
  active: 'Active',
  taxCategory: 'Danh mục thuế',
  lengthPcsUnit: 'Chiều dài(cm) (PCS)',
  widthPcsUnit: 'Chiều rộng(cm) (PCS)',
  heightPcsUnit: 'Chiều cao(cm) (PCS)',
  volumePcsUnit: 'Volume (PCS)',
  lengthCartonUnit: 'Chiều dài(cm) (Thùng)',
  widthCartonUnit: 'Chiều rộng(cm) (Thùng)',
  heightCartonUnit: 'Chiều cao(cm) (Thùng)',
  grossWeight3AUnit: 'Gross Weight (3A QA)',
  volumeCartonUnit: 'Volume (Thùng)',
  ctnsLayer3AUnit: 'Ctns/Layer (3A)',
  layerPallet3AUnit: 'Layers /Pallet (3A)',
  ctnsPallet3AStorageUnit: 'Ctns/pallet (Storage) (3A)',
  ctnsLayer3AFloorUnit: 'Ctns/Layer (3A/Floor)',
  ctnsLayer2VendorUnit: 'Ctns/Layer2 (Vendor)',
  layerPallet3VendorUnit: 'Layers /Pallet3 (Vendor)',
  ctnsPalletVendorUnit: 'Ctns/pallet (Vendor)',
  grossWeightVendorUnit: 'Gross Weight (Vendor)',

  //configuration location-list container
  divived: '(Xuyên sâu)',
  shelfTemplateColShelfName: 'Tên kệ',
  shelfTemplateColPosition: 'Vị trí (Trên,dưới)',
  shelfTemplateColTotalBin: 'Tổng số bin',
  shelfTemplateColAverageRate: 'Mức trung bình(%)',
  shelfTemplateColHighRate: 'Mức cao(%)',
  shelfTemplateColVolume: 'Sức chứa (cm)',
  shelfTemplateColBlockCode: 'Mã block',
  shelfTemplateColTierNumber: 'Số tầng',

  updateRackTitle: 'Cập nhật rack',

  bin: 'Bin',
  MATERIAL: 'MATERIAL',
  driveIn: 'Xuyên sâu',

  //configuration zone modal
  zoneCapacity: 'Số lượng pallet tối đa',
  zoneTempImport: 'TẠM NHẬP',
  zoneTempExport: 'TẠM XUẤT',

  //configuration locator
  pleaseInput: 'Vui lòng nhập',
  warehouseTo: 'Đến',
  expiryDateRequired: 'HSD cần phải nhập!',
  distributorCode: 'Mã NPP',
  distributorName: 'Tên NPP',
  customerCode: 'Mã khách hàng',
  storeName: 'Tên cửa hàng',
  businessRegistrationName: 'Tên ĐKKD',
  customerTypeCode: 'Mã loại KH',
  customerTypeName: 'Tên loại KH',
  street: 'Đường',
  ward: 'Phường/Xã',
  wardCode: 'Mã phường/xã',
  district: 'Quận/Huyện',
  province: 'Tỉnh/Thành phố',
  homeInvoiceNo: 'Số nhà ghi hóa đơn',
  streetNameInvoice: 'Tên đường ghi hóa đơn',
  provinceInvoice: 'Tỉnh/Thành phố ghi hóa đơn',
  districtInvoice: 'Quận/Huyện ghi hóa đơn',
  wardCodeInvoice: 'Mã phường/xã ghi hóa đơn',
  country: 'Quốc gia',
  dateOfBirth: 'Ngày sinh',
  channelName: 'Tên Channel',
  subChannelName: 'Tên SubChannel',
  nielsenChannelName: 'Tên Nielsen Channel',
  basedOnInventory: 'Theo tồn kho',
  exportInternal: 'Xuất PCNB',

  //configuration sale order create product edit
  requireQuantityInCartonAndTin: 'Số lượng thùng và lon là bắt buộc',
  totalQuantityInCarton: 'Số lượng thùng khả dụng',
  lessThanStockCarton: 'Nhỏ hơn số lượng thùng tồn kho',
  quantityInCarton: 'Số lượng thùng',
  totalQuantityInTin: 'Số lượng lon khả dụng',
  lessThanOneCarton: 'Nhỏ hơn số lượng của 1 thùng',
  lessThanStockTin: 'Nhỏ hơn số lượng lon tồn kho',
  quantityInTin: 'Số lượng lon',

  //configuration sale-order-create container
  pleaseSelectLocator: 'Vui lòng chọn locator!',

  //inbound
  customerReturnCode: 'Mã customer RMA',
  giftProductCode: 'Mã sản phẩm quà tặng',
  giftProductName: 'Tên sản phẩm quà tặng',
  selectVehicleNotice: 'Vui lòng chọn loại xe!',
  selectLocatorTransitNotice: 'Vui lòng chọn locator transit!',
  dispatchOrder: 'Lệnh điều động',
  dispatchOrderCode: 'Mã lệnh điều động',
  createDispatchOrder: 'Tạo lệnh điều động',
  dispatchOrderDetail:'Chi tiết lệnh điều động',
  dispatchGiftOrder: 'Tạo lệnh điều động Gift',
  tons: 'tấn',
  exportDispatchOrder: 'Xuất LĐĐ',
  exportDeliveryNotice: 'Xuất PBCH',
  update: 'Cập nhật',
  createNew: 'Tạo mới',
  deleted: 'Đã xóa',
  canceled: 'Đã hủy',
  operationCreated: 'Đã tạo nghiệp vụ',
  cancelOrderSuccessfully: 'Hủy lệnh thành công',
  updateOrderSuccessfully: 'Cập nhập lệnh thành công',
  doYouWant: 'Bạn chắc chắn muốn',
  deleteDispatchOrder: 'Xóa lệnh điều động',
  successfully: 'Thành công',
  quantityIsRequire: 'Số lượng là bắt buộc',
  availableQuantityCarton: 'Số lượng thùng khả dụng',
  lessThanInventoryQuantity: 'Nhỏ hơn số lượng kho',
  branchShipmentOrder: 'đơn xuất chi nhánh',
  pickedCanQuantity: 'SL có thể lấy',
  pickedCan: 'có thể lấy',
  pickedNeed: 'cần lấy',
  inventoryItems: 'Sản phẩm lưu trữ',
  inventoryGifts: 'Quà lưu trữ',
  temporaryImportGiftList: 'Danh sách quà tạm nhập',
  toCoordination: 'từ điều phối',
  createExport: 'tạo xuất',
  create: 'Tạo',
  quantityInCartonLessThanTotal: 'SL lấy phải nhỏ hơn SL tồn kho',
  quantityCartonAndTin: 'Số lượng thùng và lon',
  isRequire: 'là bắt buộc',
  suggest: 'Gợi ý',
  temporaryImport: 'Sản phẩm tạm nhập',
  temporaryGift: 'Quà tạm nhập',
  temporaryGiftList: 'Danh sách quà tạm nhập',
  unpackCode: 'Mã Unpack',
  unpackDetail: 'Chi tiết Unpack',
  unpackProductList: 'Danh sách sản phẩm Unpack',
  materialCode: 'Mã material',
  materialName: 'Tên material',
  cancelMaterial: 'Hủy phiếu material',
  cancelMaterialSuccessfully: 'Hủy phiếu thành công',
  form: 'phiếu',
  exportPGH: 'xuất PGH',
  giftReceiptNoteDetail: 'Chi tiết Phiếu nhập kho quà',
  materialReceiptDetail: 'Chi tiết Phiếu nhập Material',
  standardImport: 'Nhập thường',
  branchImport: 'Nhập chi nhánh',
  warehouseReceiptConfirm: 'Thủ kho xác nhận',
  warehouseFrom: 'Từ kho',
  receiptMaterial: 'Phiếu nhập material',
  receiptMaterialList: 'Danh sách phiếu nhập material',
  formHaveCode: 'phiếu có mã',
  chooseRack: 'Chọn Rack',
  pleaseChooseRack: 'Vui lòng chọn rack',
  productionCode: 'Mã Production',
  productionDetail: 'Chi tiết Production',
  productionList: 'Danh sách sản phẩm Production',
  typeProductionOrder: 'Loại lệnh sản xuất',
  requiredTypeProductionOrder: 'Loại lệnh sản xuất bắt buộc có',
  importLabel: 'Dán nhãn nhập khẩu',
  pastCard: 'Dán thẻ cào',
  packing: 'Đóng pack',
  deletedFormError: 'Không thể xóa phiếu có trạng thái đã xác nhận',
  selectedItem: 'các mục đã chọn',
  deleteProduction: 'Xóa Production',
  rawMaterialsAndSuppliesInfo: 'Thông tin nguyên phụ liệu',
  quantityNeeded: 'Số lượng cần dùng',
  productNotConfiguredYet: 'Sản phẩm chưa có cấu hình',
  packingOrderFrom: 'Từ lệnh Packing',
  packingOrder: 'Lệnh Packing',
  packingOrderRequired: 'Vui lòng chọn lệnh Packing',
  toLocator: 'Đến locatior',
  pleaseChooseProductionOrder: 'Vui lòng chọn loại lệnh sản xuất',
  receiptProduct: 'Trả Lại Sản Phẩm',
  quantityHasCreatedProduction: 'SL đã tạo Production',
  quantityHasCreatedUnpack: 'SL đã tạo UnPack',
  on: 'trên',
  created: 'Đã tạo',
  btnPicking: 'Lấy hàng',
  giftCombo: 'Combo Quà Tặng',
  productionGift: 'Danh sách quà tặng',
  quantityConfigured: 'SL cấu hình',
  quantityPicking: 'SL lấy',
  returnPicking: 'SL trả lại',
  baseProduction: 'Sản phẩm hàng Base',
  notEnough: 'không đủ',
  packingProduction: 'Sản phẩm đóng Pack',
  unpackProduction: 'Sản phẩm UNPACK',
  CTMSPerPalletStorage3AMsg: 'Không có tiêu chuẩn 3A - Số thùng/ pallet - Dưới sàn',
  ERP: 'ERP',
  tongSL: 'Tổng SL',
  PhieuNhapKho: 'PhieuNhapKho',
  pleaseChooseStore: 'Vui lòng chọn kho!',
  ntaPurchaseOrder: 'Chi tiết NTA Purchase Order',
  ntaPurchaseOrderList: 'Danh Sách NTA Purchase Order',
  poCode: 'Mã PO',
  notYetReceived: 'Chưa Nhập Hàng',
  notYetCompleted: 'Chưa Nhập Xong',
  notYetAllocated: 'Chưa điều phối',
  allocated: 'Đã điều phối',
  completedEntry: 'Đã nhập đủ',
  apInvoiceCompleteMsg: 'Hàng đã nhập đủ, vui lòng kiểm tra lại!',
  productWithCode: 'Sản phẩm có mã',
  fullyTakenMsg: 'đã lấy đủ số lượng',
  giftInventoryReceipt: 'phiếu nhập kho quà',
  validatorTotalQuantityMsg: 'Số lượng không tương thích, vui lòng kiểm tra lại!',
  needToBeEntered: 'cần phải nhập',
  invoiceType: 'Loại hóa đơn',
  seller: 'Người bán',
  partnerAddress: 'Địa chỉ đối tác',
  choosePackError: 'Vui lòng chọn loại đóng gói',
  productCondition: 'Tình trạng sản phẩm',
  chooseProductCondition: 'Chọn tình trạng sản phẩm',
  packVAS: 'Đóng VAS',
  searchByStatus: 'Tìm kiếm theo trạng thái',
  packItems: 'Soạn hàng',

};
