import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setCurrentWarehouse } from 'providers/AuthProvider/actions';
import { Layout, Menu, Dropdown, Avatar, Button, Affix, Badge, message, Modal, Form, Input, Col, Row } from 'antd';
import {
  LogoutOutlined,
  EditOutlined,
  UserOutlined,
  DownOutlined,
  BellOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import _get from 'lodash/get';

import logoCollapse from 'assets/logoAKAWMS/akawms_2.svg';
import logo from 'assets/logoAKAWMS/akawms_5.svg';

import InventoryIcon from 'assets/icons/icon-inventory.svg';
import InboundIcon from 'assets/icons/icon-Inbound.svg';
import OutboundIcon from 'assets/icons/icon-Outbound.svg';
import ConfigurationIcon from 'assets/icons/icon-Configuration.svg';
import ReportingIcon from 'assets/icons/icon-Reporting.svg';

import { userChangePasswordUser } from 'pages/admin/user/user.service';

import './style.less';
import environment from 'environments/environment';

const { Sider, Header } = Layout;
const { SubMenu } = Menu;

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguages(t);

  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
  //Roles
  const isManager = _get(user, 'roles', '').includes('manager');
  // const isInboundStaff = _get(user, 'roles', '').includes('inboundStaff');
  // const isSupervisor = _get(user, 'roles', '').includes('supervisor');
  const isStaff = _get(user, 'roles', '').includes('staff');

  useEffect(() => {
    const selectedKeys1 = [
      '/dashboard/inbound/apinvoice',
      '/dashboard/inbound/receipt',
      '/dashboard/inbound/purchaseorder',
      '/dashboard/inbound/gift-receipt',
      '/dashboard/inbound/temporary-inbound',
      '/dashboard/inbound/storing',
      '/dashboard/inbound/inbound-control',
      '/dashboard/inbound/vas',
      '/dashboard/inbound/completed-vas',
      '/dashboard/inbound/production-order',
      '/dashboard/inbound/list-production',
      '/dashboard/inbound/list-unpack',
      '/dashboard/inbound/manuever-command',
      '/dashboard/inbound/gift-manuever-command',
      '/dashboard/inbound/scan',
      '/dashboard/inbound/customer-return',
      '/dashboard/inventory/warehousestate',
      '/dashboard/inventory/reallocation',
      '/dashboard/inventory/productmanagement',
      '/dashboard/inventory/inventorymanagement',
      '/dashboard/inventory/inventoryproduct-salechannel',
      '/dashboard/inventory/physicalinventory',
      '/dashboard/inventory/gift-physicalinventory',
      '/dashboard/inventory/inventorymaterial',
      '/dashboard/inventory/giftinventory',
      '/dashboard/outbound/sale-order',
      '/dashboard/outbound/internal-tranfer',
      '/dashboard/outbound/gift-internal-tranfer',
      '/dashboard/outbound/packaging',
      '/dashboard/outbound/shipping',
      '/dashboard/outbound/confirm-order',
      '/dashboard/outbound/delivery-order',
      '/dashboard/outbound/ar-invoice',
      '/dashboard/outbound/export-branch',
      '/dashboard/outbound/material-export',
      '/dashboard/outbound/split-product/scan',
      '/dashboard/configuration/supplier',
      '/dashboard/configuration/masterproduct',
      '/dashboard/configuration/product-category',
      '/dashboard/configuration/category',
      '/dashboard/configuration/divison',
      '/dashboard/configuration/location',
      '/dashboard/configuration/replenishment',
      '/dashboard/configuration/sale-order',
      '/dashboard/configuration/bom',
      '/dashboard/configuration/giftbom',
      '/dashboard/configuration/master-product-packing',
      '/dashboard/configuration/master-products-bom-gift',
      '/dashboard/configuration/area',
      '/dashboard/configuration/locator',
      '/dashboard/configuration/sales-channel',
      '/dashboard/reporting/auditorder',
      '/dashboard/reporting/inventorywarning',
      '/dashboard/reporting/performance',
      '/dashboard/inbound/material',
      '/dashboard/outbound/gift-export-branch',
    ]?.find(key => pathname.startsWith(key));
    setSelectedKeys(selectedKeys1);
    //dashboard/inbound
    if (
      [
        '/dashboard/inbound',
        '/dashboard/inbound/receipt',
        '/dashboard/inbound/purchaseorder',
        '/dashboard/inbound/receiptgift',
        '/dashboard/inbound/temporary-inbound',
        '/dashboard/inbound/storing',
        '/dashboard/inbound/inbound-control',
        '/dashboard/inbound/apinvoice',
        '/dashboard/inbound/vas',
        '/dashboard/inbound/completed-vas',
        '/dashboard/inbound/production-order',
        '/dashboard/inbound/list-production',
        '/dashboard/inbound/list-unpack',
        '/dashboard/inbound/manuever-command',
        '/dashboard/inbound/gift-manuever-command',
        '/dashboard/inbound/scan',
        '/dashboard/inbound/customer-return',
        '/dashboard/inbound/material',
      ]?.some(key => pathname.startsWith(key))
    ) {
      setDefaultOpenKeys(['/dashboard/inbound']);
    }

    //dashboard/inventory
    if (
      [
        '/dashboard/inventory',
        '/dashboard/inventory/warehousestate',
        '/dashboard/inventory/reallocation',
        '/dashboard/inventory/productmanagement',
        '/dashboard/inventory/inventorymanagement',
        '/dashboard/inventory/physicalinventory',
        '/dashboard/inventory/inventoryproduct-salechannel',
        '/dashboard/inventory/inventorymaterial',
        '/dashboard/inventory/giftinventory',
        '/dashboard/inventory/gift-physicalinventory',
      ]?.some(key => pathname.startsWith(key))
    ) {
      setDefaultOpenKeys(['/dashboard/inventory']);
    }

    //dashboard/outbound
    if (
      [
        '/dashboard/outbound',
        '/dashboard/outbound/sale-order',
        '/dashboard/outbound/packaging',
        '/dashboard/outbound/shipping',
        '/dashboard/outbound/confirm-order',
        '/dashboard/outbound/split-product/scan',
        '/dashboard/outbound/delivery-order',
        '/dashboard/outbound/ar-invoice',
        '/dashboard/outbound/internal-tranfer',
        '/dashboard/outbound/gift-internal-tranfer',
        '/dashboard/outbound/export-branch',
        '/dashboard/outbound/gift-export-branch',
        '/dashboard/outbound/material-export',
      ]?.some(key => pathname.startsWith(key))
    ) {
      setDefaultOpenKeys(['/dashboard/outbound']);
    }

    //dashboard/configuration
    if (
      [
        '/dashboard/configuration',
        '/dashboard/configuration/supplier',
        '/dashboard/configuration/masterproduct',
        '/dashboard/configuration/product-category',
        '/dashboard/configuration/location',
        '/dashboard/configuration/replenishment',
        '/dashboard/configuration/sale-order',
        '/dashboard/configuration/category',
        '/dashboard/configuration/divison',
        '/dashboard/configuration/bom',
        '/dashboard/configuration/giftbom',
        '/dashboard/configuration/master-product-packing',
        '/dashboard/configuration/area',
        '/dashboard/configuration/locator',
        '/dashboard/configuration/sales-channel',
        '/dashboard/configuration/master-products-bom-gift',
      ]?.some(key => pathname.startsWith(key))
    ) {
      setDefaultOpenKeys(['/dashboard/configuration']);
    }

    //dashboard/reporting
    if (
      [
        '/dashboard/reporting',
        '/dashboard/reporting/auditorder',
        '/dashboard/reporting/inventorywarning',
        '/dashboard/reporting/performance',
      ]?.some(key => pathname.startsWith(key))
    ) {
      setDefaultOpenKeys(['/dashboard/reporting']);
    }
  }, [pathname]);
  const handleLogout = () => {
    Modal.confirm({
      title: lang.confirm,
      icon: <ExclamationCircleOutlined />,
      content: lang.confirmLogout,
      okText: lang.yes,
      cancelText: lang.no,
      onOk: () => {
        dispatch(logout(user?.refresh_token));
        localStorage.clear();
        navigate('/');
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" disabled style={{ cursor: 'default' }}>
        <div>
          {user?.userInfo?.name}
          <br />
          {user?.userInfo?.preferred_username}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => {
        window.location.replace(environment.urlChangePassWord);
      }}>
        <EditOutlined /> {lang.changePassword}
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        <LogoutOutlined /> {lang.logout}
      </Menu.Item>
    </Menu>
  );

  //subMenuActive
  // const selectedKeys = [pathname];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Layout className="dashboard-layout">
      <Affix offsetTop={0} style={{ zIndex: 31 }}>
        <Sider className="sider" collapsible theme={'light'} breakpoint="lg" collapsedWidth='64px' onCollapse={() => setCollapse(!collapse)} width={215}>
          <div className="sider-left">
            <div>
              {/* Logo */}
              {collapse === true ? <div className="logo-wrapper">
                <img style={{ cursor: 'pointer' }} src={logo} className="logo" alt="" onClick={() => navigate('/dashboard')} />
              </div>
                : <div className="logo-wrapper">
                  <img style={{ cursor: 'pointer' }} src={logoCollapse} className='logoCollapse' alt="" onClick={() => navigate('/dashboard')} />
                  {/* src={logoCollapse} */}
                </div>}
              <Menu
                className="menu-list"
                mode="inline"
                selectedKeys={selectedKeys}
                openKeys={defaultOpenKeys}
                onOpenChange={(openKeys) => setDefaultOpenKeys(openKeys)}
                style={{
                  height: '88vh',
                  overflowY: 'scroll',
                  paddingBottom: 40,
                  overflowX: 'hidden',
                }}
              >
                <SubMenu key="/dashboard/inbound" icon={<img alt="" src={InboundIcon} />} title={lang.inbound}>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/apinvoice')}
                    key="/dashboard/inbound/apinvoice">{lang.requestReceipt}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/receipt')}
                    key="/dashboard/inbound/receipt">{lang.receipt}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/purchaseorder')}
                    key="/dashboard/inbound/purchaseorder">{lang.ntaPurchaseOrder}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/gift-receipt')}
                    key="/dashboard/inbound/gift-receipt">{lang.giftReceipt}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/material')}
                    key="/dashboard/inbound/material">{lang.materialReceipt}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/temporary-inbound')}
                    key="/dashboard/inbound/temporary-inbound"
                  >{lang.temporaryInbound}
                  </Menu.Item>
                  {/* <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/storing')}
                    key="/dashboard/inbound/storing">{lang.storing}
                  </Menu.Item> */}
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/inbound-control')}
                    key="/dashboard/inbound/inbound-control">{lang.inboundControl}
                  </Menu.Item>
                  {/* <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/vas')}
                    key="/dashboard/inbound/vas">Vas
                  </Menu.Item> */}
                  {/* <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/completed-vas')}
                    key="/dashboard/inbound/completed-vas">{'Vas for base product'}
                  </Menu.Item> */}
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/production-order')}
                    key="/dashboard/inbound/production-order">{lang.productionOrder}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/manuever-command')}
                    key="/dashboard/inbound/manuever-command">{lang.maneuveringCommand}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/gift-manuever-command')}
                    key="/dashboard/inbound/gift-manuever-command">{lang.maneuveringCommandGift}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/list-production')}
                    key="/dashboard/inbound/list-production">{lang.production}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/list-unpack')}
                    key="/dashboard/inbound/list-unpack">{lang.unpack}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/scan')}
                    key="/dashboard/inbound/scan">{lang.scan}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inbound/customer-return')}
                    key="/dashboard/inbound/customer-return">{lang.customerReturn}
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="/dashboard/inventory" icon={<img alt="" src={InventoryIcon}></img>} title={lang.inventory}>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/inventory/warehousestate')}
                    key="/dashboard/inventory/warehousestate">{lang.warehouseState}
                  </Menu.Item>
                  {!isStaff && (
                    <>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/productmanagement')}
                        key="/dashboard/inventory/productmanagement">{lang.productManagement}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/inventoryproduct-salechannel')}
                        key="/dashboard/inventory/inventoryproduct-salechannel">{lang.inventoryProductByChannel}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/inventorymanagement')}
                        key="/dashboard/inventory/inventorymanagement">{lang.inventoryManagement}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/giftinventory')}
                        key="/dashboard/inventory/giftinventory">{lang.giftInventory}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/inventorymaterial')}
                        key="/dashboard/inventory/inventorymaterial">{lang.inventoryMaterial}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/physicalinventory')}
                        key="/dashboard/inventory/physicalinventory">{lang.physicalInventory}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => navigate('/dashboard/inventory/gift-physicalinventory')}
                        key="/dashboard/inventory/gift-physicalinventory">{lang.giftPhysicalInventory}
                      </Menu.Item>
                    </>
                  )}
                </SubMenu>
                <SubMenu key="/dashboard/outbound" icon={<img alt="" src={OutboundIcon} />} title={lang.outbound}>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/sale-order')}
                    key="/dashboard/outbound/sale-order"
                  >{lang.exportWarehouse}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/internal-tranfer')}
                    key="/dashboard/outbound/internal-tranfer">{lang.EXPORT_INTERNAL}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/gift-internal-tranfer')}
                    key="/dashboard/outbound/gift-internal-tranfer">{lang.exportInternalGift}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/export-branch')}
                    key="/dashboard/outbound/export-branch">{lang.exportBranch}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/gift-export-branch')}
                    key="/dashboard/outbound/gift-export-branch">{lang.exportBranchGift}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/material-export')}
                    key="/dashboard/outbound/material-export">{lang.materialExport}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/shipping')}
                    key="/dashboard/outbound/shipping">{lang.shippingOutboundControl}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/confirm-order')}
                    key="/dashboard/outbound/confirm-order">{lang.orderConfirm}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/delivery-order')}
                    key="/dashboard/outbound/delivery-order">{'Delivery order'}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/ar-invoice')}
                    key="/dashboard/outbound/ar-invoice">{'Ar-Invoice'}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigate('/dashboard/outbound/split-product/scan')}
                    key="/dashboard/outbound/split-product/scan">{lang.splitproduct}
                  </Menu.Item>
                </SubMenu>
                {(isManager) && (
                  <SubMenu key="/dashboard/configuration" icon={<img alt="" src={ConfigurationIcon} />} title={lang.configuration}>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/divison')}
                      key="/dashboard/configuration/divison">{lang.divison}
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/category')}
                      key="/dashboard/configuration/category">{lang.Category}
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/product-category')}
                      key="/dashboard/configuration/product-category">{lang.productCategory}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/supplier')}
                      key="/dashboard/configuration/supplier">{lang.supplier}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/masterproduct')}
                      key="/dashboard/configuration/masterproduct">{lang.product}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/location')}
                      key="/dashboard/configuration/location">{lang.location}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/bom')}
                      key="/dashboard/configuration/bom">{lang.bom}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/giftbom')}
                      key="/dashboard/configuration/giftbom">{lang.bomGift}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/master-products-bom-gift')}
                      key="/dashboard/configuration/master-products-bom-gift">{lang.masterproductsbomgift}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/master-product-packing')}
                      key="/dashboard/configuration/master-product-packing">{lang.masterproductPacking}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/area')}
                      key="/dashboard/configuration/area">{lang.area}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/locator')}
                      key="/dashboard/configuration/locator">{lang.locator}
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/sale-order')}
                      key="/dashboard/configuration/sale-order">{lang.saleOrder}
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/replenishment')}
                      key="/dashboard/configuration/replenishment">{lang.replenishment}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/configuration/sales-channel')}
                      key="/dashboard/configuration/sales-channel">{lang.salesChannel}
                    </Menu.Item>
                  </SubMenu>
                )}
                {(isManager) && (
                  <SubMenu key="/dashboard/reporting" icon={<img alt="" src={ReportingIcon} />} title={lang.reporting}>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/reporting/auditorder')}
                      key="/dashboard/reporting/auditorder">{lang.auditOrder}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/reporting/inventorywarning')}
                      key="/dashboard/reporting/inventorywarning">{lang.inventoryWarning}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => navigate('/dashboard/reporting/performance')}
                      key="/dashboard/reporting/performance">{lang.performance}
                    </Menu.Item>
                  </SubMenu>
                )}
              </Menu>
            </div>
          </div>
        </Sider>
      </Affix>
      <Layout className="site-layout">
        <Affix offsetTop={0} style={{ zIndex: 30 }}>
          <Header className="header">
            <div className="header-container">
              <div className="profile-container">
                <Dropdown overlay={
                  <Menu>
                    {user?.warehouses?.map(item => (
                      <Menu.Item key={item.id} onClick={() => {
                        dispatch(setCurrentWarehouse(item));
                      }}>
                        {item.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                } trigger={['click']} placement="bottomRight">
                  <Button type="link" size="small" style={{ color: 'white', fontWeight: 600, display: 'flex', justifyContent: 'center' }} onClick={e => e.preventDefault()}>
                    <img alt="" src={InventoryIcon} style={{ marginRight: 6, paddingTop: 2 }} /> {currentWarehouse?.name} <DownOutlined style={{ fontSize: '14px', color: 'white', marginLeft: 4, paddingTop: 4 }}
                      theme="outlined" />
                  </Button>
                </Dropdown>
                <div id='space' />
                <Badge dot>
                  <BellOutlined style={{ fontSize: 24, color: 'white' }} />
                </Badge>
                <div id='space' />
                <Dropdown overlay={menu} placement="bottomRight">
                  <div className="profile">
                    <Avatar
                      //src={}
                      icon={<UserOutlined />}
                      style={{ marginRight: '8px' }}
                    />
                    <div style={{ color: 'white', fontWeight: 600, marginRight: 6 }}>{user?.userInfo?.preferred_username}</div>
                    <DownOutlined
                      style={{ fontSize: '14px', color: 'white', paddingTop: 1 }}
                      theme="outlined"
                    />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Header>
        </Affix>
        <Outlet />
      </Layout>
      <Modal
        visible={changePasswordModal}
        title={lang.changePassword}
        onCancel={() => setChangePasswordModal(false)}
        destroyOnClose={true}
        centered
        footer={null}
      >
        <Form
          {...layout}
          name="changePasswordForm"
          className="change-password-form"
          labelAlign="left"
          onFinish={(values) => {
            setLoading(true);
            userChangePasswordUser(values)
              .then(result => {
                message.success(lang.createSuccessfully);
                setLoading(false);
                changePasswordModal(false);
              })
              .catch((e) => {
                message.error(e.message);
                setLoading(false);
              });
          }}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="currentPassword"
            label={lang.currentPassword}
            rules={[
              {
                required: true,
                message: lang.requiredPassword,
              },
            ]}
          >
            <Input.Password placeholder="" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label={lang.passwordNew}
            rules={[
              {
                required: true,
                message: lang.requiredPassword,
              },
            ]}
          >
            <Input.Password placeholder="" />
          </Form.Item>
          <Form.Item
            name="confirmation"
            label={lang.passwordConfirm}
            rules={[
              {
                required: true,
                message: lang.requiredPassword,
              },
            ]}
          >
            <Input.Password placeholder="" />
          </Form.Item>
          <div
            className="ant-modal-footer"
            style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
          >
            <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
              <Col
                className="gutter-row"
                span={24}
                style={{ textAlign: 'right', paddingRight: 20 }}
              >
                <Button
                  type="clear"
                  onClick={() => setChangePasswordModal(false)}
                  style={{ fontWeight: 'bold' }}
                >
                  {lang.btnCancel}
                </Button>
                {loading === false ? (
                  <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                    {lang.btnSave}
                  </Button>
                ) : (
                  <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                    {lang.loading}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </Layout>
  );
};

export default DashboardLayout;
