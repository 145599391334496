import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, Select, Input, Tag, Form, message, Modal, DatePicker } from 'antd';
import { SearchOutlined, InboxOutlined } from '@ant-design/icons';
import { bookingList, importExel, orderCheck } from './picking.service';
import { useDispatch, useSelector } from 'react-redux';
import { bookingOrderSet } from 'providers/OutboundProvider/actions';
import './style.less';
import moment from 'moment-timezone';
import { defaultPage, formatDate, formatDateFull, STATUS_INTERNAL_TRANFER } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
import Dragger from 'antd/lib/upload/Dragger';
import _get from 'lodash/get';
// import { orderUpdateStatus } from '../picking/picking.service';
const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const { Option } = Select;

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InternalTranferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  //Pagination
  const [params, setParams] = useState({ status: isPicker ? 'LOCK' || 'WAITING' : 'NEW', ...defaultPage });
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const [form] = Form.useForm();
  const [uploadModal, setUploadModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [file, setFile] = useState();
  const [status, setStatus] = useState(isPicker ? 'LOCK' : 'NEW');

  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        // if (params?.status === 'NEW' ) {
        //   const rempData = result.data?.reduce((prev, curr) => {
        //     if (['IMPORTLABEL', 'PASTCARD'].indexOf(curr?.typeProductionOrder)) {
        //       const finded = prev?.find(order => order?.group && order.group === curr.group);
        //       if (finded) {
        //         finded.code = `${finded.code}, ${curr.code}`;
        //         return prev;
        //       }
        //       return [...prev, { ...curr }];
        //     }
        //   }, []);
        //   setItems(rempData);
        //   setTotalItems(result.totalItem);
        // } else {
          
        // }
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      // dataIndex: 'code',
      render: rec => <Button
        key="btn-detail"
        type="link"
        onClick={() => navigate(`${rec.id}`)}
        size='small'
      >
        {rec.code}
      </Button>,
    },
    // {
    //   title: lang.documentNo,
    //   key: 'documentNo',
    //   dataIndex: 'documentNo',
    // },
    {
      title: lang.locatorTo,
      dataIndex: 'locatorCode',
      key: 'locatorCode',
      // width: '20%',
    },
    {
      title: lang.salesChannel,
      key: 'channel',
      dataIndex: ['channel'],
      render: val => val && val?.channelName + ' - ' + val?.channel,
    },
    {
      title: lang.tranferReason,
      dataIndex: 'tranferReason',
      key: 'tranferReason',
      // width: '20%',
    },
    {
      title: lang.orderType,
      dataIndex: 'orderType',
      key: 'orderType',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
      // width: '20%',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      // width: '20%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.userName,
      dataIndex: 'userId',
      key: 'userId',
      // width: '20%',
      render: value => value ? value.split(' - ')?.[1] : undefined,
      hideInTable: params?.status !== 'LOCK',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '15%',
      render: (record) => {
        return (
          <Tag>{STATUS_INTERNAL_TRANFER[record]}</Tag>
        );
      },
    },
  ];

  const extraButton = [
    !isPicker && <Button
      key="btn-create"
      type="primary"
      onClick={() => navigate('create')}
    >{lang.btnCreate}</Button>,
    !isPicker && <Button
      key="btn-import"
      type="primary"
      onClick={() => setUploadModal(true)}
    >
      {lang.importCSV}
    </Button>,
    // params?.status === 'LOCK' && !isPicker && !isManager && <Button
    // disabled={selectedRowKeys.length === 0 }
    // key="btn-save"
    // type='primary'
    // onClick={async () => {
    //   Modal.confirm({
    //     title: "Bạn có chắc chắn muốn Assign?",
    //     async onOk() {
    //       try {     
    //         setLoading(true)
    //         await orderUpdateStatus({ids: selectedRowKeys?.map(order => order?.id), assignAll: true}) //, userId: userId
    //         bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
    //           .then(result => {
    //             setItems(result.data);
    //             setSelectedRowKeys([])
    //           })
    //           .catch((e) => setLoading(false));
    //             message.success('Assign thành công!')
    //             setLoading(false)
    //             return true
    //           } catch (error) {
    //             message.error(error.message);
    //             setLoading(false)
    //             return false;
    //           }
    //         },
    //       onCancel() {},
    //   });
    // }}
    // >
    //   {'Assign Staff'}
    // </Button>,
    (isPicker) && <Button
      key="btn-complete"
      disabled={selectedRowKeys.length === 0}
      type="primary"
      onClick={async () => {
        try {
          if (selectedRowKeys.length > 0) {
            await orderCheck({ ids: selectedRowKeys?.map(order => order.id), userId:`${user?.userInfo?.sub}` });
            dispatch(bookingOrderSet({
              pickingOrder: selectedRowKeys,
              pickingProducts: selectedRowKeys?.reduce((prev, curr) => {
                const productOrders = curr?.productOrders?.map(product => {
                  product.orderCode = curr.code;
                  product.orderId = curr.id;
                  product.pickedQuantityCartton = 0;
                  product.pickedQuantityTin = 0;
                  product.groupOrder = curr.group;
                  product.saleChannel = curr?.channel?.channel;
                  return product;
                });
                return [...prev, ...productOrders];
              }, []),
            }));
            navigate('picking/scan');
            return true;
          }
          // dispatch(pickingOrderCreate());
        } catch (error) {
          const splitMessage = error?.message?.split(/\s(.*)/s);
          message.error(splitMessage[0] + ' ' + lang.messeagePicking);
        }
      }}
    >
      {lang.picking}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/outbound',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.EXPORT_INTERNAL}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            if (!cleanValue?.orderCode && !cleanValue?.status) {
              cleanValue.status = 'NEW';
            }
            if (cleanValue?.orderCode) {
              cleanValue.status = undefined;
            }
            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.status} name="status" initialValue={isPicker ? 'LOCK' : 'NEW'}>
                  <Select placeholder={lang.status} defaultValue={status} onChange={(value) => setStatus(value)} showSearch allowClear>
                    {!isPicker && <Option key={'NEW'} value={'NEW'}>{STATUS_INTERNAL_TRANFER.NEW}</Option>}
                    <Option key={'LOCK'} value={'LOCK'}>{STATUS_INTERNAL_TRANFER.LOCK}</Option>
                    <Option key={'WAITING'} value={'WAITING'}>{STATUS_INTERNAL_TRANFER.WAITING}</Option>
                    <Option key={'EXPORTED'} value={'EXPORTED'}>{STATUS_INTERNAL_TRANFER.EXPORTED}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.search}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card size="small" className="cardSearch">
          <Row style={{ marginBottom: 0 }}>
            <Col span={12} style={{ display: 'flex' }}>
              <h3>{`${lang.list} ${lang.EXPORT_INTERNAL}`}</h3>
              <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`${lang.totalExportSlips}: ${totalItems}`}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                rowSelection={(params?.status === 'LOCK' || params?.status === 'WAITING') && {
                  onChange: (_, selectedRows) => {
                    setSelectedRowKeys(selectedRows);
                  },
                }}
                className="tableCustom"
                loading={loading}
                bordered
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  if (sorter) {
                    setSortedInfo({ sortDirection: sorter.order, sortBy: sorter.field });
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: 600 }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(formData, currentWarehouse.id);
              setFile();
            }
            setParams({ ...params });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{ 'Order Code': '', Group: '' }], 'TemplateOrders')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default InternalTranferList;
