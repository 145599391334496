import produce from 'immer';

import { I18N_SET_LOCALE } from './types';

const DEFAULT_LOCALE = 'en';

const INITIAL_STATE = {
  locale: DEFAULT_LOCALE,
};

const reducer = produce((state = INITIAL_STATE, action) => {
  switch (action.type) {
    case I18N_SET_LOCALE:
      state.locale = action.payload.locale;
      return state;

    default:
      return state;
  }
});

export default reducer;
