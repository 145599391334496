import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message, Modal } from 'antd';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';
// import moment from 'moment-timezone';
// import { formatDate, formatDateFull } from 'utils/constants';
import { bookingOrderSet, pickedProductSet, pickingListSet, scanPickingProductCodeAdd } from 'providers/OutboundProvider/actions';
// import Helper from 'utils/helpers';
import SuggestLocationForm from './suggest-location.form';
import { orderGiftDetail } from './export-branch-gift.service';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
import ReceiptGiftProductPrint from 'pages/dashboard/inbound/receiptGift/receiptGift-products-print';
import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';
const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ExportBranchScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { pickingProducts, pickingOrder } = useSelector((state) => state.outbound);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemPrint, setItemPrint] = useState();
  const [locatorFind, setLocatorFind] = useState();
  const [isEffect, setIsEffect] = useState(false);
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const dispatch = useDispatch();
  const onCallbackPrint = (val) => {
    setItemPrint(val);
    setIsPrintModal(true);
  };

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id }).then(value => setLocatorFind(value?.data));
    warehouseListAll().then(result => setWarehouseList(result?.data))
  }, [currentWarehouse]);
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.codeGift,
      key: 'codeGift',
    },
    {
      title: lang.productName,
      dataIndex: ['bomGift', 'name'],
      key: 'name',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locatorCode',
      // render: val => `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.pickingquantity,
      key: 'pickedQuantity',
      dataIndex: 'pickedQuantity',
    },
    {
      title: lang.takeQuantity,
      key: 'quantityLeft',
      render: (record) => record?.quantity - record?.pickedQuantity
    },
  ];
  const extraButton = [
    <Button
      key="btn-suggest"
      type="primary"
      ghost
      onClick={async () => {
        setIsSuggestLocationModal(true);
      }}
    >
      {lang.suggestLocation}
    </Button>,
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        dispatch(bookingOrderSet({
          pickingOrder: [],
          pickingProducts: [],
        }));
        dispatch(pickingListSet([]));
        dispatch(pickedProductSet([]));
        dispatch(scanPickingProductCodeAdd([]));
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>
  ];
  useEffect(() => {
    Promise.all([...pickingOrder?.map(async (order) => {
      const reponse = await orderGiftDetail(order?.id);
      return Promise.resolve(reponse?.productGiftOrders?.map(product => {
        const userName = user?.userInfo?.preferred_username;
        return {
          ...product, orderId: reponse?.id, groupOrder: reponse?.group, orderCode: reponse?.documentNo,
          orderType: pickingOrder?.[0]?.orderGiftType, userName: userName,

        };
      }));
    })]).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
          return prev;
        }
        return prev.concat(current);
      }, []);
      setSelectedOrder([...ctx]);
    }).catch((err) => message.error(err.message));
  }, [pickingProducts, isEffect, currentWarehouse, pickingOrder, user.userInfo.preferred_username]);
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/create',
      breadcrumbName: lang.pickupList,
    },
    {
      path: '/',
      breadcrumbName: lang.scanCode,
    },
  ];
  return (
    <Layout className="storing-scan">
        <PageHeader
          ghost={false}
          title={lang.scanCode}
          extra={extraButton}
          breadcrumb={{
            routes,
            itemRender: breadcrumbItemRender,
          }}
          className="customPageHeader"
        //onBack={onBack}
        />
        <Layout.Content style={{ margin: '12px 24px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ScanProductForm type={'PICKING'} selectOrderProduct={selectedOrder} onCallbackPrint={onCallbackPrint} onCallBackEffect={() => setIsEffect(!isEffect)} />
            </Col>
            <Col span={24}>
              <ProTableCusTom
                rowKey="id"
                size="small"
                className="tableCustom"
                bordered
                columns={columnsPickingList}
                params={{ selectedOrder, pickingProducts }}
                request={async (params) => {
                  const { selectedOrder } = params;
                  // const orderProductQuantity = pickingProducts.reduce((prev, cur) => {
                  //   const find = prev?.find(prev => prev.masterProduct.id === cur.masterProduct.id);
                  //   if (find) {
                  //     find.quantityInCarton = find.quantityInCarton + cur.quantityInCarton;
                  //     find.quantity = find.quantity + cur.quantity;
                  //     return prev;
                  //   }
                  //   return [...prev, Object.assign({}, cur)];
                  // }, []);
                  return Promise.resolve({
                    data: selectedOrder,
                    total: selectedOrder.length,
                    success: true,
                  });
                }}
                search={false}
                options={{
                  search: false,
                  reload: false,
                  density: false,
                }}
                pagination={{
                  showTitle: false,
                  showTotal: false,
                  pageSize: 10,
                }}
                scroll={{ x: 2000 }}
              />
            </Col>
          </Row>
        </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => { setIsPrintModal(false); setIsEffect(!isEffect); }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptGiftProductPrint
          items={[itemPrint]}
          warehouseName={warehouseList?.find(item => item?.id === currentWarehouse?.id)?.name}
          // onCallback={(values) => {
          //   Helper.dowloadXLSX(values?.map(item => {
          //     return {
          //       code: item.bomGift?.code,
          //       name: item.bomGift.name,
          //       receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
          //     };
          //   }), 'productCode');
          //   setIsPrintModal(false);
          //   setIsEffect(!isEffect);
          // }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
        />
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          // await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={pickingOrder}
          locatorFind={locatorFind}
          productOrders={selectedOrder}
          onCallback={async (values) => {
            //       const content = `
            //   <div style="display: grid ;line-height: 0.5; width: 900px; font-family: "Times New Roman", Times, serif;">
            //   <div style="display: flex; justify-content: space-between;">
            //     <div>
            //       <h3>Công ty TNHH PinNow Việt Nam</h3>
            //       <p>134 Bạch Đằng, Phường 2, Q.Tân Bình,</p>
            //       <p>0316954732</p>
            //     </div>
            //     <div style="text-align: center;">
            //       <h3>Mẫu số: 01-VT*</h3>
            //       <p>Ban hành theo thông tư</p>
            //       <p>số 200/2014/TT-BTC</p>
            //       <p>ngày 22/12/2014 của Bộ</p>
            //     </div>
            //   </div>
            //   <div style="text-align: center;">
            //     <h1>DANH SÁCH GỢI Ý SẢN PHẨM</h1>
            //     <p>Ngày ${moment().format('DD')} Tháng ${moment().format('MM')} Năm ${moment().format('YYYY')}</p>
            //   </div>
            //   <div>
            //     <table border="1" style="width: 100%; border-collapse: collapse;margin-top: 10px; line-height: 1;">
            //       <thead>
            //         <tr style="height: 40px;">
            //           <th>STT</th>
            //           <th>Mã sản phẩm</th>
            //           <th>Mã lô sản phẩm</th>
            //           <th>Nhóm hàng</th>
            //           <th>Tên sản phẩm</th>
            //           <th>Block</th>
            //           <th>Shelf</th>
            //           <th>Rack</th>
            //           <th>Số lượng</th>
            //         </tr>
            //       </thead>
            //       <tbody>
            //         ${values?.map((product, index) => {
            //         return `<tr style="text-align: center; height: 40px;">
            //               <td>${index + 1}</td>
            //               <td>${product.masterProduct.barCode}</td>
            //               <td>${product.code}</td>
            //               <td>${product.masterProduct.productCategory?.name}</td>
            //               <td>${product.masterProduct.name}</td>
            //               <td>${product?.block?.name}</td>
            //               <td>${product?.rack?.shelf?.name}</td>
            //               <td>${product?.rack?.code}</td>
            //               <td>${product?.totalQuantity}</td>
            //           </tr>`;
            //       }).join(' \n')}
            //       </tbody>
            //     </table>
            //   </div>
            // </div>`;
            //       var template = document.createElement('div');
            //       template.innerHTML = content;
            //       document.body.appendChild(template);
            //       await html2canvas(template, { width: 1000, scale: 3 }).then((canvas) => {
            //         const imgWidth = 208;
            //         const imgHeight = canvas.height * imgWidth / canvas.width;
            //         var imgData = canvas.toDataURL('image/png');
            //         // eslint-disable-next-line new-cap
            //         const pdf = new jsPdf('p', 'mm', 'a4');
            //         pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
            //         pdf.save(`SuggestProducts${moment().format('DDMMYYYY')}.pdf`);
            //       });
            //       document.body.removeChild(template);
            //       setIsSuggestLocationModal(false);
          }}
          onCancel={async () => {
            setIsSuggestLocationModal(false);
          }}
          onLock={async (values) => {
            // try {
            //   await Promise.all(values?.map(value => suggestLock(value)), orderUpdateStatus({ ids: selectedRowKeys?.map(order => order?.id), status: 'LOCK' }));
            //   message.success(lang.lockproductSuccessfully);
            //   await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
            //   setParams({ ...params });
            //   setSelectedRowKeys([]);
            //   setIsSuggestLocationModal(false);
            //   return true;
            // } catch (error) {
            //   message.error(error.message);
            //   await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
            //   return false;
            // }
          }}
          productPicked={[]}
        />
      </Modal>
    </Layout>
  );
};

export default ExportBranchScan;
