import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, Modal, message as Alert } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import ProductPrint from './product-print';

import './style.less';
import { productSplit } from './picking.service';

const ProductEditForm = ({ item, onCancel, onCallback, isOnlyQuantityAllowForUpdate = false, isPrintBarcode = true }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemNew, setItemNew] = useState();
  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        try {
          setLoading(true);
          const result = await productSplit({ id: item.id, quantityTin: values.quantityTin, quantityCartton: values.quantityCartton });
          if (isPrintBarcode) {
            setItemNew(Object.assign(result, { orderPicking: item.orderPicking }));
            setIsPrintModal(true);
          } else onCallback(values);
          setLoading(false);
        } catch (error) {
          Alert.error(error.message);
          setLoading(false);
        }
      }}
      initialValues={{
        totalQuantity: item ? item?.totalQuantity : undefined,
        quantityCartton: item ? item.quantityCartton : undefined,
        quantityTin: item ? item.quantityTin : undefined,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.defaultCode}
          >
            <Input disabled value={item?.masterProduct?.code3A} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.shortname}
          >
            <Input disabled value={item?.masterProduct?.shortName} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            label={lang.productName}
          >
            <Input disabled value={item?.masterProduct?.nameEN} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item label={lang.productCode}>
            <Input disabled value={item?.code} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="totalQuantity"
            label={lang.totalQuantity}
            rules={[
              {
                required: true,
                message: lang.messageTotalQuantity,
              },
            ]}
          >
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='quantityCartton' label={lang.quantityCartton}
            rules={[
              {
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: (_, value) => value ? (value <= item?.quantityCartton ? Promise.resolve() : Promise.reject(lang.lessThanCurrentBoxQuantity)) : Promise.resolve(),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='quantityTin' label={lang.quantityTin}
            rules={[
              ({ getFieldValue }) => ({
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: (_, value) => value ? (value <= item?.quantityTin + (item?.quantityCartton - getFieldValue('quantityCartton')) * parseInt(item?.masterProduct?.tinPerCarton) ? Promise.resolve() : Promise.reject(lang.lessThanCurrentCanQuantity)) : Promise.resolve(),
              }),
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => {
          setIsPrintModal(false);
          onCallback();
        }}
        destroyOnClose={true}
        width={320}
        centered
        footer={null}
        wrapClassName="picking-products-print-modal"
      >
        <ProductPrint
          item={itemNew}
          onCallback={(value) => {
            setIsPrintModal(false);
            onCallback(itemNew);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            onCallback(itemNew);
          }}
        />
      </Modal>
    </Form>
  );
};

ProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isPrintBarcode: PropTypes.bool,
};

export default ProductEditForm;
