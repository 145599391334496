import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Form } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { receiptMaterialDetail, updateReceiptMaterProduct, confirmReceiptMatrial, updateReceiptMater } from './material.service';
import TableCustom from 'components/TableCustom/table';
import moment from 'moment';
import ReceiptMaterialProductDetail from './receiptMaterialProduct-detail';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import _get from 'lodash/get';
import { formatDate } from 'utils/constants';
import {
  ModalForm,
  ProFormTextArea
} from '@ant-design/pro-form';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const MaterialDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [formCancel] = Form.useForm();
  const [itemsReceiptProduct, setItemsReceiptProduct] = useState([]);
  const [receipt, setReceipt] = useState();
  const [isEditModalReceiptProduct, setIsEditModalReceiptProduct] = useState(false);
  const [receiptProduct, setReceiptProduct] = useState();
  const [loadingExportBBGNH, setLoadingExportBBGNH] = useState(false);
  //Roles
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isInboundStaff = _get(user, 'roles', '').includes('inboundStaff');
  const isIDM = _get(user, 'roles', '').includes('idm');

  useEffect(() => {
    setLoading(true);
    receiptMaterialDetail(parseInt(id))
      .then(result => {
        setReceipt(result);
        setItemsReceiptProduct(result?.receiptMaterialProduct);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id, form]);

  const columnsReceiptProduct = [
    {
      title: lang.materialCode,
      key: 'codeMaterial',
      fixed: 'left',
    //   width: '5%',
      dataIndex: 'codeMaterial'
    },
    {
        title: lang.name,
        key: 'shipment',
        dataIndex: ['bomProduction', 'name'],
      },
    // {
    //   title: lang.shipment,
    //   key: 'shipment',
    //   dataIndex: 'shipment',
    // },
    // {
    //   title: 'LineNo',
    //   key: 'lineNo',
    //   dataIndex: 'lineNo',
    // },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    // {
    //   title: lang.description,
    //   key: 'description',
    //   dataIndex: 'description',
    // },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    // {
    //   title: 'Số lượng thực nhận',
    //   key: 'quantityReceived',
    //   dataIndex: 'quantityReceived',
    // },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            key='btn-edit-receipt-product'
            disabled={!isInboundStaff || receipt?.status === 'TEMPORARYCONFIRM' || receipt?.status === 'RECEIVE'}
            onClick={() => {
              setReceiptProduct(record);
              setIsEditModalReceiptProduct(true);
            }}
          />
        </div>
      ),
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,
     isStocker && receipt?.status === 'NEW' && <ModalForm
     title={lang.cancelMaterial}
       trigger={
         <Button
           key="btn-cancel"
           type="primary"
           ghost
           disabled={receipt?.status === 'CANCELED'}
         >
           {lang.btnCancel + ` ${lang.form}`}
         </Button>
       }
       onFinish= { async (value) => {
         receipt.description = value?.description;
         receipt.status = 'CANCELED';
         await updateReceiptMater(receipt)
         message.success(`${lang.cancelMaterialSuccessfully}!`)
         navigate(-1)
       }}
       form={formCancel}
       autoFocusFirstInput
       modalProps={{
         destroyOnClose: true,
       }}
       key="btn-cancel-manuever"
     >
       <ProFormTextArea 
        name="description"
        label={lang.description}
        placeholder={lang.description}
       />
     </ModalForm>, 
     isInboundStaff && receipt?.status === 'CANCELED' && <ModalForm
     title={`${lang.btnUpdate} ${lang.form}`}
       trigger={
         <Button
           key="btn-cancel"
           type="primary"
           ghost
         >
           {`${lang.btnUpdate} ${lang.form}`}
         </Button>
       }
       onFinish= { async (value) => {
         receipt.description = value?.description;
         receipt.status = 'NEW';
         await updateReceiptMater(receipt)
         message.success(`${lang.update} ${lang.form}!`)
         navigate(-1)
       }}
       form={formCancel}
       autoFocusFirstInput
       modalProps={{
         destroyOnClose: true,
       }}
       key="btn-cancel-manuever"
     >
       <ProFormTextArea 
        name="description"
        label={lang.description}
        placeholder={lang.description}
       />
     </ModalForm>,
      (isStocker || isIDM) && <Button
      disabled={receipt?.products?.length === 0}
      key="btn-export-bbgnh"
      type="primary"
      ghost
      loading={loadingExportBBGNH}
      onClick={async () => {
        setLoadingExportBBGNH(true);
        // eslint-disable-next-line new-cap
        // let listPro = receipt?.receiptMaterialProduct;
        // console.log(listPro)
        let content = `
        <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
          <div style="display:flex">
            <div style="width: 70%">
                <p>CÔNG TY TNHH SX - TM - DV TÂN THUẬN TIẾN</p>
                <p>A54/11 Đường số 2D, KCN Vĩnh Lộc A, Q.Bình Tân, TP.HCM.</p>
                <p>Điện thoại: (84-8) 54 255 332-54 255 331</p>
                <p>Fax: (84-8) 54 255 336</p>
            </div>
            <div style="text-align:left; width: 30%; padding-top: 30px;">
              <span>Số phiếu: </span><br><br>
              <span>Ngày: ${moment().format(formatDate)}</span><br>
            </div>
          </div>
          <div>
            <div style="text-align: center; line-height: 1.2;">
              <div style="font-size: 25px; font-weight:bold; ">PHIẾU GIAO HÀNG</div>
            </div>
          </div>
          <div style="padding-top:10px; ">
            <p>1.Khách hàng: CÔNG TY TNHH DINH DƯỠNG 3A ABBOTT (Việt Nam)</p>
            <p>2.Người nhận hàng:</p>
            <p>3.Địa chỉ giao hàng: Lô 6-1A, cụm 6, đường M1, KCN Bình Tân mở rộng, P.Bình Hưng Hòa, Q.Bình Tân, TP.HCM</p>
            <p style="font-size: 17px; font-weight:bold; ">Số PO: 401550</p>
          </div>    

          <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
            <thead>
              <tr style="text-align:center; font-weight: bold;">
                <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">TÊN HÀNG</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">ĐVT</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">SỐ LƯỢNG</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">QUI CÁCH ĐÓNG THÙNG</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">ĐƠN GIÁ</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">THÀNH TIỀN</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px;">GHI CHÚ</td>
                </tr>
            </thead>
            <tbody>`;

            await receipt?.receiptMaterialProduct?.map((pro, index) => {
              // let checkNumber = pro.bomProduction.cartonRule.slice(0,2)
              // ${pro.quantity/parseInt(checkNumber)}T
                return content += `
                <tr>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + index}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.bomProduction.name ? pro.bomProduction.name : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.bomProduction.DVT ? pro.bomProduction.DVT : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantity ? pro.quantity : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.bomProduction.cartonRule ? pro.bomProduction.cartonRule : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td> 
                </tr>
                `
              })
                
        content += `</tbody>
        </table>
        <div style="display: flex; padding-top: 20px">
        <div style="width: 33%">
          <p>Người giao hàng</p>
        </div>
        <div style="width: 33%">
          <p>Người nhận hàng</p>
        </div>
        <div style="width: 33%">
          <p>Người lập phiếu</p>
        </div>
      </div>
      </div>
          `
          
        const pdf = new jsPdf('p', 'mm', 'a4');
        const template = document.createElement('div');
          template.innerHTML = content;
          document.body.appendChild(template);
          html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
            const imgWidth = 208;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
            pdf.save(`PhieuGiaoHang${moment().format('DDMMYYYY')}.pdf`);
          });
          document.body.removeChild(template);
        setLoadingExportBBGNH(false);
      }}
      >
      {lang.exportPGH}
      </Button>,
    ((receipt?.status === 'NEW' && isStocker) || (receipt?.status === 'TEMPORARYCONFIRM' && isIDM)) && <Button
      key="btn-complete"
      type="primary"
      // loading={loading}
      onClick={async () => {
        try {
          Modal.confirm({
            title: `${lang.doYouWant} ${lang.confirm}?`,
            async onOk() {
              if (isStocker) {
                await updateReceiptMater ({id: parseInt(id), status: 'TEMPORARYCONFIRM'});
                message.success(lang.confirmSuccessfully);
                navigate('/dashboard/inbound/material');
              } else if (isIDM){
                await confirmReceiptMatrial(parseInt(id));
                message.success(lang.confirmSuccessfully);
                navigate('/dashboard/inbound/material');
              }
            },
            onCancel() {}
          })
          
        } catch (error) {
          if (error?.message) {
            message.error(error.message);
          }
        }
      }}
    >
      {lang.btnConfirm}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/gift-receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.giftReceiptNoteDetail,
    },
  ];
  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.materialReceiptDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={{ xl: 5, md: 4, sm: 2, xs: 1 }}>
            <Descriptions.Item label={lang.branch}>{receipt?.warehouse?.branch}</Descriptions.Item>
            <Descriptions.Item label={lang.inboundKind}>{receipt?.inboundkind === 'IMPORT' ? lang.standardImport : lang.branchImport}</Descriptions.Item>
            {receipt?.inboundkind === 'BRANCH' && <Descriptions.Item label={'Từ kho'}>{receipt?.warehouseFrom}</Descriptions.Item>}
            <Descriptions.Item label={lang.description}>{receipt?.description}</Descriptions.Item>
            {/* <Descriptions.Item label={'ataDate'}>{receipt?.ataDate ? moment(receipt?.ataDate).format(formatDate) : undefined}</Descriptions.Item> */}
            {/* <Descriptions.Item label={lang.partnerLocation}>{receipt?.partnerLocation}</Descriptions.Item> */}
            <Descriptions.Item label={`${lang.status} ${lang.form}`}>{
            receipt?.status === 'NEW' ? lang.new : 
            receipt?.status === 'CANCELED' ? lang.cancelled : 
            receipt?.status === 'TEMPORARYCONFIRM' ? lang.warehouseReceiptConfirm :
            `IDM ${lang.confirm}`
            }</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() =>
            <Row>
              <Col span={12}><h3>{lang.productList}</h3></Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsReceiptProduct}
          dataSource={itemsReceiptProduct}
          pagination={false}
        //   scroll={{ x: 2400 }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModalReceiptProduct}
        title={lang.updateProduct}
        onCancel={() => setIsEditModalReceiptProduct(false)}
        destroyOnClose={true}
        width={768}
        centered
        footer={null}
        wrapClassName="receipt-product-pallet-edit-modal"
      >
        <ReceiptMaterialProductDetail
          item={receiptProduct}
          onCallback={async (value) => {
            try {
              setLoading(true);
              setIsEditModalReceiptProduct(false);
              const warehouseId = currentWarehouse?.id
              const payload = {
                ...value, 
                receiptMaterialId: id, 
                warehouseId: warehouseId
                }
              await updateReceiptMaterProduct(payload)
              receiptMaterialDetail(parseInt(id))
                .then(result => {
                  setItemsReceiptProduct(result?.receiptMaterialProduct);
                })
                .catch((e) => setLoading(false));
              setLoading(false);
              message.success(`${lang.update} ${lang.successfully}!`)
              return true;
            } catch (error) {
              message.error(error.message);
              setLoading(false);
              return false;
            }
          }}
          onCancel={() => setIsEditModalReceiptProduct(false)}
        />
      </Modal>
    </Layout>
  );
};

export default MaterialDetail;
