import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message, Modal } from 'antd';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import './style.less';
import { bookingDetail } from './picking.service';
import { useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
// import { saleorderDetail } from 'pages/dashboard/inbound/production-order/production-order.service';
// import { receiptList } from 'pages/dashboard/inbound/receipt/receipt.service';
// import { receiptList } from 'pages/dashboard/inbound/receipt/receipt.service';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';
import Helper from 'utils/helpers';
import SuggestLocationForm from './suggest-location.form';
const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingCreateScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { pickingProducts, pickingOrder } = useSelector((state) => state.outbound);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemPrint, setItemPrint] = useState();
  const [isEffect, setIsEffect] = useState(false);
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const onCallbackPrint = (val) => {
    setItemPrint(val);
    setIsPrintModal(true);
  };
  // useEffect(() => {
  //   productList({ warehouseId: currentWarehouse?.id, group: pickingOrder[0]?.group }).then(values => {
  //     const remp = values?.data?.map(product => {
  //       return { ...product, orderSearchId: product?.orderId, orderQuantityCartton: product?.quantityCartton, orderQuantityTin: product?.quantityTin, pickedQuantityCartton: product?.quantityCartton, pickedQuantityTin: product?.quantityTin, groupOrder: product?.group };
  //     });
  //     setSelectedOrder([...selectedOrder, ...remp]);
  //   });
  // }, [isEffect, currentWarehouse, pickingOrder, setSelectedOrder]);
  console.log(pickingOrder)
  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'barcode',
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.lot,
      key: 'lot',
      // dataIndex: 'lot',
      render: record => record?.lot ? record?.lot : ''
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      // dataIndex: 'expireDate',
      render: record => record?.expireDate ? moment(record?.expireDate).format(formatDate) : '',
    },
    {
      title: lang.locator,
      key: 'locator',
      // dataIndex: 'locator',
      render: record => record?.locator ? `${record?.locator?.locatorName} - ${record?.locator?.locator}` : '',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityInCarton',
      dataIndex: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: `${lang.takeQuantity} (${lang.carton})`,
      key: 'orderQuantityCartton',
      render: rec => rec?.quantityInCarton - rec?.pickingQuantityCartton,
    },
    {
      title: `${lang.takeQuantity} (${lang.tin})`,
      key: 'orderQuantityTin',
      render: rec => rec?.quantityTin - rec?.pickingQuantityTin,
    },
    {
      title: `${lang.pickingquantity} (${lang.carton})`,
      key: 'pickingQuantityCartton',
      dataIndex: 'pickingQuantityCartton',
    },
    {
      title: `${lang.pickingquantity} (${lang.tin})`,
      key: 'pickingQuantityTin',
      dataIndex: 'pickingQuantityTin',
    },
  ];
  const extraButton = [
    <Button
      key="btn-suggest"
      type="primary"
      ghost
      onClick={async () => {
        setIsSuggestLocationModal(true);
      }}
    >
      {lang.suggestLocation}
    </Button>,
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/sale-order');
      }}
    >
      {lang.btnCancel}
    </Button>
  ];
  useEffect(() => {
    Promise.all([...pickingOrder?.map(async (order) => {
      const reponse = await bookingDetail(order?.id);
      return Promise.resolve(...reponse?.productOrders?.map(product => {
        return {
          ...product, groupOrder: reponse?.group, orderType: pickingOrder?.[0]?.orderType, saleChannel: reponse?.channel?.channel, orderId: reponse?.id
        };
      }));
    })]).then((results) => {
      const ctx = results?.reduce((prev, cur) => {
        const finded = prev?.find(prev => prev.masterProduct.code3A === cur.masterProduct.code3A);
        if (finded) {
          finded.quantityInCarton += cur.quantityInCarton;
          finded.quantityTin += cur.quantityTin;
          finded.pickingQuantityCartton += cur.pickingQuantityCartton;
          finded.pickingQuantityTin += cur.pickingQuantityTin;
          finded.orderSubmitId = [...finded.orderSubmitId,cur.orderId];
          finded.quantityCartonSubmitArr=[...finded.quantityCartonSubmitArr, cur.quantityInCarton];
          finded.quantityTinSubmitArr= [...finded.quantityTinSubmitArr, cur.quantityTin];
          return prev;
        }
        return [...prev, {...cur, orderSubmitId: [cur.orderId], quantityCartonSubmitArr:[cur.quantityInCarton], quantityTinSubmitArr: [cur.quantityTin]}];
      }, []);
      setSelectedOrder([...ctx]);
    }).catch((err) => message.error(err.message));
  }, [pickingProducts, isEffect, currentWarehouse, pickingOrder]);
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/create',
      breadcrumbName: lang.pickupList,
    },
    {
      path: '/',
      breadcrumbName: lang.scanCode,
    },
  ];
  return (
    <Layout className="storing-scan">
        <PageHeader
          ghost={false}
          title={lang.scanCode}
          extra={extraButton}
          breadcrumb={{
            routes,
            itemRender: breadcrumbItemRender,
          }}
          className="customPageHeader"
        //onBack={onBack}
        />
        <Layout.Content style={{ margin: '12px 24px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ScanProductForm type={'PICKING'} selectOrderProduct={selectedOrder} onCallbackPrint={onCallbackPrint} onCallBackEffect={() => setIsEffect(!isEffect)} />
            </Col>
            <Col span={24}>
              <ProTableCusTom
                rowKey="id"
                size="small"
                className="tableCustom"
                bordered
                columns={columnsPickingList}
                params={{ selectedOrder, pickingProducts }}
                request={async (params) => {
                  const { selectedOrder } = params;
                  // const orderProductQuantity = pickingProducts.reduce((prev, cur) => {
                  //   const find = prev?.find(prev => prev.masterProduct.id === cur.masterProduct.id);
                  //   if (find) {
                  //     find.quantityInCarton = find.quantityInCarton + cur.quantityInCarton;
                  //     find.quantity = find.quantity + cur.quantity;
                  //     return prev;
                  //   }
                  //   return [...prev, Object.assign({}, cur)];
                  // }, []);
                  return Promise.resolve({
                    data: selectedOrder,
                    total: selectedOrder.length,
                    success: true,
                  });
                }}
                search={false}
                options={{
                  search: false,
                  reload: false,
                  density: false,
                }}
                pagination={{
                  showTitle: false,
                  showTotal: false,
                  pageSize: 10,
                }}
                scroll={{ x: 2000 }}
              />
            </Col>
          </Row>
        </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => { setIsPrintModal(false); setIsEffect(!isEffect); }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={[itemPrint]}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
        />
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          // await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={pickingOrder}
          totalItems={1}
          productOrders={selectedOrder}
          onCallback={async (values) => {
          }}
          onCancel={async () => {
            setIsSuggestLocationModal(false);
          }}
        />
      </Modal>
    </Layout>
  );
};

export default PickingCreateScan;
