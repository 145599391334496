import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, DatePicker, Input, Tag, message, Select } from 'antd';
import { SearchOutlined, InboxOutlined } from '@ant-design/icons';
import { productList } from '../product.service';
import ProductReportForm from 'components/ProductReportForm/product-report.form';
import './style.less';

import moment from 'moment';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import Dragger from 'antd/lib/upload/Dragger';
import Helper from 'utils/helpers';
import { importExel } from './vas.service';
import { blockList } from 'pages/dashboard/configuration/location/location.service';
import { Option } from 'antd/lib/mentions';

// const tableScrollHeightArea = window.innerHeight - 360; // px

// const defaultSort = {
//   order: 'descend',
//   columnKey: 'createdAt',
// };

// const VIEW_MODE = {
//   CARD_VIEW: 0,
//   LIST_VIEW: 1,
// };

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};
const defaultParams = {
  ...defaultPage,
  status: 'VAS',
};

const InboundControlList = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth);
  // const isStaff = _get(user, 'roles', '').includes('staff');

  // const [mode, setMode] = useState(VIEW_MODE.CARD_VIEW);
  const [isReportModal, setIsReportModal] = useState(false);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [loadingBtn, setLoadingBtn] = useState(false);
  //Pagination
  const [params, setParams] = useState({ ...defaultParams });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');
  const [file, setFile] = useState();
  const [uploadModal, setUploadModal] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState();

  const getProducts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    //   status: 'STORED',
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }
    // params.warehouseId = currentWarehouse.id;

    productList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    getProducts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  useEffect(() => {
    blockList({ warehouseId: currentWarehouse?.id }).then(values => {
      if (values?.data) {
        setBlocks(values.data);
      }
    });
  }, [currentWarehouse]);

  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container No',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.UOM,
      key: 'UOM',
      dataIndex: 'masterProduct',
      // width: '5%',
      render: value => value?.UOM_VN,
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
      // width: '5%',
      // render: value => value?.shelf.name,
    },
    {
      title: lang.documentNo,
      key: 'documentNo',
      dataIndex: 'documentNo',
      // width: '5%',
    },
    {
      title: lang.documentType,
      key: 'documentType',
      dataIndex: 'documentType',
      // width: '5%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            size="small"
            type="link"
            onClick={() => {
              //report
              const detail = {
                id: record?.id,
                productCode: record?.code,
                productName: record?.masterProduct?.name,
                totalQuantity: record?.totalQuantity,
              };

              setItem(detail);
              setIsReportModal(true);
            }}
          >{lang.report}</Button>
        </div>
      ),
    },
  ];

  const extraButton = [
    // <Radio.Group
    //   key="segment-control-view-mode"
    //   buttonStyle="solid"
    //   value={mode}
    //   onChange={(val) => setMode(val?.target?.value)}
    // >
    //   <Radio.Button value={VIEW_MODE.CARD_VIEW}>
    //     <AppstoreOutlined />
    //   </Radio.Button>
    //   <Radio.Button value={VIEW_MODE.LIST_VIEW}>
    //     <BarsOutlined />
    //   </Radio.Button>
    // </Radio.Group>,
    <Button
      key="btn-export"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const response = await productList({ warehouseId: currentWarehouse.id, status: 'STORED' });
          const dataRemp = response?.data?.map(product => {
            return {
              'Mã lô': product.code,
              Barcode: product?.masterProduct?.barCode,
              Tên: product?.masterProduct.name,
              'Ngày nhận': product?.receipt?.receiptDate && moment(product?.receipt?.receiptDate, formatDateFull).format(formatDate),
              'Số PO': product?.receipt?.code,
              'Số lượng tồn': product.totalQuantity,
              Block: product?.block?.name,
              Shelf: product?.rack?.shelf?.name,
              Rack: product?.rack?.code,
              'Ngày hết hạn': product?.expireDate && moment(product?.expireDate, formatDateFull).format(formatDate),
            };
          });
          Helper.dowloadXLSX(dataRemp, 'Product');
          setLoadingBtn(false);
        } catch (error) {
          setLoadingBtn(false);
          return false;
        }
      }}
    >
      {lang.exportCSV}
    </Button>,
    // <Button
    //   key="btn-complete"
    //   disabled={isStaff}
    //   type="primary"
    //   onClick={() => {
    //     navigate('vas-scan');
    //   }}
    // >
    //   {lang.scan}
    //   <ScanOutlined />
    // </Button>,
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/vas',
      breadcrumbName: lang.Vas,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.Vas}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.inboundControlDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.block}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select placeholder={lang.block} style={{ width: '100%' }} allowClear onChange={(value) => setSelectedBlock(value)}>
                {blocks?.map(block => <Option key={block.id} value={block.id}>{block.name}</Option>)}
              </Select>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input
                placeholder={lang.keyword}
                onChange={(value) => setKeyword(value.target.value)}
              />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  if (selectedBlock) {
                    params.blockId = selectedBlock;
                  } else {
                    delete params.blockId;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          //rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 2600 }}
        />
      </Layout.Content>
      <Modal
        visible={isReportModal}
        title={lang.reportErrorProduct}
        onCancel={() => setIsReportModal(false)}
        destroyOnClose={true}
        width={570}
        centered
        footer={null}
        wrapClassName="temporary-inbound-product-report-modal"
      >
        <ProductReportForm
          type={'inbound-control'}
          item={item}
          onCallback={(value) => {
            setParams({ ...defaultParams });
            setIsReportModal(false);
          }}
          onCancel={() => setIsReportModal(false)}
        />
      </Modal>
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(currentWarehouse.id, formData);
              setFile();
            }
            setParams({ ...defaultPage });
            message.success(lang.importSuccess);
            setUploadModal(false);
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => Helper.dowloadXLSX([{}], 'TemplateProduct')}>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default InboundControlList;
