import React, { useRef } from 'react';
import { Col, Row, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { blockCreate, blockUpdate } from './location.service';
import { ModalForm, ProFormDigit, ProFormText, ProFormSelect } from '@ant-design/pro-form';
import { CheckCircleOutlined } from '@ant-design/icons';
import './location-style.less';
import { useSelector } from 'react-redux';
import Helper from 'utils/helpers';
import PropTypes from 'prop-types';
import { areaList } from '../area/area.service';

const BlockModalForm = ({ item, setVisible, visible, fetchBlock, setBlockSelected, position }) => {
  const formRef = useRef();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   if (formRef?.current) {
  //     return () => {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       formRef.current.resetFields();
  //     };
  //   }
  // }, [visible]);
  const initValues = {
    name: item ? item?.name : '',
    totalShelf: item ? item?.totalShelf : null,
    areaId: item?.area ? item.area.id : undefined,
  };
  const onCancel = () => {
    const valueForm = formRef?.current?.getFieldsValue();
    const isDefined = Object.values(valueForm).some(value => value !== undefined);
    const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
    if (!isDefined || isUnChange) {
      setVisible(false);
      return true;
    }
    Helper.conFirm(setVisible);
  };
  const handleAddBlock = async (value) => {
    if (item) {
      await blockUpdate(value, item);
      message.success(lang.updateSuccessfully);
    } else {
      await blockCreate({ ...value, position: position });
      message.success(lang.createSuccessfully);
    }
  };

  return (
    <ModalForm
      title={item ? lang.blockDetail : lang.blockCreate}
      visible={visible}
      formRef={formRef}
      onFinish={async (value) => {
        try {
          await handleAddBlock({ ...value, warehouseId: currentWarehouse.id, status: 'ENABLE' });
          fetchBlock(currentWarehouse.id);
          setBlockSelected(null);
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }}
      requiredMark={false}
      initialValues={initValues}
      submitter={{
        render: ({ form }) => {
          return [
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              key="extra-reset"
              onClick={onCancel}
            >
              {lang.btnCancel}
            </Button>,
            <Button
              style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
              type="primary"
              key="extra-submit"
              onClick={() => form?.submit?.()}
            >
              {lang.btnSave} <CheckCircleOutlined />
            </Button>,
          ];
        },
      }}
      onVisibleChange={setVisible}
      modalProps={{
        closable: false,
        centered: true,
        destroyOnClose: true,
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <ProFormText name="name" label={lang.blockName} placeholder={lang.placeholderName}
            rules={[
              {
                required: true,
                message: lang.requiredBlockName,
              },
            ]} />
        </Col>
        <Col span={8}>
          <ProFormDigit name="totalShelf" label={lang.totalShelf} placeholder={lang.placeholderTotalshelf}
            rules={[
              {
                required: true,
                message: lang.requiredTotalshelf,
              },
            ]} />
        </Col>
        <Col span={8}>
          <ProFormSelect name="areaId" label={lang.area} placeholder={lang.area}
            // rules={[
            //   {
            //     required: true,
            //     message: lang.requiredTotalshelf,
            //   },
            // ]}
                         params={{ warehouseId: 1 }}
                         request={async ({ warehouseId }) => {
              const area = await areaList({ warehouseId });
              return area?.data?.map(dt => ({
                value: dt.id,
                label: dt.warehouseID,
              }));
            }}
          />
        </Col>
      </Row>
    </ModalForm>
  );
};
BlockModalForm.propTypes = {
  item: PropTypes.object,
  setVisible: PropTypes.func,
  fetchBlock: PropTypes.func,
  visible: PropTypes.bool,
  setBlockSelected: PropTypes.func,
  position: PropTypes.number,
};
export default BlockModalForm;
