import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message, DatePicker, Card, Form, Input } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import { saleorderCreate } from './sale-order.service';
import ReceiptCreateProductEditForm from './sale-order-create-product-edit.form';

import './style.less';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { locatorList } from '../locator/locator.service';
import { areaList } from '../area/area.service';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
import { salesChannelList } from '../sales-channel/sale-channel.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState(document.location.pathname.indexOf('internal-tranfer') > -1 ? 'EXPORT_INTERNAL' : undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [locators, setLocators] = useState([]);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [saleChannels, setSaleChannels] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    locatorList().then(results => setLocators(results?.data));
    salesChannelList({ warehouseId: currentWarehouse.id }).then(rs => setSaleChannels(rs?.data));
  }, [currentWarehouse]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'code3A',
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'nameEN',
      key: 'productName',
    },
    {
      title: lang.productCategory,
      dataIndex: 'productCategory',
      key: 'productCategory',
      render: (value) => value?.name,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'suppliers',
      render: (values) => values.map(value => value.name).join(', '),
    },
    {
      title: lang.locator,
      key: 'locatorId',
      dataIndex: ['locatorId', 'label'],
      hideInTable: type === 'EXPORT_SALE',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantity',
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: val => moment(val).format(formatDate),
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              const val = {...record};
              val.locator =  {locator: val?.locatorId?.value};
              val.masterProduct = {...val};
              val.quantityTin = val.quantity;
              setItem(val);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          const formData = { ...form.getFieldsValue(true) };
          const dataFieldForm = form.getFieldsValue();
          setLoadingBtn(true);
          const payload = items.map(item => {
            return {
              quantityInCarton: item.quantityInCarton,
              quantityTin: item.quantity,
              code3A: item?.code3A,
              locatorCode: item?.locatorId?.value,
              locatorCodeTo: formData?.locatorToId?.value,
              total: item.tinPerCarton * item.quantityInCarton + item.quantity,
              warehouseId: formData?.orderType === 'EXPORT_BRANCH' ? formData?.locatorTransit?.warehouseToId : formData?.locatorToId?.warehouseId,
              lot: item?.lot,
              expireDate: item?.expireDate,
            };
          });
          if (formData.channel) {
            const saleChannel = saleChannels?.find(sale => sale.id === formData?.channel);
            formData.subChannel = saleChannel.subChannel;
            formData.channel = saleChannel.channel;
            formData.channelName = saleChannel.channelName;
          }
          if (formData?.movementDate) {
            formData.movementDate = formData.movementDate.format(formatDateFull);
          }
          formData.creatorId = user?.userInfo?.name;
          formData.locatorTransitCode = formData?.locatorTransit?.value;
          formData.locatorCode = formData.locatorToId?.value;
          delete formData.locatorToId;
          delete formData.locatorTransit;
          if (dataFieldForm?.locatorToId) {
            console.log(formData)
            await saleorderCreate({ ...formData, productOrders: payload, warehouseId: currentWarehouse.id });
            message.success(lang.createSuccessfully);
            setLoadingBtn(false);
            navigate(-1);
          } else {
            Modal.warning({title: lang.pleaseSelectLocator})
            setLoadingBtn(false);
          }
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }
      }
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('internal-tranfer') > -1 ? {
      path: '/internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL,
    }
      : {
        path: '/sale-order',
        breadcrumbName: lang.exportWarehouse,
      },
    {
      path: '/',
      breadcrumbName: document.location.pathname.indexOf('internal-tranfer') > -1 ? `${lang.create} ${lang.EXPORT_INTERNAL}` : lang.createExportWarehouse,
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('internal-tranfer') > -1 ? lang.EXPORT_INTERNAL : lang.createExportWarehouse}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card bodyStyle={{ padding: 12 }}>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{
              orderType: document.location.pathname.indexOf('internal-tranfer') > -1 ? 'EXPORT_INTERNAL' : undefined,
              movementDate: moment(),
            }}
            form={form}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="orderType" label={lang.orderType}
                  placeholder={lang.orderType}
                  params={{ warehouseId: currentWarehouse.id }}
                  options={[
                    {
                      value: 'EXPORT_SALE',
                      label: lang.EXPORT_SALE,
                    },
                    {
                      value: 'EXPORT_BORROW',
                      label: lang.EXPORT_BORROW,
                    },
                  ]}
                  onChange={(value) => setType(value)}
                  disabled={document.location.pathname.indexOf('internal-tranfer') > -1}
                />
              </Col>
              {type !== 'EXPORT_INTERNAL' && <>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="lDDNo"
                    label={lang.lDDNo}
                  >
                    <Input placeholder={lang.lDDNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  {/* <Form.Item
                    name="truckType"
                    label={lang.truckType}
                  >
                    <Input placeholder={lang.truckType} />
                  </Form.Item> */}
                  <ProFormSelect
                    name="truckType"
                    label={lang.truckType}
                    options={[
                      {
                        value: 'Container 20FT',
                        label: 'Container 20FT',
                      },
                      {
                        value: 'Container 40FT',
                        label: 'Container 40FT',
                      },
                      {
                        value: 'Motorbike',
                        label: 'Motorbike',
                      },
                      {
                        value: 'Other',
                        label: 'Other',
                      },
                      {
                        value: 'Truck 1.25T',
                        label: 'Truck 1.25T',
                      },
                      {
                        value: 'Truck 1.5T',
                        label: 'Truck 1.5T',
                      },
                      {
                        value: 'Truck 10T',
                        label: 'Truck 10T',
                      },
                      {
                        value: 'Truck 15T',
                        label: 'Truck 15T',
                      },
                      {
                        value: 'Truck 2.0T',
                        label: 'Truck 2.0T',
                      },
                      {
                        value: 'Truck 2.5T',
                        label: 'Truck 2.5T',
                      },
                      {
                        value: 'Truck 5.0T',
                        label: 'Truck 5.0T',
                      },
                      {
                        value: 'Truck 8.0T',
                        label: 'Truck 8.0T',
                      },
                    ]}
                    placeholder={lang.truckType}
                  // rules={[{ required: true, message: 'Please select your country!' }]}
                  />
                </Col>
              </>}
              {type === 'EXPORT_SALE' && <>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="company"
                    label={lang.company}
                  >
                    <Input placeholder={lang.company} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="branch"
                    label={lang.branch}
                  >
                    <Input placeholder={lang.branch} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="orderReference"
                    label={lang.orderReference}
                  >
                    <Input placeholder={lang.orderReference} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="documentNo"
                    label={lang.documentNo}
                  >
                    <Input placeholder={lang.documentNo} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="invoicePrintNo"
                    label='Invoice Print No'
                  >
                    <Input placeholder='Invoice Print No' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="bussinessLine"
                    label={lang.businessLine}
                  >
                    <Input placeholder={lang.businessLine} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="abbottPOReference"
                    label={lang.abbottPOReference}
                  >
                    <Input placeholder={lang.abbottPOReference} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 8 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="description"
                    label={lang.description}
                  >
                    <Input placeholder={lang.description} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="dateOrdered"
                    label={lang.dateOrdered}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="businessPartner"
                    label='Business Partner'
                  >
                    <Input placeholder='Business Partner' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="areaId" label={lang.area}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.area}
                    params={{ warehouseId: 1 }}
                    request={async (params) => {
                      const response = await areaList(params);
                      return response?.data?.map(area => {
                        return {
                          value: area.id,
                          label: area.warehouseName,
                        };
                      });
                    }}
                  />
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="giftWarehouse"
                    label={lang.giftWarehouse}
                  >
                    <Input placeholder={lang.giftWarehouse} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="customerProgram"
                    label={lang.customerProgram}
                  >
                    <Input placeholder={lang.customerProgram} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="subChannel"
                    label={lang.subChannel}
                  >
                    <Input placeholder={lang.subChannel} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="shipAddress"
                    label='ShipAddress'
                  >
                    <Input placeholder='ShipAddress' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="invoiceAddress"
                    label='InvoiceAddress'
                  >
                    <Input placeholder='InvoiceAddress' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="user"
                    label='User'
                  >
                    <Input placeholder='User' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="warehouseType"
                    label='Warehouse Type'
                  >
                    <Input placeholder='Warehouse Type' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="representative"
                    label='Representative'
                  >
                    <Input placeholder='Representative' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="documentStatus"
                    label='Document Status'
                  >
                    <Input placeholder='Document Status' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="paymentMethod"
                    label='Payment Method'
                  >
                    <Input placeholder='Payment Method' />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="locatorId" label={lang.locator}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.locator}
                    options={locators?.map(locator => ({
                      label: `${locator.locatorName} - ${locator.locator}`,
                      value: locator.id,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                  />
                </Col>
              </>}
              {(type === 'EXPORT_INTERNAL' || type === 'EXPORT_BORROW' || type === 'EXPORT_BRANCH') && <>
                {/* <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="internalTranferCode"
                    label={lang.documentNo}
                  >
                    <Input placeholder={lang.documentNo} />
                  </Form.Item>
                </Col> */}
                {type === 'EXPORT_BRANCH' ? <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="locatorTransit" label={`${lang.locator} (Transit)`}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.locator}
                    options={locators?.filter(locator => locator.warehouseId === currentWarehouse?.id && locator.warehouseToId)?.map(locator => ({
                      label: `${locator.locatorName} - ${locator.locator}`,
                      value: locator.locator,
                      warehouseToId: locator.warehouseToId,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                    fieldProps={{
                      labelInValue: true,
                    }}
                  />
                </Col> : <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="locatorToId" label={lang.locatorTo}
                    rules={[
                      {
                        required: true,
                        message: lang.requiredLocator,
                      },
                    ]}
                    placeholder={lang.locator}
                    options={locators?.filter(locator => locator.warehouseId === currentWarehouse?.id)?.map(locator => ({
                      label: `${locator.locatorName} - ${locator.locator}`,
                      value: locator.locator,
                      warehouseId: locator.warehouseId,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                    fieldProps={{
                      labelInValue: true,
                    }}
                  />
                </Col>}
                {type === 'EXPORT_INTERNAL' && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="channel" label={lang.salesChannel}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.salesChannel}
                    options={saleChannels?.map(saleChannel => ({
                      label: `${saleChannel.channelName} - ${saleChannel.channel} - ${saleChannel.customerCode}`,
                      value: saleChannel.id,
                    }))}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // return true;
                      return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                  />
                </Col>}
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="movementDate"
                    label={lang.movementDate}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <Form.Item
                    name="tranferReason"
                    label={lang.tranferReason}
                  >
                    <Input placeholder={lang.tranferReason} />
                  </Form.Item>
                </Col>
                {type === 'EXPORT_BORROW' && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                  <ProFormSelect style={{ fontWeight: 600 }}
                    name="typeBorrow" label={lang.consignmentOrder}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: lang.requiredLocator,
                    //   },
                    // ]}
                    placeholder={lang.consignmentOrder}
                    options={[
                      {
                        label: lang.borrowed,
                        value: 'EXPORT_BORROW',
                      },
                      {
                        label: lang.enterBorrow,
                        value: 'IMPORT_BORROW',
                      },
                    ]}
                  />
                </Col>}
              </>}
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productAdded}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setItem();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                ghost
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.indx) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="indx"
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={item ? lang.editProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          items={items}
          onCallback={(value) => {
            const filters = items?.filter(item=>item.indx !== value.indx);
            setItems([...filters, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
          type={type}
          channel={saleChannels?.find(saleChannel => saleChannel.id === form?.getFieldValue('channel'))}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
