import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Input, Tag, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { purchaseOrderList } from './purchaseOrder.service';
import _get from 'lodash/get';
import moment from 'moment';

import './style.less';

import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PurchaseOrderList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isStaff = _get(user, 'roles', '').includes('staff');
  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [status, setStatus] = useState();
  const [params, setParams] = useState({ ...defaultPage });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  const [keyword, setKeyword] = useState('');

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // function onSelectChange(selectedRowKeys) {
  //   setSelectedRowKeys(selectedRowKeys);
  // }

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const getReceipts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);

    // const params = {
    //   page,
    //   limit,
    //   sortBy,
    //   sortDirection,
    //   keyword,
    // };
    // if (startDate) {
    //   params.startDate = startDate.startOf('date').format(formatDateFull);
    // }
    // if (endDate) {
    //   params.endDate = endDate.endOf('date').format(formatDateFull);
    // }
    // params.warehouseId = currentWarehouse.id;
    const newParams = { ...params };
    const status = newParams?.status;
    delete newParams.status;
    const isDived = status === 'COMPLETE' ? true : status === 'ENABLE' ? false : undefined;
    purchaseOrderList({ ...newParams, sortBy, sortDirection, warehouseId: currentWarehouse.id === 1 ? undefined : currentWarehouse.id, status: currentWarehouse.id === 1 ? status : status, isDived: currentWarehouse.id === 1 ? undefined : isDived })
      .then(result => {
        setLoading(true)
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getReceipts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);
  const columns = [
    {
      title: lang.poCode,
      key: 'documentno',
      render: (record) => {
        if (isStaff) {
          return (
            <div>{record.documentNo}</div>
          );
        } else {
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record.id}`)}>{record.documentNo}</Button>
          );
        }
      },
    },
    {
      title: lang.company,
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: lang.branch,
      dataIndex: 'branch',
      key: 'branch',
    },
    {
      title: lang.businessPartner,
      dataIndex: 'businessPartner',
      key: 'businessPartner',
    },
    {
      title: lang.status,
      key: 'status',
      // dataIndex: 'status',
      render: (record) => {
        if (record?.status === "ENABLE") {
          return <Tag>{lang.notReceived}</Tag>;
        } else if (record?.status === "INPROGRESS") {
          return <Tag>{lang.partiallyReceived}</Tag>;
        } 
        else if (record?.status === "DISABLE") {
          return <Tag>{lang.CANCELLED}</Tag>;
        } 
        else {
          return <Tag>{lang.fullyReceived}</Tag>;
        }
      },
    },
    {
      title: lang.warehouse,
      dataIndex: ['warehouse', 'name'],
      key: 'name',
    },
    {
      title: lang.createDate,
      key: 'createDate',
      render: (record) => {
        return (moment(record.createDate).format(formatDate));
      },
    },
  ];
  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/apinvoice',
      breadcrumbName: lang.ntaPurchaseOrder,
    },
  ];
  // const extraButton = [
  //   <Button
  //     key="btn-cancel"
  //     type="primary"
  //     ghost
  //     onClick={() => {
  //       setIsEditModal(true);
  //     }}
  //   >
  //     {lang.btnCreate}
  //   </Button>,
  // ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.ntaPurchaseOrder}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Select placeholder={lang.status} defaultValue={lang.all} onChange={(value) => setStatus(value)} style={{ width: '100%' }} allowClear>
                <Option key='ENABLE' value="ENABLE">{currentWarehouse.id === 1 ? lang.coordinationPending : lang.notReceived}</Option>
                {currentWarehouse.id !== 1 && <Option key='sdsa' value="INPROGRESS">{lang.partiallyReceived}</Option>}
                <Option key='COMPLETED' value="COMPLETED">{currentWarehouse.id === 1 ? lang.coordinated: lang.fullyReceived}</Option>
                <Option key='DISABLE' value="DISABLE">{lang.CANCELLED}</Option>
              </Select>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} allowClear />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (status) {
                    Object.assign(params, { status: status });
                  } else {
                    Object.assign(params, { status: undefined });
                  }
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }
                  params.page = 1;
                  setParams({ ...params, keyword });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.ntaPurchaseOrderList}</h3></Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        receiptDelete(selectedRowKeys[0])
                          .then(result => {
                            params.page = 1;
                            setParams({ ...defaultPage });
                            setSelectedRowKeys([]);
                            Modal.success({
                              title: lang.deleteReceiptsSuccessfully,
                              content: `${lang.receiptIdsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                            });
                          })
                          .catch((e) => message.error(e.message));
                      },
                      onCancel() {
                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col> */}
          </Row>}
          rowKey="id"
          // rowSelection={!isStaff && rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            // if (sorter) {
            //   setSortedInfo({
            //     columnKey: sorter.columnKey,
            //     order: sorter.order,
            //   });
            // }
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default PurchaseOrderList;
