export const getLanguages = (t) => {
  return {

    login: t('login'),
    password: t('password'),
    slogan: t('slogan'),
    akaName: t('akaName'),
    intro: t('intro'),
    copyright: t('copyright'),
    akaDescription: t('akaDescription'),
    messagePassword: t('messagePassword'),
    placeholderPassword: t('placeholderPassword'),
    placeholderUsername: t('placeholderUsername'),
    messageUsername: t('messageUsername'),
    loading: t('loading'),

    //layout
    inbound: t('inbound'),
    receipt: t('receipt'),
    receiptGiftList: t('receiptGiftList'),
    materialReceipt: t('materialReceipt'),
    giftReceipt: t('giftReceipt'),
    ntaPurchaseOrder: t('ntaPurchaseOrder'),
    ntaPurchaseOrderDetail: t('ntaPurchaseOrderDetail'),
    poCode: t('poCode'),
    maneuveringCommand: t('maneuveringCommand'),
    maneuveringCommandGift: t('maneuveringCommandGift'),
    production: t('production'),
    unpack: t('unpack'),
    temporaryInbound: t('temporaryInbound'),
    storing: t('storing'),
    inboundControl: t('inboundControl'),
    inventory: t('inventory'),
    home: t('home'),
    warehouseState: t('warehouseState'),
    reallocation: t('reallocation'),
    productManagement: t('productManagement'),
    inventoryProductBySalesChannel: t('inventoryProductBySalesChannel'),
    inventoryProductByChannel: t('inventoryProductByChannel'),
    outbound: t('outbound'),
    pickingOrder: t('pickingOrder'),
    packaging: t('packaging'),
    shippingOutboundControl: t('shippingOutboundControl'),
    configuration: t('configuration'),
    supplier: t('supplier'),
    product: t('product'),
    productCategory: t('productCategory'),
    location: t('location'),
    replenishment: t('replenishment'),
    saleOrder: t('saleOrder'),
    exportWarehouse: t('exportWarehouse'),
    createExportWarehouse: t('createExportWarehouse'),
    reporting: t('reporting'),
    auditOrder: t('auditOrder'),
    inventoryWarning: t('inventoryWarning'),
    performance: t('performance'),
    changePassword: t('changePassword'),
    logout: t('logout'),
    confirm: t('confirm'),
    confirmCancelDO: t('confirmCancelDO'),
    cancelDO: t('cancelDO'),
    confirmLogout: t('confirmLogout'),
    warehouse: t('warehouse'),
    user: t('user'),
    admin: t('admin'),

    //inbound/receipt
    receiptId: t('receiptId'),
    receiptCode: t('receiptCode'),
    exportCode: t('exportCode'),
    inboundKind: t('inboundKind'),
    status: t('status'),
    confirmed: t('confirmed'),
    receiptDate: t('receiptDate'),
    receiptStatus: t('receiptStatus'),
    receiveDate: t('receiveDate'),
    inboundType: t('inboundType'),
    new: t('new'),
    importFromOtherBranches: t('importFromOtherBranches'),
    internalImport: t('internalImport'),
    return: t('return'),
    stockerConfirmed: t('stockerConfirmed'),
    idmConfirmed: t('idmConfirmed'),
    stockReceiving: t('stockReceiving'),
    stockReceived: t('stockReceived'),
    inboundStatus: t('inboundStatus'),
    erpIntegration: t('erpIntegration'),
    containerNo: t('containerNo'),
    from: t('from'),
    to: t('to'),
    creator: t('creator'),
    keyword: t('keyword'),
    search: t('search'),
    receiptList: t('receiptList'),
    doYouWantToDeleteTheseItems: t('doYouWantToDeleteTheseItems'),
    deleteReceiptsSuccessfully: t('deleteReceiptsSuccessfully'),
    receiptIdsHasBeenDeleted: t('receiptIdsHasBeenDeleted'),

    //inbound
    productCode: t('productCode'),
    productName: t('productName'),
    poReference: t('poReference'),
    totalTinQuantityErp: t('totalTinQuantityErp'),
    conversedBoxQuantityErp: t('conversedBoxQuantityErp'),
    separatedTinQuantityAfterConversionToBoxErp: t('separatedTinQuantityAfterConversionToBoxErp'),
    expectedQuantity: t('expectedQuantity'),
    cost: t('cost'),
    salePrice: t('salePrice'),
    expireDate: t('expireDate'),
    createReceipt: t('createReceipt'),
    createReceiptSuccessfully: t('createReceiptSuccessfully'),
    pleaseSelectAInboundKind: t('pleaseSelectAInboundKind'),
    messageReceiptDate: t('messageReceiptDate'),
    messageReceiveDate: t('messageReceiveDate'),
    productAdded: t('productAdded'),
    importCSV: t('importCSV'),
    pleaseSelecteAProduct: t('pleaseSelecteAProduct'),
    messageExpectedQuantity: t('messageExpectedQuantity'),
    messageCost: t('messageCost'),
    totalQuantity: t('totalQuantity'),
    stockAvailableForSale: t('stockAvailableForSale'),
    quantityAwaitingDispatch: t('quantityAwaitingDispatch'),
    quantityForSale: t('quantityForSale'),
    totalQuantityTins: t('totalQuantityTins'),
    totalQuantityTinOdd: t('totalQuantityTinOdd'),
    totalAvailableCartonQuantity: t('totalAvailableCartonQuantity'),
    totalUsableSeparatedTinQuantity: t('totalUsableSeparatedTinQuantity'),
    totalAvailableTinQuantity: t('totalAvailableTinQuantity'),
    totalQuantityCartonsAwait: t('totalQuantityCartonsAwait'),
    totalQuantityTinsAwait: t('totalQuantityTinsAwait'),
    totalQuantityOddTinsAwait: t('totalQuantityOddTinsAwait'),
    zone: t('zone'),
    block: t('block'),
    shelf: t('shelf'),
    rack: t('rack'),
    suggested: t('suggested'),
    confirmReceiptSuccessfully: t('confirmReceiptSuccessfully'),
    receiptDetail: t('receiptDetail'),
    productList: t('productList'),
    updateLocationSuccessfully: t('updateLocationSuccessfully'),
    updateLocation: t('updateLocation'),
    print: t('print'),
    messageTotalQuantity: t('messageTotalQuantity'),
    report: t('report'),
    scanReturnProduct: t('scanReturnProduct'),
    reportErrorProduct: t('reportErrorProduct'),
    reportSuccessfully: t('reportSuccessfully'),
    reportContent: t('reportContent'),
    messageReportContent: t('messageReportContent'),
    btnSubmit: t('btnSubmit'),
    inputCode: t('inputCode'),
    packageCode: t('packageCode'),
    locationCode: t('locationCode'),
    completeScanReturnProductSuccessfully: t('completeScanReturnProductSuccessfully'),
    scanReturnProductComplete: t('scanReturnProductComplete'),
    scanMore: t('scanMore'),
    scan: t('scan'),
    storingDate: t('storingDate'),
    scanProduct: t('scanProduct'),
    storingScanProduct: t('storingScanProduct'),
    completeScanStoringSuccessfully: t('completeScanStoringSuccessfully'),
    storingScanProductComplete: t('storingScanProductComplete'),
    inboundControlDate: t('inboundControlDate'),
    inboundControlScanProduct: t('inboundControlScanProduct'),
    completeInboundControlScanSuccessfully: t('completeInboundControlScanSuccessfully'),
    inboundControlScanProductComplete: t('inboundControlScanProductComplete'),

    createNew: t('createNew'),
    deleted: t('deleted'),
    canceled: t('canceled'),
    successfully: 'successfully',
    doYouWant: t('doYouWant'),
    deleteDispatchOrder: t('deleteDispatchOrder'),
    operationCreated: t('operationCreated'),
    customerReturnCode: t('customerReturnCode'),
    dispatchOrderCode: t('dispatchOrderCode'),
    dispatchOrder: t('dispatchOrder'),
    dispatchOrderDetail: t('dispatchOrderDetail'),
    createDispatchOrder: t('createDispatchOrder'),
    dispatchGiftOrder: t('dispatchGiftOrder'),
    giftProductCode: t('giftProductCode'),
    giftProductName: t('giftProductName'),
    selectVehicleNotice: t('selectVehicleNotice'),
    selectLocatorTransitNotice: t('selectLocatorTransitNotice'),
    tons: t('tons'),
    exportDispatchOrder: t('exportDispatchOrder'),
    exportDeliveryNotice: t('exportDeliveryNotice'),
    cancelOrderSuccess: t('cancelOrderSuccess'),
    updateOrderSuccess: t('updateOrderSuccess'),
    quantityIsRequire: t('quantityIsRequire'),
    lessThanInventoryQuantity: t('lessThanInventoryQuantity'),
    branchShipmentOrder: t('branchShipmentOrder'),
    pickedCanQuantity: t('pickedCanQuantity'),
    inventoryItems: t('inventoryItems'),
    inventoryGifts: t('inventoryGifts'),
    temporaryImportGiftList: t('temporaryImportGiftList'),
    toCoordination: t('toCoordination'),
    coordinationPending: t('coordinationPending'),
    coordinated: t('coordinated'),
    partiallyReceived: t('partiallyReceived'),
    fullyReceived: t('fullyReceived'),
    notReceived: t('notReceived'),
    createExport: t('createExport'),
    pickedCan: t('can picked'),
    pickedNeed: t('pickedNeed'),
    quantityInCartonLessThanTotal: t('quantityInCartonLessThanTotal'),
    quantityCartonAndTin: t('quantityCartonAndTin'),
    isRequire: t('isRequire'),
    availableQuantityCarton: t('availableQuantityCarton'),
    suggest: t('suggest'),
    temporaryImport: t('temporaryImport'),
    temporaryGiftList: t('temporaryGiftList'),
    unpackCode: t('unpackCode'),
    unpackDetail: t('unpackDetail'),
    unpackProductList: t('unpackProductList'),
    materialCode: t('materialCode'),
    materialName: t('materialName'),
    cancelMaterial: t('cancelMaterial'),
    cancelMaterialSuccessfully: t('cancelMaterialSuccessfully'),
    form: t('form'),
    exportPGH: t('exportPGH'),
    giftReceiptNoteDetail: t('giftReceiptNoteDetail'),
    materialReceiptDetail: t('materialReceiptDetail'),
    standardImport: t('standardImport'),
    branchImport: t('branchImport'),
    warehouseReceiptConfirm: t('warehouseReceiptConfirm'),
    importDate: t('importDate'),
    warehouseFrom: t('warehouseFrom'),
    receiptMaterial: t('receiptMaterial'),
    receiptMaterialList: t('receiptMaterialList'),
    formHaveCode: t('formHaveCode'),
    chooseRack: t('chooseRack'),
    pleaseChooseRack: t('pleaseChooseRack'),
    productionCode: t('productionCode'),
    productionList: t('productionList'),
    typeProductionOrder: t('typeProductionOrder'),
    requiredTypeProductionOrder: t('requiredTypeProductionOrder'),
    importLabel: t('importLabel'),
    pastCard: t('pastCard'),
    packing: t('packing'),
    deletedFormError: t('deletedFormError'),
    selectedItem: t('selectedItem'),
    deleteProduction: t('deleteProduction'),
    rawMaterialsAndSuppliesInfo: t('rawMaterialsAndSuppliesInfo'),
    quantityNeeded: t('quantityNeeded'),
    productNotConfiguredYet: t('productNotConfiguredYet'),
    packingOrderFrom: t('packingOrderFrom'),
    packingOrder: t('packingOrder'),
    packingOrderRequired: t('packingOrderRequired'),
    toLocator: t('toLocator'),
    pleaseChooseProductionOrder: t('pleaseChooseProductionOrder'),
    receiptProduct: t('receiptProduct'),
    quantityHasCreatedProduction: t('quantityHasCreatedProduction'),
    quantityHasCreatedUnpack: t('quantityHasCreatedUnpack'),
    ERP: t('ERP'),
    tongSL: t('tongSL'),

    //admin
    username: t('username'),
    name: t('name'),
    role: t('role'),
    users: t('users'),
    requiredUsername: t('requiredUsername'),
    requiredPassword: t('requiredPassword'),
    requiredName: t('requiredName'),
    requiredRole: t('requiredRole'),
    requiredWarehouse: t('requiredWarehouse'),
    warehouseCode: t('warehouseCode'),
    warehouseName: t('warehouseName'),
    address: t('address'),
    warehouses: t('warehouses'),
    acreage: t('acreage'),

    btnCancel: t('btnCancel'),
    btnComplete: t('btnComplete'),
    btnAdd: t('btnAdd'),
    btnEdit: t('btnEdit'),
    btnConfirm: t('btnConfirm'),
    btnDelete: t('btnDelete'),
    btnSave: t('btnSave'),
    confirmQuestion: t('confirmQuestion'),
    confirmContent: t('confirmContent'),

    addZone: t('addZone'),
    addShelf: t('addShelf'),
    addBlock: t('addBlock'),
    zoneDetail: t('zoneDetail'),
    zoneCreate: t('zoneCreate'),
    zoneName: t('zoneName'),
    zoneAreDeletedSuccessfully: t('zoneAreDeletedSuccessfully'),
    blockAreDeletedSuccessfully: t('blockAreDeletedSuccessfully'),
    shelfAreDeletedSuccessfully: t('shelfAreDeletedSuccessfully'),
    areYouSureToDeleteThisZone: t('areYouSureToDeleteThisZone'),
    areYouSureToDeleteThisBlock: t('areYouSureToDeleteThisBlock'),
    areYouSureToDeleteThisShelf: t('areYouSureToDeleteThisShelf'),

    //Product category
    placeholderName: t('placeholderName'),
    placeholderSelectMethod: t('placeholderSelectMethod'),
    requiredCategoryName: t('requiredCategoryName'),
    requiredMethod: t('requiredMethod'),

    //Supplier
    placeholderSelectProductCate: t('placeholderSelectProductCate'),
    placeholderAddress: t('placeholderAddress'),
    placeholderPhoneNumber: t('placeholderPhoneNumber'),
    requiredCategory: t('requiredCategory'),
    requiredSupplierName: t('requiredSupplierName'),
    requiredEmail: t('requiredEmail'),
    requiredAddress: t('requiredAddress'),
    requiredPhoneNumber: t('requiredPhoneNumber'),

    //Product
    placeholderSelectSupplier: t('placeholderSelectSupplier'),
    placeholderCapacity: t('placeholderCapacity'),
    requiredImage: t('requiredImage'),
    requiredProductName: t('requiredProductName'),
    requiredSupplier: t('requiredSupplier'),
    requiredCapacity: t('requiredCapacity'),
    requiredStoreTime: t('requiredStoreTime'),

    //Replenishment
    placeholderMin: t('placeholderMin'),
    placeHolderMax: t('placeHolderMax'),
    requiredMin: t('requiredMin'),
    requiredMax: t('requiredMax'),
    placeholderSelectProduct: t('placeholderSelectProduct'),
    requiredProduct: t('requiredProduct'),

    //Location
    requiredZoneName: t('requiredZoneName'),
    requiredBlockName: t('requiredBlockName'),
    placeholderTotalshelf: t('placeholderTotalshelf'),
    requiredTotalshelf: t('requiredTotalshelf'),
    requiredShelfName: t('requiredShelfName'),
    placeholderTotalRack: t('placeholderTotalRack'),
    placeholderLevelPercent: t('placeholderLevelPercent'),
    requiredLevelPercent: t('requiredLevelPercent'),
    requiredTotalRack: t('requiredTotalRack'),

    //Sale order
    placeholderQuantity: t('placeholderQuantity'),
    requiredQuantity: t('requiredQuantity'),
    quantityBook: t('quantityBook'),
    quantityBookIsNegative: t('quantityBookIsNegative'),
    capacity: t('capacity'),
    success: t('success'),
    blockDetail: t('blockDetail'),
    blockCreate: t('blockCreate'),
    btnUpdate: t('btnUpdate'),
    btnCreate: t('btnCreate'),
    btnSearch: t('btnSearch'),
    blockName: t('blockName'),
    totalShelf: t('totalShelf'),
    updateShelf: t('updateShelf'),
    createShelf: t('createShelf'),
    update: t('update'),
    complete: t('complete'),
    shelfName: t('shelfName'),
    capacityRack: t('capacityRack'),
    levelPercent: t('levelPercent'),
    medium: t('medium'),
    high: t('high'),
    code: t('code'),
    createSuccessfully: t('createSuccessfully'),
    updateSuccessfully: t('updateSuccessfully'),
    confirmSuccessfully: t('confirmSuccessfully'),
    deleteSuccessfully: t('deleteSuccessfully'),
    idsHasBeenDeleted: t('idsHasBeenDeleted'),
    email: t('email'),
    userName: t('userName'),
    orderDate: t('orderDate'),
    orderQuantity: t('orderQuantity'),
    takeQuantity: t('takeQuantity'),
    pickingquantity: t('pickingquantity'),
    placeholderSelectOrderCode: t('placeholderSelectOrderCode'),
    titlePicking: t('titlePicking'),
    suggestLocation: t('suggestLocation'),
    quantity: t('quantity'),
    qtyScrapped: t('qtyScrapped'),
    createPicking: t('createPicking'),
    labelBooking: t('labelBooking'),
    placeholderSelectOrder: t('placeholderSelectOrder'),
    okText: t('okText'),
    cancelText: t('cancelText'),
    titleModalBooking: t('titleModalBooking'),
    scanCode: t('scanCode'),
    confirmPickupList: t('confirmPickupList'),
    titleModalPslitProduct: t('titleModalPslitProduct'),
    placeholderSelectPackageCode: t('placeholderSelectPackageCode'),
    btnDone: t('btnDone'),
    btnClose: t('btnClose'),
    titleModalSuccessSuggestLocation: t('titleModalSuccessSuggestLocation'),
    pickupList: t('pickupList'),
    generatePackageCode: t('generatePackageCode'),
    orderNumber: t('orderNumber'),
    productOrderCode: t('productOrderCode'),
    pickingList: t('pickingList'),
    packagingCode: t('packagingCode'),
    tempOutLot: t('tempOutLot'),
    orderandpackagingInfo: t('orderandpackagingInfo'),
    packagingDetail: t('packagingDetail'),
    orderandshippingInfo: t('orderandshippingInfo'),
    confirmPackageList: t('confirmPackageList'),
    outQuantity: t('outQuantity'),
    startDate: t('startDate'),
    endDate: t('endDate'),
    orderInfo: t('orderInfo'),
    inStock: t('inStock'),
    printQrCode: t('printQrCode'),
    outOfStock: t('outOfStock'),
    selectProduct: t('selectProduct'),
    expirationDate: t('expirationDate'),
    storageExpirationDate: t('storageExpirationDate'),
    exportCSV: t('exportCSV'),
    agingStockAlert: t('agingStockAlert'),
    dotRestock: t('dotRestock'),
    productDetail: t('productDetail'),
    total: t('total'),
    inventorySummary: t('inventorySummary'),
    inboundsummary: t('inboundsummary'),
    productSummary: t('productSummary'),
    action: t('action'),
    warehouseProductDetail: t('warehouseProductDetail'),
    requiredReportContent: t('requiredReportContent'),
    editLocation: t('editLocation'),
    dotEditLocation: t('dotEditLocation'),
    onlyEditLocation: t('onlyEditLocation'),
    pendingState: t('pendingState'),
    inboundState: t('inboundState'),
    inboundStateGift: t('inboundStateGift'),
    lostState: t('lostState'),
    updateProduct: t('updateProduct'),
    requiredZone: t('requiredZone'),
    requiredLocator: t('requiredLocator'),
    requiredBlock: t('requiredBlock'),
    requiredShelf: t('requiredShelf'),
    requiredRack: t('requiredRack'),
    requiredExpirationDate: t('requiredExpirationDate'),
    requiredTotalQauntity: t('requiredTotalQauntity'),
    requiredCost: t('requiredCost'),
    requiredSalePrice: t('requiredSalePrice'),
    requiredStatus: t('requiredStatus'),
    requiredLostDate: t('requiredLostDate'),
    requiredDocumentType: t('requiredDocumentType'),

    totalPerPallet: t('totalPerPallet'),
    sepratePallet: t('sepratePallet'),
    totalPallet: t('totalPallet'),
    totalRackbetween: t('totalRackbetween'),
    backtoProductList: t('backtoProductList'),
    back: t('back'),
    productInfo: t('productInfo'),
    storageTime: t('storageTime'),
    productImage: t('productImage'),
    imageExtra: t('imageExtra'),
    upload: t('upload'),
    methodExport: t('methodExport'),
    createDate: t('createDate'),
    createProduct: t('createProduct'),
    productCategoryName: t('productCategoryName'),
    productCategoryCode: t('productCategoryCode'),
    productCategoryList: t('productCategoryList'),
    updateProductCategoty: t('updateProductCategoty'),
    createProductCategoty: t('createProductCategoty'),
    minQuantity: t('minQuantity'),
    maxQuantity: t('maxQuantity'),
    notification: t('notification'),
    onHand: t('onHand'),
    toOrder: t('toOrder'),
    replenishmentList: t('replenishmentList'),
    createReplenishment: t('createReplenishment'),
    updateReplenishment: t('updateReplenishment'),
    backtoSupplierList: t('backtoSupplierList'),
    supplierInfo: t('supplierInfo'),
    phone: t('phone'),
    cooperationDate: t('cooperationDate'),
    supplierName: t('supplierName'),
    requiredCooperationDate: t('requiredCooperationDate'),
    supplierCode: t('supplierCode'),
    supplierList: t('supplierList'),
    createSupplier: t('createSupplier'),
    updateSupplier: t('updateSupplier'),
    createSaleOrder: t('createSaleOrder'),
    addProduct: t('addProduct'),
    editProduct: t('editProduct'),
    saleOrderDetail: t('saleOrderDetail'),
    confirmOrderDetail: t('confirmOrderDetail'),
    confirmOrder: t('confirmOrder'),
    orderList: t('orderList'),
    reportDate: t('reportDate'),
    firstName: t('firstName'),
    lastName: t('lastName'),
    lostDate: t('lostDate'),
    phoneLengthMessage: t('phoneLengthMessage'),
    phoneMessage: t('phoneMessage'),
    full: t('full'),
    empty: t('empty'),
    Medium: t('Medium'),
    resetPassword: t('resetPassword'),
    passwordNew: t('passwordNew'),
    currentPassword: t('currentPassword'),
    passwordConfirm: t('passwordConfirm'),
    zoneNameExist: t('zoneNameExist'),
    blockNameExist: t('blockNameExist'),
    shelfNameExist: t('shelfNameExist'),
    subTitleInboundControl: t('subTitleInboundControl'),
    subTitleOutboundCreatePickUpNotify: t('subTitleOutboundCreatePickUpNotify'),
    subTitleStoringNotify: t('subTitleStoringNotify'),
    subTitleInboundTemporaryNotify: t('subTitleInboundTemporaryNotify'),
    yes: t('yes'),
    no: t('no'),
    passwordRegex: t('passwordRegex'),
    updateImageLess: t('updateImageLess'),
    creatorName: t('creatorName'),
    invalidCode: t('invalidCode'),
    tryAgain: t('tryAgain'),
    orderStatus: t('orderStatus'),
    packageStatus: t('packageStatus'),
    noRecordsFound: t('noRecordsFound'),
    emailRequirePlaceholder: t('emailRequirePlaceholder'),
    messeageExpiration: t('messeageExpiration'),
    unlockAccount: t('unlockAccount'),
    january: t('january'),
    february: t('february'),
    march: t('march'),
    april: t('april'),
    may: t('may'),
    june: t('june'),
    july: t('july'),
    august: t('august'),
    september: t('september'),
    october: t('october'),
    november: t('november'),
    december: t('december'),
    splitproduct: t('splitproduct'),
    split: t('split'),
    splitProductList: t('splitProductList'),
    vans: t('vans'),
    addVans: t('addVans'),
    createVans: t('createVans'),
    updateVans: t('updateVans'),
    vansName: t('vansName'),
    vansLicensePlate: t('vansLicensePlate'),
    placeholderLicensePlate: t('placeholderLicensePlate'),
    requiredLicensePlate: t('requiredLicensePlate'),
    requiredVansName: t('requiredVansName'),
    vansState: t('vansState'),
    vansDetail: t('vansDetail'),
    defaultCode: t('defaultCode'),
    requiredDefaultCode: t('requiredDefaultCode'),
    placeHolderDefaultCode: t('placeHolderDefaultCode'),
    placeholderSalePrice: t('placeholderSalePrice'),
    retailPrice: t('retailPrice'),
    placeholderRetailPrice: t('placeholderRetailPrice'),
    lot: t('lot'),
    requiredLot: t('requiredLot'),
    placeholderLot: t('placeholderLot'),
    vat: t('vat'),
    divison: t('divison'),
    category: t('category'),
    divisonCode: t('divisonCode'),
    divisonName: t('divisonName'),
    createDivison: t('createDivison'),
    updateDivison: t('updateDivison'),
    placeholderDivisonName: t('placeholderDivisonName'),
    requiredDivisonName: t('requiredDivisonName'),
    categoryCode: t('categoryCode'),
    categoryName: t('categoryName'),
    createCategory: t('createCategory'),
    updateCategory: t('updateCategory'),
    placeholderCategoryName: t('placeholderCategoryName'),
    requiredParentCategoryName: t('requiredCategoryName'),
    requiredDivison: t('requiredDivison'),
    requiredParentCategory: t('requiredCategory'),
    categoryList: t('categoryList'),
    divisonList: t('divisonList'),
    DVT: t('DVT'),
    isActive: t('isActive'),
    contractNumber: t('contractNumber'),
    contractNo: t('contractNo'),
    packingRule: t('packingRule'),
    NEW: t('NEW'),
    CONFIRM1: t('CONFIRM1'),
    CONFIRM2: t('CONFIRM2'),
    TEMPORARYCONFIRM: t('TEMPORARYCONFIRM'),
    INPROGRESS: t('INPROGRESS'),
    ERROR: t('ERROR'),
    TEMPORARY: t('TEMPORARY'),
    STORED: t('STORED'),
    MOVING: t('MOVING'),
    TEMPORARY_OUT: t('TEMPORARY_OUT'),
    LOST: t('LOST'),
    REALLOCATE: t('REALLOCATE'),
    ENABLE: t('ENABLE'),
    DISABLE: t('DISABLE'),
    requestReceipt: t('requestReceipt'),
    note: t('note'),
    itemCode: t('itemCode'),
    length: t('length'),
    width: t('width'),
    height: t('height'),
    RECEIVE: t('RECEIVE'),
    dowloadTemplate: t('dowloadTemplate'),
    importSuccess: t('importSuccess'),
    exportPdf: t('exportPdf'),
    discount: t('discount'),
    isResources: t('isResources'),
    vansCode: t('vansCode'),
    createPo: t('createPo'),
    taxCode: t('taxCode'),
    requiredPackingRule: t('requiredPackingRule'),
    CANCELLED: t('CANCELLED'),
    PICKING: t('PICKING'),
    EXPORTED: t('EXPORTED'),
    PACKAGED: t('PACKAGED'),
    deliveryTime: t('deliveryTime'),
    messeagePicking: t('messeagePicking'),
    bom: t('bom'),
    bomList: t('bomList'),
    caseUOM: t('caseUOM'),
    average: t('average'),
    wastage: t('wastage'),
    createBom: t('createBom'),
    updateBom: t('updateBom'),
    bomCode: t('bomCode'),
    codeNpl: t('codeNpl'),
    producerRule: t('producerRule'),
    cartonRule: t('cartonRule'),
    grossWeightPerCarton: t('grossWeightPerCarton'),
    cartonPerPallet: t('cartonPerPallet'),
    abbottCode16: t('abbottCode16'),
    threeACode: t('threeACode'),
    shortname: t('shortname'),
    nameEn: t('nameEn'),
    UOM_EN: t('UOM_EN'),
    nameVn: t('nameVn'),
    UOM_VN: t('UOM_VN'),
    flavor: t('flavor'),
    tinPerCarton: t('tinPerCarton'),
    brand: t('brand'),
    skuGroup: t('skuGroup'),
    fiveKeybrand: t('fiveKeybrand'),
    countryOfOrigin: t('countryOfOrigin'),
    size: t('size'),
    shelfLifeMonths: t('shelfLifeMonths'),
    typeOfProduct: t('typeOfProduct'),
    ctnsPerLayer3A: t('ctnsPerLayer3A'),
    layersPerPallet3A: t('layersPerPallet3A'),
    ctmsPerPalletStorage3A: t('ctmsPerPalletStorage3A'),
    ctnsPerLayer3AFloor: t('ctnsPerLayer3AFloor'),
    layersPerPallet3AFloor3: t('layersPerPallet3AFloor3'),
    ctnsPerPalletStorage3AFloor4: t('ctnsPerPalletStorage3AFloor4'),
    ctnsPerLayer2Import: t('ctnsPerLayer2Import'),
    layersPerPallet3Import: t('layersPerPallet3Import'),
    ctnsPerPalletImport: t('ctnsPerPalletImport'),
    isPackagedSku: t('isPackagedSku'),
    milkCode: t('milkCode'),
    subBrandFollowAds: t('subBrandFollowAds'),
    typeOfProduct2: t('typeOfProduct2'),
    grossWeightKgPerCase: t('grossWeightKgPerCase'),
    netWeightGramPerTins: t('netWeightGramPerTins'),
    netWeightKgPerCase: t('netWeightKgPerCase'),
    volumeCm3: t('volumeCm3'),
    grossWeightKgPerPallet: t('grossWeightKgPerPallet'),
    netWeightKgPerPallet: t('netWeightKgPerPallet'),
    lengthCartonCm: t('lengthCartonCm'),
    widthCartonCm: t('widthCartonCm'),
    heightCartonCm: t('heightCartonCm'),
    volumeCartonCm3: t('volumeCartonCm3'),
    barcodeTins: t('barcodeTins'),
    barcodeCarton: t('barcodeCarton'),
    itemGroupName: t('itemGroupName'),
    bomGift: t('bomGift'),
    bomGiftlist: t('bomGiftlist'),
    createBomGift: t('createBomGift'),
    updateBomGift: t('updateBomGift'),
    productBomCode: t('productBomCode'),
    umIsFreeItem: t('umIsFreeItem'),
    masterproductPacking: t('masterproductPacking'),
    masterproductPackingList: t('masterproductPackingList'),
    createMasterproductPacking: t('createMasterproductPacking'),
    updateMasterproductPacking: t('updateMasterproductPacking'),
    type: t('type'),
    double: t('double'),
    single: t('single'),
    handWork: t('handWork'),
    area: t('Area'),
    areaList: t('areaList'),
    areaName: t('areaName'),
    areaCode: t('areaCode'),
    areaCreate: t('areaCreate'),
    areaUpdate: t('areaUpdate'),
    locator: t('Locator'),
    locatorList: t('locatorList'),
    locatorName: t('locatorName'),
    locatorCode: t('locatorCode'),
    locatorCreate: t('locatorCreate'),
    locatorUpdate: t('locatorUpdate'),
    apInvoiceList: t('apInvoiceList'),
    apInvoiceCode: t('apInvoiceCode'),
    updateApInvoice: t('updateApInvoice'),
    apInvoiceDetail: t('apInvoiceDetail'),
    productListDivided: t('productListDivided'),
    giftCode: t('giftCode'),
    giftName: t('giftName'),
    UOM: t('UOM'),
    PCSCase: t('PCSCase'),
    specification: t('specification'),
    lengthPcs: t('lengthPcs'),
    widthPcs: t('widthPcs'),
    heightPcs: t('heightPcs'),
    grossWeightPcs: t('grossWeightPcs'),
    volumePcs: t('volumePcs'),
    grossWeight3A: t('grossWeight3A'),
    ctnsPerLayer2Vender: t('ctnsPerLayer2Vender'),
    layersPerPallet3Vender: t('layersPerPallet3Vender'),
    ctnsPerPalletVender: t('ctnsPerPalletVender'),
    grossWeightVender: t('grossWeightVender'),
    isRack: t('isRack'),
    salesChannel: t('salesChannel'),
    saleChannelList: t('saleChannelList'),
    salesChannelCode: t('salesChannelCode'),
    salesChannelName: t('salesChannelName'),
    quota: t('quota'),
    shelfLife: t('shelfLife'),
    createSalesChannel: t('createSalesChannel'),
    updateSalesChannel: t('updateSalesChannel'),
    shelftype: t('shelftype'),
    quantityCartton: t('quantityCartton'),
    quantityTin: t('quantityTin'),
    totalQuantityTinErp: t('totalQuantityTinErp'),
    totalQuantityErp: t('totalQuantityErp'),
    available: t('available'),
    totalQuantityCartton: t('totalQuantityCartton'),
    totalQuantityCartonLabel: t('totalQuantityCartonLabel'),
    totalQuantityTin: t('totalQuantityTin'),
    totalQuantityTinLabel: t('totalQuantityTinLabel'),
    quantityCarttonAtLocation: t('quantityCarttonAtLocation'),
    quantityTinAtLocation: t('quantityTinAtLocation'),
    quantityCarttonTaken: t('quantityCarttonTaken'),
    quantityCarttonLeft: t('quantityCarttonLeft'),
    quantityTaken: t('quantityTaken'),
    quantityLeft: t('quantityLeft'),
    quantityCarttonReceived: t('quantityCarttonReceived'),
    quantityTinReceived: t('quantityTinReceived'),
    company: t('company'),
    branch: t('branch'),
    orderReference: t('orderReference'),
    businessLine: t('businessLine'),
    abbottPOReference: t('abbottPOReference'),
    description: t('description'),
    dateOrdered: t('dateOrdered'),
    giftWarehouse: t('giftWarehouse'),
    customerProgram: t('customerProgram'),
    subChannel: t('subChannel'),
    rcreturn: t('rcreturn'),
    rcinternal: t('rcinternal'),
    rcborrow: t('rcborrow'),
    rcimport: t('rcimport'),
    returnDate: t('returnDate'),
    movementDate: t('movementDate'),
    tranferReason: t('tranferReason'),
    locatorTo: t('locatorTo'),
    movementQuantity: t('movementQuantity'),
    documentNo: t('documentNo'),
    documentType: t('documentType'),
    perpetualInventory: t('perpetualInventory'),
    orderType: t('orderType'),
    consignmentOrder: t('consignmentOrder'),
    borrowed: t('borrowed'),
    enterBorrow: t('enterBorrow'),
    EXPORT_INTERNAL: t('EXPORT_INTERNAL'),
    exportInternalGift: t('exportInternalGift'),
    exportStocktakeAdjustment: t('exportStocktakeAdjustment'),
    exportPhysicalInventory: t('exportPhysicalInventory'),
    adjustExpiredItems: t('adjustExpiredItems'),
    EXPORT_SALE: t('EXPORT_SALE'),
    EXPORT_BORROW: t('EXPORT_BORROW'),
    masterproductsbomgift: t('masterproductsbomgift'),
    createMasterproductsbomgift: t('createMasterproductsbomgift'),
    updateMasterproductsbomgift: t('updateMasterproductsbomgift'),
    masterproductsbomgiftList: t('masterproductsbomgiftList'),
    Vas: t('Vas'),
    completeVasScanSuccessfully: t('completeVasScanSuccessfully'),
    VAS: t('VAS'),
    floor: t('floor'),
    productionOrder: t('productionOrder'),
    productionOrderList: t('productionOrderList'),
    createProductionOrder: t('createProductionOrder'),
    productionOrderDetail: t('productionOrderDetail'),
    confirmProductionOrderProduct: t('confirmProductionOrderProduct'),
    createComboProduct: t('createComboProduct'),
    invoiceNo: t('invoiceNo'),
    orderNo: t('orderNo'),
    shipment: t('shipment'),
    shipmentNo: t('shipmentNo'),
    mfgDate: t('mfgDate'),
    btnlock: t('btnlock'),
    lockproductSuccessfully: t('lockproductSuccessfully'),
    picking: t('picking'),
    expectedQuantityCarton: t('expectedQuantityCarton'),
    expectedQuantityTin: t('expectedQuantityTin'),
    requiredContainerNo: t('requiredContainerNo'),
    locatorFrom: t('locatorFrom'),
    receivedRemainingQuantityCarton: t('receivedRemainingQuantityCarton'),
    receivedRemainingQuantityTin: t('receivedRemainingQuantityTin'),
    orderConfirm: t('orderConfirm'),
    orderConfirmCreate: t('orderConfirmCreate'),
    inventoryManagement: t('inventoryManagement'),
    lDDNo: t('lDDNo'),
    truckType: t('truckType'),
    noPallet: t('noPallet'),
    quantityCarttonRemainder: t('quantityCarttonRemainder'),
    quantityTinRemainder: t('quantityTinRemainder'),
    warehouseZoneDetail: t('warehouseZoneDetail'),
    customerReturn: t('customerReturn'),
    customerReturnList: t('customerReturnList'),
    targetDocType: t('targetDocType'),
    rmaCategory: t('rmaCategory'),
    origSalesOrder: t('origSalesOrder'),
    origDO: t('origDO'),
    DODate: t('DODate'),
    businessPartner: t('businessPartner'),
    billTo: t('billTo'),
    partnerLocation: t('partnerLocation'),
    representative: t('representative'),
    campaign: t('campaign'),
    department: t('department'),
    origDoLine: t('origDoLine'),
    attributeSetInstance: t('attributeSetInstance'),
    unitPrice: t('unitPrice'),
    customerReturnCreate: t('customerReturnCreate'),
    customerReturnDetail: t('customerReturnDetail'),
    billToLocation: t('billToLocation'),
    createReceiptReturn: t('createReceiptReturn'),
    rmaDate: t('rmaDate'),
    availableProduct: t('availableProduct'),
    exportBranch: t('exportBranch'),
    exportBranchGift: t('exportBranchGift'),
    rcBranch: t('rcBranch'),
    HOLD: t('HOLD'),
    AV: t('AV'),
    rcnew: t('rcnew'),
    EXPORT: t('EXPORT'),
    enablehd: t('enablehd'),
    COMPLETED: t('COMPLETED'),
    NORMAL: t('NORMAL'),
    GIFT: t('GIFT'),
    giftInventory: t('giftInventory'),
    inventoryMaterial: t('inventoryMaterial'),
    physicalInventory: t('physicalInventory'),
    stocktaking: t('stocktaking'),
    phyAdj: t('phyAdj'),
    stocktakeAdjustment: t('stocktakeAdjustment'),
    stockAdjustment: t('stockAdjustment'),
    createPhysicalInventory: t('createPhysicalInventory'),
    giftPhysicalInventory: t('giftPhysicalInventory'),
    physicalInventoryGiftDetail: t('physicalInventoryGiftDetail'),
    exportPickingSlip: t('exportPickingSlip'),
    chooseFile: t('chooseFile'),
    truckWeight: t('truckWeight'),
    iddNo: t('iddNo'),
    listARInvoices: t('listARInvoices'),
    internalTransferNote: t('internalTransferNote'),
    internalStockTransferNumber: t('internalStockTransferNumber'),
    internalUseExportNumber: t('internalUseExportNumber'),
    printDate: t('printDate'),
    transferDate: t('transferDate'),
    completionDate: t('completionDate'),
    abbottCode: t('abbottCode'),
    code3A: t('code3A'),
    lotNumberExpiryDate: t('lotNumberExpiryDate'),
    fromWarehouse: t('fromWarehouse'),
    toWarehouse: t('toWarehouse'),
    carton: t('carton'),
    tin: t('tin'),
    damaged: t('damaged'),
    dented: t('dented'),
    missing: t('missing'),
    sum: t('sum'),
    pickingStaff: t('pickingStaff'),
    signatureFullName: t('signatureFullName'),
    warehouseKeeper: t('warehouseKeeper'),
    tableCreator: t('tableCreator'),
    vehicleLoadCapacityTons: t('vehicleLoadCapacityTons'),
    transit: t('transit'),
    totalExportSlips: t('totalExportSlips'),
    threeANutritionLLCVietnam: t('threeANutritionLLCVietnam'),
    date: t('date'),
    month: t('month'),
    year: t('year'),
    pickedProductList: t('pickedProductList'),
    itemNo: t('itemNo'),
    productBatchCode: t('productBatchCode'),
    productGroup: t('productGroup'),
    pickedProducts: t('pickedProducts'),
    entryDate: t('entryDate'),
    importedDate: t('importedDate'),
    canBeTaken: t('canBeTaken'),
    lessThanCurrentCanQuantity: t('lessThanCurrentCanQuantity'),
    lessThanCurrentBoxQuantity: t('lessThanCurrentBoxQuantity'),
    serialNumber: t('serialNumber'),
    goodsReceiptPaymentAdjustmentSlip: t('goodsReceiptPaymentAdjustmentSlip'),
    salesUnit3ANutritionAbbottVietnam: t('salesUnit3ANutritionAbbottVietnam'),
    shippingAddressBinhTan: t('shippingAddressBinhTan'),
    poNumber401550: t('poNumber401550'),
    purchasingUnit: t('purchasingUnit'),
    information: t('information'),
    fullName: t('fullName'),
    deliveryAddress: t('deliveryAddress'),
    billingAddress: t('billingAddress'),
    route: t('route'),
    customerType: t('customerType'),
    salesStaff: t('salesStaff'),
    payment: t('payment'),
    bankTransfer: t('bankTransfer'),
    deliverySlipNumber: t('deliverySlipNumber'),
    purchaseOrderNumber: t('purchaseOrderNumber'),
    orderPlacementDate: t('orderPlacementDate'),
    deliveryDueDate: t('deliveryDueDate'),
    purchaseOrderType: t('purchaseOrderType'),
    purchaseOrderReferenceNumber: t('purchaseOrderReferenceNumber'),
    systemCode: t('systemCode'),
    quantityCansPackagesPack: t('quantityCansPackagesPack'),
    batchNumberExpiryDate: t('batchNumberExpiryDate'),
    invoiceAmountBeforeDiscountInvoiceNumber: t('invoiceAmountBeforeDiscountInvoiceNumber'),
    tradeDiscountInvoiceNumber: t('tradeDiscountInvoiceNumber'),
    cumulativeTradeDiscountPreviousOrdersInvoiceNumber: t('cumulativeTradeDiscountPreviousOrdersInvoiceNumber'),
    programAmountOffsetPayables: t('programAmountOffsetPayables'),
    remainingAmount: t('remainingAmount'),
    deliveryConfirmationOriginalVATInvoice: t('deliveryConfirmationOriginalVATInvoice'),
    copy2GivenToBuyerNoPaymentConfirmation: t('copy2GivenToBuyerNoPaymentConfirmation'),
    customerReceivedFullOrder: t('customerReceivedFullOrder'),
    customerReceivedFullOrderAndProgramPayment: t('customerReceivedFullOrderAndProgramPayment'),
    deliveryStaff: t('deliveryStaff'),
    issueGoodsDeliveryNote: t('issueGoodsDeliveryNote'),
    confirmationSuccessful: t('confirmationSuccessful'),
    warehouseExportSlip: t('warehouseExportSlip'),
    fromDate: t('fromDate'),
    deliveryStaffCode: t('deliveryStaffCode'),
    vehicleNumber: t('vehicleNumber'),
    doCreated: t('doCreated'),
    doNotCreated: t('doNotCreated'),
    materialExport: t('materialExport'),
    newCreate: t('newCreate'),
    exported: t('exported'),
    cancelled: t('cancelled'),
    slipDeletedSuccessfully: t('slipDeletedSuccessfully'),
    materialExportSlipDetails: t('materialExportSlipDetails'),
    warning: t('warning'),
    deliveryRoute: t('deliveryRoute'),
    printedTime: t('printedTime'),
    toDate: t('toDate'),
    totalCarton: t('totalCarton'),
    dailyTransferSequence: t('dailyTransferSequence'),
    checkerStaff: t('checkerStaff'),
    securityGuard: t('securityGuard'),
    slipCreator: t('slipCreator'),
    issueExportSlip: t('issueExportSlip'),
    confirmProceedSure: t('confirmProceedSure'),
    DOCancelledSuccessfully: t('DOCancelledSuccessfully'),
    waitingForPicking: t('waitingForPicking'),
    inPicking: t('inPicking'),
    picked: t('picked'),
    pendingCancellation: t('pendingCancellation'),
    completedShipment: t('completedShipment'),
    areYouSureGroup: t('areYouSureGroup'),
    ordersGroupedSuccessfully: t('ordersGroupedSuccessfully'),
    groupPick: t('groupPick'),
    areYouSureUngroup: t('areYouSureUngroup'),
    ungroupSuccessful: t('ungroupSuccessful'),
    areYouSureAssign: t('areYouSureAssign'),
    assignSuccessful: t('assignSuccessful'),
    selectEmployee: t('selectEmployee'),
    deliveryOrderList: t('deliveryOrderList'),
    areYouSureCancelExportBranch: t('areYouSureCancelExportBranch'),
    cancelSuccessful: t('cancelSuccessful'),
    cancelExportBranch: t('cancelExportBranch'),
    exportXML: t('exportXML'),
    pleaseSelectZone: t('pleaseSelectZone'),
    areYouSureConfirmSlip: t('areYouSureConfirmSlip'),
    slipType: t('slipType'),
    quantityCannotBeZero: t('quantityCannotBeZero'),
    pleaseEnterQuantity: t('pleaseEnterQuantity'),
    actualExportCans: t('actualExportCans'),
    actualImportCans: t('actualImportCans'),
    soDocNo: t('soDocNo'),
    doYouWantToContinue: t('doYouWantToContinue'),
    itemQuantityShort: t('itemQuantityShort'),
    createMaterialExportSlip: t('createMaterialExportSlip'),
    dateFormat: t('dateFormat'),
    licensePlate: t('licensePlate'),
    driverEmployee: t('driverEmployee'),
    exportIWS: t('exportIWS'),
    internalTransferSlipWithInternalTransport: t('internalTransferSlipWithInternalTransport'),
    productSuggestionList: t('productSuggestionList'),
    createDeliveryOrder: t('createDeliveryOrder'),
    createDeliveryOrderFailed: t('createDeliveryOrderFailed'),
    orderWithCode: t('orderWithCode'),
    deliveryOrderCreated: t('deliveryOrderCreated'),
    productNoExpiryDate: t('productNoExpiryDate'),
    productNoLocator: t('productNoLocator'),
    productNoLot: t('productNoLot'),
    deliveryOrderCreatedContinue: t('deliveryOrderCreatedContinue'),
    list: t('list'),
    areYouSureDeleteSlip: t('areYouSureDeleteSlip'),
    availableQuantity: t('availableQuantity'),
    orderProduct: t('orderProduct'),
    lessThanStockQuantity: t('lessThanStockQuantity'),
    quantityIsRequired: t('quantityIsRequired'),
    invalidQuantity: t('invalidQuantity'),
    quantityAvailableToPick: t('quantityAvailableToPick'),
    order: t('order'),
    materialExportSlipList: t('materialExportSlipList'),
    all: t('all'),
    exportBranch1: t('exportBranch1'),
    internalExport: t('internalExport'),
    exportSlipType: t('exportSlipType'),
    updateSuccessful: t('updateSuccessful'),
    selectReceivingWarehouse: t('selectReceivingWarehouse'),
    exportWSlipWithITransport: t('exportWSlipWithITransport'),
    details: t('details'),
    picked1: t('picked1'),
    companyDirector3AVietnam: t('companyDirector3AVietnam'),
    company3AVietnam: t('company3AVietnam'),
    address3AVietnam: t('address3AVietnam'),
    hcm: t('hcm'),
    centecTowerAddress: t('centecTowerAddress'),
    symbol: t('symbol'),
    number: t('number'),
    warehouseExportSlipConvertedFromEWarehouseSlip: t('warehouseExportSlipConvertedFromEWarehouseSlip'),
    basedOnDispatchOrderNo: t('basedOnDispatchOrderNo'),
    onlyOneSelectionAllowed: t('onlyOneSelectionAllowed'),
    suggestion: t('suggestion'),
    areYouSureCreateDOForSelectedOrders: t('areYouSureCreateDOForSelectedOrders'),
    pleaseSelectVehicleType: t('pleaseSelectVehicleType'),
    receivingWarehouse: t('receivingWarehouse'),
    areYouSureCancelProductionOrder: t('areYouSureCancelProductionOrder'),
    locatorIsRequired: t('locatorIsRequired'),
    customerFullName: t('customerFullName'),
    transporterFullName: t('transporterFullName'),
    transportationMode: t('transportationMode'),
    exportAtWarehouse: t('exportAtWarehouse'),
    importAtWarehouse: t('importAtWarehouse'),
    lotNumber: t('lotNumber'),
    expiryDate: t('expiryDate'),
    totalAmount: t('totalAmount'),
    export: t('export'),
    import: t('import'),
    slipCreatorOrConverter: t('slipCreatorOrConverter'),
    warehouseReceivingUnit: t('warehouseReceivingUnit'),
    transportationUnit: t('transportationUnit'),
    warehouseExportUnit: t('warehouseExportUnit'),
    signedBy: t('signedBy'),
    signedDate: t('signedDate'),
    orderedProductList: t('orderedProductList'),
    suggestedProductList: t('suggestedProductList'),

    //configuration bom-list import template
    bomListTemplateColName: t('bomListTemplateColName'),
    bomListTemplateColProducerRule: t('bomListTemplateColProducerRule'),
    bomListTemplateColCartonRule: t('bomListTemplateColCartonRule'),
    bomListTemplateColGrossWeightPerCarton: t('bomListTemplateColGrossWeightPerCarton'),
    bomListTemplateColCartonPerPallet: t('bomListTemplateColCartonPerPallet'),
    expiryDateRequired: t('expiryDateRequired'),
    distributorCode: t('distributorCode'),
    distributorName: t('distributorName'),
    customerCode: t('customerCode'),
    storeName: t('storeName'),
    businessRegistrationName: t('businessRegistrationName'),
    customerTypeCode: t('customerTypeCode'),
    customerTypeName: t('customerTypeName'),
    street: t('street'),
    ward: t('ward'),
    wardCode: t('wardCode'),
    district: t('district'),
    province: t('province'),
    homeInvoiceNo: t('homeInvoiceNo'),
    streetNameInvoice: t('streetNameInvoice'),
    provinceInvoice: t('provinceInvoice'),
    districtInvoice: t('districtInvoice'),
    wardCodeInvoice: t('wardCodeInvoice'),
    country: t('country'),
    dateOfBirth: t('dateOfBirth'),
    channelName: t('channelName'),
    subChannelName: t('subChannelName'),
    nielsenChannelName: t('nielsenChannelName'),
    basedOnInventory: t('basedOnInventory'),
    exportInternal: t('exportInternal'),

    //configuration bom-gift edit form
    vietNameseName: t('vietNameseName'),
    active: t('active'),
    taxCategory: t('taxCategory'),
    lengthPcsUnit: t('lengthPcsUnit'),
    widthPcsUnit: t('widthPcsUnit'),
    heightPcsUnit: t('heightPcsUnit'),
    volumePcsUnit: t('volumePcsUnit'),
    lengthCartonUnit: t('lengthCartonUnit'),
    widthCartonUnit: t('widthCartonUnit'),
    heightCartonUnit: t('heightCartonUnit'),
    grossWeight3AUnit: t('grossWeight3AUnit'),
    volumeCartonUnit: t('volumeCartonUnit'),
    ctnsLayer3AUnit: t('ctnsLayer3AUnit'),
    layerPallet3AUnit: t('layerPallet3AUnit'),
    ctnsPallet3AStorageUnit: t('ctnsPallet3AStorageUnit'),
    ctnsLayer3AFloorUnit: t('ctnsLayer3AFloorUnit'),
    ctnsLayer2VendorUnit: t('ctnsLayer2VendorUnit'),
    layerPallet3VendorUnit: t('layerPallet3VendorUnit'),
    ctnsPalletVendorUnit: t('ctnsPalletVendorUnit'),
    grossWeightVendorUnit: t('grossWeightVendorUnit'),

    //configuration location-list container
    divived: t('divived'),
    shelfTemplateColShelfName: t('shelfTemplateColShelfName'),
    shelfTemplateColPosition: t('shelfTemplateColPosition'),
    shelfTemplateColTotalBin: t('shelfTemplateColTotalBin'),
    shelfTemplateColAverageRate: t('shelfTemplateColAverageRate'),
    shelfTemplateColHighRate: t('shelfTemplateColHighRate'),
    shelfTemplateColVolume: t('shelfTemplateColVolume'),
    shelfTemplateColBlockCode: t('shelfTemplateColBlockCode'),
    shelfTemplateColTierNumber: t('shelfTemplateColTierNumber'),

    updateRackTitle: t('updateRackTitle'),

    bin: t('bin'),
    MATERIAL: t('MATERIAL'),
    driveIn: t('driveIn'),

    //configuration zone modal
    zoneCapacity: t('zoneCapacity'),
    zoneTempImport: t('zoneTempImport'),
    zoneTempExport: t('zoneTempExport'),

    //configuration locator
    pleaseInput: t('pleaseInput'),
    warehouseTo: t('warehouseTo'),

    //configuration sale order create product edit
    requireQuantityInCartonAndTin: t('requireQuantityInCartonAndTin'),
    totalQuantityInCarton: t('totalQuantityInCarton'),
    lessThanStockCarton: t('lessThanStockCarton'),
    quantityInCarton: t('quantityInCarton'),
    totalQuantityInTin: t('totalQuantityInTin'),
    lessThanOneCarton: t('lessThanOneCarton'),
    lessThanStockTin: t('lessThanStockTin'),
    quantityInTin: t('quantityInTin'),

    //configuration sale-order-create container
    pleaseSelectLocator: t('pleaseSelectLocator'),
    create: t('create'),
    //inbound
    giftinventory: t('giftinventory'),
    inventorymaterial: t('inventorymaterial'),
    exportSpickingSlip: t('exportSpickingSlip'),
    on: t('on'),
    created: t('created'),
    btnPicking: t('btnPicking'),
    giftCombo: t('giftCombo'),
    productionGift: t('productionGift'),
    quantityConfigured: t('quantityConfigured'),
    quantityPicking: t('quantityPicking'),
    returnPicking: t('returnPicking'),
    baseProduction: t('baseProduction'),
    notEnough: t('notEnough'),
    packingProduction: t('packingProduction'),
    unpackProduction: t('unpackProduction'),
    CTMSPerPalletStorage3AMsg: t('CTMSPerPalletStorage3AMsg'),
    remainingQuantity: t('remainingQuantity'),
    phieuNhapKho: t('phieuNhapKho'),
    pleaseChooseStore: t('pleaseChooseStore'),
    notYetReceived: t('notYetReceived'),
    notYetCompleted: t('notYetCompleted'),
    apInvoiceCompleteMsg: t('apInvoiceCompleteMsg'),
    productWithCode: t('productWithCode'),
    fullyTakenMsg: t('fullyTakenMsg'),
    giftInventoryReceipt: t('giftInventoryReceipt'),
    validatorTotalQuantityMsg: t('validatorTotalQuantityMsg'),
    notYetAllocated: t('notYetAllocated'),
    allocated: t('allocated'),
    ntaPurchaseOrderList: t('ntaPurchaseOrderList'),
    needToBeEntered: t('needToBeEntered'),
    seller: t('seller'),
    partnerAddress: t('partnerAddress'),
    choosePackError: t('choosePackError'),
    productCondition: t('productCondition'),
    chooseProductCondition: t('chooseProductCondition'),
    packVAS: t('packVAS'),
    searchByStatus: t('searchByStatus'),
    packItems: t('packItems'),
  };
};
