import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Card, Row, Col, Input, Tag, Form, Select } from 'antd';

import { ScanOutlined, SearchOutlined } from '@ant-design/icons';

import './style.less';
import { bookingList } from '../picking/picking.service';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { defaultPage, formatDate, formatDateFull, STATUS_OUTBOUND } from 'utils/constants';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import { packagingOrderSet } from 'providers/OutboundProvider/actions';
import { productPicking } from '../packaging/packaging.service';
import { Option } from 'antd/lib/mentions';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ShippingList = () => {
  const navigate = useNavigate();

  //Roles
  const { currentWarehouse } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);
  const [form] = Form.useForm();
  const [isPackagingDetailModal, setIsPackagingDetailModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //Pagination
  const [params, setParams] = useState({ status: 'PICKING', orderType: 'EXPORT_BRANCH', ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  // const [searchCode, setSearchCode] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [initDataOrders, setInitDataOrders] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    bookingList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        const rempData = result.data?.reduce((prev, curr) => {
          const finded = prev?.find(order => order?.group && order.group === curr.group);
          if (finded) {
            finded.code = `${finded.code}, ${curr.code}`;
            return prev;
          }
          return [...prev, { ...curr }];
        }, []);
        setItems(rempData);
        setInitDataOrders(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    {
      title: lang.orderCode,
      key: 'orderCode',
      // width: '20%',
      // dataIndex: 'code',
      render: rec => <Button
        key="btn-detail"
        type="link"
        onClick={() => navigate(`/dashboard/outbound/sale-order/${rec.id}`)}
        size='small'
      >
        {rec.code}
      </Button>,
    },
    {
      title: lang.truckType,
      key: 'truckType',
      dataIndex: 'truckType',
      hideInTable: params?.orderType !== 'EXPORT_BRANCH',
    },
    {
      title: lang.vehicleLoadCapacityTons,
      key: 'truckWeight',
      dataIndex: 'truckWeight',
      hideInTable: params?.orderType !== 'EXPORT_BRANCH',
    },
    {
      title: `${lang.locator} (${lang.transit})`,
      key: 'locatorTransitCode',
      dataIndex: 'locatorTransitCode',
      hideInTable: params?.orderType !== 'EXPORT_BRANCH',
    },
    {
      title: lang.tranferReason,
      dataIndex: 'tranferReason',
      key: 'tranferReason',
      hideInTable: params?.orderType !== 'EXPORT_BRANCH',
    },
    {
      title: lang.movementDate,
      dataIndex: 'movementDate',
      key: 'movementDate',
      render: (record) => moment(record, formatDateFull).format(formatDate),
      hideInTable: params?.orderType !== 'EXPORT_BRANCH',
    },
    {
      title: lang.company,
      key: 'company',
      dataIndex: 'company',
      hideInTable: params?.orderType === 'EXPORT_BRANCH',
    },
    {
      title: lang.branch,
      key: 'branch',
      // width: '30%',
      dataIndex: 'branch',
      hideInTable: params?.orderType === 'EXPORT_BRANCH',
    },
    {
      title: lang.orderReference,
      dataIndex: 'orderReference',
      key: 'orderReference',
      // width: '20%',
      hideInTable: params?.orderType === 'EXPORT_BRANCH',
    },
    {
      title: lang.businessLine,
      dataIndex: 'bussinessLine',
      key: 'bussinessLine',
      // width: '20%',
      hideInTable: params?.orderType === 'EXPORT_BRANCH',
    },
    {
      title: lang.orderDate,
      dataIndex: 'dateOrdered',
      key: 'dateOrdered',
      // width: '20%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
      sorter: true,
      hideInTable: params?.orderType === 'EXPORT_BRANCH',
    },
    {
      title: lang.orderType,
      dataIndex: 'orderType',
      key: 'orderType',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
      // width: '20%',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '15%',
      render: (record) => {
        return (
          <Tag>{STATUS_OUTBOUND[record]}</Tag>
        );
      },
    },
  ];

  const extraButton = [
    <Button
      key="btn-complete"
      disabled={selectedRowKeys.length === 0}
      type="primary"
      onClick={async () => {
        const orders = initDataOrders?.filter(order => order.group === selectedRowKeys[0]?.group);
        const products = await productPicking({ orderId: selectedRowKeys[0]?.id, warehouseId: currentWarehouse.id, status: 'PICKING' });
        dispatch(packagingOrderSet({
          packagingOrder: { orders },
          pickingProducts: products.data,
        }));
        navigate('scan');
      }}
    >
      {lang.scan}
      <ScanOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/outbound',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/shipping',
      breadcrumbName: lang.shippingOutboundControl,
    },
  ];

  return (
    <Layout className="picking-list">
      <PageHeader
        ghost={false}
        title={lang.shippingOutboundControl}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');
            // if (!cleanValue?.boothCode && !cleanValue?.status) {
            //   cleanValue.status = 'READY';
            // }
            // if (cleanValue?.boothCode) {
            //   cleanValue.status = undefined;
            // }
            setParams({ ...cleanValue, status: 'PICKING', ...defaultPage });
          }}>
            <Row gutter={[16, 16]}>
              <Col xxl={4} sm={12} md={6} xs={16}>
                <Form.Item label={lang.vansCode} name="boothCode">
                  <Input style={{ width: '100%' }} placeholder={lang.vansCode} />
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item label={lang.orderType} name="orderType" initialValue={'EXPORT_BRANCH'}>
                  <Select placeholder={lang.orderType} showSearch allowClear>
                    <Option key={'EXPORT_BRANCH'} value={'EXPORT_BRANCH'}>{lang.exportBranch}</Option>
                    <Option key={'EXPORT_SALE'} value={'EXPORT_SALE'}>{lang.EXPORT_SALE}</Option>
                    <Option key={'EXPORT_BORROW'} value={'EXPORT_BORROW'}>{lang.EXPORT_BORROW}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xxl={4} md={6} sm={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col sm={3}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <Row style={{ marginBottom: 10 }}>
            <Col span={12}><h3>{lang.titlePicking}</h3></Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <TableCustom
                rowKey="id"
                loading={loading}
                bordered
                className="tableCustom"
                columns={columns}
                dataSource={items}
                onChange={(pagination, filters, sorter) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  if (pagination.pageSize !== params.limit) {
                    params.page = 1;
                  } else {
                    params.page = pagination.current;
                  }
                  params.limit = pagination.pageSize;
                  setParams({ ...params });
                }}
                pagination={{
                  showSizeChanger: true,
                  total: totalItems,
                  pageSize: params.limit,
                  current: params.page,
                }}
                scroll={{ x: '800' }}
                rowSelection={{
                  onChange: (_, selectedRows) => {
                    setSelectedRowKeys(selectedRows);
                  },
                }}
              />
            </Col>
          </Row>
        </Card>
      </Layout.Content>
      <Modal
        visible={isPackagingDetailModal}
        title={lang.packagingDetail}
        onCancel={() => setIsPackagingDetailModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
      </Modal>
    </Layout>
  );
};

export default ShippingList;
