import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message, Modal } from 'antd';
// import { LoginOutlined } from '@ant-design/icons';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import './style.less';
import moment from 'moment-timezone';
import { formatDate } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';
// import { suggestLocationByOrder } from 'pages/dashboard/outbound/picking/picking.service';
// import { productScan } from '../product.service';
import { scanProductionOrderProductCodeAdd } from 'providers/InboundProvider/actions';
import { saleorderDetail } from './production-order.service';
import SuggestLocationForm from './suggest-location.form';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ProductionOrderScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pickingOrder } = useSelector((state) => state.outbound); //pickingProducts,
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemPrint, setItemPrint] = useState();
  const [isEffect, setIsEffect] = useState(false);
  const dispatch = useDispatch();
  const onCallbackPrint = (val) => {
    setItemPrint(val);
    setIsPrintModal(true);
  };

  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'barcode',
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.shortname,
      key: 'name',
      render: record => record?.masterProduct?.shortName,
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: val => val ? moment(val).format(formatDate) : '',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: `${lang.quantityCartton} ${lang.picking.tolowerCase()}`,
      key: 'pickingQuantityCartton',
      dataIndex: 'pickingQuantityCartton',
      // render: (record) => record?.quantityInCarton - record?.pickingQuantityCartton
    },
    {
      title: `${lang.quantityTin} ${lang.picking.tolowerCase()}`,
      key: 'pickingQuantityTin',
      dataIndex: 'pickingQuantityTin',
      // render: (record) => record?.quantityTin - record?.pickingQuantityTin
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        dispatch(scanProductionOrderProductCodeAdd([]));
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,
    <Button
      key="btn-suggestLocation"
      type="primary"
      ghost
      onClick={async () => {
        setIsSuggestLocationModal(true)
      }}
    >
      {lang.suggestLocation}
    </Button>,
  ];
  useEffect(() => {
    setLoading(true);
    Promise.all([...pickingOrder?.map(async (order) => {
      if (order?.orderType === "COMMAND_MANUFACTURING") {
        const reponse = await saleorderDetail(order.id)
        return Promise.resolve(reponse?.productOrders?.map(product => {
          const userName = user?.userInfo?.preferred_username;
          return {
            ...product, orderSearchId: reponse?.id, groupOrder: reponse?.group, orderCode: reponse?.code,
            orderType: pickingOrder?.[0]?.orderType, saleChannel: reponse?.channel?.channel, userName: userName,
  
          };
        }));
      }
    })]).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
          return prev;
        }
        return prev.concat(current);
      }, []);
      // console.log(ctx)
      setSelectedOrder([...ctx]);
      setLoading(false);
    }).catch((err) => { message.error(err.message); setLoading(false); });
  }, [pickingOrder, currentWarehouse,isEffect, user.userInfo.preferred_username]);
  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/production-order',
      breadcrumbName: lang.productionOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.scanCode,
    },
  ];

  return (
    <Layout className="storing-scan">
      <PageHeader
        ghost={false}
        title={lang.scanCode}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content style={{ margin: '12px 24px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <ScanProductForm type={'PICKING'} onCallbackPrint={onCallbackPrint} selectOrderProduct={selectedOrder} onCallBackEffect={() => setIsEffect(!isEffect)} />
          </Col>
          <Col span={24}>
            <ProTableCusTom
            title={() => <Row>
              <Col span={12}><h3>{lang.productList}</h3></Col>
            </Row>}
              rowKey="id"
              size="small"
              className="tableCustom"
              bordered
              columns={columnsPickingList}
              loading={loading}
              params={{ selectedOrder }}
              request={async (params) => {
                const { selectedOrder } = params;
                return Promise.resolve({
                  data: selectedOrder,
                  total: selectedOrder.length,
                  success: true,
                });
              }}
              search={false}
              options={{
                search: false,
                reload: false,
                density: false,
              }}
              pagination={{
                showTitle: false,
                showTotal: false,
                pageSize: 10,
              }}
              scroll={{ x: 800 }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => { setIsPrintModal(false); setIsEffect(!isEffect); }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={[itemPrint]}
          // onCallback={(values) => {
          //   Helper.dowloadXLSX(values?.map(item => {
          //     return {
          //       code: item.code,
          //       name: item.masterProduct.name,
          //       receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
          //     };
          //   }), 'productCode');
          //   setIsPrintModal(false);
          //   setIsEffect(!isEffect);
          // }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
        />
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          // await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={pickingOrder}
          totalItems={1}
          productOrders={selectedOrder}
          onCancel={async () => {
            setIsSuggestLocationModal(false);
          }}
          productPicked={[]}
        />
      </Modal>
    </Layout>
  );
};

export default ProductionOrderScan;
