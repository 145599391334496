import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message, DatePicker, Card, Form, Input } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import { saleorderCreate, saleorderDetail } from './production-order.service';
import './style.less';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { locatorList } from 'pages/dashboard/configuration/locator/locator.service';
import ReceiptCreateProductEditForm from './production-order-create-product-edit.form';
import ReceiptProductPackEditForm from './production-order-packing-product-edit.form';
import moment from 'moment-timezone';
import { formatDate, formatDateFull, TYPE_PRODUCTION_ORDER } from 'utils/constants';
import { salesChannelList } from 'pages/dashboard/configuration/sales-channel/sale-channel.service';
import { saleorderList } from './production-order.service';
import { bomList } from 'pages/dashboard/configuration/masster-products-bom-gift/master-products-bom-gift.service';
import ProducttionUnpack from './producttion-order-unpack-edit';
import { productList } from '../../inventory/inventory-management/product.service';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [modalEditUnPack, setModalEditUnPack] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [locators, setLocators] = useState([]);
  const [saleChannels, setSaleChannels] = useState([]);
  const [listProductionPacking, setListProductionPacking] = useState([]);
  const [dataUnPack, setDataUnPack] = useState([]);
  const [listUnPack, setListUnPack] = useState([]);
  const [comboDataSource, setComboDataSource] = useState([]);
  const [masterProductLocator, setMasterProductLocator] = useState([]);
  const [masterProductLocatorFinded, setMasterProductLocatorFinded] = useState([]);
  const [typeOrder, setTypeOrder] = useState();
  const [sortedInfo] = useState(defaultSort);
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse.id }).then(results => setLocators(results?.data));
    salesChannelList({ warehouseId: currentWarehouse.id }).then(rs => setSaleChannels(rs?.data));
    bomList({warehouseId: currentWarehouse?.id})
    .then(result => {
      setComboDataSource(result?.data);
    })
    .catch((e) => message.error(e));
  }, [currentWarehouse]);

  useEffect(() => {
    saleorderList({ ...sortedInfo, orderType: 'COMMAND_MANUFACTURING', warehouseId: currentWarehouse?.id })
      .then(result => {
        const dataOrder = result?.data;
        const listPacking = dataOrder.filter(itemPacking => itemPacking?.typeProductionOrder === 'PACKING' && itemPacking?.status === 'EXPORTED')
        setListProductionPacking(listPacking)
      })
      .catch((e) => message.error(e));
      productList({ ...sortedInfo, warehouseId: currentWarehouse?.id, isAvailableQauntity: true,  })
      .then(result => {
        setMasterProductLocator(result.data);
      })
      .catch((e) => message.error(e));
  }, [sortedInfo, currentWarehouse]);
 
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'code3A',
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'nameEN',
      key: 'productName',
    },
    {
      title: lang.locator,
      key: 'locatorId',
      dataIndex: ['locatorId', 'label'],
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK'
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: typeOrder === 'PACKING' ? 'lotPacking' : 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: typeOrder === 'PACKING' ? 'expireDatePacking' : 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: 'Shelf Life',
      key: 'shelfLife',
      dataIndex: 'shelfLife',
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK'
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityInCarton',
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK'
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantity',
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK'
    },
    {
      title: lang.totalQuantityTin,
      key: 'totalQuantity',
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK',
      render: rec => rec?.quantityInCarton * rec?.tinPerCarton + rec?.quantity
    },
    {
      title: lang.totalQuantityTin,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      hideInTable: typeOrder === 'IMPORTLABEL' || typeOrder === 'PASTCARD',
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: typeOrder === 'PACKING' || typeOrder === 'UNPACK',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              const val = { ...record };
              val.locator = { locator: val?.locatorId?.value };
              val.masterProduct = { ...val };
              val.quantityTin = val.quantity;
              setIsEditModal(true);
              setItem(val);
            }}
          />
        </div>
      ),
    },
  ];

  const columnsUnPack = [
    {
      title: lang.defaultCode,
      dataIndex: 'code3A',
      key: 'code3A',
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct','shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct','nameEN'],
      key: 'productName',
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: lang.quantityTin,
      key: 'totalQuantity',
      dataIndex: 'total'
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              try {
                setModalEditUnPack(true);
                const proTemp = comboDataSource?.find(pro => pro?.code3A === record?.code3A);
                const listProductionGiftUnPack = [];
                const listProductionMasterUnPack = [];
                proTemp.comboData.bomGift.forEach((itemGift, index) => {
                  const locatorIdGift = record?.bundledGift[index]?.locatorId;
                  const locator = locators.find(itemLocator => itemLocator?.id === locatorIdGift);
                  // console.log(locatorCode)
                  itemGift.locator = locator;
                  itemGift.name = itemGift?.name;
                  itemGift.code = itemGift?.code;
                  itemGift.movementQuantity = record?.bundledGift[index]?.quantity;
                  itemGift.warehouseId = currentWarehouse?.id;

                  listProductionGiftUnPack.push(itemGift);
                });

                proTemp.comboData.masterProduct.forEach((itemMaster, indexProMaster) => {
                  const locatorIdMaster = record?.bundledProduct[indexProMaster]?.masterProductLocatorId;
                  const locator = locators.find(itemLocator => itemLocator?.id === locatorIdMaster);
                  itemMaster.code = itemMaster?.code3A;
                  itemMaster.name = itemMaster?.nameEN;
                  itemMaster.locator = locator;
                  itemMaster.lot = record?.bundledProduct[indexProMaster]?.lot;
                  itemMaster.expireDate = record?.bundledProduct[indexProMaster]?.expireDate ? record?.bundledProduct[indexProMaster]?.expireDate : '';
                  itemMaster.movementQuantity = record?.bundledProduct[indexProMaster]?.quantity;
                  itemMaster.warehouseId = currentWarehouse?.id;

                  listProductionMasterUnPack.push(itemMaster)
                });
                const payload = {
                  productUnPack: `${record?.code3A} - ${record?.masterProduct?.nameEN} - ${record?.masterProduct?.shortName}`,
                  totalUnPack: record?.total,
                  listProductionGiftUnPack: listProductionGiftUnPack,
                  listProductionMasterUnPack: listProductionMasterUnPack,
                }
                setListUnPack({...payload, ...record})
              } catch (error) {
                setModalEditUnPack(true);
                message.error(error)
              }
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          // VAS FOR BASE PRODUCT
          if (typeOrder === 'IMPORTLABEL' || typeOrder === 'PASTCARD') {
            const formData = { ...form.getFieldsValue(true) };
            // console.log(formData)
            const saleChannel = saleChannels?.find(sale => sale.id === formData?.channel);
            formData.orderType = 'COMMAND_MANUFACTURING';
              formData.productOrders = items.map(item => {
                // console.log(item)
                return {
                  quantityInCarton: item.quantityInCarton,
                  quantityTin: item.quantity,
                  code3A: item?.code3A,
                  locatorCode: item?.locatorId?.value,
                  locatorCodeTo: formData?.locatorToId?.value,
                  total: item.tinPerCarton * item.quantityInCarton + item.quantity,
                  warehouseId: item?.warehouseId,
                  lot: item?.lot,
                  expireDate: item?.expireDate,
                  productionMaterial: item?.bomProductionIds,
                  isPackagedSku: item?.isPackagedSku
                };
              });
            if (formData?.movementDate) {
              formData.movementDate = formData.movementDate.format(formatDateFull);
            }
            formData.subChannel = saleChannel?.subChannel;
            formData.channel = saleChannel?.channel;
            formData.channelName = saleChannel?.channelName;
            formData.locatorCode = formData.locatorToId?.value;
            formData.creatorId = user?.userInfo?.preferred_username;
            delete formData.locatorToId;
            await saleorderCreate({ ...formData, warehouseId: currentWarehouse.id });
            message.success(lang.createSuccessfully);
            setLoadingBtn(false);
            navigate('/dashboard/inbound/production-order');
          } 
          
          // PACKING, UNPACK
          else {
            const formData = { ...form.getFieldsValue(true) };
            if (!(formData?.locatorToId)) {
              Modal.confirm({
                content: lang.pleaseSelectLocator
              })
              setLoadingBtn(false);
            } else {
              formData.orderType = 'COMMAND_MANUFACTURING';
              formData.productOrders = items.map(item => {
                // console.log(item)
                // console.log(masterProductLocator)
                // const isPackagedSku = masterProductLocator?.find(pro => pro?.code3A === item?.code3A)?.isPackagedSku;
                return {
                  quantityTin: typeOrder === 'UNPACK' ? item.total : item.totalQuantity,
                  quantityInCarton: 0,
                  code3A: item?.code3A,
                  lot: typeOrder === 'UNPACK' ? item?.lot : item?.lotPacking ? item?.lotPacking : '',
                  expireDate:  typeOrder === 'UNPACK' ? item?.expireDate : item?.expireDatePacking ? new Date(item?.expireDatePacking) : '',
                  locatorCode: typeOrder === 'UNPACK' ? formData?.fromLocator?.locatorCode : formData?.locatorToId?.value,
                  locatorCodeTo: formData?.locatorToId?.value,
                  warehouseId: item?.warehouseId,
                  productionMaterial: typeOrder === 'UNPACK' ? item?.productionMaterial : item?.bomProductionIds,
                  bundledGift: typeOrder === 'UNPACK' ? item?.bundledGift : item?.bundledGift,
                  bundledProduct: typeOrder === 'UNPACK' ? item?.bundledProduct : item?.bundledProduct,
                  productGiftChildPack: item?.productGiftChildPack,
                  productChildPack: item?.productChildPack,
                  total: typeOrder === 'UNPACK' ? item.total : item.totalQuantity,
                  isPackagedSku: masterProductLocator?.find(pro => pro?.code3A === item?.code3A)?.isPackagedSku
                };
              });
              formData.locatorCode = formData.locatorToId?.value;
              formData.creatorId = user?.userInfo?.preferred_username;
              delete formData.locatorToId;
              console.log(formData)
              await saleorderCreate({ ...formData, warehouseId: currentWarehouse.id });
              message.success(lang.createSuccessfully);
              setLoadingBtn(false);
              navigate('/dashboard/inbound/production-order');
            }
          }
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }}
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/production-order',
      breadcrumbName: lang.productionOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.createProductionOrder,
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createProductionOrder}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card bodyStyle={{ padding: 12 }}>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{
              movementDate: moment(),
            }}
            form={form}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="typeProductionOrder" label={lang.typeProductionOrder}
                  rules={[
                    {
                      required: true,
                      message: `${lang.requiredTypeProductionOrder}!`,
                    },
                  ]}
                  placeholder={lang.typeProductionOrder}
                  options={TYPE_PRODUCTION_ORDER}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                  onChange={(value) => {
                    setTypeOrder(value);
                    setItems([]);
                    return ''
                  }}
                />
              </Col>
              {
                (typeOrder === ('UNPACK')) && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="packingFrom" label={lang.packingOrderFrom}
                  rules={[
                    {
                      required: true,
                      message: `${lang.packingOrderRequired}!`,
                    },
                  ]}
                  placeholder={lang.packingOrderFrom}
                  options={listProductionPacking.map(proPacking => ({
                    label: `${proPacking?.code}`,
                    value: proPacking?.id,
                    locatorCode: proPacking?.locatorCode
                  }))}
                  fieldProps={{
                    labelInValue: true,
                    onChange: async value => {
                      if (typeOrder && !_.isEmpty(value)) {
                        saleorderDetail(value?.value)
                        .then(rs => {
                          setDataUnPack(rs?.productOrders);
                          const listLocatorInventory = []
                          masterProductLocator.forEach(itemPro => {
                            rs.productOrders.forEach(item => {
                              if (item?.code3A === itemPro?.code3A) {
                                itemPro.masterProductsLocators.forEach(dataRs => listLocatorInventory.push(dataRs))
                              }
                            })
                            return ''
                          });
                          console.log(listLocatorInventory)
                          setMasterProductLocatorFinded(listLocatorInventory)
                        })
                        .catch((e) => message.error(e))
                      }
                    },
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                />
              </Col>
              }
              {
                (typeOrder === ('UNPACK')) && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="fromLocator" label={lang.packingOrderFrom}
                  rules={[
                    {
                      required: true,
                      message: lang.pleaseSelectLocator,
                    },
                  ]}
                  placeholder={lang.packingOrder}
                  options={masterProductLocatorFinded.map(proPackingLocator => ({
                    label: `${proPackingLocator?.locators?.locator} - ${proPackingLocator?.locators?.locatorName}`,
                    value: proPackingLocator?.locators?.locator,
                    locatorCode: proPackingLocator?.locators?.locator
                  }))}
                  fieldProps={{
                    labelInValue: true,
                    // onChange: async value => {
                    //   if (typeOrder && !_.isEmpty(value)) {
                    //     saleorderDetail(value?.value)
                    //     .then(rs => {
                    //       setDataUnPack(rs?.productOrders)
                    //     })
                    //     .catch((e) => message.error(e))
                    //   }
                    // },
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                />
              </Col>
              }
              {(typeOrder === ('PACKING') || typeOrder === ('UNPACK')) && <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="locatorToId" label={lang.toLocator}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredLocator,
                    },
                  ]}
                  placeholder={lang.locatorTo}
                  options={locators?.filter(locator => locator.warehouseId === currentWarehouse?.id)?.map(locator => ({
                    label: `${locator.locatorName} - ${locator.locator}`,
                    value: locator.locator,
                    warehouseId: locator.warehouseId,
                  }))}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                  fieldProps={{
                    labelInValue: true,
                  }}
                />
              </Col>}
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect style={{ fontWeight: 600 }}
                  name="channel" label={lang.salesChannel}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: lang.requiredLocator,
                  //   },
                  // ]}
                  placeholder={lang.salesChannel}
                  options={saleChannels?.map(saleChannel => ({
                    label: `${saleChannel.channelName} - ${saleChannel.channel} - ${saleChannel.customerCode}`,
                    value: saleChannel.channel,
                  }))}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                />
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="movementDate"
                  label={lang.movementDate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="tranferReason"
                  label={lang.tranferReason}
                >
                  <Input placeholder={lang.tranferReason} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{typeOrder === 'UNPACK' ? lang.unpackProductList : lang.productAdded}</h3></Col>
            <Col span={12} style={{ textAlign: 'right'}}>
            {typeOrder !== 'UNPACK' && <Button
                type="primary"
                onClick={ async () => {
                 if (typeOrder === undefined) {
                    Modal.confirm({
                      title: lang.pleaseChooseProductionOrder
                    })
                  } else {
                    setIsEditModal(true);
                    setItem();
                  }
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
            }

            {dataUnPack?.length > 0 && typeOrder === 'UNPACK' && <Button
                type="primary"
                onClick={ async () => {
                setItems(dataUnPack)
                }}
              >
                {'Áp dụng'}
                <PlusOutlined />
              </Button>
            }
              {/* <Button
                type="primary"
                ghost
                style={{ marginLeft: 8 }}
              >
                {lang.importCSV}
              </Button> */}
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.indx) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="indx"
          rowSelection={rowSelection}
          bordered
          columns={typeOrder === 'UNPACK' ? columnsUnPack : columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={typeOrder === 'UNPACK' ? lang.receiptProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={1200}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        {
          (['PACKING', 'UNPACK'].indexOf(typeOrder) === -1) ? <ReceiptCreateProductEditForm
          item={item}
          items={items}
          orderType={typeOrder}
          onCallback={(value) => {
            const filters = items?.filter(item => item.indx !== value.indx);
            setItems([...filters, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
          channel={saleChannels?.find(saleChannel => saleChannel.id === form?.getFieldValue('channel'))}
        /> : <ReceiptProductPackEditForm
        item={item}
        items={items}
        orderType={typeOrder}
        onCallback={(value) => {
          // console.log(value)
          const filters = items?.filter(item => item.indx !== value.indx);
          setItems([...filters, value]);
          setIsEditModal(false);
        }}
        onCancel={() => setIsEditModal(false)}
        channel={saleChannels?.find(saleChannel => saleChannel.id === form?.getFieldValue('channel'))}
      />
        }  
      </Modal>
      <Modal
        visible={modalEditUnPack}
        title={lang.btnEdit + ` ${lang.product}`}
        onCancel={() => setModalEditUnPack(false)}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProducttionUnpack
          item={listUnPack}
          isEditModal={modalEditUnPack}
          onCallBack={async (value) => {
            try {
              // console.log(value) 
              setItems([value])
              setModalEditUnPack(false);
              message.success(`${lang.updateSuccessfully}!`);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => setModalEditUnPack(false)}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptCreate;
