import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Tag, Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import html2canvas from 'html2canvas';
// import jsPdf from 'jspdf';
import { receiptGifttList } from './receiptGift.service';
import _get from 'lodash/get';

import './style.less';
import moment from 'moment';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptGiftList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);

  //Roles
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isInboundStaff = _get(user, 'roles', '').includes('inboundStaff');
  // const isOperator = _get(user, 'roles', '').includes('operator');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isIdm = _get(user, 'roles', '').includes('idm');

  const [items, setItems] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [filterPO, setKeyword] = useState('');

  const [params, setParams] = useState({ 
    ...defaultPage, status: isStocker || isInboundStaff ? 'NEW' : 
    isIdm ? 'TEMPORARYCONFIRM' : 
    isInboundStaff ? '' :
    'RECEIVE', 
    inboundkind: 'NEW' 
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sortBy] = useState('id');
  const [sortDirection] = useState('desc');
  // const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState(isStocker || isInboundStaff ? 'NEW' : isIdm ? 'TEMPORARYCONFIRM' : isInboundStaff ? '' : 'RECEIVE');
  // const [loadingExport, setLoadingExport] = useState(false);
  //
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const onSelectChange = selectedRowKeys => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  const getReceipts = (params, sortBy, sortDirection, currentWarehouse) => {
    setLoading(true);
    receiptGifttList({ ...params, sortBy, sortDirection, warehouseId: currentWarehouse.id, })
      .then(result => {
        result.data.map((value) => {
          value.shipmentNo = value.apInvoice?.shipmentNo;
          return value;
        });
        setItems(result.data);
        setTotalItem(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getReceipts(params, sortBy, sortDirection, currentWarehouse);
  }, [params, sortBy, sortDirection, currentWarehouse]);

  const columns = [
    {
      title: lang.receiptCode,
      // dataIndex: 'code',
      key: 'code',
      render: (record) => {
          return (
            <Button type="link" size="small" onClick={() => navigate(`${record?.id}`)}>{record?.code}</Button>
          );
      },
    },
    {
      title: lang.poCode,
      dataIndex: ['purchaseOrder','documentNo'],
      key: 'documentNo',
    },
    {
      title: lang.inboundKind,
      dataIndex: 'inboundkind',
      key: 'inboundkind',
      render: value => lang[`RC${value}`]
    },
    // {
    //   title: lang.partnerLocation,
    //   dataIndex: 'partnerLocation',
    //   key: 'partnerLocation',
    // },
    {
      title: lang.representative,
      dataIndex: 'representative',
      key: 'representative',
    },
    {
      title: lang.receiptStatus,
      key: 'representative',
      render: record => {
        if (record?.status === 'NEW') {
          return lang.new
        } else if (record?.status === 'TEMPORARYCONFIRM') {
          return lang.stockerConfirmed
        }else if (record?.status === 'DELETED') {
          return lang.cancelled
        } 
        else {
          return lang.idmConfirmed
        }
      }
    },
    {
      title: lang.inboundStatus,
      key: 'representative',
      render: record => {
        if (record?.statusProduct === 'NEW') {
          return lang.new
        } else if (record?.statusProduct === 'INPROGRESSTEMP') {
          return lang.stockReceiving
        }else if (record?.statusProduct === 'DELETED') {
          return lang.cancelled
        }
         else {
          return lang.stockReceived
        }
      }
    },
    {
      title: lang.description,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: lang.createDate,
      dataIndex: 'createDate',
      key: 'createDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.creator,
      dataIndex: 'creator',
      key: 'creator',
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      render: (record) => {
        if (record === 'NEW') {
          return <Tag>{lang.new}</Tag> 
        } else if (record === 'TEMPORARYCONFIRM') {
          return <Tag>{lang.stockerConfirmed}</Tag>
        }else if (record === 'DELETED') {
          return <Tag>{lang.cancelled}</Tag>
        } 
        else {
          return <Tag>{lang.idmConfirmed}</Tag> 
        }
      },
    },
  ];
  const extraButton = [
  ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/receipt-gift',
      breadcrumbName: lang.giftReceipt,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.giftReceipt}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Row gutter={[16, 16]}>
            <Col style={{ marginTop: 4 }}>
              <label>{lang.receiveDate}</label>
            </Col>
            <Col xxl={{ span: 4 }} sm={6} xs={16}>
              <DatePicker.RangePicker
                placeholder={[lang.from, lang.to]}
                onChange={(dates) => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate();
                    setEndDate();
                  }
                }}
                style={{ width: '100%' }}
                format={formatDate}
              />
            </Col>

            {/* <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} />
            </Col> */}
            <Col xxl={{ span: 4 }} sm={6}>
              <Select placeholder={lang.status} defaultValue={status} onChange={(value) => setStatus(value)} style={{ width: '100%' }} allowClear>
              <Option value="">{lang.all}</Option>
                <Option value="NEW">{lang.new}</Option>
                <Option value="TEMPORARYCONFIRM">{lang.stockerConfirmed}</Option>
                <Option value="RECEIVE">{lang.idmConfirmed}</Option>
                <Option value="DELETED">{lang.cancelled}</Option>

              </Select>
            </Col>
            <Col xxl={{ span: 4 }} sm={6}>
              <Input placeholder={lang.keyword} onChange={(value) => setKeyword(value.target.value)} allowClear />
            </Col>
            <Col sm={3}>
              <Button
                type={'primary'}
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  if (status) {
                    Object.assign(params, { status: status });
                  } else {
                    Object.assign(params, { status: '' });
                  }
                  if (startDate) {
                    params.startDate = startDate.startOf('date').format(formatDateFull);
                  } else {
                    delete params.startDate;
                  }
                  if (endDate) {
                    params.endDate = endDate.endOf('date').format(formatDateFull);
                  } else {
                    delete params.endDate;
                  }

                  params.page = 1;
                  setParams({ ...params, filterPO });
                }}
              >{lang.search}</Button>
            </Col>
          </Row>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.receiptGiftList}</h3></Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        receiptDelete({
                          ids: selectedRowKeys,
                        })
                          .then(result => {
                            params.page = 1;
                            setParams({ ...defaultPage, status });
                            setSelectedRowKeys([]);
                            Modal.success({
                              title: lang.deleteReceiptsSuccessfully,
                              content: `${lang.receiptIdsHasBeenDeleted} ${JSON.stringify(selectedRowKeys)}`,
                            });
                          })
                          .catch((e) => message.error(e.message));
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col> */}
          </Row>}
          rowKey="id"
          // rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItem,
            showSizeChanger: true,
            pageSize: params.limit,
            current: params.page,
          }}
          scroll={{ x: 526 }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default ReceiptGiftList;
