import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col } from 'antd';
// import { EditOutlined } from '@ant-design/icons';
// import jsPdf from 'jspdf';
// import html2canvas from 'html2canvas';
import './style.less';
import { arInvoiceDetail } from './arInvoice.service';
import { formatDate } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ArInvoiceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [isEditModal, setIsEditModal] = useState(false);
  // const [item, setItem] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleteProduct, setDeleteProduct] = useState(false);

  useEffect(() => {
    setLoading(true);
    arInvoiceDetail(id)
      .then(result => {
        setSaleorder(result);
        setProductOrder(result?.productARInvoices);
        setLoading(false);
      })
      .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
      .catch((e) => setLoading(false));
  }, [id]);
  const columns = [
    // {
    //   title: lang.productCategory,
    //   dataIndex: ['masterProduct', 'productCategory', 'name'],
    //   key: 'productCategory',
    // },
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: ['masterProduct', 'suppliers'],
      render: (values) => values?.map(value => value.name).join(', '),
    },
    // {
    //   title: lang.quantityCartton,
    //   key: 'quantityInvoice',
    //   render: rec => rec?.quantityInCarton || 0,
    // },
    {
      title: lang.quantityTin,
      key: 'quantityInvoice',
      render: rec => rec?.quantityInvoice || 0,
    },
  ];
  const expandedRowRender = (rec) => {
    const quantityLo = parseInt(rec.quantityInCarton / rec.masterProduct.CTNSPerPalletImport);
    const dataSource = [...Array(2)]?.map((_, index) => {
      const product = { ...rec };
      if (index === 0) {
        product.quantityInCarton = quantityLo * product.masterProduct.CTNSPerPalletImport;
        product.quantityInTin = 0;
      } else {
        product.quantityInCarton = parseInt(rec.quantityInCarton % rec.masterProduct.CTNSPerPalletImport);
      }
      return product;
    });
    return <TableCustom columns={columns} dataSource={dataSource} pagination={false} />;
  };
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/ar-invoice',
      breadcrumbName: 'Ar-invoice',
    },
    {
      path: '',
      breadcrumbName: 'Ar-invoice detail',
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={'Ar-invoice detail'}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.code}>{saleorder?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.orderCode}>{saleorder?.orderCode}</Descriptions.Item>
            <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
            <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
            <Descriptions.Item label={lang.orderReference}>{saleorder?.orderReference}</Descriptions.Item>
            <Descriptions.Item label={lang.businessLine}>{saleorder?.bussinessLine}</Descriptions.Item>
            <Descriptions.Item label={lang.abbottPOReference}>{saleorder?.abbottPOReference}</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
            <Descriptions.Item label={lang.dateOrdered}>{moment(saleorder?.dateOrdere).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.giftWarehouse}>{saleorder?.giftWarehouse}</Descriptions.Item>
            <Descriptions.Item label={lang.customerProgram}>{saleorder?.customerProgram}</Descriptions.Item>
            <Descriptions.Item label={lang.subChannel}>{saleorder?.subChannel}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: record => record.quantityInCarton > record.masterProduct.CTNSPerPalletImport,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ArInvoiceDetail;
