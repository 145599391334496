import request from 'utils/request';
import environment from 'environment';
import qs from 'querystring';

export async function physicalInventoryList(payload) {
  const params = qs.stringify(payload);
  return request(`${environment.api.physicalInventoryGift}/get-all?${params}`, {}, 'GET');
}

export async function physicalInventoryDetail(id) {
  return request(`${environment.api.physicalInventoryGift}/get-one/${id}`, {}, 'GET');
}

export async function physicalInventoryConfirm(id) {
  return request(`${environment.api.physicalInventoryGift}/confirm/${id}`, {}, 'GET');
}

export async function physicalInventoryCreate(payload) {
  return request(environment.api.physicalInventoryGift + '/create', payload, 'POST');
}


export async function getAllBomGift(payload) {
  return request(environment.api.bomGift + '/get-all', payload, 'GET');
}
export async function getAllProudctLocator(payload) {
  return request(environment.api.masterProductsGiftLocators + 'get-all', payload, 'GET');
}