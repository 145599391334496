import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, message as Alert, Modal, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import Helper from 'utils/helpers';
import { salesChannelCreate, salesChannelUpdate } from './sale-channel.service';
import { MODE } from 'utils/constants';

const SalesChannelEditForm = ({ item, onCallback, setIsEditModal, isEditModal }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    ...item,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);

  const onCancel = () => {
    // const valueForm = form.getFieldsValue();
    // const isDefined = Object.values(valueForm).some(value => value !== undefined);
    // const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);

    // if (!isDefined || isUnChange) {
    //   setIsEditModal(false);
    //   return true;
    // }
    // Helper.conFirm(setIsEditModal);
    setIsEditModal(false);
  };

  return (
    <Modal
      visible={isEditModal}
      title={item ? lang.updateSalesChannel : lang.createSalesChannel}
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      width={960}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="productCategoryEditForm"
        requiredMark={false}
        onFinish={(values) => {
          setLoading(true);
          values.warehouseId = currentWarehouse?.id;
          if (!item) { // Create
            salesChannelCreate(values)
              .then(() => {
                Alert.success(lang.createSuccessfully);
                setLoading(false);
                onCallback(MODE.CREATE);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          } else { // Update
            values.id = item?.id;
            salesChannelUpdate(values)
              .then(() => {
                Alert.success(lang.updateSuccessfully);
                onCallback(MODE.EDIT);
                setLoading(false);
              })
              .catch((e) => { setLoading(false); Alert.error(e.message); });
          }
        }}
        initialValues={initValues}
      >
        <Row gutter={[16, 16]}>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="distributorCode"
              label={lang.distributorCode}
            >
              <Input style={{ width: '100%' }} placeholder={lang.distributorCode} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="distributorName"
              label={lang.distributorName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.distributorName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerCode"
              label={lang.customerCode}
            >
              <Input style={{ width: '100%' }} placeholder={lang.customerCode} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="storeName"
              label={lang.storeName}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.storeName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="registerName"
              label={lang.businessRegistrationName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.businessRegistrationName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerTypeCode"
              label={lang.customerTypeCode}
            >
              <Input style={{ width: '100%' }} placeholder={lang.customerTypeCode} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="customerTypeName"
              label={lang.customerTypeName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.customerTypeName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="status"
              label={lang.status}
            >
              <Input style={{ width: '100%' }} placeholder={lang.status} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="street"
              label={lang.street}
            >
              <Input style={{ width: '100%' }} placeholder={lang.street} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="ward"
              label={lang.ward}
            >
              <Input style={{ width: '100%' }} placeholder={lang.ward} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="wardCode"
              label={lang.wardCode}
            >
              <Input style={{ width: '100%' }} placeholder={lang.wardCode} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="district"
              label={lang.district}
            >
              <Input style={{ width: '100%' }} placeholder={lang.district} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="province"
              label={lang.province}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.province} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="homeInvoiceNo"
              label={lang.homeInvoiceNo}
            >
              <Input style={{ width: '100%' }} placeholder={lang.homeInvoiceNo} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="streetNameInvoice"
              label={lang.streetNameInvoice}
            >
              <Input style={{ width: '100%' }} placeholder={lang.streetNameInvoice} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="provinceInvoice"
              label={lang.provinceInvoice}
            >
              <Input style={{ width: '100%' }} placeholder={lang.provinceInvoice} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="districtInvoice"
              label={lang.districtInvoice}
            >
              <Input style={{ width: '100%' }} placeholder={lang.districtInvoice} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="wardCodeInvoice"
              label={lang.wardCodeInvoice}
            >
              <Input style={{ width: '100%' }} placeholder={lang.wardCodeInvoice} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="country"
              label={lang.country}
            >
              <Input style={{ width: '100%' }} placeholder={lang.country} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="geoLocation"
              label='Geo-Location'
            >
              <Input style={{ width: '100%' }} placeholder='Geo-Location' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="dateOfBirth"
              label={lang.dateOfBirth}
            >
              <Input style={{ width: '100%' }} placeholder={lang.dateOfBirth} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="channel"
              label='Channel'
            >
              <InputNumber style={{ width: '100%' }} placeholder='Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="channelName"
              label={lang.channelName}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.channelName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="subChannel"
              label='Sub Channel'
            >
              <Input style={{ width: '100%' }} placeholder='Sub Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="subChannelName"
              label={lang.subChannelName}
            >
              <Input style={{ width: '100%' }} placeholder={lang.subChannelName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="nielsenChannel"
              label='Nielsen Channel'
            >
              <Input style={{ width: '100%' }} placeholder='Nielsen Channel' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="nielsenChannelName"
              label={lang.nielsenChannelName}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.nielsenChannelName} />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="payment"
              label='Payment Method'
            >
              <Input style={{ width: '100%' }} placeholder='Payment Method' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="standardDelivery"
              label='Standard delivery time'
            >
              <Input style={{ width: '100%' }} placeholder='Standard delivery time' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="contractNo"
              label='Contract No'
            >
              <Input style={{ width: '100%' }} placeholder='Contract No' />
            </Form.Item>
          </Col>
          <Col sm={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item
              name="shelfLife"
              label={lang.shelfLife}
            >
              <InputNumber style={{ width: '100%' }} placeholder={lang.shelfLife} />
            </Form.Item>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" onClick={() => form.submit()} style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

SalesChannelEditForm.propTypes = {
  item: PropTypes.object,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default SalesChannelEditForm;
