import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Form, Input } from 'antd';
import { TableOutlined, EditOutlined, CheckOutlined, ScissorOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { productSplit, productUpdateLocation } from '../product.service';
import { receiptDetail, receiptConfirm, updateReceiptProduct, receiptUpdate, deleteProducts, deleteReceiptProduct, splitReceipts, receiptConfirmVirtual } from './receipt.service';
import { productUpdate, locatorList, createPallet, productUpdateLocator } from 'pages/dashboard/inventory/product-management/product-management.service';
import moment from 'moment';
import { formatDate, formatDateFull } from 'utils/constants';
import ReceiptDetailProductEditForm from './receipt-detail-product-edit.form';
import ReceiptProductsPrint from './receipt-products-print';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import _get from 'lodash/get';
import {
  ModalForm,
  ProFormSelect,
  ProFormTextArea,
} from '@ant-design/pro-form';
// import { saleorderDetail } from '../../configuration/sale-order/sale-order.service'

import './style.less';
import TableCustom from 'components/TableCustom/table';
import Helper from 'utils/helpers';
// import numeral from 'numeral';
import ReceiptProductDetail from './receiptproduct-detail';
import logo from 'assets/logoAKAWMS/akawms.svg';
import ProductEditForm from 'pages/dashboard/outbound/picking/product-edit.form';
import ProTableCusTom from 'components/TableCustom/Protable';
import ReceiptProductSplitForm from './receipt-product-split.form';
import ReceiptSplitForm from './receipt-split.form';
// import { Option } from 'antd/lib/mentions';
// import exportFromJSON from 'export-from-json';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ReceiptDetail = () => {
  const { receiptId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExportTallySheet, setLoadingExportTallySheet] = useState(false);
  const [loadingExportBBGNH, setLoadingExportBBGNH] = useState(false);
  const [loadingExportKTCLHN, setLoadingExportKTCLHN] = useState(false);
  const [loadingExportPNK, setLoadingExportPNK] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isManager = _get(user, 'roles', '').includes('manager');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isStaff = _get(user, 'roles', '').includes('inboundStaff');
  const isstaff1 = _get(user, 'roles', '').includes('staff');
  const [form] = Form.useForm();
  const [formCancel] = Form.useForm();
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [printProducts, setPrintProducts] = useState([]);
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [itemsReceiptProduct, setItemsReceiptProduct] = useState([]);
  const [receipt, setReceipt] = useState();
  const [isEditModalReceiptProduct, setIsEditModalReceiptProduct] = useState(false);
  const [receiptProduct, setReceiptProduct] = useState();
  const [isclose, setIsClose] = useState(false);
  const [locatorsList, setLocatorList] = useState([]);
  const [containerNo, setContainerNo] = useState();
  const [description, setDescription] = useState();
  const [isSplit, setIsSplit] = useState(false);
  const [isSplitProductReceipt, setIsSplitProductReceipt] = useState(false);
  const [isSplitReceipt, setIsSplitReceipt] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [prodeuctReceiptSplit, setProdeuctReceiptSplit] = useState([]);
  // const [inboundKindVirtual, setInboundKindVirtual] = useState();
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  useEffect(() => {
    setLoading(true);
    receiptDetail({ receiptId: parseInt(receiptId) })
      .then(result => {
        setReceipt(result);
        setItems(result?.products);
        setItemsReceiptProduct(result?.receiptProduct);
        setContainerNo(result?.boothCode);
        setDescription(result?.description);
        form.setFieldsValue({ locator: result?.locator?.id });
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [receiptId, form]);
  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id }).then(value => setLocatorList(value?.data));
  }, [currentWarehouse]);
  const columns = [
    {
      title: lang.defaultCode,
      key: 'barcode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.masterProduct?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'productName',
      render: (value) => value?.nameEN,
      width: '15%',
    },
    {
      title: 'QR code',
      key: 'code',
      // dataIndex: 'masterProduct',
      render: (value) => value?.code,
    },
    {
      title: 'Lot',
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.shelfLifeMonths,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: lang.quantityCarttonReceived,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTinReceived,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.expectedQuantityCarton,
      key: 'expectedQuantityCarton',
      dataIndex: 'expectedQuantityCarton',
    },
    {
      title: lang.expectedQuantityTin,
      key: 'expectedQuantityTin',
      dataIndex: 'expectedQuantityTin',
    },
    {
      title: lang.totalQuantity,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
    },
    {
      title: lang.productCategory,
      dataIndex: 'masterProduct',
      key: 'productCategory',
      render: (value) => value?.productCategory?.name,
    },
    {
      title: lang.DVT,
      key: 'DVT',
      dataIndex: 'masterProduct',
      render: (value) => value?.UOM_VN,
    },
    {
      title: lang.locator,
      key: 'locator',
      // dataIndex: 'locator',
      // hideInTable: receipt?.inboundKind === 'BRANCH',
      render: rec =>  rec?.locatorFrom && rec?.inboundkind === 'NEW' ? `${rec?.locatorFrom?.locatorName} - ${rec?.locatorFrom?.locator}` : rec.locator !== null ? `${rec?.locator?.locatorName} - ${rec?.locator?.locator}` : '',
    },
    {
      title: lang.locatorFrom,
      key: 'locatorFrom',
      dataIndex: ['locatorFrom', 'locatorName'],
      hideInTable: receipt?.inboundkind !== 'INTERNAL',
    },
    {
      title: lang.block, //['TEMPORARYCONFIRM', 'RECEIVE'].indexOf(receipt?.status) > -1 ? lang.block : lang.block + '(Gợi ý)',
      key: 'block',
      // dataIndex: 'block',
      render: record => {
        if (record?.status === 'NEW') {
          if ( record?.block) {
            return   record?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.block) {
            return   record?.block?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.block?.name
        }

      }
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if ( record?.rack?.shelf) {
            return   record?.rack?.shelf?.name+ ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return record?.rack?.shelf?.name
        }
        
      }
    },
    {
      title: lang.rack,
      key: 'rack',
      render: record => {
        if (record?.status === 'NEW') {
          if (record?.rack) {
            return  record?.rack?.code + ' (Gợi ý)'
          } else {
            return ''
          }
        } else if (record?.status === 'TEMPORARY') {
          if (record?.rack) {
            return  record?.rack?.code + ' (Gợi ý)'
          } else {
            return ''
          }
        } else {
          return  record?.rack?.code
        }
        
      }
    },
    {
      title: lang.zone, 
      key: 'zone',
      hideInTable: receipt?.status === 'RECEIVE',
      render: record => {
        if (record?.status === 'NEW') {
          return  record?.zone?.name + ' (Gợi ý)'
        } else if (record?.status === 'TEMPORARY') {
          return record?.zone?.name
        } else {
          return  'Trong kho'
        }
        
      }
    },
    {
      title: 'Status QAcode',
      key: 'statusQA',
      dataIndex: 'status',
      render: (value) => {
        if (value === 'NEW') {
          return 'Mới tạo'
        } else if (value === 'TEMPORARY'){
          return 'Tạm nhập'
        } else {
          return 'Trong kho'
        }
      },
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: 'supplier',
      render: (value) => value?.name,
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            disabled={ isstaff1}
            icon={<EditOutlined />}
            onClick={() => {
              setItem(record);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];
console.log(items)
  const columnsReceiptProduct = [
    {
      title: lang.defaultCode,
      key: 'barcode',
      dataIndex: ['masterProduct', 'code3A'],
    },
    {
      title: lang.shortname,
      key: 'shortName',
      dataIndex: 'masterProduct',
      render: (value) => value?.shortName,
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'nameEN',
      render: (value) => value?.nameEN,
    },
    {
      title: 'Lot',
      key: 'Lot',
      dataIndex: 'lot',
    },
    {
      title: lang.shelfLifeMonths,
      key: 'expireDate',
      dataIndex: 'expireDate',
      render: (value) => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: lang.locatorFrom,
      key: 'locatorFrom',
      dataIndex: ['locatorFrom', 'locatorName'],
      hideInTable: receipt?.inboundkind !== 'BRANCH',
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
      hideInTable: receipt?.inboundkind === 'RETURN',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTin,
      render: (record) => {
        return record?.quantityCartton * parseInt(record?.masterProduct?.tinPerCarton) + record?.quantityTin;
      },
    },
    {
      title: lang.quantityCarttonReceived,
      key: 'quantityCarttonReceived',
      dataIndex: 'quantityCarttonReceived',
      hideInTable: receipt?.inboundkind === 'RETURN',
    },
    {
      title: lang.quantityTinReceived,
      key: 'quantityTinReceived',
      dataIndex: 'quantityTinReceived',
    },
    {
      title: lang.receivedRemainingQuantityTin,
      key: 'receivedRemainingQuantityTin',
      render: rec => rec?.quantityCartton * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTin - rec?.quantityCarttonReceived * parseInt(rec?.masterProduct?.tinPerCarton) - rec?.quantityTinReceived,
    },
  
    {
      title: lang.size,
      dataIndex: 'masterProduct',
      key: 'netWeightGramPerTins',
      render: (value) => value?.netWeightGramPerTins,
    },
    {
      title: 'Contract No',
      key: 'contract',
      dataIndex: 'contract',
    },
    {
      title: 'PO Reference',
      key: 'poReference',
      dataIndex: 'poReference',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.action,
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            disabled={isIdm || isstaff1 || isStocker}
            key='btn-edit-receipt-product'
            onClick={() => {
              setReceiptProduct(record);
              setIsEditModalReceiptProduct(true);
            }}
          />
          <Button
            key='btn-split-receipt-product'
            type="link"
            size="small"
            icon={<ScissorOutlined />}
            disabled={isIdm || isstaff1 || isStocker}
            onClick={() => {
              // const remaining = items?.reduce((prev, curr) => {
              //   if (record.id === curr.receiptProductId) {
              //     prev.cartons += curr.quantityCartton;
              //     prev.tins += curr.quantityTin;
              //     return prev;
              //   }
              //   return prev;
              // }, { cartons: 0, tins: 0 });
              setReceiptProduct(record);//, quantityCarttonReceived: remaining.cartons + record.quantityCarttonReceived, quantityTinReceived: remaining.tins + record.quantityTinReceived
              setIsSplitProductReceipt(true);
            }}
          />
          <Button
            key='btn-delete-receipt-product'
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            disabled={items?.some(item => record.id === item.receiptProductId) || isstaff1 ||
              (record?.quantityCarttonReceived > 0 || record?.quantityTinReceived > 0) || isStocker}
            onClick={async () => {
              try {
                const findReceiptPro = receipt?.receiptProduct.find(item => item?.id === record?.id);
                if (findReceiptPro) {
                  await deleteReceiptProduct(record?.id);
                }
                receiptDetail({ receiptId: parseInt(receiptId) })
                  .then(result => {
                    setItemsReceiptProduct(result?.receiptProduct);
                    setContainerNo(result?.boothCode);
                  })
                  .catch((e) => setLoading(false));
                message.success(lang.deleteSuccessfully);
                return true;
              } catch (error) {
                message.error(error.message);
                return false;
              }
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
    key="btn-back"
    type="primary"
    ghost
    onClick={() => (navigate(-1))}
    >
      {'Back'}
    </Button>,
    ['TEMPORARYCONFIRM', 'NEW'].indexOf(receipt?.status) > -1 && !isManager && !isStaff && <ModalForm
      title="Hủy phiếu nhập kho"
      trigger={
        <Button
          key="btn-cancel"
          type="primary"
          ghost
        >
          {lang.btnCancel + ' Phiếu'}
        </Button>
      }
      form={formCancel}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        // onCancel: () => console.log('run'),
      }}
      onFinish={async (values) => {
        try {
          setLoading(true);
          if (isStocker && receipt.status === 'NEW' ) {
            receipt.description = values?.description;
            receipt.status = 'NEW';
            delete receipt.products;
            delete receipt.receiptProduct;
            delete receipt.locator;
            delete receipt.locatorCode;
            await receiptUpdate(receipt);
            receiptDetail({ receiptId: parseInt(receiptId) })
              .then(result => {
                setReceipt(result);
                setItems(result?.products);
                setItemsReceiptProduct(result?.receiptProduct);
                setContainerNo(result?.boothCode);
                setDescription(result?.description);
                // form.setFieldsValue({ locator: result?.locator?.id });
                setLoading(false);
              })
              .catch((e) => setLoading(false));
              navigate(-1)
            return true;
          } else if (isIdm && receipt.status === 'TEMPORARYCONFIRM' ) {
            receipt.description = values?.description;
            receipt.status = 'NEW';
            delete receipt.products;
            delete receipt.receiptProduct;
            delete receipt.locator;
            delete receipt.locatorCode;
            await receiptUpdate(receipt);
            receiptDetail({ receiptId: parseInt(receiptId) })
              .then(result => {
                setReceipt(result);
                setItems(result?.products);
                setItemsReceiptProduct(result?.receiptProduct);
                setContainerNo(result?.boothCode);
                setDescription(result?.description);
                form.setFieldsValue({ locator: result?.locator?.id });
                setLoading(false);
              })
              .catch((e) => setLoading(false));
              message.success('Hủy phiếu thành công!')
              navigate(-1)
            return true;
          } else if (!isStocker && receipt.status === 'NEW') {
            Modal.warning({title: 'Không thể hủy phiếu, bạn không phải là Thủ Kho!'});
            setLoading(false);
          } else if (!isIdm && receipt.status === 'TEMPORARYCONFIRM') {
            Modal.warning({title: 'Không thể hủy phiếu, bạn không phải là IDM!'});
            setLoading(false);
          } 
        } catch (error) {
          message.err(error.message);
          setLoading(true);
          return false;
        }
      }}
      key="btn-cancel"
    >
      <ProFormTextArea
        name="description"
        label={lang.description}
        placeholder={lang.description}
      />
    </ModalForm>,
    receipt?.status !== 'RECEIVE' && (isStocker || isStaff || isIdm) && <Button
      key="btn-save"
      type="primary"
      ghost
      onClick={async () => {
        try {
          setLoading(true);
          if (receipt?.inboundkind !== 'BRANCH') {
            await form.validateFields().catch(err => {
              throw new Error(err?.errorFields?.[0]?.errors?.[0]);
            });
            const locatorFind = locatorsList.find(locator => locator.id === form.getFieldValue('locator'));
            receipt.locatorCode = locatorFind.locator;
          }
          
          if (receipt?.inboundkind !== 'INTERNAL' || receipt?.inboundkind !== 'BRANCH') {
            if (!containerNo) throw new Error(lang.requiredContainerNo);
            // if (!inboundKindVirtual) throw new Error('Loại nhập kho là bắt buộc');
          }
            
            receipt.boothCode = containerNo;
            receipt.description = description;
            // receipt.receiptType = inboundKindVirtual; 
            delete receipt.products;
            delete receipt.receiptProduct;
            delete receipt.locator;
          await receiptUpdate(receipt);
          receiptDetail({ receiptId: parseInt(receiptId) })
            .then(result => {
              setReceipt(result);
              setItems(result?.products);
              setItemsReceiptProduct(result?.receiptProduct);
              setContainerNo(result?.boothCode);
              setDescription(result?.description);
              form.setFieldsValue({ locator: result?.locator?.id });
              setLoading(false);
            })
            .catch((e) => setLoading(false));
          setIsEditModal(false);
          message.success(lang.updateSuccessfully);
          return true;
        } catch (error) {
          message.error(error.message);
          setLoading(false);
          return false;
        }
      }}
    >
      {lang.btnSave}
    </Button>,
    receipt?.status === 'NEW' && (isStocker || isStaff) && <Button
    key="btn-splidRec"
    type="primary"
    ghost
    disabled={prodeuctReceiptSplit.length === 0}
    onClick={ async () => {
      //Kiểm tra sản phẩm đã chia có phải là sản phẩm đã chọn để tách hay không?

      const filterProduct = receipt?.products.map(item => item?.masterProduct?.code3A) // Tìm trong danh sách sp đã chia: trả về mảng code3A
      const findProductSelected = prodeuctReceiptSplit?.map(proSelected => proSelected?.masterProduct?.code3A) // Tìm trong danh sách sp : trả về mảng code3A

      const findCode3A = [];
      const findCode3ADiv = [];
      for (const pro of findProductSelected) {
        const check = filterProduct.includes(pro) // Kiểm tra xem có code3A nào trong danh sách sp có tồn tại trong mảng code3A danh sách sp đã chia hay k?
        if (check) {
          findCode3A.push(pro)
        }
      }

      let dataCheck = [];
      // TH đã chia pallet
      if (findCode3A?.length > 0) {
        //Kiểm tra xem SP đã chia hết hay chia còn?

        await prodeuctReceiptSplit?.map(async (proSelected) => {
          const quantityCarttonByProSelected = proSelected?.quantityCartton;
          const quantityTinByProSelected = proSelected?.quantityTin;

          let quantityCarttonByProDiv = 0;
          let quantityTinByProDiv = 0;
          await items?.map(proDiv => {
            if (proDiv?.masterProduct?.code3A === proSelected?.masterProduct?.code3A) {
              quantityCarttonByProDiv += proDiv?.quantityCartton;
              quantityTinByProDiv += proDiv?.quantityTin;
            }
            return '';
          })


          //Chia còn
          if (quantityCarttonByProSelected > quantityCarttonByProDiv || quantityTinByProSelected > quantityTinByProDiv) {
            const remaining = items?.reduce((prev, curr) => {
                if (proSelected.id === curr.receiptProductId) {
                  prev.cartons += curr.quantityCartton;
                  prev.tins += curr.quantityTin;
                  return prev;
                }
                return prev;
              }, { cartons: 0, tins: 0 });
             const result = {...proSelected, quantityCarttonReceived: remaining.cartons + proSelected.quantityCarttonReceived, quantityTinReceived: remaining.tins + proSelected.quantityTinReceived }
            dataCheck.push(result)

          } else {
            findCode3ADiv.push(proSelected?.masterProduct?.code3A)
          }
          return '';
        });

        // TH chọn all sản phẩm và nếu all sp đó đã chia hết
        if (findCode3A?.length > 0 && dataCheck.length === 0) {
          message.warning(`Sản phẩm có mã ${findCode3A} đã chia Pallet!`)
        } 
        // TH chọn all sp nhưng có 1 sp nào đó đã chia hết (đk để biết có sp chia hết: findCode3ADiv?.length > 0)
        else if (findCode3A?.length > 0 && dataCheck.length > 0 && findCode3ADiv?.length > 0) { 
          Modal.confirm({
            title: `Sản phẩm có mã ${findCode3ADiv} đã chia Pallet, bạn có muốn tiếp tục!`,
            onOk() {
              setProdeuctReceiptSplit(dataCheck)
              setIsSplitReceipt(true)
            },
            onCancel() {},
          })
        } 
        // TH chỉ chọn 1 sp và sp đó chia còn
        else if (dataCheck.length > 0) {
          setProdeuctReceiptSplit(dataCheck)
          setIsSplitReceipt(true)
        } else {
          setProdeuctReceiptSplit(dataCheck)
          setIsSplitReceipt(true)
        }

      } else { // TH này là chưa chia bất kì sp nào cả
          setIsSplitReceipt(true)
      }
      
    }}
    >
      {'Tách phiếu'}
    </Button>,
    (isStocker || isIdm) && <Button
    key="btn-export-tallysheet"
    type="primary"
    ghost
    disabled={receipt?.products?.length === 0}
    loading={loadingExportTallySheet}
    onClick={async () => {
      setLoadingExportTallySheet(true);
      const listItems = await receipt?.products;
      const rempData = listItems?.reduce((prev, curr) => {
        if(curr?.locator?.locatorName?.indexOf('Suspense') > -1){
          curr.sampleProduct = curr.quantityCartton * curr.masterProduct.tinPerCarton + curr.quantityTin;
        } else curr.sampleProduct = 0;
        const finded = prev?.find(product => product?.masterProduct?.id === curr?.masterProduct?.id);
        if (finded) {
          if(curr.sampleProduct){
          finded.sampleProduct += curr.sampleProduct;
          return prev;
          }
          finded.sampleProduct += curr.sampleProduct;
          finded.pallet = [...finded.pallet, curr.quantityCartton, curr.quantityTin];
          return prev;
        }
        return [...prev, { ...curr, pallet: [curr.quantityCartton, curr.quantityTin] }];
      }, [])?.map(product => {
        const temp = product?.pallet?.reduce((prev, curr, indx) => {
          if (indx % 2 === 0 && curr > 0)
            prev.arrayCarton = [...prev.arrayCarton, curr];
          else prev.tins += curr;
          return prev;
        }, { arrayCarton: [], tins: 0 });
        product.pallet = [...temp.arrayCarton, temp.tins];
        return product;
      });
      let content = `
      <div style="display: grid ;line-height: 1;width: 960px; font-size: 16px; font-family: Times New Roman, Times, serif; margin-right:50px">
        <div style="display:flex; margin-bottom: 20px;">
          <div style=" width: 20%; border-image: initial;">
              <img src="${logo}" style=" height: 80%; width: 80%" />
          </div>
          <div style=" margin-top: 40px; padding: 10px 0; line-height: 1.25; width: 30%; font-size: 13px; margin-left: -20px">
              <div>
                  Công Ty TNHH Dinh Dưỡng 3A(Việt Nam) <br> 3A Nutrition(Việt Nam)Co. Ltd.
              </div>    
              <div>
                  ${rempData[0].warehouse.address}
              </div>
              <div>
                  Tel: (84.8) 3823 8222
              </div>
              <div>
                  Fax: (84.8) 3823 8777
              </div>
          </div>
          <div style=" margin-top: 40px; margin-left: 30px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 10%; font-size: 14px;"></div>
          <div style=" margin-top: 40px; padding: 10px 0px 10px 10px; line-height: 1.25; width: 40%; font-size: 14px;">
              <div style="border: 2px solid #333; width: 40%; padding: 10px">
                  Code: P-85-02-F1 <br>
                  Issua <br>
                  date: 02/04/2016
              </div>    
          </div>
      
        </div>
        <div style="display: flex; min-height: 88px; clear: both;">
          <div style="width: 100%;  line-height: 1.2; margin-top: -10px">
              <div style="font-size: 26px; font-weight:bold; text-align: center;">PHIẾU KIỂM HÀNG/TALLY SHEET</div>
          
              <div style="display: flex; ">
                  <div style="width: 30.33%;"></div>
                  <div style="width: 30.33%; text-align: right; margin-top:20px">
                      <div style="font-weight:bold;">Ticket No: ${receipt?.code} </div>
                  </div>
                  <div style="width: 30.33%; text-align: right; margin-top:20px">
                      <div style="font-weight:bold; ">Ngày xác nhận: ${moment(receipt?.updateDate, formatDateFull).format(formatDate)}</div>
                  </div>
              </div>
          </div>
        </div>

        <div style="margin-bottom: 20px">
          <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
              <tbody >
                  <tr>
                      <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Container/Truck No (Số Cont/ Số xe): ${receipt.boothCode}</td>
                      <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Day (Ngày): ${moment(receipt?.products[0]?.importDate, formatDateFull).format(formatDate)}</td>
                  </tr>
                  <tr>
                      <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Seal No (Số Seal):</td>
                      <td style="border-bottom: 1px solid #333; padding: 5px 0px 5px 9px;">Receiving time (Giờ nhận):</td>
                  </tr>
                  <tr>
                      <td style="padding: 5px 0px 5px 9px;">Custome clearance No (Tờ khai Hải quan số):</td>
                      <td style="padding: 5px 0px 5px 9px;">Finished time (Giờ hoàn thành):</td>
                  </tr>
              </tbody>
    
          </table>
        </div>

        <table style="border: 2px solid #333; width: 100%; border-collapse: collapse; font-size: 11px">
          <thead>
              <tr style="text-align:center; font-weight: bold;">
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">NO STT</th>
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">PRODUCT NAME <br>Tên sản phẩm</td>
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">LOT NO<br>(Số lô)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">MFG.DATE <br>(Ngày sản xuất)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">EXP.DATE <br>(Hạn dùng)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" colspan="2">SAMPLE<br>(Kiểm mẫu)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" colspan="9">QTY RECEIVED<br>(Số lượng thực nhận)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" colspan="2">QUANTITY<br>(Số lượng)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" rowspan="2">Remark<br>(Ghi chú)</th>
              </tr>
              <tr>
                  <th style=" border: 1px solid #333; padding: 5px;">Quantity<br>(Số lượng)</th>
                  <th style=" border: 1px solid #333; padding: 5px;">Accept<br>(Chấp nhận)</th>
                  <th style=" border: 1px solid #333; padding: 5px;" colspan="7">Số lượng thùng/pallet</th>
                  <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Carton/thùng)</th>
                  <th style=" border: 1px solid #333; padding: 5px;">Total/Tổng số lượng<br>(Tin/lon)</th>
                  <th style=" border: 1px solid #333; padding: 5px;">Denlted/hàng móp<br>(Tin/lon)</th>
                  <th style=" border: 1px solid #333; padding: 5px;">Damaged/hàng hỏng<br>(Tin/lon)</th>
              </tr>
          </thead>
          <tbody>`;
      let totalQuantityTin = 0;
      let totalQuantityCartton = 0;
      let totalCarton = 0;
      let totalTinOdd = 0;
      let totalQuantityTins = 0;
      let totalTinsOdd = 0;
      let totalSamp = 0;
      rempData.forEach((product, index) => {
        const pallet = product?.pallet;
        totalQuantityCartton = product?.pallet?.reduce((prev, curr, ind, arr) => ind === arr.length -1 ? prev : curr + prev, 0);

        totalQuantityTin = product?.pallet?.at(-1);
        totalQuantityCartton += Math.floor(totalQuantityTin / product?.masterProduct?.tinPerCarton);
        totalCarton += totalQuantityCartton;

        totalTinOdd += totalQuantityTin % product?.masterProduct?.tinPerCarton;
        totalTinsOdd += totalTinOdd;
        totalQuantityTins += (product?.masterProduct?.tinPerCarton * totalQuantityCartton) + totalTinOdd;
        totalSamp += product.sampleProduct;
        [...Array(parseInt(Math.ceil(pallet.length / 7))).keys()].forEach((value, indexPallet) => {
          content += `
                  <tr>
                    <td style=" width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? 1 + index : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product.masterProduct.shortName : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 ? product?.lot : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.mfgDate ? moment(product.mfgDate, formatDateFull).format(formatDate) : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product?.expireDate ? moment(product.expireDate, formatDateFull).format(formatDate) : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === 0 && product.sampleProduct ? `${product.sampleProduct}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[0 + indexPallet * 7] ? (indexPallet * 7 < pallet.length - 1) ? `${pallet[0 + indexPallet * 7]}T` : `${pallet[0 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[1 + indexPallet * 7] ? (1 + indexPallet * 7 < pallet.length - 1) ? `${pallet[1 + indexPallet * 7]}T` : `${pallet[1 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[2 + indexPallet * 7] ? (2 + indexPallet * 7 < pallet.length - 1) ? `${pallet[2 + indexPallet * 7]}T` : `${pallet[2 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[3 + indexPallet * 7] ? (3 + indexPallet * 7 < pallet.length - 1) ? `${pallet[3 + indexPallet * 7]}T` : `${pallet[3 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[4 + indexPallet * 7] ? (4 + indexPallet * 7 < pallet.length - 1) ? `${pallet[4 + indexPallet * 7]}T` : `${pallet[4 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[5 + indexPallet * 7] ? (5 + indexPallet * 7 < pallet.length - 1) ? `${pallet[5 + indexPallet * 7]}T` : `${pallet[5 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${pallet[6 + indexPallet * 7] ? (6 + indexPallet * 7 < pallet.length - 1) ? `${pallet[6 + indexPallet * 7]}T` : `${pallet[6 + indexPallet * 7]}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? `${totalQuantityCartton}T,${totalTinOdd}L` : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${indexPallet === parseInt(Math.ceil(pallet.length / 7)) - 1 ? (totalQuantityCartton * product?.masterProduct?.tinPerCarton) + totalTinOdd : ''}</td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
                  </tr>`;
        });
        totalTinOdd = 0;
      });
      content += `
            <tr>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;" colspan="2">TOTAL</td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalSamp}L</td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalCarton}T, ${totalTinsOdd}L</td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityTins}</td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
              <td style=" border: 1px solid #333; padding: 5px; text-align: center;"></td>
            </tr>
          </tbody>
        </table>

        <div style="display: flex">
          <div style="width: 33.33%">
              <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Manager(Quản lý kho hàng)</p>
              <div>
                  <div>(Họ & tên):</div> <br>
                  <span>Date(Ngày):</span>
              </div>
          </div>
          <div style="width: 33.33%;">
              <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Keeper(Thủ kho)</p>
              <div>
                  <div>(Họ & tên):</div> <br>
                  <span>Date(Ngày):</span>
              </div>
          </div>
          <div style="width: 33.33%;">
              <p style="margin-top:20px; padding-bottom: 40px;">Warehouse Staff(Nhân viên nhập hàng)</p>
              <div>
                  <div>(Họ & tên):</div> <br>
                  <span>Date(Ngày):</span>
              </div>
          </div>
        </div>

        <div style="margin-top: 30px; font-weight: bold; font-size: 14px; width: 100%">
            Lưu ý: <i>Ghi vào cột ghi chú nếu Container/xe hàng có các tình trạng sau: màng co mỏng và không phủ lớp trên cùng; Nhiều số lô, hạn dùng trên pallet; Hàng có dấu hiệu bung, hỏng; Pallet hàng xô lệch;
              Thùng hàng móp; Thùng hàng bẩn; Số lô trên thùng không in hoặc không rõ ràng; Sàn xe thủng, rò rỉ hoặc ẩm ướt; Xe vận chuyển có mùi hôi của hải sản, gia cầm, thuốc sâu, côn trùng, ...; Pallet cũ, ẩm mốc, mối mọt, 
              gãy, nứt,...</i>  
        </div>
      </div>`;

      const template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1254, scale: 2 }).then((canvas) => {
        const imgWidth = 210;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 25, 10, imgWidth, imgHeight);
        pdf.save(`TallySheet${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingExportTallySheet(false);
    }}
  >
    {'Xuất TallySheet'}
  </Button>,
    // (isStocker || isIdm)  && <Button
    //   key="btn-export"
    //   type="primary"
    //   onClick={() => {
    //     const dataXml = [
    //       {
    //         Name: "Trieu",
    //         Author: "trieu nguyen",
    //         Subject: "History",
    //         "Publication Date": 1998,
    //         "additional data": {
    //           rating: 10.0,
    //           opinion: "Nice to have"
    //         }
    //       },
    //       {
    //         Name: "L'Ancien Régime et la Révolution",
    //         Author: "Alexis de Tocqueville",
    //         Subject: "History",
    //         "Publication Date": 1866
    //       },
    //       {
    //         Name: "A Brief History of Time",
    //         Author: "Stephen Hawking",
    //         Subject: "Cosmology",
    //         "Publication Date": 1988
    //       },
    //       {
    //         Name: "ooooo",
    //         Author: "ooooooooo",
    //         Subject: "Novel",
    //         "Publication Date": 1995
    //       }
    //     ];
    //     ;
    //     const fileName = "XuatXML";
    //     let fields = {
    //       Name: "Name column header",
    //       Author: "Author column header",
    //       Subject: "Subject column header",
    //       "Publication Date": "Publication Date column header",
    //     };  //empty list means "use all"
    //     const exportType = 'xml';
    //     exportFromJSON({ data: dataXml, fileName, fields, exportType })
    //   }}
    // >
    //   {'Xuất XML'}
    //   <VerticalAlignBottomOutlined />
    // </Button>,
   (isIdm || isStocker) && <Button
      disabled={receipt?.products?.length === 0}
      key="btn-export-pdf"
      type="primary"
      ghost
      loading={loadingExportPNK}
      onClick={async () => {
        setLoadingExportPNK(true);
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        const listProduct = receipt?.products.reduce((prev, curr) => {
          const finded = receipt?.products?.find(pro => pro?.masterProduct?.id === curr?.masterProduct?.id);
          const find = prev?.find(pre => pre?.some(pr => pr?.locator?.locator === curr?.locator?.locator));
          if (find) {
            find.push({
              ...curr,
              warehouse: finded?.warehouse,
            });
            return prev;
          }
          return [...prev, [{
            ...curr,
            warehouse: finded?.warehouse,
          }]];
        }, []);
        await listProduct.forEach((product, index) => {
          var content = '';
          if (product?.[0]?.locator) {
            content = `
            <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif; borderBottom: 1px solid #333; paddingBottom: 30px">
              <div style="display:flex">
                <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 70%; ">
                  <div>
                    <p>
                      <h4>CÔNG TY TRÁCH NHIỆM HỮU HẠN DINH DƯỠNG 3A (VIỆT NAM)</h4>
                    </p>
                  </div>    
                  <div>
                    <p>
                      <span style="font-weight: bold;">Địa chỉ</span>: Centec Towe, 72-74, Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Q.3, TP Hồ Chí Minh.
                    </p>
                  </div>
              </div>
              <div style="margin-top: 20px; text-align:center; margin-left:20px; padding: 10px 0; line-height: 1; width: 30%; font-weight: bold">
                <div>
                  <p>Mẫu số 01 - VT</p>
                </div>
                <div>
                  <p>(Ban hành theo QĐ số 15/2006/QĐ-BTC <br> Ngày 20/03/2006 của Bộ trưởng BTC)</p>
                </div>
              </div>
              </div>
              <div style="display: flex; min-height: 120px;">
                <div style="text-align:center; width: 20%; border-image: initial; margin-top: -60px">
                  <img src="${logo}" style=" height: 100%; width: 100%" />
                </div>
                <div style="width: 60%; text-align: center; line-height: 1.2; margin-top: -10px">
                  <div style="font-size: 30px; font-weight:bold; ">PHIẾU NHẬP KHO</div>`;

            const dateTime = new Date();
            const date = dateTime.getDate();
            const month = (dateTime.getMonth() + 1);
            const year = (dateTime.getFullYear());

            content += `
                      <div style="text-align: center;">${moment(receipt?.updateDate, formatDateFull).format(formatDate )}</div>
                      `;
            content += `
                    <div style="text-align: center;">Số: ${receipt?.code}</div>
                  </div>
                </div>'
            
              <div style="margin-bottom: 10px; font-weight: bold">
                
                <div style="display: flex; margin-top: 10px">
                  <div style = "width: 40%; display: flex; height: 100%;">
                    <div style="width: 60%;">Họ và tên người giao: </div>
                    <div style="border-bottom: 1px solid; width: 90%;"></div>
                  </div>
                  
                </div>
                <div style="display: flex; margin-top: 10px">
                  <div style = "width: 100%; display: flex; height: 100%;">
                    <div style="width: 20%;">Diễn giải: ${receipt?.description ? receipt?.description : ''}</div>
                    <div style="width: 80%">${''}</div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style = "width: 100%; display: flex; height: 100%;">
                    <div style="width: 20%;">Nhập tại kho: </div>
                    <div style="width: 80%;">${product?.[0]?.locatorFrom && receipt?.inboundkind === 'NEW' ? product?.[0]?.locatorFrom?.locatorName : product?.[0]?.locator?.locatorName} - ${product?.[0]?.locatorFrom && receipt?.inboundkind === 'NEW' ? product?.[0]?.locatorFrom?.locator : product?.[0]?.locator?.locator}</div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 10px;">
                  <div style = "width: 100%; display: flex; height: 100%;">
                    <div style="width: 10%">Địa chỉ: </div>
                    <div style="width: 90%; margin-left: 20px">${product?.[0].warehouse.address}</div>
                  </div>
                </div>
              </div>
        
              <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
                <thead>
                  <tr style="text-align:center; font-weight: bold;">
                      <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên, nhãn hiệu, qui cách, phẩm chất vật tư, dụng cụ SP, hàng hóa</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên SP viết tắt</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lô</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Hạn dùng</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">ShipmentNo</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Số HĐ</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Thùng</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Lon</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px;">Mô tả</td>
                    </tr>
                </thead>
                <tbody>`;
            let totalQuantityCarton = 0
            let totalQuantitins = 0
            const listPro = product.reduce((prev, curr) => {
              const fine = prev?.find((item) => item?.lot === curr?.lot && item?.expireDate === curr?.expireDate && item?.masterProduct.code3A === curr?.masterProduct.code3A)
              totalQuantityCarton += curr.quantityCartton;
              totalQuantitins += curr.quantityTin;
              if (fine) {
                fine.quantityCartton += curr?.quantityCartton;
                fine.quantityTin += curr?.quantityTin;
                return prev;
              }
              return [...prev, curr];
            }, [])
            listPro.forEach((pro, indexpro) => {
              return content += `
                    <tr>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + indexpro}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.code3A} - ${pro.masterProduct.nameEN}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.masterProduct.shortName}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.lot}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${moment(pro.expireDate, formatDateFull).format(formatDate)}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.shipment}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.contract}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityCartton}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro.quantityTin}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${pro?.description ? pro?.description : ''}</td>
                    </tr>
                  `;

            })
            content += `<tr>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">Total</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantityCarton}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${totalQuantitins}</td>
                        <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;"></td>
                    </tr>
            </tbody>
              </table>
              <div style="display: flex; padding-top: 100px">
                <div style="width: 25%"></div>
                <div style="width: 25%"></div>
                <div style="width: 25%"></div>
                <div style="width: 25%">
                  <p >Ngày  ${date}  tháng  ${month}  năm  ${year}</p>
                </div>
              </div>
              <div style="display: flex;">
                <div style="width: 25%">
                  <div><p></p></div>
                  <p style="font-weight: bold;">Người lập phiếu</p>
                  <p>(Ký, họ tên)</p>
                </div>
                <div style="width: 25%">
                  <div><p></p></div>
                  <p style="font-weight: bold;">Người giao hàng</p>
                  <p>(Ký, họ tên)</p>
                </div>
                <div style="width: 25%">
                  <div><p></p></div> 
                  <p style="font-weight: bold;">Thủ kho</p>
                  <p>(Ký, họ tên)</p>
                </div>
                <div style="width: 25%; padding-top: 12px;">
                  <p style="font-weight: bold; margin-left: 15px">Kế toán trưởng</p>
                  <p style="margin-left: 18px">(Ký, họ tên)</p>
                </div>
              </div>
             
            </div>`;
            var template = document.createElement('div');
            template.innerHTML = content;
            document.body.appendChild(template);
            html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
              const imgWidth = 208;
              const imgHeight = canvas.height * imgWidth / canvas.width;
              var imgData = canvas.toDataURL('image/png');
              pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
              if (index < listProduct?.length - 1) {
                pdf.addPage();
              }
              if (index === listProduct?.length - 1) {
                pdf.save(`PhieuNhapKho${moment().format('DDMMYYYY')}.pdf`);
              }
            });
            document.body.removeChild(template);
          }
        });
        setLoadingExportPNK(false);
      }}
    >
      {'Xuất PNK'}
    </Button>,
   (isStocker || isIdm) && <Button
    disabled={receipt?.products?.length === 0}
    key="btn-export-bbgnh"
    type="primary"
    ghost
    loading={loadingExportBBGNH}
    onClick={async () => {
      setLoadingExportBBGNH(true);
      // eslint-disable-next-line new-cap
      let content = `
        <div style="display: grid ;line-height: 1;width: 950px; font-size: 18px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
          <div style="display:flex">
            <div style="text-align:center; width: 30%; border-image: initial; margin-top: -60px">
              <img src="${logo}" style=" height: 100%; width: 100%" />
            </div>
            <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 50%; ">
              <div>
                <p>Công Ty TNHH Dinh Dưỡng 3A (Việt Nam)</p>
                <p>3A Nutrition (Việt Nam) Co. Ltd.</p>
                <p>No: 72-74 Nguyen Thi Minh Khai St, Ward 6</p>
                <p>Dist 3, Ho Chi Minh City, Viet nam</p>
                <p>Tel: (84.8) 3823 8222</p>
                <p>Fax: (84.8) 3823 8777</p>
              </div>    
            </div>
            <div style="width: 20%; "></div>
          </div>
          <div style="display: flex; min-height: 120px;">
            <div style="width: 60%; text-align: center; line-height: 1.2;">
              <div style="font-size: 30px; font-weight:bold; ">BIÊN BẢN GIAO NHẬN <br>HÀNG HÓA VẬN CHUYỂN </br> </div>
            </div>
            <div style="margin-top: 20px; text-align:left; margin-left:20px; padding: 4px;width: 25%; font-weight: bold; border: 2px solid #333;">
              <span>Code: P-85-01-F3</span><br>
              <span>Issue date: 02/02/2016</span><br>
              <span>No:</span>
            </div>
          </div>

          <div style="margin-bottom: 10px;">
            <div>
              <p> Hôm nay, Ngày....Tháng....Năm.... Tại.......................................</p>
              <p>Chúng tôi gồm có:</p>
              <div>
                <h3 style="font-weight: bold">Bên nhận hàng:.............................................................</h3>
                <p>Địa chỉ:.............................................Điện thoại:............</p>
                <p>Đại diện bên nhận hàng:.....................................................</p>
                <p><span style="margin-right: 10px">Số container hoặc số xe: ${receipt?.boothCode ? receipt?.boothCode : ''}</span> Số Seal:.............</p>
                <p>Số hợp đồng:.....................</p>
              </div>
              <div>
                <h3 style="font-weight: bold">Bên giao hàng: Công Ty Trách Nhiệm Hữu Hạn Dinh Dưỡng 3A</h3>
                <p>Địa chỉ:</p>
                <p>Đại diện bên giao hàng:.....................................................</p>
              </div>
              <div>
                <p>Hai bên cùng tiến hành giao nhận hàng hóa theo số lượng chi tiết như sau:</p>
              </div>
            </div>    
          </div>

          <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
            <thead>
                <tr style="text-align:center; font-weight: bold;">
                  <td style="width: auto; border: 1px solid #333; padding: 5px;" rowspan="2">STT</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;" rowspan="2">TÊN HÀNG HÓA</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;" colspan="2">SỐ LƯỢNG</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;" rowspan="2">SỐ LƯỢNG PALLET </td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;" rowspan="2">GHI CHÚ</td>
                </tr>
                <tr style="text-align:center; font-weight: bold;">
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">Thùng</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">Lon/hộp/chai/ống/gói/...</td>
                </tr>
            </thead>
            <tbody>`;
            await receipt?.receiptProduct.map((product, index)  => {
              const cartton = product?.quantityCartton;
              const numberPallet = cartton / parseInt(product?.masterProduct?.CTNSPerPalletImport)
              return content += `
                <tr>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${index + 1}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.masterProduct?.nameEN}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.quantityCartton ? product?.quantityCartton : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.quantityTin ? product?.quantityTin : ''}</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${numberPallet % 1 === 0 ? numberPallet : Math.floor(numberPallet) + 1 }</td>
                    <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.description ? product?.description : ''}</td>
                </tr>`;
              });
          content += `
          </tbody>
          </table>
          <div style="margin-top: 8px">
            <p style="font-weight: bold"><u>Ghi chú</u></p>
            <p>Tài xế áp tải hàng khi đến nhận cont phải đối chiếu đúng số CONT và số SEAL ghi trên biên bản</p>
          </div>
          <div style="border: 1px solid #333;">
            <div style="display: flex; border-bottom: 1px solid #333;">
              <div style="width: 50%; border-right: 1px solid #333; padding: 10px">
                <h2>ĐẠI DIỆN BÊN GIAO HÀNG</h2>
                <div style="padding-top: 70px">
                  <p>Họ và tên:</p>
                  <p>Ngày: .....................</p>
                </div>
              </div>
              <div style="width: 50%; padding: 10px">
                <h2>ĐẠI DIỆN BÊN NHẬN HÀNG</h2>
                <div style="padding-top: 70px">
                  <p>Họ và tên:</p>
                  <p>Ngày: .....................</p>
                </div>
              </div>
            </div>
            <div style="padding: 10px">
              <h2>ĐẠI DIỆN KHO 3A NUTRITION (VIỆT NAM) CO. LTD</h2>
              <div style="display: flex">
                <div style="width: 50%">
                  <p>Xe tải đã được niêm phong (Seal checking)</p>
                  <form action="">
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="">
                    <label for="vehicle1">Đảm bảo</label><br><br>
                    <input type="checkbox" id="vehicle2" name="vehicle2" value="">
                    <label for="vehicle2">Không đảm bảo</label><br>
                  </form>
                  <div style="padding-top: 30px">
                    <p>Giờ nhận hàng : .....................</p>
                    <p>Giờ kết thúc  : .....................</p>
                  </div>
                </div>
                <div style="width: 50%">
                  <h2>NGƯỜI NHẬN HÀNG</h2>
                  <div style="padding-top: 70px">
                    <p>Họ và tên:</p>
                    <p>Ngày: .....................</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`
        
      const pdf = new jsPdf('p', 'mm', 'a4');
      const template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`BBGNH${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
      setLoadingExportBBGNH(false);
    }}
  >
    {'Xuất BBGNH'}
  </Button>,
  (isStocker || isIdm) && <Button
  disabled={receipt?.products?.length > 0 && receipt?.products.filter(item => item?.locator?.area.warehouseName === 'Suspense')?.length === 0}
  key="btn-export-ktclhn"
  type="primary"
  ghost
  loading={loadingExportKTCLHN}
  onClick={async () => {
    setLoadingExportKTCLHN(true);
    // eslint-disable-next-line new-cap
    const dataRecipetPro = receipt?.products.find(item => item?.locator?.area.warehouseName === 'Suspense');
    let content = `
    <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
    <div style="display:flex">
      <div style=" margin-top: 20px; line-height: 1; width: 20%;">
        <div style="text-align:center; border-image: initial;">
          <img src="${logo}" style=" height: 100%; width: 100%" />
        </div>
      </div>
      <div style="width: 80%"></div>
      <div style="width: 20%; margin-top: 20px; ">
        <div style="text-align:left; margin-left:20px; font-weight: bold; border: 2px solid #333;">
          <p>Code: P-85-01-F3</p>
          <p>Issue date: </p>
        </div>
      </div>
    </div>
    <div style="margin-top: -40px; margin-bottom: 15px">
      <div style="text-align: center; line-height: 1.2;">
        <div style="font-size: 25px; font-weight:bold; ">QUALITY CHECK FOR NEW SHIPMENT <br> KIỂM TRA CHẤT LƯỢNG HÀNG NHẬP</div>
      </div>
    </div>

    <div>
      <div style="display: flex;">
        <div style="width: 50%;"><p>Truck/ Container No/ Số xe: <span style="font-weight: bold">${receipt?.boothCode ? receipt?.boothCode : ''}</span></p></div>
        <div style="width: 50%;"><p>Date of arrival/ Ngày nhập:<span style="font-weight: bold">${receipt?.createDate ? moment(receipt?.createDate, formatDateFull).format(formatDate) : ''}</span></p></div>
      </div>
      <p>Product/ Sản phẩm: <span style="font-weight: bold">${dataRecipetPro?.masterProduct?.nameEN ? dataRecipetPro?.masterProduct?.nameEN : ''}</span></p>
      <p>Batch number/ Số lô: <span style="font-weight: bold">${dataRecipetPro?.lot ? dataRecipetPro?.lot : ''}</span></p>
      <p>Hạn dùng/ Expiry date: <span style="font-weight: bold">${dataRecipetPro?.expireDate ? moment(dataRecipetPro?.expireDate, formatDateFull).format(formatDate) : ''}</span></p>
      <p>Ngày sản xuất/ Manufacturing date: <span  style="font-weight: bold">${dataRecipetPro?.mfgDate ? moment(dataRecipetPro?.mfgDate, formatDateFull).format(formatDate) : ''}</span></p>
      <p>Quantities arrived/ Số lượng nhập: <span style="font-weight: bold">${dataRecipetPro?.totalQuantity ? dataRecipetPro?.totalQuantity : ''}</span></p>
      <p>Temperature of storage required/ Yêu cầu nhiệt độ bảo quản của hàng hóa:</p>
    </div>    

    <div style="border: 1px solid #333;">
      <div style="border: 1px solid #333;">
        <div style="display: flex; border: 1px solid #333;">
          <div style="width: 60%; font-weight: bold;"><p>I. PRODUCT CHECKED/Kiểm chi tiết sản phẩm</p></div>
          <div style="width: 20%">
            <div style="display: flex;">
              <div style="width: 50%; border-left: 1px solid #333; text-align: center;"><p>Yes</p></div>
              <div style="width: 50%; border-right: 1px solid #333; text-align: center;"><p>No</p></div>
            </div>
          </div>
          <div style="width: 20%; text-align: center;"><p>Remark/ Ghi chú</p></div>
        </div>
      </div>
      <div style="border-bottom: 1px solid #333;">
        <div style="padding: 5px; border-left: 2px solid #333;border-right: 2px solid #333;">1/ Sampling check (visual)/Số lượng mẫu lấy kiểm tra/ tổng SL nhập:</div>
      </div>
      
      <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
        <tr>
          <td style="width: 60%; padding: 5px;">2/ Lot & exp. date unclear/ missing on product/ Số lô, hạn dùng thiếu, không rõ ràng</td>
          <td style="width: 10%;"></td>
          <td style="width: 10%;"></td>
          <td style="width: 20%; text-align: center;"></td>
        </tr>
        <tr>
          <td style="padding: 5px;">3/ Wrong Lot & exp. date/ Sai số lô, hạn dùng</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">4/ Wrong commodity number/ Sai số commodity # (ghi số commodity vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">5/ Wrong list number/ Sai số list # (ghi số list number vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">6/ Wrong product Registration no./ Có sai số công bố sản phẩm (ghi số công bố vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">7/ Wrong product inside shipper/ Có sản phẩm sai bên trong lô hàng (ghi rõ tên sản phẩm sai vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">8/ Wrong quantity and pack size/ Sai số lượng và qui cách đóng gói (ghi rõ qui cách đóng gói vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">9/ Glue tape broken/ Rách băng keo thùng</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">10/ Dirty packet, tin/ Hộp sữa bẩn</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">11/ Smell in the carton/ brick/ packet/ thùng/ hộp sữa có mùi</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">12/ Sign of damages e.g. leakages/ Có dấu hiệu hư hỏng (rò rỉ, mốc,..)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">13/ Sign of insects/Có dấu hiệu côn trùng (ghi rõ tên côn trùng nếu biết vào cột ghi chú)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">14/ Wet product/sản phẩm bị ướt</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">15/ Rusty product/ sản phẩm có dấu hiệu rỉ sét</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr style="border-top: 2px solid #333;">
          <td style="padding: 5px; font-weight: bold; font-size: 18px; border-bottom: 2px solid #333; border-left: 2px solid #333;">II. SỮA NƯỚC (HỘP GIẤY) - RTD</td>
          <td style="border-left: 2px solid #333; border-right: 2px solid #333; border-bottom: 2px solid #333;"></td>
          <td style="border-right: 2px solid #333; border-bottom: 2px solid #333;"></td>
          <td style="border-right: 2px solid #333; border-bottom: 2px solid #333;"></td>
        </tr>
        <tr>
          <td style="padding: 5px;">16/ Blown/ Phồng: </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">17/ Dented or deformed packets/ Móp:</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">18/ Box edge burst/ Bung tai</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">19/ Label or packet defects/ Trầy nhãn, nhãn đậm, nhãn lợt hoặc nhãn lem</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">20/ Brick Shrink wrap defects/ Màng co rách, lỏng, bị lỗi </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 5px;">21/ Missing or damaged Straw/ Thiếu ống hút, ống hút dính keo, ống hút không đúng vị trí</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <div style="padding: 10px;">
        <div style="display: flex;">
          <div style="width: 70%;">
            <div>
              <b>Accept/ Đạt chuyển qua kho hàng để bảo quản hoặc dán nhãn lại cho phù hợp với qui định Quarantine for futher advice from principal/ Biệt trữ chờ ý kiến hãng</b>
              <div><b>Remark</b>/ Nhận xét (nếu có):</div>
            </div>
          </div>
          <div style="width: 30%; text-align: right; ">
            <input type="checkbox" style="width: 50%;"><br>
            <input type="checkbox" style="width: 50%;">
          </div>
        </div>
      </div>
      <div style="border-top: 1px solid #333 ;">
        <div style="display: flex;">
          <div style="width: 40%; border-right: 1px solid #333; padding-bottom: 60px; padding-top: 10px;">
            <div><u>Checked by/ Kiểm tra bởi</u></div>
            <div><b>QA staff/ Ngày</b></div>
          </div>
          <div style="width: 60%; text-align: center; padding-bottom: 60px; padding-top: 10px;">
            <div><u>Verified by/ Kiểm tra lại</u></div>
            <div style="font-weight: bold"><u>QA Supervisor/ Manager/ Ngày</u></div>
          </div>
        </div>
      </div>
    </div>
</div>`
      
    const pdf = new jsPdf('p', 'mm', 'a4');
    const template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`KTCLHN${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
    setLoadingExportKTCLHN(false);
  }}
>
  {'Xuất PKTCLHN'}
</Button>,
    !isstaff1 && <Button
      key="btn-complete"
      type="primary"
      disabled={(isStocker && !(receipt?.status === 'NEW')) || (isStocker && (receipt?.status === 'TEMPORARYCONFIRM')) || (isIdm && !(receipt?.status === 'TEMPORARYCONFIRM')) || (isIdm && (receipt?.status === 'NEW')) || (isStaff || isManager) || receipt?.products?.length === 0}
      onClick={() => {
        try {
          if (receipt?.status === 'NEW') {
            Modal.confirm({
              title: 'Bạn có chắc chắn muốn xác nhận?',
              icon: <ExclamationCircleOutlined />,
              async onOk() {
                //Lúc này Thủ ko vào xác nhận để chuyển status sang TEMPORARYCOMFIRM
                try {
                  if (receipt?.inboundkind !== 'BRANCH') {
                    if (!(form.getFieldValue('locator'))) {
                      throw new Error('Locator bắt buộc có!');
                    } else {
                      const locatorFind = locatorsList.find(locator => locator.id === form.getFieldValue('locator'));
                      receipt.locatorCode = locatorFind.locator;
                    }
                  }
                  
                  if (receipt?.inboundkind !== 'INTERNAL' || receipt?.inboundkind !== 'BRANCH') {
                    if (!containerNo) throw new Error(lang.requiredContainerNo);
                    // if (!inboundKindVirtual) throw new Error('Loại nhập kho là bắt buộc');
                  }
                  // Là kho ảo
                  if (currentWarehouse?.id === 2  || currentWarehouse?.id === 3) {
                    // if (receipt.receiptType) {
                      receipt.boothCode = containerNo;
                      receipt.description = description;
                      receipt.status = 'TEMPORARYCONFIRM';
                      receipt.warehouseId = currentWarehouse?.id;
                      receipt.receiptType = 'NORMAL'; // phải có thằng này để BE check vì lựa chọn loại nhập kho chỉ hiển thị ở kho ảo, các kho R1, CDC k hiển thị nên mặc định nó phải là NORMAL
                      delete receipt.products;
                      delete receipt.receiptProduct;
                      delete receipt.locator;
                      await receiptUpdate(receipt);
                      receiptDetail({ receiptId: parseInt(receiptId) })
                        .then(result => {
                          setReceipt(result);
                          setItems(result?.products);
                          setItemsReceiptProduct(result?.receiptProduct);
                          setContainerNo(result?.boothCode);
                          setDescription(result?.description);
                          form.setFieldsValue({ locator: result?.locator?.id });
                          setLoading(false);
                        })
                        .catch((e) => setLoading(false));
                      setIsEditModal(false);
                      message.success(lang.confirmSuccessfully);
                      navigate('/dashboard/inbound/receipt');
                    // } 
                    // else {
                    //   message.error('Loại nhập kho chưa được lưu lại')
                    // }
                  } else {
                      receipt.boothCode = containerNo;
                      receipt.description = description;
                      receipt.status = 'TEMPORARYCONFIRM';
                      receipt.warehouseId = currentWarehouse?.id
                      receipt.receiptType = 'VIRTUAL' // phải có thằng này để BE check vì lựa chọn loại nhập kho chỉ hiển thị ở kho ảo, các kho R1, CDC k hiển thị nên mặc định nó phải là NORMAL
                      delete receipt.products;
                      delete receipt.receiptProduct;
                      delete receipt.locator;
                      await receiptUpdate(receipt);
                      receiptDetail({ receiptId: parseInt(receiptId) })
                        .then(result => {
                          setReceipt(result);
                          setItems(result?.products);
                          setItemsReceiptProduct(result?.receiptProduct);
                          setContainerNo(result?.boothCode);
                          setDescription(result?.description);
                          form.setFieldsValue({ locator: result?.locator?.id });
                          setLoading(false);
                        })
                        .catch((e) => setLoading(false));
                      setIsEditModal(false);
                      message.success(lang.confirmSuccessfully);
                      navigate('/dashboard/inbound/receipt');
                  }
                } catch (error) {
                  message.error(error.message);
                }
              },
              onCancel() { },
            });
          } else if (receipt?.status === 'TEMPORARYCONFIRM') {
            //Lúc này Idm vào xác nhận để tăng tồn
            if (receipt?.receiptType === 'NORMAL') {
              Modal.confirm({
                title: 'Bạn có chắc chắn muốn xác nhận?',
                icon: <ExclamationCircleOutlined />,
                async onOk() {
                  try {
                    await receiptConfirm({
                      id: parseInt(receiptId),
                      zoneId: form.getFieldValue('zone'),
                      locatorId: form.getFieldValue('locator'),
                      warehouseId: currentWarehouse?.id,
                      receiptType: 'NORMAL',
                      products: items.map(item => {
                        return {
                          id: item.id,
                          quantity: item.totalQuantity,
                        };
                      }),
                    });
                    message.success(lang.confirmSuccessfully);
                    navigate('/dashboard/inbound/receipt');
                  } catch (error) {
                    message.error(error.message);
                  }
                },
                onCancel() { },
              });
            } else if (receipt?.receiptType === 'VIRTUAL') {
              Modal.confirm({
                title: 'Bạn có chắc chắn muốn xác nhận?',
                icon: <ExclamationCircleOutlined />,
                async onOk() {
                  try {
                    await receiptConfirmVirtual({
                      id: parseInt(receiptId),
                      zoneId: form.getFieldValue('zone'),
                      locatorId: form.getFieldValue('locator'),
                      warehouseId: currentWarehouse?.id,
                      receiptType: receipt?.receiptType,
                      products: items.map(item => {
                        return {
                          id: item.id,
                          quantity: item.totalQuantity,
                        };
                      }),
                    });
                    message.success(lang.confirmSuccessfully);
                    navigate('/dashboard/inbound/receipt');
                  } catch (error) {
                    message.error(error.message);
                  }
                },
                onCancel() { },
              });
            }
          } 
        } catch (error) {
          if (error?.message) {
            message.error(error.message);
          }
        }
      }}
    >
      {lang.btnConfirm}
      <CheckOutlined />
    </Button>,
  ];
  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/receipt',
      breadcrumbName: lang.receipt,
    },
    {
      path: '',
      breadcrumbName: lang.receiptDetail,
    },
  ];
  return (
    <Layout className="receipt-detail layoutContent">
      <PageHeader
        ghost={false}
        title={lang.receiptDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions className="descriptions-class" column={{ xl: 3, md: 3, sm: 2, xs: 1 }}>
            <Descriptions.Item label={lang.receiptCode}>{receipt?.inboundkind === 'RETURN' ? receipt?.documentNo : receipt?.code}</Descriptions.Item>
            <Descriptions.Item label={lang.inboundKind}>{lang[`RC${receipt?.inboundkind}`]}</Descriptions.Item>
            <Descriptions.Item label={lang.businessPartner}>{receipt?.businessPartner}</Descriptions.Item>
            {receipt?.inboundkind === 'NEW' && <Descriptions.Item label={lang.shipmentNo}>{receipt?.receiptProduct[0]?.shipment}</Descriptions.Item>}
            <Descriptions.Item label={lang.receiveDate}>{moment(receipt?.receiptDate, formatDateFull).format(formatDate)}</Descriptions.Item>
            {receipt?.inboundkind === 'RETURN' && <Descriptions.Item label={lang.returnDate}>{moment(receipt?.rmaDate, formatDateFull).format(formatDate)}</Descriptions.Item>}
            <Descriptions.Item label={lang.receiptStatus}>{
              receipt?.status === 'NEW' ? lang.new
                  : receipt?.status === 'TEMPORARYCONFIRM' ? lang.stockerConfirmed
                    : lang.idmConfirmed
            }</Descriptions.Item>
            <Descriptions.Item label={lang.inboundStatus}>{
              receipt?.statusProduct === 'NEW' || receipt?.statusProduct === null ? lang.new
                  : receipt?.statusProduct === 'INPROGRESS' ? lang.stockReceiving
                    : lang.stockReceived
            }</Descriptions.Item>
            <Descriptions.Item label={lang.creatorName}>{receipt?.creatorName}</Descriptions.Item>
            {receipt?.inboundkind === 'BRANCH' && <Descriptions.Item label={lang.orderCode} >{receipt?.orderNumber}</Descriptions.Item>}
            <Descriptions.Item label={lang.description}>
              <Input disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker || isIdm))}
                name='description' onChange={(val) => setDescription(val.target.value)} value={description}></Input>
            </Descriptions.Item>
            <Descriptions.Item label={lang.containerNo}>
              <Input disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker || isIdm))} name='container' onChange={(val) => setContainerNo(val.target.value)} style={{ width: '80%' }} value={containerNo}></Input>
            </Descriptions.Item>
            {receipt?.status === 'RECEIVE' && <Descriptions.Item label={lang.erpIntegration}>{receipt?.statusERP}</Descriptions.Item>}
            {/* { 
            (receipt?.receiptType ? <Descriptions.Item label={'Loại nhập kho'} >
              <Form form={form}>
                <Select 
                  allowClear 
                  style={{ width: '250%' }} 
                  onChange={(value) => { setInboundKindVirtual(value); }}
                  value={receipt?.receiptType}
                  >
                  <Option value="NORMAL">{'NORMAL'}</Option>
                  <Option value="VIRTUAL">{'VIRTUAL'}</Option>
                </Select>
              </Form>
            </Descriptions.Item> :
            <Descriptions.Item label={'Loại nhập kho'} >
            <Form form={form}>
              <Select 
                allowClear 
                style={{ width: '250%' }} 
                onChange={(value) => { setInboundKindVirtual(value); }}
                >
                <Option value="NORMAL">{'NORMAL'}</Option>
                <Option value="VIRTUAL">{'VIRTUAL'}</Option>
              </Select>
            </Form>
          </Descriptions.Item>)} */}
            {receipt?.inboundkind !== 'BRANCH' && <Descriptions.Item >
              <Form form={form} style={{ width: '80%' }} >
                <ProFormSelect style={{ fontWeight: 600, width: '80%' }}
                  disabled={receipt?.status === 'RECEIVE' || (receipt?.status === 'TEMPORARYCONFIRM' && (isStocker))}
                  name="locator" label={lang.locator}
                  placeholder={lang.locator}
                  rules={[
                    {
                      required: true,
                      message: lang.requiredLocator,
                    },
                  ]}
                  options={
                    receipt?.inboundkind === 'NEW' ? locatorsList.filter(item => (item?.area?.warehouseName === 'Hold' || item?.area?.warehouseName === 'Suspense')).map(locator => {
                      return {
                        value: locator.id,
                        label: `${locator.locatorName} - ${locator.locator}`,
                      };
                  }) :
                  locatorsList.map(locator => {
                    return {
                      value: locator.id,
                      label: `${locator.locatorName} - ${locator.locator}`,
                    };
                })}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                />
              </Form>
              </Descriptions.Item>}
            </Descriptions>
        </Card>
        <TableCustom
          title={() =>
            <Row>
              <Col span={12}><h3>{lang.productList}</h3></Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsReceiptProduct}
          dataSource={itemsReceiptProduct}
          pagination={false}
          scroll={{ x: 3200 }}
          rowSelection={(receipt?.status === 'NEW') && {
            onChange: (_, rowSelected) => {
              setProdeuctReceiptSplit(rowSelected)
            }
          }}
        />
        <ProTableCusTom
          headerTitle={lang.productListDivided}
          toolBarRender={() => [
            (isStaff) && <Button
              key="btn-delete"
              type="primary"
              ghost
              onClick={async () => {
                Modal.confirm({
                  title: 'Bạn có chắc chắn muốn xóa sản phẩm?',
                  icon: <ExclamationCircleOutlined />,
                  async onOk() {
                    try {
                      setLoading(true);
                      const totalProductSelected = items?.length
                      await deleteProducts({ ids: selectedRowKeys, totalProductSelected: totalProductSelected });
                      await receiptDetail({ receiptId: parseInt(receiptId) })
                        .then(result => {
                          setReceipt(result);
                          setItems(result?.products);
                          setItemsReceiptProduct(result?.receiptProduct);
                          setContainerNo(result?.boothCode);
                          setDescription(result?.description);
                          form.setFieldsValue({ locator: result?.locator?.id });
                          setLoading(false);
                        })
                        .catch((e) => setLoading(false));
                      setSelectedRowKeys([]);
                      message.success(lang.deleteSuccessfully);
                      return true;
                    } catch (error) {
                      message.error(error.message);
                      return false;
                    }
                  },
                  onCancel() { },
                });
              }}
              disabled={selectedRowKeys.length === 0 || isstaff1}
            >
              {lang.btnDelete}
            </Button>,
            (isStaff) && <Button
              key="btn-print"
              type="primary"
              disabled={isIdm}
              ghost
              onClick={() => {
                setPrintProducts(items);
                setIsPrintModal(true);
              }}
            >
              {lang.print}
            </Button>,
            (isStaff) && <Button
              key="btn-updatelocation"
              type="primary"
              onClick={() => {
                const productIds = items?.filter(item => !item?.rack).map(item => item.id);
                productUpdateLocation({ productIds })
                  .then(result => {
                    Modal.success({
                      title: lang.updateLocationSuccessfully,
                      icon: <CheckOutlined />,
                      onOk() {
                        setLoading(true);
                        receiptDetail({ receiptId: parseInt(receiptId) })
                          .then(result => {
                            setItems(result?.products.map(product => {
                              if (items.map(item => item.id).includes(product.id)) {
                                const find = items.find(x => x.id === product.id);
                                return {
                                  ...product,
                                  totalQuantity: find?.totalQuantity,
                                };
                              } else {
                                return product;
                              }
                            }));
                            setLoading(false);
                          })
                          .catch((e) => setLoading(false));
                      },
                    });
                  })
                  .catch((e) => {
                    message.error(e.message);
                    receiptDetail({ receiptId: parseInt(receiptId) })
                      .then(result => {
                        setReceipt(result);
                        setItems(result?.products);
                        setLoading(false);
                      })
                      .catch((e) => setLoading(false));
                  },
                  );
              }}
              disabled={items.length === 0 || isstaff1}
            >
              {lang.updateLocation}
              <TableOutlined />
            </Button>]}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            pageSize: 10,
            showTotal: (total, range) => `${range.join('-')} trên ${total} pallet`
          }}
          scroll={{ x: 3500 }}
          rowSelection={(isStaff) && rowSelection}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        // afterClose={() => {
        //   formEdit.resetFields();
        // }}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptDetailProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              const rackId = item?.id === value?.id ? item?.rack?.id : ''
              setLoading(true);
              await productUpdate({ id: value.id, expireDate: value.expireDate, note: value.note, quantityCartton: value.quantityCartton, quantityTin: value.quantityTin, locatorId: value.locatorId, status: value.status, rackId: rackId });
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItems(result?.products);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => { setIsEditModal(false); }}
          onPrint={() => {
            setPrintProducts([item]);
            setIsPrintModal(true);
          }}
          onSplit={async (values) => {
            try {
              setLoading(true);
              const res = await productSplit({ id: item.id, quantityTin: values.quantityTin, quantityCartton: values.quantityCartton, locatorId: values.locatorId });
              setPrintProducts([res]);
              setIsPrintModal(true);
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItems(result?.products);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
            } catch (error) {
              setLoading(false);
              message.error(error.message);
            }
          }}
          warehouseId={currentWarehouse.id}
          locatorsList={locatorsList}
          isEditModal={isEditModal}
        />
      </Modal>
      <Modal
        visible={isSplit}
        title={lang.splitproduct}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ProductEditForm
          isOnlyQuantityAllowForUpdate={true}
          item={item}
          onCancel={() => setIsSplit(false)}
        />
      </Modal>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => setIsPrintModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={printProducts}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.rack.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
          onCancel={() => {
            setIsPrintModal(false);
            if (isclose) {
              navigate('/dashboard/inbound/receipt');
              setIsClose(false);
            }
          }}
        />
      </Modal>
      <Modal
        visible={isEditModalReceiptProduct}
        title={lang.updateProduct}
        onCancel={() => setIsEditModalReceiptProduct(false)}
        destroyOnClose={true}
        width={992}
        centered
        footer={null}
        wrapClassName="receipt-product-pallet-edit-modal"
      >
        <ReceiptProductDetail
          item={receiptProduct}
          inboundKind={receipt?.inboundkind}
          onCallback={async (value) => {
            console.log(value)
            try {
              setLoading(true);
              await form.validateFields().catch(err => {
                throw new Error(err?.errorFields?.[0]?.errors?.[0]);
              });
              if (value.amountPallet > 0) {
                if (receipt?.inboundkind !== 'INTERNAL') {
                  if (!containerNo) throw new Error(lang.requiredContainerNo);
                }
                await createPallet({
                  warehouseId: currentWarehouse.id,
                  balletNumber: value.amountPallet,
                  masterProductId: receiptProduct?.masterProduct?.id,
                  receiptId: receiptId,
                  quantityCartton: value.quantityCarttonPallet,
                  quantityTin: value.quantityTinPallet,
                  expiredDate: value.expireDate,
                  lot: value.lot,
                  mfgDate: receiptProduct?.mfgDate,
                  locatorId: receipt?.inboundkind === 'NEW' ? form.getFieldValue('locator') : value?.locator,
                  container: containerNo,
                  contract: receiptProduct.contract,
                  zoneId: form.getFieldValue('zone'),
                  shipment: receiptProduct?.shipment,
                  autoDive: value.autoDive,
                  receiptProductId: receiptProduct?.id,
                  inboundkind: receipt?.inboundkind
                });
              }
              if (receipt?.inboundkind === 'BRANCH') {
                const productsFind = items?.filter(pro =>  pro?.masterProduct?.code3A === value?.code3A);
                const locatorCodeFind = locatorsList.find(locator => locator?.id === value?.locator)?.locator;
                productUpdateLocator({ productIds: productsFind?.map(proId => proId?.id), locatorCode: locatorCodeFind });
              } 
              delete value?.code3A
              await updateReceiptProduct({ ...value, receiptId, container: containerNo, code3A: receiptProduct?.masterProduct?.code3A});
                receiptDetail({ receiptId: parseInt(receiptId) })
                  .then(result => {
                    setReceipt(result);
                    setItems(result?.products);
                    setItemsReceiptProduct(result?.receiptProduct);
                    setLoading(false);
                  })
                  .catch((e) => setLoading(false));
                message.success(lang.updateSuccessfully);
                setIsEditModalReceiptProduct(false);
                return true;
              
            } catch (error) {
              message.error(error.message);
              setLoading(false);
              return false;
            }
          }}
          onCancel={() => setIsEditModalReceiptProduct(false)}
          divePallet={items?.filter(item => item?.receiptProductId === receiptProduct?.id)?.length > 0 ||
            (receiptProduct?.quantityCarttonReceived === receiptProduct?.quantityCartton && receiptProduct?.quantityTinReceived === receiptProduct?.quantityTin)}
          quantityCarttonItems={items?.reduce((prev, curr) => {
            if (receiptProduct?.id === curr.receiptProductId && curr.status === 'NEW') {
              return prev + curr.quantityCartton;
            }
            return prev;
          }, 0)}
          quantityTinItems={items?.reduce((prev, curr) => {
            if (receiptProduct?.id === curr.receiptProductId && curr.status === 'NEW') {
              return prev + curr.quantityTin;
            }
            return prev;
          }, 0)}
        />
      </Modal>
      <Modal
        visible={isSplitProductReceipt}
        title={lang.splitproduct}
        onCancel={() => setIsSplitProductReceipt(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptProductSplitForm
          isOnlyQuantityAllowForUpdate={true}
          item={receiptProduct}
          onCallback={async (value) => {
            try {
              setLoading(true);
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItemsReceiptProduct(result?.receiptProduct);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsSplitProductReceipt(false);
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => setIsSplitProductReceipt(false)}
        />
      </Modal>
      <Modal
        visible={isSplitReceipt}
        title={'Tách Phiếu'}
        destroyOnClose={true}
        onCancel={() => {return (setIsSplitReceipt(false), setProdeuctReceiptSplit([]))}}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptSplitForm
          items={prodeuctReceiptSplit}
          receipt={receipt}
          // receiptSplit={receiptSplit}
          onCallback={async (value) => {
            try {
              setLoading(true);
              const data = value?.receiptProducts;
              const payload = {
                id: receipt?.id,
                warehouseId: receipt?.warehouseId,
                receiptProducts: data
              }
              await splitReceipts(payload);
              receiptDetail({ receiptId: parseInt(receiptId) })
                .then(result => {
                  setReceipt(result);
                  setItemsReceiptProduct(result?.receiptProduct);
                  setLoading(false);
                })
                .catch((e) => setLoading(false));
              message.success('Tách phiếu thành công!')
              setIsSplitReceipt(false);
              setProdeuctReceiptSplit([])
            } catch (error) {
              message.error(error.message);
            }
          }}
          onCancel={() => { return (setIsSplitReceipt(false), setProdeuctReceiptSplit([]))}}
        />
      </Modal>
    </Layout>
  );
};

export default ReceiptDetail;