import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Modal, Row, Col, message, DatePicker, Card, Form, Input, InputNumber, Select } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import { CheckOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { materialCommandManueverCreate } from './production-order.service';
import './style.less';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import MaterialReceiptCreateProductEditForm from './material-production-order-create-product-edit.form';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
import { Option } from 'antd/lib/mentions';
import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const MaterialManueuverCommandCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    warehouseListAll()
      .then(async(result) => {
        let dataWarehouseTaken =[];
       await result.data?.map(item => {
          if (item?.id !== currentWarehouse?.id) {
            dataWarehouseTaken.push(item);
          }
          return true
        })
        const dataTaken = dataWarehouseTaken?.filter(wh => wh?.id !== 1)
        setWarehouse(dataTaken);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: lang.productName,
      dataIndex: 'name',
      key: 'productName',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    <Button
      key="btn-complete"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const formData = { ...form.getFieldsValue(true) };
          const dataFiledForm = form.getFieldsValue()
          const payload = items.map(item => {
            return {
              quantity: item.quantity,
              codeMaterial: item?.code,
              warehouseId: currentWarehouse.id,
            };
          });
          if (formData?.movementDate) {
            formData.movementDate = formData.movementDate.format(formatDateFull);
          }
          // formData.locatorTransitCode = formData.locatorTransit?.value;
          formData.creatorId = user?.userInfo?.name;
          delete formData.locatorTransit;
          if ((!dataFiledForm?.truckType)) {
            Modal.warning({
              title: lang.pleaseSelectVehicleType
            });
            setLoadingBtn(false);
          } else {
            await materialCommandManueverCreate({ ...formData, productMaterialOrders: payload, warehouseId: currentWarehouse.id });
            message.success(lang.createSuccessfully);
            setLoadingBtn(false);
            navigate('/dashboard/outbound/material-export');
          }
         
        } catch (error) {
          message.error(error.message);
          setLoadingBtn(false);
        }
      }
      }
      disabled={!(items?.length > 0)}
    >
      {lang.btnComplete}
      <CheckOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/manuever-command',
      breadcrumbName: lang.materialExport,
    },
    {
      path: '/',
      breadcrumbName: lang.createMaterialExportSlip,
    },
  ];

  return (
    <Layout className="Sale-order-list layoutContent">
      <PageHeader
        ghost={false}
        title={lang.createMaterialExportSlip}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      //onBack={onBack}
      />
      <Layout.Content>
        <Card bodyStyle={{ padding: 12 }}>
          <Form
            colon={false}
            layout="vertical"
            name="productEditForm"
            initialValues={{
              movementDate: moment(),
            }}
            form={form}
          >
            <Row gutter={24} type="flex">
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect
                required={true}
                  name="truckType"
                  label={lang.truckType}
                  options={[
                    {
                      value: 'Container 20FT',
                      label: 'Container 20FT',
                    },
                    {
                      value: 'Container 40FT',
                      label: 'Container 40FT',
                    },
                    {
                      value: 'Motorbike',
                      label: 'Motorbike',
                    },
                    {
                      value: 'Truck 1.25T',
                      label: 'Truck 1.25T',
                    },
                    {
                      value: 'Truck 1.5T',
                      label: 'Truck 1.5T',
                    },
                    {
                      value: 'Truck 10T',
                      label: 'Truck 10T',
                    },
                    {
                      value: 'Truck 15T',
                      label: 'Truck 15T',
                    },
                    {
                      value: 'Truck 2.0T',
                      label: 'Truck 2.0T',
                    },
                    {
                      value: 'Truck 2.5T',
                      label: 'Truck 2.5T',
                    },
                    {
                      value: 'Truck 5.0T',
                      label: 'Truck 5.0T',
                    },
                    {
                      value: 'Truck 8.0T',
                      label: 'Truck 8.0T',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                  placeholder={lang.truckType}
                  rules={[{ required: true, message: 'Please select truck type!' }]}
                />
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="truckWeight"
                  label={lang.vehicleLoadCapacityTons}
                >
                  <InputNumber placeholder={lang.vehicleLoadCapacityTons} style={{width: '100%'}} min={0}/>
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item label={lang.slipType} name="orderMaterialType" >
                  <Select placeholder={lang.slipType} allowClear>
                    <Option key={'NEW'} value={'EXPORT_INTERNAL'}>{lang.internalExport}</Option>
                    <Option key={'PICKING'} value={'EXPORT_BRANCH'}>{lang.exportBranch1}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <ProFormSelect
                name='warehouseIdTo'
                label={lang.receivingWarehouse}
                options={warehouse?.map(item => ({
                  value: item?.id,
                  label: item?.name,
                  key: item?.id
                }))}
                placeholder={lang.selectReceivingWarehouse}
                />
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="movementDate"
                  label={lang.movementDate}
                >
                  <DatePicker style={{ width: '100%' }} format={formatDate} />
                </Form.Item>
              </Col>
              <Col lg={{ span: 4 }} xs={{ span: 12 }}>
                <Form.Item
                  name="description"
                  label={lang.description}
                >
                  <Input placeholder={lang.description} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsEditModal(true);
                  setItem();
                }}
              >
                {lang.btnAdd}
                <PlusOutlined />
              </Button>
              {selectedRowKeys.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: lang.doYouWantToDeleteTheseItems,
                      icon: <ExclamationCircleOutlined />,
                      //content: 'Some descriptions',
                      onOk() {
                        const dataFilter = items.filter(item => selectedRowKeys.indexOf(item.indx) === -1);
                        setItems(dataFilter);
                        setSelectedRowKeys([]);
                      },
                      onCancel() {

                      },
                    });
                  }}
                  style={{ marginLeft: 8 }}
                >
                  {lang.btnDelete}
                  <DeleteOutlined />
                </Button>
              )}
            </Col>
          </Row>}
          rowKey="indx"
          rowSelection={rowSelection}
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            showSizeChanger: true,
            total: items.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
              setCurrentPage(1);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>

      <Modal
        visible={isEditModal}
        title={item ? lang.editProduct : lang.addProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <MaterialReceiptCreateProductEditForm
          item={item}
          items={items}
          onCallback={(value) => {
            setItems([...items, value]);
            setIsEditModal(false);
          }}
          onCancel={() => setIsEditModal(false)}
        />
      </Modal>
    </Layout>
  );
};

export default MaterialManueuverCommandCreate;
