import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';

import { suggestLocationByOrder } from './picking.service';

import './style.less';
import TableCustom from 'components/TableCustom/table';
import { formatDate } from 'utils/constants';
import moment from 'moment-timezone';

const tableScrollHeightArea = window.innerHeight - 200; // px

const SuggestLocationForm = ({ item, onCancel, onCallback, productOrders, totalItems }) => {
  // console.log(totalItem);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [items, setItems] = useState([]);
  const columnsBookingOrders = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
    },
    {
      title: lang.shortname,
      key: 'productCategory',
      render: record => record?.masterProduct?.shortName,
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityInCarton',
      key: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityTin',
      key: 'quantityTin',
    },
    {
      title: `${lang.pickingquantity} (${lang.carton})`,
      key: 'pickingQuantityCartton',
      dataIndex: 'pickingQuantityCartton',
    },
    {
      title: `${lang.pickingquantity} (${lang.tin})`,
      key: 'pickingQuantityTin',
      dataIndex: 'pickingQuantityTin',
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
      hideInTable: item[0]?.orderType === 'EXPORT_SALE',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (val) => moment(val).format(formatDate),
      hideInTable: item[0]?.orderType === 'EXPORT_SALE',
    },
    {
      title: lang.locator,
      dataIndex: 'locator',
      key: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
      hideInTable: item[0]?.orderType === 'EXPORT_SALE',
    },
  ];
  useEffect(() => {
    setLoading(true);
    setProductOrder(productOrders);
    Promise.all(productOrders?.map(async (product) => {
      const { masterProduct, quantityInCarton, quantityTin, warehouseId, code, orderId, pickingQuantityCartton, pickingQuantityTin } = product;
      const reponse = await suggestLocationByOrder({ masterProductId: masterProduct.id, quantityCarton: quantityInCarton - pickingQuantityCartton, quantityTin: quantityTin - pickingQuantityTin, warehouseId, orderId, screenLock: true, lot: product.lot, expireDate: product.expireDate, locatorId: product?.locator?.id });
      const result = reponse?.data?.map(productSuggest => ({ ...productSuggest, codeProductOrder: code }));
      console.log(reponse)
      return Promise.resolve(result);
    })).then((results) => {
      const ctx = results?.reduce((prev, current) => {
        // if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
        //   return prev;
        // }
        return prev.concat(current);
      }, []);
      // console.log(ctx)
      setItems([...ctx]);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [productOrders, item]);
  const columns = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct?.code3A,
      key: 'productCode',
      fixed: true,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
      fixed: true,
    },
    {
      title: lang.shortname,
      key: 'productCategory',
      render: record => record?.masterProduct?.shortName,
    },
    {
      title: lang.productName,
      key: 'productName',
      render: record => record?.masterProduct?.nameEN,
    },
    {
      title: lang.zone,
      dataIndex: 'zone',
      key: 'zone',
      render: value => value?.name,
    },
    {
      title: lang.block,
      dataIndex: 'block',
      key: 'block',
      render: value => value?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => record?.rack?.shelf?.name,
    },
    {
      title: lang.rack,
      dataIndex: 'rack',
      key: 'rack',
      render: value => value?.code,
    },
    {
      title: lang.quantityCartton,
      dataIndex: 'quantityCartton',
      key: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      dataIndex: 'quantityTin',
      key: 'quantityTin',
    },
    {
      title: `${lang.quantityCartton} ${lang.canBeTaken}`,
      // dataIndex: 'quantityCartonSubmit',
      key: 'quantityCarttonLock',
      render: rec => rec?.quantityCartton - rec?.quantityCartonSubmit,
    },
    {
      title: `${lang.quantityTin} ${lang.canBeTaken}`,
      // dataIndex: 'quantityTinSubmit',
      key: 'quantityCarttonLock',
      render: rec => (rec?.quantityCartton - rec?.quantityCartonSubmit) * parseInt(rec?.masterProduct?.tinPerCarton) + rec?.quantityTin - rec?.quantityTinSubmit,
    },
    {
      title: lang.lot,
      key: 'lot',
      dataIndex: 'lot',
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (val) => moment(val).format(formatDate),
    },
    {
      title: lang.locator,
      dataIndex: 'locator',
      key: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      initialValues={{}}
    >
      <Row gutter={24} >
        <Col>
          <h3>{`${lang.labelBooking}`}</h3>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 12 }}>
          <Input value={item?.map(ite => ite.code)} disabled />
        </Col>
        {/* <div style={{ lineHeight: '27px', marginLeft: '100px' }}>{`Tổng số đơn hàng: ${totalItems}`}</div> */}
        {/*<Col>
          <h3>{`${lang.lot}`}</h3>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 12 }}>
          <Form.Item name='lot'>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <h3>{`${lang.expireDate}`}</h3>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 12 }}>
          <Form.Item name='expireDate'>
            <DatePicker format={formatDate} />
          </Form.Item>
        </Col> */}
      </Row>
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columnsBookingOrders}
        dataSource={productOrder}
        scroll={{ x: 1300, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 1600, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {/* {loading === false ? (
              <Button type="primary" htmlType='submit' style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )} */}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SuggestLocationForm.propTypes = {
  item: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  productOrders: PropTypes.array,
  onLock: PropTypes.func,
  totalItems: PropTypes.number,
};

export default SuggestLocationForm;
