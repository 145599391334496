import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import exportFromJSON from "export-from-json";
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Input } from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons'; //EditOutlined, DeleteOutlined, ExclamationCircleOutlined,
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import numeral from 'numeral';
import { saleorderDetail } from 'pages/dashboard/configuration/sale-order/sale-order.service'; //deleteProductOrder, updateProductOrder
import { formatDate, formatDateFull, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import logo from 'assets/logoAKAWMS/akawms.svg';
import { productPicking } from 'pages/dashboard/outbound/packaging/packaging.service';
// import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service'; //orderUpdate, suggestUnLock
import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { confirmExportBranchGift, listProductPickedApi, orderGiftDetail, updateOrderStatusExportBranch } from './export-branch-gift.service'; //productUpdateStatus, productUpdateStatus

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ExportBranchDetail = () => {
  const { saleorderID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingExportPXK, setLoadingExportPXK] = useState(false);
  const [loadingExportPXKKVCNB, setLoadingExportPXKKVCNB] = useState(false);
  // const [isEditModal, setIsEditModal] = useState(false);
  // const [item, setItem] = useState();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleteProduct, setDeleteProduct] = useState(false);
  // const [productLock, setProductLock] = useState([]);
  const { user, currentWarehouse } = useSelector((state) => state.auth);
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isStocker = _get(user, 'roles', '').includes('stocker');
  const isManager = _get(user, 'roles', '').includes('manager');
  const isOutboundStaff = _get(user, 'roles', '').includes('outboundStaff');
  const isPicker = _get(user, 'roles', '').includes('forkliftDriver');
  const [container, setContainer] = useState();
  const [driverName, setdriverName] = useState();
  const [warehouses, setWarehouses] = useState([]);
  const [listProductPicked, setListProductPicked] = useState([]);
  // const [status, setStatus] = useState('');
  useEffect(() => {
    setLoading(true);
    warehouseListAll()
      .then(result => {
        setWarehouses(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  useEffect(() => {
    setLoading(true);
    orderGiftDetail(saleorderID)
      .then(result => {
        setSaleorder(result);
        // setStatus(result?.status)
        // if (result.orderType === 'EXPORT_BRANCH') {
        //   productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
        // }
        if (result.status !== 'EXPORT') {
          setProductOrder(result?.productGiftOrders);
          setLoading(false);
        } else {
          return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
        }
      })
      .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
      .catch((e) => setLoading(false));

      listProductPickedApi({ warehouseId: currentWarehouse?.id, orderId: saleorderID })
      .then(data => {
        setListProductPicked(data?.data)
      })
  }, [currentWarehouse, saleorderID]);
  
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'codeGift',
      key: 'codeGift',
    },
    {
      title: lang.productName,
      dataIndex: ['bomGift', 'name'],
      key: 'productName',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locatorCode',
      // render: val => val && `${val?.locatorName} - ${val?.locator}`,
    },
    {
      title: lang.quantity,
      key: 'quantity',
      render: rec => rec?.quantity,
    },
  // {
    //   title: lang.action,
    //   align: 'center',
    //   width: '8%',
    //   fixed: 'right',
    //   hideInTable: saleorder?.status !== 'NEW' || saleorder.orderType === 'EXPORT_SALE' || saleorder.orderType === 'EXPORT_INTERNAL',
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'space-around' }}>
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           setIsEditModal(true);
    //           setItem(record);
    //         }}
    //       />
    //       <Button
    //         type="link"
    //         size="small"
    //         icon={<DeleteOutlined />}
    //         onClick={() => {
    //           Modal.confirm({
    //             title: lang.doYouWantToDeleteTheseItems,
    //             icon: <ExclamationCircleOutlined />,
    //             onOk: async () => {
    //               try {
    //                 await deleteProductOrder(record.id);
    //                 message.success(lang.deleteSuccessfully);
    //                 setDeleteProduct(!deleteProduct);
    //                 return true;
    //               } catch (error) {
    //                 message.error(error.message);
    //                 return false;
    //               }
    //             },
    //             onCancel() {

    //             },
    //           });
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  const columnsProduct = [
    {
      title: lang.defaultCode,
      dataIndex: ['bomGift', 'code'],
      key: 'codeGift',
    },
    {
      title: lang.productName,
      dataIndex: ['bomGift', 'name'],
      key: 'name',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    // {
    //   title: lang.vansCode,
    //   dataIndex: 'container',
    //   key: 'container No',
    // }, 
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: value => `${value?.locatorName} - ${value?.locator}`,
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
    {
      title: 'User Picked',
      key: 'userPicked',
      dataIndex: 'userName',
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {'Back'}
    </Button>,
    saleorder?.orderType === 'EXPORT_BRANCH' && saleorder?.status === 'EXPORTED' && <Button
    key="btn-export"
    type="primary"
    onClick={() => {
      const dataXml = [
        {
        Invoices: {
          Inv: {
            key: `${saleorder?.code}`,
            Invoice: {
              DelieriName: "Ho Chi Minh",
              IRNUMBER: "503/22-XM",
              DeliverDate: `${moment(saleorder?.movementDate, formatDateFull).format(formatDate)}`,
              DeliverContent: `${saleorder?.description ? saleorder?.description : ''}`,
              NoilapYC: lang.companyDirector3AVietnam,
              OrgNameOut: lang.company3AVietnam,
              StoreOut: `${saleorder?.locatorTransit?.locatorName} - ${saleorder?.locatorTransit?.locator}`,
              FromAddress: lang.address3AVietnam,
              OrgNameIn: lang.company3AVietnam,
              ToAddress: lang.address3AVietnam,
              CusCode: "",
              CusName: "",
              Buyer: "",
              CusAddress: "",
              DVQLKHO: "",
              DeliverAddress: "",
              DeliverTaxCode: "",
              DeliverPhone: "",
              ComFax: "",
              ComBankNo: "",
              ComBankName: "",
              CurrencyCode: "",
              TyGia: "",
              VNMInvoiceType: "",
              OrderNoOrderNo: "",
              DeliverCom: "",
              ContractNo: "",
              DeliverTruck: `${saleorder?.truckType}`,
              TripNumber: "",
              DeliverNumber: "",
              CusTaxCode: "",
              PaymentMethod: "",
              CusBankNo: "",
              BookingNo: "",
              Extra: "",
              InvoiceDate: `${moment(saleorder?.createDate, formatDateFull).format(formatDate)}`,
              TransNumber: "",
              PrintDate: "",
              Printer: "",
              Products: {
                Product : saleorder?.productOrders?.map(val => ({
                  ProductName : `${val?.masterProduct?.nameEN}`,
                  Code: `${val?.masterProduct?.code3A}`,
                  ProdUnit: `${val?.masterProduct?.UOM_VN}`,
                  ProdQuantity: `${val?.quantityInCarton}T - ${val?.quantityTin}L`,
                  Remarks: "",
                  ProdPrice: "",
                  Total: "",
                  Extra1: "1178413",
                  Extra2: `${moment(val?.expireDate, formatDateFull).format(formatDate)}`,
                  VATRate: "",
                  VATAmount: "",
                  Amount: "",
                }))
              },
              CountTHU: "",
              WeightTON: "",
              CountItem: "",
              SoldVia: "",
              InvoiceSigner: "",
              Total: "",
              DiscountAmount: "",
              VATRate: "",
              VATAmount: "",
              Amount: "",
              AmountInWords: "",
              PaymentStatus: "",
              ResourceCode: "",
              GrossValueNon: "",
              GrossValue0: "",
              GrossValue5: "",
              GrossValue10: "",
              VatAmountNon: "",
              VatAmount0: "",
              VatAmount5: "",
              VatAmount10: "",
              AmountNon: "",
              Amount0: "",
              Amount5: "",
              Amount10: "",
            }
          }
        }
        },
      ];
    ;
      const fileName = "ExportXML";
      let fields = [
        "Invoices",
        "Inv",
        "key",
        "Invoice",
        "DelieriName",
        "IRNUMBER",
        "DeliverDate",
        "DeliverContent",
        "NoilapYC",
        "OrgNameOut",
        "StoreOut",
        "FromAddress",
        "OrgNameIn",
        "ToAddress",
        "CusCode",
        "CusName",
        "Buyer",
        "CusAddress",
        "DVQLKHO",
        "DeliverAddress",
        "DeliverTaxCode",
        "DeliverPhone",
        "ComFax",
        "DeliverPhone",
        "ComBankNo",
        "ComBankName",
        "CurrencyCode",
        "TyGia",
        "VNMInvoiceType",
        "OrderNo",
        "DeliverCom",
        "ContractNo",
        "DeliverTruck",
        "TripNumber",
        "DeliverNumber",
        "TripNumber",
        "CusTaxCode",
        "PaymentMethod",
        "CusBankNo",
        "BookingNo",
        "Extra",
        "InvoiceDate",
        "TransNumber",
        "PrintDate",
        "Printer",
        "Products",
        "Product",
        "ProdName",
        "Code",
        "ProdUnit",
        "ProdQuantity",
        "Remarks",
        "ProdPrice",
        "Total",
        "Extra1",
        "Extra2",
        "VATRate",
        "Extra2",
        "VATAmount",
        "Amount",
        "CountTHU",
        "WeightTON",
        "CountItem",
        "SoldVia",
        "InvoiceSigner",
        "Total",
        "DiscountAmount",
        "VATRate",
        "VATAmount",
        "Amount",
        "AmountInWords",
        "PaymentStatus",
        "ResourceCode",
        "GrossValueNon",
        "GrossValue0",
        "GrossValue5",
        "GrossValue10",
        "VatAmountNon",
        "VatAmount0",
        'VatAmount5',
        "VatAmount10",
        "AmountNon",
        "Amount0",
        "Amount5",
        "Amount10",
      ];  //empty list means "use all"
      const exportType = 'xml';
      exportFromJSON({data: dataXml, fileName, fields, exportType})
    }}
    >
    {lang.exportXML}
    <VerticalAlignBottomOutlined />
  </Button>,
    // ['NEW', 'CONFIRM'].indexOf(saleorder?.status) > -1 &&<Button
    //   key="btn-cancel"
    //   type="primary"
    //   // ghost
    //   onClick={() => {
    //     Modal.confirm({
    //       title: 'Bạn có chắc chắn muốn hủy lệnh sản xuất?',
    //       async onOk() {
    //         try {
    //           if(saleorder?.status !== 'NEW')
    //             await orderUpdate({ id: saleorder?.id, status: 'NEW' });
    //           else await orderUpdate({ id: saleorder?.id, status: 'CANCELLED', internalTranferCode: saleorder?.internalTranferCode });
    //           message.success(lang.updateSuccessfully);
    //           navigate(-1);
    //           return true;
    //         } catch (error) {
    //           message.error(error.message);
    //           return false;
    //         }
    //       },
    //     });
    //   }}
    // >
    //   {lang.btnCancel}
    // </Button>,
    
    // saleorder?.orderType === 'EXPORT_SALE' && <Button
    //   key="btn-spicking-slip"
    //   type="primary"
    //   // ghost
    //   loading={loadingExport}
    //   onClick={async () => {
    //     setLoadingExport(true);
    //     const content = `
    //     <div style="display: grid ;line-height: 1.5;width: 950px; font-size: 18px; font-family: 'Times New Roman', Times, serif;">
    //     <!-- <div style="text-align:right; width: 100%; padding: 10px">
    //       <p><b style="padding: 10px">Ngày in: 22/08/2022 </b></p>
    //     </div> -->
    //     <div style="display: flex; height: 120px;">
    //       <div style="width: 80%; text-align: center; ">
    //         <h1>Picking Slip</h1>
    //       </div>
    //     </div>
    //     <div style="display: flex; width: 100%;">
    //       <div style="width: 25%;">
    //         <p><b>Số đơn hàng:</b> </p>
    //         <p><b>Ngày</b>: </p>
    //         <p><b>Họ tên khách hàng</b>: </p>
    //         <p><b>Nhân viên bán hàng</b>: </p>
    //       </div>
    //       <div style="width: 25%;">
    //         <p>${saleorder?.documentNo}</p>
    //         <p>${saleorder?.dateOrdered ? moment(saleorder?.dateOrdered, formatDateFull).format(formatDate) : ''}</p>
    //         <p>${saleorder?.nameCustomer}</p>
    //         <p>${saleorder?.userContact}</p>
    //       </div>
    //       <div style="width: 25%;">
    //         <p><b>Delivery Note No:</b> </p>
    //         <p><b>Order Type</b>: </p>
    //         <p><b>Order Reference</b>: </p>
    //         <p><b>Locator</b>: </p>
    //       </div>
    //       <div style="width: 25%;">
    //         <p>${saleorder?.code}</p>
    //         <p>${saleorder?.orderType}</p>
    //         <p>${saleorder?.orderReference}</p>
    //         <p>${saleorder?.locator?.locatorName} - ${saleorder?.locator?.locator}</p>
    //       </div>
    //     </div>
    //     <div>
    //       <p> <b>Địa chỉ: ${saleorder?.shipAddress}</b> </p>
    //     </div>
    //     <div style="min-height: 1000px;">
    //       <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
    //         <thead>
    //           <tr style=" background-color: #f5f5f5; ">
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">No</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Code</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Product Name</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Short</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInCart</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">QtyInTin</div></th>
    //             <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">Lot & Exp Date</div></th>
    //           </tr>
    //         </thead>
    //         <tbody>

    //         ${productOrder?.map((product, index) => {
    //       return `<tr style="text-align: center;">
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.code3A}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.nameEN}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.masterProduct?.shortName}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityTin}</div></td>
    //                     <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product?.lot}&gt;_${product?.expireDate}</div></td>
    //                   </tr>`;
    //     }).join(' \n')}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>`;
    //     var template = document.createElement('div');
    //     template.innerHTML = content;
    //     document.body.appendChild(template);
    //     await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
    //       const imgWidth = 208;
    //       const imgHeight = canvas.height * imgWidth / canvas.width;
    //       var imgData = canvas.toDataURL('image/png');
    //       // eslint-disable-next-line new-cap
    //       const pdf = new jsPdf('p', 'mm', 'a4');
    //       pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
    //       pdf.save(`PhieuPickingSlip${moment().format('DDMMYYYY')}.pdf`);
    //     });
    //     document.body.removeChild(template);
    //     setLoadingExport(false);
    //   }}
    // // disabled={saleorder?.orderType !== 'EXPORT_INTERNAL'}
    // >
    //   {lang.exportSpickingSlip}
    // </Button>,
    //Phiếu Xuất Kho, chỉ Idm xuất đc
    saleorder?.orderType === 'EXPORT_BRANCH' && (isStocker || isIdm) && <Button 
    key="btn-export-pxk"
    type="primary"
    ghost
    loading={loadingExportPXK}
    onClick={async () => {
      setLoadingExportPXK(true);
      const date = moment(saleorder?.createDate, formatDateFull).format(formatDate);
      const tottalQuantity = saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0);
      const content = `
    <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
      <div style="display: flex; height: 120px;">
        <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
          <img src="${logo}" style=" height: 100%; width: 100%" />
        </div>
        <div style="width: 60%; text-align: center; padding-top: 7vh; ">
          <h1>{lang.warehouseExportSlip}</h1>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
      <div style="display: grid; line-height: 1;">
        <p> {lang.area}:  <b>{lang.hcm}</b></p>
        <p> {lang.fromDate}: <b>${date}</b> </p>
        <p> {lang.vehicleNumber}: <b>59K1-00005</b> </p>
        <p> {lang.deliveryStaffCode}: <b>Nguyen Van A</b> </p>
        <p> {lang.deliveryRoute}: <b>{lang.hcm}</b> </p>
      </div>
      <div style="display: grid; line-height: 1;">
        <p> {lang.printedTime}: <b>${moment().format(formatDatetime)}</b> </p>
        <p> {lang.toDate}: <b>${date}</b> </p>
        <p> {lang.totalCarton}: <b>${tottalQuantity}</b> </p>
        <p> {lang.documentNo}: <b></b> </p>
        <p> {lang.dailyTransferSequence}: <b>1</b> </p>
      </div>
    </div>
      <div style="min-height: 400px;">
        <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
          <thead>
            <tr>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.itemNo}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.productName}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.shortname</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.carton}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.tin}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.lotNumberExpiryDate}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.soDocNo}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.invoiceNo}</div></th>
              <th><div style="min-height: 50px; display: flex; align-items: center;">{lang.locator}</div></th>
            </tr>
          </thead>
          <tbody>
          ${saleorder?.productOrders?.map((productOrder, index) => {
            return `<tr style="text-align: center;">
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${index +1}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.masterProduct.nameEN}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.masterProduct.shortName}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder?.quantityInCarton || productOrder.quantityCartton}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.quantityTin}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder.lot}_'${productOrder.expireDate}'</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${productOrder?.locator?.locator}</div></td>
                    </tr>`;
          }).join(' \n')}
          
            <tr>
              <td colSpan="3" style="text-align:center; padding-top: 10px;">
                <b style="min-height: 30px; display: block;">Total</b>
              </td>
              <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0)}</td>
              <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.checkerStaff}</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.securityGuard}</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.pickingStaff}</b> </p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.slipCreator}</b> </p>
        </div>
      </div>
    </div>`;
      var template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        var imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`ExportSlip${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingExportPXK(false);
    }}
  >
    {lang.issueExportSlip}
  </Button>,
  //Phiếu chuyển kho nội bộ
    saleorder?.orderType === 'EXPORT_BRANCH' && (isStocker || isIdm) && <Button
      key="btn-export-NB"
      type="primary"
      ghost
      loading={loadingExport}
      onClick={async () => {
        setLoadingExport(true);
        const content = `
      <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; height: 120px;">
          <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
            <img src="${logo}" style=" height: 100%; width: 100%" />
          </div>
          <div style="width: 60%; text-align: center; padding-top: 7vh; ">
            <h1>{lang.internalTransferNote}</h1>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid;">
            <p> <b>{lang.internalStockTransferNumber}: ${saleorder?.internalTranferCode} </b> </p>
            <p> <b>{lang.internalUseExportNumber}: </b> </p>
          </div>
          <div style="display: grid; line-height: 0.5;">
            <p> <b>{lang.printDate}: ${moment().format(formatDatetime)} </b> </p>
            <p> <b>{lang.transferDate}: ${moment().format(formatDate)}</b> </p>
            <p> <b>{lang.completionDate}:</b> </p>
          </div>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #009ada; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.abbottCode}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.code3A}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.productName}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.lotNumberExpiryDate}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.fromWarehouse}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.toWarehouse}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.carton}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.tin}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.damaged}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.dented}</div></th> 
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.ERROR}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.missing}</div></th>
              </tr>
            </thead>
            <tbody>
            ${productOrder?.map((product) => {
          const productfind = saleorder?.productOrders?.find(productOrder => productOrder.masterProduct.id === product.masterProduct.id);
          return `<tr style="text-align: center;">
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.abbottCode16}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.code3A}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.nameEN}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">&lt;${product.lot}&gt;_${product.expireDate}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${productfind?.locator ? productfind?.locator.locatorName : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${productfind?.locatorTo ? productfind?.locatorTo.locatorName : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton || product.quantityCartton}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.quantityTin}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td> 
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      </tr>`;
        }).join(' \n')}
              <tr>
                <td colSpan="6">
                  <b style="min-height: 30px; display: flex;">{lang.sum}</b>
                </td>
                <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0)}</td>
                <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.pickingStaff}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.warehouseKeeper}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.tableCreator}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`InternalWarehouseExportSlip${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
        setLoadingExport(false);
      }}
    >
      {lang.exportIWS}
    </Button>,
    saleorder?.orderType === 'EXPORT_BRANCH' && (isStocker || isIdm) && <Button
    key="btn-export-NB"
    type="primary"
    ghost
    loading={loadingExportPXKKVCNB}
    onClick={async () => {
      setLoadingExportPXKKVCNB(true);
      const dateTime = new Date(saleorder?.movementDate);
      const date = dateTime.getDate();
      const month = (dateTime.getMonth() + 1);
      const year = (dateTime.getFullYear());
      const listProducts = saleorder?.productOrders[0].locatorTo;
      const currWarehouse = warehouses.find(item => item?.id === listProducts?.warehouseId);
      const warehouseTo = warehouses.find(item => item?.id === listProducts?.warehouseToId);
      const content = `
      <div style="display: grid ;line-height: 1;width: 960px;font-size: 16px; font-family: Times New Roman, Times, serif;">
    <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; ">
        <div>
          <p>
            <h3>{lang.threeANutritionLLCVietnam}</h3>
          </p>
        </div>
        <div>
          <p>
            {lang.phone}: 028 3823 8222   -   Fax: 028 3823 8777
          </p>
        </div>
        <div>
          <p>
            {lang.taxCode}: <b>0310139770</b>
          </p>
        </div>
        <div>
          <p>
            {lang.address}: {lang.centecTowerAddress}
          </p>
        </div>
    </div>
    <div style="display: flex; min-height: 120px;">
      <div style="text-align:center; width: 20%; border-image: initial;">
        <img src="${logo}" style=" height: 100%; width: 100%" />
      </div>
      <div style="width: 60%; text-align: center; padding-top: 7vh; line-height: 1.2;">
        <h1>{lang.internalTransferSlipWithInternalTransport}</h1>
        <div style="text-align: center;">{lang.date} ${new Date().getDate()} {lang.month} ${new Date().getMonth() + 1} {lang.year} ${new Date().getFullYear()}</div>
      </div>
      <div style="width: 20%; padding-top: 8vh;">
        <p> <b>{lang.serialNumber}: 03XKNB0/001</b> </p>
        <p> <b>{lang.symbol}: SG/20E</b> </p>
        <p> <b>{lang.number}: <span style="font-size: 25px;">0000330</span></b> </p>
      </div>
    </div>
    <div style="text-align: center"><h2>{lang.warehouseExportSlipConvertedFromEWarehouseSlip}</h2></div>
    <div style="margin: 20px 0px;">
      <div style="display: flex;">
        <div style = "width: 40%; display: flex; height: 100%;">
          <div style="width: 50%;">{lang.basedOnDispatchOrderNo}:</div>
          <div style="border-bottom: 1px solid; width: 50%;">${saleorder?.lDDNo}</div>
        </div>
        <div style = "width: 20%; display: flex; height: 100%;">
          <div>{lang.date}:</div>
          <div style="border-bottom: 1px solid; width: 90%; text-align: center;">${date}</div>
        </div>
        <div style = "width: 20%; display: flex; height: 100%;">
          <div>{lang.month}:</div>
          <div style="border-bottom: 1px solid; width: 90%; text-align: center;">${month}</div>
        </div>
        <div style = "width: 20%; display: flex; height: 100%;">
          <div>{lang.year}:</div>
          <div style="border-bottom: 1px solid; width: 90%; text-align: center;">${year}</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style = "width: 50%; display: flex; height: 100%;">
          <div>Của: </div>
          <div style="border-bottom: 1px solid; width: 90%;"><h4>{lang.companyDirector3AVietnam}</h4></div>
        </div>
        <div style = "width: 50%; display: flex; height: 100%;">
          <div style="width: 13%;">Về việc  :</div>
          <div style="border-bottom: 1px solid; width: 87%; text-align: center;"><h4>CHUYỂN HÀNG DONG NAI (LDD: HCM ${saleorder?.lDDNo})</h4></div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style = "width: 50%; display: flex; height: 100%;">
          <div style="width: 40%;">{lang.transporterFullName}: </div>
          <div style="border-bottom: 1px solid; width: 60%;"></div>
        </div>
        <div style = "width: 50%; display: flex; height: 100%;">
          <div style="width: 20%;">{lang.contractNumber}: </div>
          <div style="border-bottom: 1px solid; width: 80%;">1A12345</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style = "width: 100%; display: flex; height: 100%;">
          <div style="width: 20%;">{lang.transportationMode}:</div>
          <div style="border-bottom: 1px solid; width: 80%;font-weight: bold">${saleorder?.truckType}</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style = "width: 100%; display: flex; height: 100%;">
          <div style="width: 10%;">{lang.exportAtWarehouse}:</div>
          <div style="border-bottom: 1px solid; width: 90%; font-weight: bold">${currWarehouse?.name} - ${currWarehouse?.address}</div>
        </div>
      </div>
      <div style="border-bottom: 1px solid; margin-top: 20px;"></div>
      <div style="display: flex; margin-top: 10px;">
        <div style = "width: 100%; display: flex; height: 100%;">
          <div style="width: 10%;">{lang.importAtWarehouse}:</div>
          <div style="border-bottom: 1px solid; width: 90%;font-weight: bold">${warehouseTo?.name} - ${warehouseTo?.address}</div>
        </div>
      </div>
      <div style="border-bottom: 1px solid; margin-top: 20px;"></div>
    </div>
    <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
        <thead>
          <tr>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.itemNo}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.productName}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.lotNumber}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.expiryDate}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.DVT}</div></th>
            <th colspan="2"><div style="min-height: 20px; align-items: center;">{lang.quantity}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.unitPrice}</div></th>
            <th rowspan="2"><div style="min-height: 20px; align-items: center;">{lang.totalAmount}</div></th>
          </tr>
            <tr style="text-align: center">
            <td><div style="min-height: 20px; align-items: center;">{lang.actualExportCans}</div></td>
            <td><div style="min-height: 20px; align-items: center;">{lang.actualImportCans}</div></td>
          </tr>
        </thead>
        <tbody>
        ${saleorder?.productOrders?.map((product, index) => {
      return `<tr style="text-align: center;">
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${index + 1}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.nameEN}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.lot}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${(product?.expireDate) ? moment(product?.expireDate, formatDate).format(formatDate) : ''}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.UOM_VN}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;">${parseInt(product.masterProduct.tinPerCarton) * product?.quantityInCarton + product?.quantityTin}</div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                  </tr>`;
    }).join(' \n')}
          <tr>
            <td colSpan="5" style="text-align: center;">
              <b style="min-height: 30px;">{lang.sum}</b>
            </td>
            <td style="font-weight: bold;">${numeral(saleorder?.productOrders?.reduce((prev, cur) => { prev += parseInt(cur.masterProduct.tinPerCarton) * cur?.quantityInCarton + cur?.quantityTin; return prev; }, 0)).format('0,0')}</td>
          </tr>
          <tr>
            <td colSpan="4">
              <b style="min-height: 40px; display: flex; align-items: center; justify-content: center;">{lang.export}, {lang.date} ${date} {lang.month} ${month} {lang.year} ${year}</b>
            </td>
            <td colSpan="5" style="font-weight: bold; text-align: center;">{lang.import}, {lang.date} ${new Date().getDate()} {lang.month} ${new Date().getMonth() + 1} {lang.year} ${new Date().getFullYear()}</td>
          </tr>
        </tbody>
      </table>
      <div style="text-align:center; width: 70%; border-image: initial;">
        <img src="${logo}" style=" height: 70%; width: 60%" />
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.slipCreatorOrConverter}</b> </p>
          <p>{lang.signatureFullName}</p>
          <p style="margin-top: 50px">{lang.dateFormat}</p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.warehouseReceivingUnit}</b> </p>
          <p>{lang.signatureFullName}</p>
          <p style="margin-top: 50px">{lang.dateFormat}</p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%; text-align: center;">
          <p> <b>{lang.transportationUnit}</b> </p>
          <p>{lang.signatureFullName}</p>
          <p style="margin-top: 50px">{lang.dateFormat}</p>
        </div>
        <div style="display: grid; justify-content: center; width: 25%;">
          <p> <b>{lang.warehouseExportUnit}</b> </p>
          <div style="border: 1px solid;">
            <div><h5>Signature Valid</h5></div>
            <div>{lang.signedBy}: {lang.company3AVietnam}</div>
            <div>{lang.signedDate}: 22/04/2022</div>
          </div>
        </div>
      </div>
  </div>`
      var template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        var imgData = canvas.toDataURL('image/png');
        // eslint-disable-next-line new-cap
        const pdf = new jsPdf('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`PXKKVXNB${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
      setLoadingExportPXKKVCNB(false);
    }}
  >
    {'Xuất PXKKVCNB'}
  </Button>,
    // saleorder?.orderType === 'EXPORT_SALE' && <Button
    //   key="btn-create"
    //   type="primary"
    //   ghost
    //   onClick={async () => {
    //     try {
    //       const payload = Object.assign({}, saleorder);
    //       payload.orderId = saleorder?.id;
    //       payload.orderCode = saleorder?.code;
    //       payload.productDeliverys = saleorder?.productOrders?.map(product => {
    //         return {
    //           lineNo: product?.lineNo,
    //           code3A: product?.code3A,
    //           branch: product?.branch,
    //           description: product?.description,
    //           quantityInCarton: product?.quantityInCarton,
    //           quantityInTin: product?.quantityTin,
    //           quantity: product?.quantity,
    //           lot: product?.lot,
    //           expireDate: product?.expireDate,
    //           warehouseId: product?.warehouseId,
    //         };
    //       });
    //       delete payload.id;
    //       delete payload.createDate;
    //       delete payload.productOrders;
    //       await deliveryOrderCreate(payload);
    //       setDeleteProduct(!deleteProduct);
    //       message.success(lang.createSuccessfully);
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       return false;
    //     }
    //   }}
    //   disabled={saleorder?.deliveryOrder}
    // >
    //   {'Tạo Delivery Order'}
    // </Button>,
    // saleorder?.status === 'LOCK' && isPicker && <Button
    //   key="btn-unlock"
    //   type="primary"
    //   onClick={async () => {
    //     try {
    //       setLoading(true);
    //       await Promise.all([(await suggestUnLock({ orderId: saleorder?.id, warehouseId: saleorder.warehouseId, subChannel: saleorder?.subChannel })),
    //       orderUpdateStatus({ ids: [saleorder?.id], status: 'NEW' })]);
    //       saleorderDetail(saleorderID)
    //         .then(result => {
    //           setSaleorder(result);
    //           if (result.status === 'LOCK') {
    //             productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
    //           }
    //           if (result.status !== 'EXPORT') {
    //             setProductOrder(result?.productOrders);
    //             setLoading(false);
    //           } else {
    //             return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
    //           }
    //         })
    //         .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
    //         .catch((e) => setLoading(false));
    //       message.success('Unlock thành công');
    //       return true;
    //     } catch (error) {
    //       message.error(error.message);
    //       setLoading(false);
    //       return false;
    //     }
    //   }}
    // >
    //   {'Unlock'}
    // </Button>,
    (saleorder?.status === 'COMPLETE' || saleorder?.status === 'NEW' ) && !isPicker && <Button
      key="btn-confirm"
      type="primary"
      onClick={async () => {
        Modal.confirm({
          title: lang.confirmProceedSure,
          async onOk() {
            try {
              setLoading(true);
              if (saleorder?.status === 'COMPLETE') {
                const orderUpdatesPending = {
                  orderGiftId: parseInt(saleorder?.id),
                  productGiftId: listProductPicked?.map(item => item?.id),
                  locatorTransitCode: saleorder?.locatorTransitCode?.locator,
                  driverName,
                  container,
                };
                // const orderCode = saleorder?.code;
                // const locatorTransitCode = saleorder?.locatorTransitCode;
                // await orderUpdateStatus(orderUpdatesPending);
                await confirmExportBranchGift(orderUpdatesPending);
                message.success(lang.confirmationSuccessful);
                navigate(-1)
              }
              else {
                await updateOrderStatusExportBranch({ id: saleorder?.id, status: 'LOCK', assignAll: true });
                message.success(lang.confirmationSuccessful);
                navigate(-1)
              }
              saleorderDetail(saleorderID)
                .then(result => {
                  setSaleorder(result);
                  // if (result?.status === 'LOCK') {
                  //   productPicking({ warehouseId: result.warehouseId, orderSubmitId: result.id }).then(val => setProductLock(val?.data));
                  // } 
                  // else if (result.orderType === 'EXPORT_BRANCH') {
                  //   productPicking({ warehouseId: result.warehouseId, orderId: result.id }).then(val => setProductLock(val?.data));
                  // }
                  if (result.status !== 'EXPORT') {
                    setProductOrder(result?.productOrders);
                    setLoading(false);
                  } else {
                    return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
                  }
                })
                .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
                .catch((e) => setLoading(false));
            } catch (err) {
              message.error(err.message);
            }
          },
          onCancel() { },

        });
      }}
      disabled={['CONFIRM1'].indexOf(saleorder?.status) !== -1 || (isIdm && saleorder?.status === 'NEW') || (isOutboundStaff && saleorder?.status !== 'NEW') || (isManager && saleorder?.status !== 'PICKING') || (isIdm && saleorder?.status === 'PICKING') || (isStocker && saleorder?.status === 'CONFIRM')}
    >
      {lang.btnConfirm}
    </Button >,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    document.location.pathname.indexOf('internal-tranfer') > -1 ? {
      path: '/internal-tranfer',
      breadcrumbName: lang.EXPORT_INTERNAL,
    } : {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '',
      breadcrumbName: document.location.pathname.indexOf('internal-tranfer') > -1 ? `${lang.details} ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={document.location.pathname.indexOf('internal-tranfer') > -1 ? `${lang.details} ${lang.EXPORT_INTERNAL}` : lang.saleOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.documentNo}</Descriptions.Item>
            <Descriptions.Item label={`${lang.locator} (Transit)`}>{`${saleorder?.locatorTransitCode?.locatorName} - ${saleorder?.locatorTransitCode?.locator}`}</Descriptions.Item>
            <Descriptions.Item label={lang.iddNo}>{saleorder?.IDDNo}</Descriptions.Item>
            <Descriptions.Item label={lang.truckType}>{saleorder?.truckType}</Descriptions.Item>
            <Descriptions.Item label={lang.truckWeight}>{saleorder?.truckWeight}</Descriptions.Item>
            <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>
              {
                saleorder?.status === 'NEW' ? lang.newCreate
                : saleorder?.status === 'LOCK' ? lang.waitingForPicking
                : saleorder?.status === 'PICKING' ? lang.inPicking
                : saleorder?.status === 'COMPLETE' ? lang.picked
                : lang.completedShipment
              }
            </Descriptions.Item>
            {saleorder?.status === 'COMPLETE' && !isPicker && <>
              <Descriptions.Item label={lang.vansCode}>
                <Input onChange={(val) => setContainer(val.target.value)} style={{ width: '80%' }}></Input>
              </Descriptions.Item>
              <Descriptions.Item label={lang.driverEmployee}>
                <Input onChange={(val) => setdriverName(val.target.value)} style={{ width: '80%' }}></Input>
              </Descriptions.Item>
            </>}
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{`${lang.productList} ${lang.picked1}`}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columnsProduct}
          dataSource={listProductPicked}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          // scroll={{ x: 2600 }}
        />
      </Layout.Content>
      {/* <Modal
        visible={isEditModal}
        title={lang.editProduct}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <ReceiptCreateProductEditForm
          item={item}
          onCallback={async (value) => {
            try {
              await updateProductOrder({ id: value?.id, quantityInCarton: value?.quantityInCarton, quantityTin: value?.quantity });
              message.success(lang.updateSuccessfully);
              setDeleteProduct(!deleteProduct);
              setIsEditModal(false);
              return true;
            } catch (error) {
              message.error(error.message);
              return false;
            }
          }}
          onCancel={() => setIsEditModal(false)}
        // type={type}
        // locators={locators}
        />
      </Modal> */}
    </Layout>
  );
};

export default ExportBranchDetail;
