import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Form, Card, Row, Col, DatePicker, Input, message } from 'antd';
import { EditOutlined, SearchOutlined, InboxOutlined } from '@ant-design/icons';
import { defaultPage, formatDate, formatDateFull, MODE } from 'utils/constants';

import './style.less';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import TableCustom from 'components/TableCustom/table';
import { bomGiftList, importExel } from './bom-gift.service';
import BomEditForm from './bom-gift-edit.form';
import Helper from 'utils/helpers';
import Modal from 'antd/lib/modal/Modal';
import Dragger from 'antd/lib/upload/Dragger';
import moment from 'moment-timezone';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const BomList = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [isEditModal, setIsEditModal] = useState(false);

  const [item, setItem] = useState();
  const [items, setItems] = useState([]);
  const [file, setFile] = useState();
  //Pagination
  const [params, setParams] = useState({ ...defaultPage });
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [uploadModal, setUploadModal] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    bomGiftList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);

  const columns = [
    {
      title: 'Gift Code',
      dataIndex: 'code',
      key: 'code',
      width: '10%',
    },
    {
      title: 'Gift Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'UOM',
      dataIndex: 'UOM',
      key: 'UOM',
    },
    // {
    //   title: lang.totalQuantity,
    //   dataIndex: 'stock',
    //   key: 'stock',
    // },
    {
      title: lang.PCSCase,
      dataIndex: 'PCSCase',
      key: 'PCSCase',
    },
    {
      title: 'Product Loss',
      dataIndex: 'productionLoss',
      key: 'productionLoss',
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: value => value ? moment(value, formatDateFull).format(formatDate) : undefined,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: lang.action,
      align: 'center',
      width: '10%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              const itemDetail = items.find((item) => item.id === record.id);
              setItem(itemDetail);
              setIsEditModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-export"
      type="primary"
      loading={loadingBtn}
      onClick={async () => {
        try {
          setLoadingBtn(true);
          const response = await bomGiftList({ warehouseId: currentWarehouse.id });
          Helper.dowloadXLSX(response?.data, 'Bom-gift');
          setLoadingBtn(false);
        } catch (error) {
          setLoadingBtn(false);
          return false;
        }
      }}
    >
      {lang.exportCSV}
    </Button>,
    <Button
      key="btn-import"
      type="primary"
      onClick={() => setUploadModal(true)}
    >
      {lang.importCSV}
    </Button>,
    // <Button
    //   key="btn-complete"
    //   type="primary"
    //   onClick={() => {
    //     setIsEditModal(true);
    //     setItem();
    //   }}
    // >
    //   {lang.btnCreate}
    //   <PlusOutlined />
    // </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/bom-gift',
      breadcrumbName: lang.bomGift,
    },
  ];

  return (
    <Layout className="product-category-listing">
      <PageHeader
        ghost={false}
        title={lang.bomGift}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content style={{ margin: ' 12px 24px' }}>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={20}>
              <Col col={16} style={{ flexDirection: 'row', display: 'flex' }}>
                <Form.Item label={lang.createDate} name="date">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
                <Form.Item name="code" style={{ marginLeft: 20 }}>
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col>
              <Col col={8}>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>

        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.bomGiftlist}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            total: totalItems,
            pageSize: params.limit,
            current: params.page,
            showSizeChanger: true,
          }}
          className="custom-table-row"
        />
      </Layout.Content>
      <BomEditForm
        item={item}
        onCallback={(mode) => {
          mode === MODE.CREATE ? setParams({ ...defaultPage }) : setParams({ ...params });
          setIsEditModal(false);
        }}
        // onCancel={() => setIsEditModal(false)}
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
      />
      <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
        bodyStyle={{ minHeight: 340 }} onOk={async () => {
          try {
            if (file) {
              const formData = new FormData();
              formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
              await importExel(currentWarehouse.id, formData);
              setFile();
              setParams({ ...defaultPage });
              setUploadModal(false);
              message.success(lang.importSuccess);
            } else {
              message.info(lang.chooseFile)
            }
          } catch (error) {
            message.error(error.message);
            setUploadModal(false);
            setFile();
          }
        }}
        okText={lang.okText} cancelText={lang.cancelText}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={() => 
              Helper.dowloadXLSX([
                {  
                  GiftCode: 'S2119',
                  GiftName: 'Similac mom diaper bag',
                  UOM: 'Pcs',
                  'Pcs/Case': '100',
                  ProductLost: '',
                }], 'TemplateBom')
            }>{lang.dowloadTemplate}</Button>
          </Col>
          <Col span={24}>
            <Dragger onChange={(info) => {
              setFile(info.file);
            }}
              maxCount={1}
              // defaultFileList={file}
              showUploadList={true}
              customRequest={async (options) => {
                const { onSuccess } = options;
                onSuccess('OK');
              }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default BomList;
