import request from 'utils/request';
import environment from 'environment';

export async function packagingList(payload) {
  //return request(environment.api.packagingList, payload, 'GET');
  const result = [
    {
        id: 1,
        customer: 'Nguyen Hoai Bao',
        orderCode: 'OD_03',
        orderQuantity: 500,
        packagings: [
            {
                id: 1,
                tempOutLot: 'Zone A',
                packageCode: 'PK_01',
                status: 'NEW',
            },
            {
                id: 2,
                tempOutLot: 'Zone A',
                packageCode: 'PK_02',
                status: 'NEW',
            },
            {
                id: 3,
                tempOutLot: 'Zone A',
                packageCode: 'PK_03',
                status: 'NEW',
            },
            {
                id: 4,
                tempOutLot: 'Zone A',
                packageCode: 'PK_04',
                status: 'NEW',
            },
        ],
    },
    {
        id: 2,
        customer: 'Truong Quoc Trong',
        orderCode: 'OD_04',
        orderQuantity: 600,
        packagings: [
            {
                id: 1,
                tempOutLot: 'Zone B',
                packageCode: 'PK_10',
                status: 'NEW',
            },
            {
                id: 2,
                tempOutLot: 'Zone B',
                packageCode: 'PK_11',
                status: 'NEW',
            },
            {
                id: 3,
                tempOutLot: 'Zone B',
                packageCode: 'PK_12',
                status: 'NEW',
            },
            {
                id: 4,
                tempOutLot: 'Zone B',
                packageCode: 'PK_13',
                status: 'NEW',
            },
        ],
    },
    {
        id: 3,
        customer: 'Ho minh Tri',
        orderCode: 'OD_05',
        orderQuantity: 700,
        packagings: [
            {
                id: 1,
                tempOutLot: 'Zone C',
                packageCode: 'PK_15',
                status: 'NEW',
            },
            {
                id: 2,
                tempOutLot: 'Zone C',
                packageCode: 'PK_16',
                status: 'NEW',
            },
            {
                id: 3,
                tempOutLot: 'Zone C',
                packageCode: 'PK_17',
                status: 'NEW',
            },
            {
                id: 4,
                tempOutLot: 'Zone C',
                packageCode: 'PK_18',
                status: 'NEW',
            },
        ],
    },
    {
        id: 4,
        customer: 'Nguyen Thanh Toan',
        orderCode: 'OD_06',
        orderQuantity: 800,
        packagings: [
            {
                id: 1,
                tempOutLot: 'Zone D',
                packageCode: 'PK_21',
                status: 'NEW',
            },
            {
                id: 2,
                tempOutLot: 'Zone D',
                packageCode: 'PK_22',
                status: 'NEW',
            },
            {
                id: 3,
                tempOutLot: 'Zone D',
                packageCode: 'PK_23',
                status: 'NEW',
            },
            {
                id: 4,
                tempOutLot: 'Zone D',
                packageCode: 'PK_24',
                status: 'NEW',
            },
        ],
    },
  ];

  return result;
}

export async function packagingDetail(payload) {
    //return request(environment.api.receiptList, payload, 'GET');
    const result = [
        {
            id: 1,
            productCode: 'A001',
            productName: 'Produc Name 1',
            productCategory: 'Produc Category 1',
            orderQuantity: 100,
            block: 'Block.A',
            shelf: 'S.01',
            rack: 'R.01',
        },
        {
            id: 2,
            productCode: 'A002',
            productName: 'Produc Name 2',
            productCategory: 'Produc Category 2',
            orderQuantity: 50,
            block: 'Block.B',
            shelf: 'S.02',
            rack: 'R.01',
        },
        {
            id: 3,
            productCode: 'A003',
            productName: 'Produc Name 3',
            productCategory: 'Produc Category 3',
            orderQuantity: 100,
            block: 'Block.C',
            shelf: 'S.03',
            rack: 'R.01',
        },
        {
            id: 4,
            productCode: 'A004',
            productName: 'Produc Name 4',
            productCategory: 'Produc Category 4',
            orderQuantity: 100,
            block: 'Block.D',
            shelf: 'S.04',
            rack: 'R.01',
        },
        {
            id: 5,
            productCode: 'A005',
            productName: 'Produc Name 5',
            productCategory: 'Produc Category 5',
            orderQuantity: 100,
            block: 'Block.E',
            shelf: 'S.05',
            rack: 'R.01',
        },
    ];

    return result;
}

export async function scanProduct(payload) {
    //return request(environment.api.receiptList, payload, 'GET');
    const result = [
        {
            id: 1,
            code: 'PM_01',
            name: 'Nước mắm chinsu',
            orderQuantity: 100,
            pickingQuantity: 90,
            status: 'PICKING',
        },
        {
            id: 2,
            code: 'PM_02',
            name: 'Sữa tươi vinamilk',
            orderQuantity: 50,
            pickingQuantity: 50,
            status: 'PICKING',
        },
        {
            id: 3,
            code: 'PM_03',
            name: 'Bia tiger',
            orderQuantity: 200,
            pickingQuantity: 170,
            status: 'PICKING',
        },
        {
            id: 4,
            code: 'PM_04',
            name: 'Bút bi thiên long',
            orderQuantity: 100,
            pickingQuantity: 100,
            status: 'PICKING',
        },
    ];

    return result;
}

export async function pickingDetail(payload) {
    return request(environment.api.receiptDetail, payload, 'GET');
}

export async function pickingCreate(payload) {
    return request(environment.api.receiptCreate, payload, 'POST');
}

export async function pickingUpdate(payload) {
    return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function pickingDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
      resolve('1');
  });
}

export async function productPicking(payload) {
    return request(environment.api.products + 'get-all', payload, 'GET');
}

export async function packingCreate(payload) {
    return request(environment.api.packages + 'create', payload, 'POST');
}
export async function packingGetone(id) {
    return request(environment.api.packages + `get-one/${id}`, {}, 'GET');
}
export async function orderConfirm(payload) {
    return request(environment.api.Orders + `confirm`, payload, 'POST');
}
