import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, message, Form } from 'antd';
import { ProFormText, ModalForm, ProFormTextArea, ProFormDigit } from '@ant-design/pro-form';
import './warehouse-style.less';
import { productGiftOfRack, productMaterialOfRack, productOfRack } from './warehousestate.service';
import { productUpdate } from '../product-management/product-management.service';
import ProTableCusTom from 'components/TableCustom/Protable';
import Helper from 'utils/helpers';
import { useSelector } from 'react-redux';

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const WarehouseBlock = (props) => {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const { state } = useLocation();
    const [rack] = useState(state);
    const [item, setItem] = useState(null);
    const [rackCode, setRackCode] = useState(rack?.code);
    const actionRef = useRef();
    const formRef = useRef();
    const [form] = Form.useForm();
    const { currentWarehouse } = useSelector((state) => state.auth);
    const [sortBy] = useState('createDate');
    const [sortDirection] = useState('desc');

    useEffect(() => {
        return () => {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    const columns = [
        {
            title: lang.defaultCode,
            dataIndex: 'code',
            key: 'barcode',
            render: (text, record, index) => {
                if (text.startsWith('GIFT')) {
                  return record.bomGift.code;
                }
                return record.masterProduct.code3A;
              },
            },        
        
        {
            title: lang.shortname,
            dataIndex: ['masterProduct', 'shortName'],
            key: 'shortName',
            width: '10%',
        },
        {
            title: lang.productName,
            key: 'name',
            width: '20%',
            dataIndex: 'code',
            render: (text, record, index) => {
                if (text.startsWith('GIFT')) {
                  return record.bomGift.name;
                }
                return record.masterProduct.shortName;
              },
        },
        {
            title: lang.productCode,
            dataIndex: 'code',
            key: 'productCode',
        },
        {
            title: lang.productCategory,
            key: 'productCategorys',
            render: (record) => {
                return record?.masterProduct?.productCategory?.name;
            },
        },
        {
            title: lang.quantityCartton,
            key: 'quantityCartton',
            dataIndex: 'quantityCartton',
        },
        {
            title: lang.quantityTin,
            key: 'quantityTin',
            dataIndex: 'quantityTin',
        },
        {
            title: lang.totalQuantity,
            key: 'totalQuantity',
            dataIndex: 'totalQuantity',
            render: (text, record, index) => {
                if ( record.code.startsWith('GIFT')) {
                  return record.quantity;
                }
                return record.totalQuantity;
              },
        },
        {
            title: lang.block,
            key: 'block',
            render: record => record?.block?.name,
        },
        {
            title: lang.shelf,
            key: 'shelf',
            render: record => record?.rack?.shelf?.name,
        },
        {
            title: lang.rack,
            key: 'rackCode',
            render: record => record?.rack?.code,
        },
        {
            title: lang.action,
            width: '8%',
            fixed: 'right',
            render: (record) => {
                return <Button onClick={() => { setItem(record); setVisible(true); }} style={{ width: '110px', borderRadius: '4px' }} type="primary">{lang.report}</Button>;
            },
        },

    ];

    const routes = [
        {
            path: '/dashboard',
            breadcrumbName: lang.home,
        },
        {
            path: '/inventory',
            breadcrumbName: lang.inventory,
        },
        {
            path: '/warehousestate',
            breadcrumbName: lang.warehouseState,
        },
        {
            path: '/warehouseblock/:blockId',
            breadcrumbName: lang.warehouseProductDetail,
        },
    ];
    const onCancel = () => {
        const valueForm = form.getFieldsValue();
        const isDefined = Object.values(valueForm).some(value => value !== undefined);
        const isUnChange = JSON.stringify(initValues) === JSON.stringify(valueForm);
        if (!isDefined || isUnChange) {
            setVisible(false);
            return true;
        }
        Helper.conFirm(setVisible);
    };
    const initValues = {
        code: item?.code,
        name: item?.masterProduct?.nameEN,
        lostQuantityCarton: 0,
        lostQuantityTin: 0,
        description: item?.description ? item?.description : '',
    };
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.warehouseProductDetail}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <Card size="small" className="cardSearch">
                    <Form
                        className="customFormSearch"
                        onFinish={({ rackCode }) => setRackCode(rackCode)}
                    >
                        <Row gutter={[16, 16]}>
                            <Col sm={6} >
                                <ProFormText name="rackCode" label={lang.rack} value={rackCode} placeholder={lang.inputCode} />
                            </Col>
                            <Col offset={16}>
                                <Button key="btn-search"
                                    type="primary"
                                    htmlType='submit'
                                >{lang.btnSearch}</Button>
                            </Col>
                        </Row>

                    </Form>

                </Card>
                <ProTableCusTom
                    rowKey="id"
                    actionRef={actionRef}
                    headerTitle={lang.productList}
                    columns={columns.filter((col) => {
                        if (col.key === 'shortName' && state?.selfType === 'GIFT') {
                          return false; // ẩn cột Code nếu selfType là GIFT
                        }
                        if (col.key === 'productCategorys' && state?.selfType === 'GIFT') {
                            return false; // ẩn cột Code nếu selfType là GIFT
                          }
                          if (col.key === 'quantityCartton' && state?.selfType === 'GIFT') {
                            return false; // ẩn cột Code nếu selfType là GIFT
                          }
                          if (col.key === 'quantityTin' && state?.selfType === 'GIFT') {
                            return false; // ẩn cột Code nếu selfType là GIFT
                          }
                        return true;
                      })}
                    options={{
                        search: false,
                        reload: false,
                        density: false,
                    }}
                    search={false}
                    scroll={{ x: 2000 }}
                    params={{ rackCode: rackCode }}
                    request={async (params) => {
                        try {
                            if (params?.rackCode) {
                                if (state?.selfType === "NORMAL") {
                                    const { pageSize, current, rackCode } = params;
                                    const response = await productOfRack({ page: current, limit: pageSize, rackCode: rackCode, status: 'STORED', warehouseId: currentWarehouse?.id });
                                    return Promise.resolve({
                                        data: response.data,
                                        total: response.totalItem,
                                        success: true,
                                    });
                                } else if (state?.selfType === "GIFT") {
                                    const { pageSize, current, rackCode } = params;
                                    const response = await productGiftOfRack({ page: current, limit: pageSize, rackCode: rackCode, status: 'STORED', warehouseId: currentWarehouse?.id });
                                    return Promise.resolve({
                                        data: response.data,
                                        total: response.totalItem,
                                        success: true,
                                    });
                                } else if (state?.selfType === "MATERIAL") {
                                    const { pageSize, current, rackCode } = params;
                                    const response = await productMaterialOfRack({ page: current, limit: pageSize, sortBy, sortDirection, warehouseId: currentWarehouse?.id, isAvailable: true, rackCode: rackCode });
                                    return Promise.resolve({
                                        data: response.data,
                                        total: response.totalItem,
                                        success: true,
                                    });
                                } 
                            }
                            return [];
                        } catch (error) {
                            message.log(error.message);
                        }
                    }}
                    pagination={{
                        pageSize: 10,
                        current: 1,
                        showTotal: false,
                    }}
                />
                <ModalForm
                    visible={visible}
                    width={760}
                    formRef={formRef}
                    form={form}
                    title={lang.reportErrorProduct}
                    onVisibleChange={setVisible}
                    modalProps={{
                        closable: false,
                        destroyOnClose: true,
                        // bodyStyle: { padding: '24px 24px 0' },
                        centered: true,
                    }}
                    initialValues={initValues}
                    onFinish={async (value) => {
                        try {
                            await productUpdate({ id: item?.id, description: value?.description, status: 'ERROR', lostNumber: value.lostNumber });
                            actionRef.current.reload();
                            message.success(lang.reportSuccessfully);
                            return true;
                        } catch (error) {
                            message.error(error.message);
                            return false;
                        }
                    }}
                    submitter={{
                        render: ({ form }) => {
                            return [
                                <Button
                                    style={{ width: '130px', height: '32px', borderRadius: '4px' }}
                                    key="extra-reset"
                                    onClick={onCancel}
                                >
                                    {lang.btnCancel}
                                </Button>,
                                <Button
                                    style={{ width: '130px', height: '32px', borderRadius: '4px' }}
                                    type="primary"
                                    key="extra-submit"
                                    onClick={() => form?.submit?.()}
                                >
                                    {lang.btnSave}
                                </Button>,
                            ];
                        },
                    }}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <ProFormText disabled={true} name="code" label={lang.productCode} placeholder={lang.productCode} />
                        </Col>
                        <Col span={8}>
                            <ProFormText disabled={true} name="name" label={lang.productName} placeholder={lang.productName} />
                        </Col>
                        <Col span={8}>
                            <ProFormDigit name="lostQuantityCarton" label={`${lang.quantityCartton} (<=${item?.quantityCartton})`} placeholder={lang.placeholderQuantity} min={0} max={item?.quantityCartton || 1}
                                rules={
                                    [{
                                        required: true,
                                        message: lang.requiredQuantity,
                                    }]
                                } />
                        </Col>
                        <Col span={8}>
                            <ProFormDigit name="lostQuantityTin" label={`${lang.quantityTin} (<=${item?.quantityTin})`} placeholder={lang.placeholderQuantity} min={0} max={item?.quantityTin || 1}
                                rules={
                                    [{
                                        required: true,
                                        message: lang.requiredQuantity,
                                    }]
                                } />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <ProFormTextArea
                                name="description"
                                label={lang.reportContent}
                                placeholder={lang.messageReportContent}
                                fieldProps={{
                                    rows: 2,
                                }}
                                rules={
                                    [{
                                        required: true,
                                        message: lang.requiredReportContent,
                                    }]
                                }
                            />
                        </Col>
                    </Row>

                </ModalForm>
            </Layout.Content>
        </Layout>
    );
};
export default WarehouseBlock;
