import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Row, Col, message, Modal } from 'antd';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';
import './style.less';
// import { bookingDetail } from './picking.service';
import { useSelector } from 'react-redux';
import ProTableCusTom from 'components/TableCustom/Protable';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';
// import { saleorderDetail } from 'pages/dashboard/inbound/production-order/production-order.service';
// import { receiptList } from 'pages/dashboard/inbound/receipt/receipt.service';
// import { receiptList } from 'pages/dashboard/inbound/receipt/receipt.service';
import ReceiptProductsPrint from '../../inbound/receipt/receipt-products-print';
import Helper from 'utils/helpers';
import { deliveryOrderDetail, locatorList } from './delivery-order.service';
import SuggestLocationForm from './suggest-location.form';
import { productList } from 'pages/dashboard/configuration/product/product.service';
const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PickingCreateScan = () => {
  // const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { pickingProducts, pickingOrder } = useSelector((state) => state.outbound);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [productOrderList, setProductOrderList] = useState([]);
  const [locator, setLocator] = useState();
  const [masterProduct, setMasterProduct] = useState([]);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [itemPrint, setItemPrint] = useState();
  const [isEffect, setIsEffect] = useState(false);
  const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const onCallbackPrint = (val) => {
    setItemPrint(val);
    setIsPrintModal(true);
  };
  // useEffect(() => {
  //   productList({ warehouseId: currentWarehouse?.id, group: pickingOrder[0]?.group }).then(values => {
  //     const remp = values?.data?.map(product => {
  //       return { ...product, orderSearchId: product?.orderId, orderQuantityCartton: product?.quantityCartton, orderQuantityTin: product?.quantityTin, pickedQuantityCartton: product?.quantityCartton, pickedQuantityTin: product?.quantityTin, groupOrder: product?.group };
  //     });
  //     setSelectedOrder([...selectedOrder, ...remp]);
  //   });
  // }, [isEffect, currentWarehouse, pickingOrder, setSelectedOrder]);

  // useEffect(() => {
  //   deliveryOrderDetail(id).then(result => {
  //     setPickingList([result])
  //   }).catch(err => err.message)
  // }, [currentWarehouse, id]);

  useEffect(() => {
    locatorList()
    .then(results => {
      setLocator(results.data)
    })
    .catch(err => {
      message.error(err)
     })
  }, [currentWarehouse]);

  useEffect(() => {
    productList({warehouseId: currentWarehouse?.id })
    .then(results => {
      setMasterProduct(results.data)
    })
   .catch(err => {
    message.error(err)
   })
  }, [currentWarehouse]);

  const columnsPickingList = [
    {
      title: lang.defaultCode,
      render: record => record?.masterProduct ? record?.masterProduct?.code3A : record?.code3A,
      key: 'barcode',
    },
    {
      title: lang.shortname,
      // dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
      render: record => {
        if (record?.masterProduct) {
          return record?.masterProduct?.code3A
        } else { 
          const findMasterProduct = masterProduct.find(product => product?.code3A === record?.code3A)
          return findMasterProduct?.shortName
        }
      }
    },
    {
      title: lang.productName,
      key: 'name',
      render: record => {
        if (record?.masterProduct) {
          return record?.masterProduct?.nameEN
        } else { 
          const findMasterProduct = masterProduct.find(product => product?.code3A === record?.code3A)
          return findMasterProduct?.nameEN
        }
      }
    },
    {
      title: lang.lot,
      key: 'lot',
      // dataIndex: 'lot',
      render: record => record?.lot ? record?.lot : ''
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      // dataIndex: 'expireDate',
      render: record => record?.expireDate ? moment(record?.expireDate).format(formatDate) : '',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`
    },
    {
      title: lang.quantityCartton,
      key: 'quantityInCarton',
      dataIndex: 'quantityInCarton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityInTin',
    },
    {
      title: `${lang.takeQuantity} (${lang.carton})`,
      key: 'orderQuantityCartton',
      render: rec => rec?.quantityInCarton - rec?.pickingQuantityCartton,
    },
    {
      title: `${lang.takeQuantity} (${lang.tin})`,
      key: 'orderQuantityTin',
      render: rec => rec?.quantityInTin - rec?.pickingQuantityTin,
    },
    {
      title: `${lang.pickingquantity} (${lang.carton})`,
      key: 'pickingQuantityCartton',
      dataIndex: 'pickingQuantityCartton',
    },
    {
      title: `${lang.pickingquantity} (${lang.tin})`,
      key: 'pickingQuantityTin',
      dataIndex: 'pickingQuantityTin',
    },
  ];
  const extraButton = [
    <Button
      key="btn-suggest"
      type="primary"
      ghost
      onClick={async () => {
        setIsSuggestLocationModal(true);
      }}
    >
      {lang.suggestLocation}
    </Button>,
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate('/dashboard/outbound/delivery-order');
      }}
    >
      {lang.btnCancel}
    </Button>
  ];
  useEffect(() => {
    Promise.all([...pickingOrder.map(async (order) => {
      const reponse = await deliveryOrderDetail(order?.id);
      setProductOrderList(pickingProducts)
      return Promise.resolve(...reponse?.productDeliveryOrders?.map(async (product) => {
        const userName = user?.userInfo?.preferred_username;
        return await {
           ...product, groupOrder: reponse?.group, orderType: 'EXPORT_SALE', saleChannel: reponse?.channel?.channel, orderId: reponse?.id,  warehouse: order.warehouse, userName: userName, //locator: order.product.locator,
        };
      }));
    })]).then((results) => {
      // console.log(results)
      const ctx = results?.reduce((prev, current) => {
        if (current.length > 0 && prev?.find(pre => pre.id === current[0].id)) {
          return prev;
        }
        return prev.concat(current);
      }, []);
      setSelectedOrder([...ctx]);
    }).catch((err) => message.error(err.message));
  }, [isEffect, pickingOrder, currentWarehouse, pickingProducts, user.userInfo.preferred_username]);
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/create',
      breadcrumbName: lang.pickupList,
    },
    {
      path: '/',
      breadcrumbName: lang.scanCode,
    },
  ];
  return (
    <Layout className="storing-scan">
        <PageHeader
          ghost={false}
          title={lang.scanCode}
          extra={extraButton}
          breadcrumb={{
            routes,
            itemRender: breadcrumbItemRender,
          }}
          className="customPageHeader"
        //onBack={onBack}
        />
        <Layout.Content style={{ margin: '12px 24px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ScanProductForm type={'PICKING'} selectOrderProduct={productOrderList?.length > 1 ? productOrderList : selectedOrder} onCallbackPrint={onCallbackPrint} onCallBackEffect={() => setIsEffect(!isEffect)} />
            </Col>
            <Col span={24}>
              <ProTableCusTom
                rowKey="id"
                size="small"
                className="tableCustom"
                bordered
                columns={columnsPickingList}
                params={{ selectedOrder, pickingProducts, productOrderList }}
                request={async (params) => {
                  const { selectedOrder, productOrderList } = params;
                  return Promise.resolve({
                    data: productOrderList?.length > 1 ? productOrderList : selectedOrder,
                    // data: pickingProducts,
                    total: productOrderList?.length > 1 ? productOrderList?.length : selectedOrder?.length,
                    // total: productOrderList?.length,
                    success: true,
                  });
                }}
                search={false}
                options={{
                  search: false,
                  reload: false,
                  density: false,
                }}
                pagination={{
                  showTitle: false,
                  showTotal: false,
                  pageSize: 10,
                }}
                scroll={{ x: 2000 }}
              />
            </Col>
          </Row>
        </Layout.Content>
      <Modal
        visible={isPrintModal}
        title={lang.print}
        onCancel={() => { setIsPrintModal(false); setIsEffect(!isEffect); }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <ReceiptProductsPrint
          items={[itemPrint]}
          onCallback={(values) => {
            Helper.dowloadXLSX(values?.map(item => {
              return {
                code: item.code,
                name: item.masterProduct.name,
                receiptDate: moment(item?.receiptDate, formatDateFull).format(formatDate),
              };
            }), 'productCode');
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onPrint={() => {
            var content = document.getElementById('print');
            var myWindow = window.open('', '');
            myWindow.document.write(content.innerHTML);
            myWindow.document.close(); //missing code
            myWindow.focus();
            myWindow.print();
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
          onCancel={() => {
            setIsPrintModal(false);
            setIsEffect(!isEffect);
          }}
        />
      </Modal>
      <Modal
        visible={isSuggestLocationModal}
        title={lang.suggestLocation}
        onCancel={async () => {
          // await orderCheck({ ids: selectedRowKeys.map(Order => Order.id), userId: null });
          setIsSuggestLocationModal(false);
        }}
        destroyOnClose={true}
        width={1220}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        <SuggestLocationForm
          item={pickingOrder}
          locator={locator}
          masterProductSuggest={masterProduct}
          totalItems={1}
          productOrders={productOrderList.length > 1 ? productOrderList : selectedOrder}
          onCallback={async (values) => {
          }}
          onCancel={async () => {
            setIsSuggestLocationModal(false);
          }}
        />
      </Modal>
    </Layout>
  );
};

export default PickingCreateScan;
