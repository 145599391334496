import request from 'utils/request';
import environment from 'environment';

export async function shippingList(payload) {
  //return request(environment.api.packagingList, payload, 'GET');
  const result = [
    {
        id: 1,
        customer: 'Nguyen Hoai Phuc',
        orderCode: 'OD_07',
        orderQuantity: 500,
        orderDate: '24/8/2021',
        packagings: [
            {
                id: 1,
                packageCode: 'PK_01',
                status: 'WAITING',
            },
            {
                id: 2,
                packageCode: 'PK_02',
                status: 'WAITING',
            },
            {
                id: 3,
                packageCode: 'PK_03',
                status: 'WAITING',
            },
            {
                id: 4,
                packageCode: 'PK_04',
                status: 'WAITING',
            },
        ],
    },
    {
        id: 2,
        customer: 'Truong Quoc Nam',
        orderCode: 'OD_08',
        orderQuantity: 600,
        orderDate: '2/8/2021',
        packagings: [
            {
                id: 1,
                packageCode: 'PK_10',
                status: 'WAITING',
            },
            {
                id: 2,
                packageCode: 'PK_11',
                status: 'WAITING',
            },
            {
                id: 3,
                packageCode: 'PK_12',
                status: 'WAITING',
            },
            {
                id: 4,
                packageCode: 'PK_13',
                status: 'WAITING',
            },
        ],
    },
    {
        id: 3,
        customer: 'Ho Minh Toan',
        orderCode: 'OD_09',
        orderQuantity: 700,
        orderDate: '24/6/2021',
        packagings: [
            {
                id: 1,
                packageCode: 'PK_15',
                status: 'WAITING',
            },
            {
                id: 2,
                packageCode: 'PK_16',
                status: 'WAITING',
            },
            {
                id: 3,
                packageCode: 'PK_17',
                status: 'WAITING',
            },
            {
                id: 4,
                packageCode: 'PK_18',
                status: 'WAITING',
            },
        ],
    },
    {
        id: 4,
        customer: 'Nguyen Thanh Tu',
        orderCode: 'OD_10',
        orderQuantity: 800,
        orderDate: '1/8/2021',
        packagings: [
            {
                id: 1,
                packageCode: 'PK_21',
                status: 'WAITING',
            },
            {
                id: 2,
                packageCode: 'PK_22',
                status: 'WAITING',
            },
            {
                id: 3,
                packageCode: 'PK_23',
                status: 'WAITING',
            },
            {
                id: 4,
                packageCode: 'PK_24',
                status: 'WAITING',
            },
        ],
    },
  ];

  return result;
}

export async function packagingDetail(payload) {
    //return request(environment.api.receiptList, payload, 'GET');
    const result = [
        {
            id: 1,
            productCode: 'A001',
            productName: 'Produc Name 1',
            productCategory: 'Produc Category 1',
            orderQuantity: 100,
            block: 'Block.A',
            shelf: 'S.01',
            rack: 'R.01',
        },
        {
            id: 2,
            productCode: 'A002',
            productName: 'Produc Name 2',
            productCategory: 'Produc Category 2',
            orderQuantity: 50,
            block: 'Block.B',
            shelf: 'S.02',
            rack: 'R.01',
        },
        {
            id: 3,
            productCode: 'A003',
            productName: 'Produc Name 3',
            productCategory: 'Produc Category 3',
            orderQuantity: 100,
            block: 'Block.C',
            shelf: 'S.03',
            rack: 'R.01',
        },
        {
            id: 4,
            productCode: 'A004',
            productName: 'Produc Name 4',
            productCategory: 'Produc Category 4',
            orderQuantity: 100,
            block: 'Block.D',
            shelf: 'S.04',
            rack: 'R.01',
        },
        {
            id: 5,
            productCode: 'A005',
            productName: 'Produc Name 5',
            productCategory: 'Produc Category 5',
            orderQuantity: 100,
            block: 'Block.E',
            shelf: 'S.05',
            rack: 'R.01',
        },
    ];

    return result;
}

export async function scanPackage(payload) {
    //return request(environment.api.receiptList, payload, 'GET');
    const result = [
        {
            id: 1,
            packageCode: 'PK_01',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
        {
            id: 2,
            packageCode: 'PK_02',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
        {
            id: 3,
            packageCode: 'PK_03',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
        {
            id: 4,
            packageCode: 'PK_04',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
        {
            id: 5,
            packageCode: 'PK_05',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
        {
            id: 6,
            packageCode: 'PK_06',
            orderCode: 'OD_01',
            customer: 'Nguyen Hoai Bao',
        },
    ];

    return result;
}

export async function pickingDetail(payload) {
    return request(environment.api.receiptDetail, payload, 'GET');
}

export async function pickingCreate(payload) {
    return request(environment.api.receiptCreate, payload, 'POST');
}

export async function pickingUpdate(payload) {
    return request(environment.api.receiptUpdate, payload, 'PUT');
}

export async function pickingDelete(payload) {
  //return request(environment.api.productDelete, payload, 'DELETE');
  return new Promise((resolve, reject) => {
      resolve('1');
  });
}
export async function getOrder(id) {
    return request(environment.api.Orders + `get-one/${id}`, {}, 'GET');
}
export async function updateMultiplePackage(payload) {
    return request(environment.api.packages + 'update-multiple', payload, 'PUT');
}
export async function getPackageStatus(payload) {
    return request(environment.api.packages + 'get-all', payload, 'GET');
}
