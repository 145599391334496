// Section Import Librari
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; //Link, useNavigate,
import { Layout, PageHeader, Descriptions, Card, Row, Col, message } from 'antd';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { unpackDetail } from './unpack.service';
import moment from 'moment-timezone';

//Section Import Internal
import TableCustom from 'components/TableCustom/table';
import { formatDate, formatDateFull } from 'utils/constants';

const UnpackDetail = () => {
// Section Difine Vảiable
const { t } = useTranslation();
const lang = getLanguages(t);
const {id} = useParams();
const [pageSize, SetPageSize] = useState(10);
const [currentPage, setCurrentPage] = useState(1);
const [loading, setLoading] = useState(false);
const [listProductPack, setListProductPack] = useState([])

// Section Define UseEffect call API
useEffect(()=> {
    setLoading(true)
    unpackDetail(parseInt(id))
        .then(result => {
            setListProductPack([result])
            setLoading(false)
        })
        .catch(err => {
            message.error(err);
            setLoading(false)
        })
}, [id])

    const columns = [
        {
            title: lang.unpackCode,
            dataIndex: 'documentNo',
            key: 'documentNo',
        },

        {
            title: lang.code,
            dataIndex: 'codeProductPack',
            key: 'codeProductPack',
        },

        {
            title: lang.productName,
            dataIndex: 'nameProduct',
            key: 'name',
        },

        {
            title: lang.locator,
            key: 'locatorCode',
            dataIndex: 'locatorCode',
        },

        {
            title: lang.lot,
            key: 'lot',
            dataIndex: 'lot',
        },

        {
            title: lang.expireDate,
            key: 'expireDate',
            dataIndex: 'expireDate',
            render: (val) => {
                return moment(val, formatDateFull).format(formatDate)
            }
        },

        {
            title: lang.quantity,
            key: 'quantity',
            dataIndex: 'quantity',
        },

    ]

    const extraButton = [

    ]

    return (
        <Layout>
            <PageHeader 
             ghost={false}
             title={lang.unpackDetail}
             extra={extraButton}
             className="customPageHeader"
            
            />

            <Layout.Content>
            <Card size="small" className="cardSearch">
                <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 2 }} >
                    <Descriptions.Item label={'Mã UnPack'}>{listProductPack[0]?.documentNo}</Descriptions.Item>
                    <Descriptions.Item label={lang.createDate}>{moment(listProductPack[0]?.createDate, formatDateFull).format(formatDate)}</Descriptions.Item>
                    <Descriptions.Item label={lang.description}>{listProductPack[0]?.description}</Descriptions.Item>
                    <Descriptions.Item label={lang.status}>{
                    listProductPack[0]?.status === 'ENABLE' ? 'Mới tạo'
                    // : production?.status === 'PICKING' ? 'Đã tạo nghiệp vụ'
                    : ''
                    }</Descriptions.Item>
                </Descriptions>
            </Card>

            <TableCustom
                title={() => <Row>
                    <Col span={12}><h3>{lang.unpackProductList}</h3></Col>
                </Row>}
                rowKey="id"
                loading={loading}
                bordered
                columns={columns}
                dataSource={listProductPack}
                onChange={(pagination, filters, sorter) => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setCurrentPage(pagination.current);
                }}
                pagination={{
                    showSizeChanger: true,
                    total: listProductPack?.length,
                    onShowSizeChange: (current, size) => {
                    SetPageSize(size);
                    },
                    pageSize: pageSize,
                    current: currentPage,
                }}
                scroll={{ x: 467 }}
                // rowSelection = {{
                //     onChange: (_, selectedRows) => {
                //     setSelectedRowKeys(selectedRows)
                //     },
                //     getCheckboxProps: (record) => ({
                //     disabled: (record?.locatorCode === null)
                //     })
                // }}
                />
            </Layout.Content>
        </Layout>
    )
}

export default UnpackDetail