import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';
import _ from 'lodash';
import _get from 'lodash/get';
import { productList } from 'pages/dashboard/configuration/product/product.service';
import { getAllProudctLocator } from './production-order.service';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';

const { Option } = Select;

const ReceiptProductEditForm = ({ item, onCancel, onCallback, isOnlyQuantityAllowForUpdate = false, items, type, channel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  // const [productListFind, setProductListFind] = useState([]);
  const isIdm = _get(user, 'roles', '').includes('idm');
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  const [locatorFind, setLocatorFind] = useState();
  const [locators, setLocators] = useState();
  const [quantityTaken, setQuantityTaken] = useState(0);
  
  // console.log(item?.id);
  useEffect(() => {
    setLoading(true);
    productList({ status: 'ENABLE', isAvailableQauntity: true, warehouseId: currentWarehouse?.id })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
    // locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true }).then(val => {
    //   setLocators(val.data);
    //   setLoading(false);
    // }).catch(e => setLoading(false));
  }, [currentWarehouse]);

  useEffect(() => {
    if (item) {
      setProductFind(item?.masterProduct);
      getAllProudctLocator({ masterProductId: item?.masterProduct.id, locatorCode: item?.locator?.locator }).then(res => {
        setLocatorFind(res?.data)
      })
    }
  }, [item]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true, masterProductId: productFind?.id }).then(val => {
      setLocators(val.data);
      setLoading(false);
    });
  }, [currentWarehouse, productFind]);

  useEffect(() => {
    if (item?.masterProduct) {
      setProductFind(item?.masterProduct);
      // form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0 });
    }
  }, [form, item]);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        try {
          if (values.quantityInCarton === 0 && values.quantity === 0) {
            throw new Error(`${lang.quantityCartonAndTin} ${lang.isRequire}`);
          }
          setLoading(true);
          onCallback({
            ...productFind,
            ...values,
            type,
            id: item?.id,
            indx: item?.indx || items?.length + 1,
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: item ? item?.masterProduct?.id : undefined,
        quantityInCarton: item ? item?.quantityInCarton : 0,
        quantity: item ? item?.quantityTin : 0,
        locatorId: item ? item?.locator?.locator : undefined,
        lot: item ? item?.lot : undefined,
        expireDate: item ? item?.expireDate : undefined,

      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                setProductFind(itemFind);
                form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0 });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {products?.map(item => (
                <Option key={item.id} value={item.id}>{item.code3A} - {item.shortName} - {item.nameEN}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isIdm &&  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name='locatorId'
            label={lang.locator}
            rules={[
              {
                required: true,
                message: lang.requiredLocator,
              },
            ]}
            options={locators?.filter(locator => locator.warehouseId === currentWarehouse.id && !locator.warehouseToId)?.map(locator => ({
              label: `${locator.locatorName} - ${locator.locator}`,
              value: locator.locator,
              warehouseId: locator.warehouseId,
              id: locator.id,
            }))}
            placeholder={lang.locator}
            fieldProps={{
              labelInValue: true,
              onChange: async value => {
                if (productFind && !_.isEmpty(value)) {
                  const locator = await getAllProudctLocator({ masterProductId: productFind.id, locatorCode: value?.value });
                  if (locator?.data) {
                    setLocatorFind(locator?.data);
                  } else setLocatorFind();
                } else setLocatorFind();
                form.setFieldsValue({ lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0 });
              },
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              // return true;
              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            showSearch
          />
        </Col>
        }
        {
          isIdm && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lot"
            label={lang.lot}
            rules={[
              {
                required: true,
                message: lang.requiredLot,
              },
            ]}
          >
            <Select
              placeholder={lang.lot}
              // onChange={(value) => {
              //   const itemFind = products.find(product => product.id === value);
              //   setProductFind(itemFind);
              // }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
              onChange={(val) => {
                const find = locatorFind?.find(value => value.lot === val);
                form.setFieldsValue({ expireDate: find?.expireDate, quantityInCarton: 0, quantity: 0 })
              }}
            >
              {locatorFind?.reduce((prev, curr) => {
                if (!prev.some(lot => lot === curr.lot)) {
                  return [...prev, curr.lot];
                }
                return prev;
              }, [])?.map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        }
        {
          isIdm && <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => <Form.Item
              name="expireDate"
              label={lang.expireDate}
              rules={[
                {
                  required: true,
                  message: lang.requiredExpirationDate,
                },
              ]}
            >
              <Select
                placeholder={lang.expireDate}
                // onChange={(value) => {
                //   const itemFind = products.find(product => product.id === value);
                //   setProductFind(itemFind);
                // }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // return true;
                  return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
                onChange={() => form.setFieldsValue({ quantityInCarton: 0, quantity: 0 })}
              >
                {locatorFind?.filter(val => val.lot === getFieldValue('lot'))?.map(item => (
                  <Option key={item.expireDate} value={item.expireDate}>{item.expireDate}</Option>
                ))}
              </Select>
            </Form.Item>}
          </Form.Item>
        </Col>
        }
        {
          isIdm ? <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              if (locatorFind) {
                let totalQuantity = locatorFind?.reduce((prev, cur) => prev + cur.totalQuantityCarttonForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantity = locatorFind?.find(locator => locator.lot === form.getFieldValue('lot'))?.totalQuantityCarttonForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantity = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityCarttonForSale;
                }
                if (items) {
                  totalQuantity -= items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                } else totalQuantity += item?.quantityInCarton;
                return <Form.Item
                  name="quantityInCarton"
                  label={totalQuantity > 0 ? `${lang.availableQuantityCarton} (<=${totalQuantity})` : `${'Số lượng thùng khả dụng'}(=${totalQuantity})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng thùng tồn kho')) : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantity <= 0} style={{ width: '100%' }} min={0} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantityInCarton"
                label={lang.availableQuantityCarton}
              >
                <InputNumber  style={{ width: '100%' }} min={0} max={productFind?.capacity} placeholder={'Số lượng thùng khả dụng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col> : 
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Form.Item shouldUpdate>
          {() => {
            let totalQuantity = productFind ? productFind.masterProductsLocators.reduce((prev, curr) => prev + curr?.totalQuantityCarttonForSale, 0) : 0;
            return <Form.Item 
            name="quantityInCarton"
            label={`${lang.quantityCartton} (${totalQuantity})`}
            rules={[
              {
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: (_, value) => value ? (value <= totalQuantity ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng thùng tồn kho')) : Promise.resolve(),
              },
            ]}
            >
              <InputNumber disabled={!productFind} style={{ width: '100%' }} min={0} onChange={(value) => setQuantityTaken(value)}/>
            </Form.Item>
          }}
        </Form.Item>
      </Col>
        }
        {
          isIdm ? <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              if (locatorFind) {
                let totalQuantityTin = locatorFind?.reduce((prev, cur) => prev + cur.totalQuantityForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantityTin = locatorFind?.find(locator => locator.lot === form.getFieldValue('lot'))?.totalQuantityForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantityTin = locatorFind?.find(locator => form.getFieldValue('lot') === locator.lot && locator.expireDate === form.getFieldValue('expireDate'))?.totalQuantityForSale;
                }
                if (items) {
                  const cartons = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                  const tins = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantity, 0)
                  totalQuantityTin -= (form.getFieldValue('quantityInCarton') + cartons) * parseInt(productFind.tinPerCarton) - tins;
                } else {
                  totalQuantityTin += item?.quantityInCarton * parseInt(productFind.tinPerCarton) + item?.quantityTin;
                  totalQuantityTin -= form.getFieldValue('quantityInCarton') * parseInt(productFind.tinPerCarton);
                }
                return <Form.Item
                  name="quantity"
                  label={totalQuantityTin > 0 ? `${'Số lượng lon khả dụng'}(<=${totalQuantityTin})` : `${'Số lượng lon khả dụng'}(=${totalQuantityTin})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => value ? (value <= totalQuantityTin ? Promise.resolve() : Promise.reject('Nhỏ hơn số lượng lon tồn kho')) : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantityTin <= 0} style={{ width: '100%' }} min={0} placeholder={'Số lượng lon khả dụng'} value={totalQuantityTin} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantity"
                label={'Số lượng lon khả dụng'}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={productFind?.capacity} placeholder={'Số lượng lon khả dụng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col> :
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
          {() => {
            let totalQuantity = productFind ? productFind.masterProductsLocators.reduce((prev, curr) => prev + curr?.totalQuantityForSale, 0) - quantityTaken * (productFind?.tinPerCarton) : 0;
            return <Form.Item 
            name="quantity"
            label={`Số lượng lon (${totalQuantity})`}
            rules={[
              {
                // eslint-disable-next-line prefer-promise-reject-errors
                validator: (_, value) => value ? (
                  (value >= productFind.tinPerCarton) ? Promise.reject('Nhỏ hơn số lượng của 1 thùng') :
                value <= totalQuantity ? Promise.resolve() : 
                Promise.reject('Nhỏ hơn số lượng thùng tồn kho')) : Promise.resolve(),
              },
            ]}
            >
              <InputNumber disabled={!productFind || totalQuantity < 0} style={{ width: '100%' }} min={0} />
            </Form.Item>
          }}
        </Form.Item>
      </Col>
        }
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  items: PropTypes.array,
  type: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
