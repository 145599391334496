import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col } from 'antd';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import './style.less';
import { saleorderDetail } from './sale-order.service';
import { formatDate, formatDateFull, formatDateSlash, formatDatetime } from 'utils/constants';
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
import logo from 'assets/logoAKAWMS/akawms.svg';
import { productPicking } from 'pages/dashboard/outbound/packaging/packaging.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const ConfirmOrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    saleorderDetail(id)
      .then(result => {
        setSaleorder(result);
        return productPicking({ warehouseId: result.warehouseId, orderId: result.id });
      })
      .then(rs => rs && (setProductOrder(rs?.data), setLoading(false)))
      .catch((e) => setLoading(false));
  }, [id]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['masterProduct', 'code3A'],
      key: 'code3A',
    },
    {
      title: lang.productCode,
      key: 'code',
      render: rec => rec?.code,
    },
    {
      title: lang.productName,
      dataIndex: ['masterProduct', 'nameEN'],
      key: 'productName',
    },
    {
      title: lang.lot,
      key: 'lot',
      render: rec => rec?.lot,
    },
    {
      title: lang.expireDate,
      key: 'expireDate',
      render: rec => moment(rec?.expireDate, formatDate).format(formatDate),
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      render: rec => rec?.quantityCartton || rec?.quantityInCarton || 0,
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      render: rec => rec?.quantityTin,
    },
    {
      title: lang.supplier,
      key: 'supplier',
      dataIndex: ['masterProduct', 'suppliers'],
      render: (values) => values?.map(value => value.name).join(', '),
    },
  ];
  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1);
      }}
    >
      {lang.btnCancel}
    </Button>,
    saleorder?.orderType === 'EXPORT_INTERNAL' && <Button
      key="btn-export"
      type="primary"
      ghost
      onClick={async () => {
        const content = `
      <div style="display: grid ;line-height: 0.5;width: 950px; font-size: 16px; font-family: "Times New Roman", Times, serif;">
        <div style="display: flex; height: 120px;">
          <div style="text-align:center; width: 20%; border-image: initial; padding: 10px">
            <img src="${logo}" style=" height: 100%; width: 100%" />
          </div>
          <div style="width: 60%; text-align: center; padding-top: 7vh; ">
            <h1>{lang.btnCancel} {lang.internalTransferNote}</h1>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid;">
            <p> <b>{lang.internalStockTransferNumber}: ${saleorder?.internalTranferCode} </b> </p>
            <p> <b>{lang.internalUseExportNumber}: </b> </p>
          </div>
          <div style="display: grid; line-height: 0.5;">
            <p> <b>{lang.printDate}: ${moment().format(formatDatetime)} </b> </p>
            <p> <b>{lang.transferDate}: ${saleorder?.movementDate ? moment(saleorder?.movementDate, formatDateFull).format(formatDate) : moment().format(formatDate)}</b> </p>
            <p> <b>{lang.completionDate}:</b> </p>
          </div>
        </div>
        <div style="min-height: 1000px;">
          <table border="1" style="width: 100%; border-collapse: collapse; line-height: 1;">
            <thead>
              <tr style=" background-color: #009ada; ">
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.completionDate}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.code3A}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.productName}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.lotNumberExpiryDate}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.fromWarehouse}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.toWarehouse}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.carton}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.tin}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.damaged}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.dented}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.ERROR}</div></th>
                <th rowspan="2"><div style="min-height: 50px; display: flex; align-items: center;">{lang.missing}</div></th>
              </tr>
            </thead>
            <tbody>
            ${productOrder?.map((product) => {
          const productfind = saleorder?.productOrders?.find(productOrder => productOrder.masterProduct.id === product.masterProduct.id);
          return `<tr style="text-align: center;">
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.abbottCode16}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.code3A}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.masterProduct.nameEN}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${saleorder?.status === 'EXPORTED' ? `&lt;${product.code}&gt;_${moment(product.expireDate).format(formatDateSlash)}` : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${productfind?.locatorTo ? productfind?.locatorTo.locatorName : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${productfind?.locator ? productfind?.locator.locatorName : ''}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product?.quantityInCarton || product.quantityCartton}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;">${product.quantityTin}</div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                          <td><div style="min-height: 30px; display: flex; align-items: center;"></div></td>
                      </tr>`;
        }).join(' \n')}
              <tr>
                <td colSpan="7">
                  <b style="min-height: 30px; display: flex;">{lang.sum}</b>
                </td>
                <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0)}</td>
                <td style="font-weight: bold;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.pickingStaff}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.warehouseKeeper}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
          <div style="display: grid; justify-content: center; width: 33%; text-align: center;">
            <p> <b>{lang.tableCreator}</b> </p>
            <p> <b>{lang.signatureFullName}</b> </p>
          </div>
        </div>
      </div>`;
        var template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        await html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          var imgData = canvas.toDataURL('image/png');
          // eslint-disable-next-line new-cap
          const pdf = new jsPdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`PhieuNoiBo${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
      }}
    >
      {lang.exportPdf}
    </Button>,
  ];

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.configuration,
    },
    {
      path: '/sale-order',
      breadcrumbName: lang.saleOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.confirmOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.confirmOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 2 }} >
            <Descriptions.Item label={lang.orderCode}>{saleorder?.code}</Descriptions.Item>
            {saleorder?.orderType === 'EXPORT_SALE' && <>
              <Descriptions.Item label={lang.company}>{saleorder?.company}</Descriptions.Item>
              <Descriptions.Item label={lang.branch}>{saleorder?.branch}</Descriptions.Item>
              <Descriptions.Item label={lang.orderReference}>{saleorder?.orderReference}</Descriptions.Item>
              <Descriptions.Item label={lang.businessLine}>{saleorder?.bussinessLine}</Descriptions.Item>
              <Descriptions.Item label={lang.abbottPOReference}>{saleorder?.abbottPOReference}</Descriptions.Item>
              <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
              <Descriptions.Item label={lang.dateOrdered}>{moment(saleorder?.dateOrdere).format(formatDatetime)}</Descriptions.Item>
              <Descriptions.Item label={lang.giftWarehouse}>{saleorder?.giftWarehouse}</Descriptions.Item>
              <Descriptions.Item label={lang.customerProgram}>{saleorder?.customerProgram}</Descriptions.Item>
              <Descriptions.Item label={lang.subChannel}>{saleorder?.subChannel}</Descriptions.Item>
            </>
            }
            {saleorder?.orderType !== 'EXPORT_SALE' && <>
              <Descriptions.Item label={lang.locator}>{saleorder?.orderType === 'EXPORT_BRANCH' ? `${saleorder?.locatorTransit?.locatorName} - ${saleorder?.locatorTransit?.locator}` : saleorder?.productOrders[0]?.locatorTo?.locatorName} - {saleorder?.productOrders[0]?.locatorTo?.locator}</Descriptions.Item>
              <Descriptions.Item label={lang.documentNo}>{saleorder?.internalTranferCode}</Descriptions.Item>
              <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.createDate).format(formatDatetime)}</Descriptions.Item>
              <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
              {saleorder?.orderType === 'EXPORT_BORROW' && <Descriptions.Item label={lang.consignmentOrder}>{saleorder?.typeBorrow}</Descriptions.Item>}
            </>}
            <Descriptions.Item label={lang.status}>
              {saleorder?.status}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={productOrder}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: productOrder?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default ConfirmOrderDetail;
