import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, message, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormSelect, ProFormText, ProFormDatePicker } from '@ant-design/pro-form';
import { createAPInvoice } from './apinvoice.service';
import { productList } from 'pages/dashboard/configuration/product/product.service';
import { MinusSquareOutlined, PlusOutlined } from '@ant-design/icons';
import { formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';

const ApInvoiceCreateForm = ({ onCallBack, setIsEditModal, isEditModal, warehouseId }) => {
    const { currentWarehouse } = useSelector((state) => state.auth);
    const [formParent] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [products, setProducts] = useState([]);
    const initValues = {
        invoiceProducts: [
            {
                masterProductId: undefined,
                quantityCartton: 0,
                quantityTin: 0,
                lot: undefined,
                warehouseId: undefined,
                invoiceNo: undefined,
                orderNo: undefined,
                shipment: undefined,
                mfgDate: undefined,
                contractNo: undefined,
            },
        ],
    };

    useEffect(() => {
        return () => {
            formParent.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditModal]);

    useEffect(() => {
        productList({ warehouseId: currentWarehouse.id, status: 'ENABLE' })
            .then(result => {
                setProducts(result.data);
            });
    }, [currentWarehouse]);
    const onCancel = () => {
        setIsEditModal(false);
    };

    return (
        <Modal
            visible={isEditModal}
            title={`${lang.new} APInvoice`}
            onCancel={onCancel}
            afterClose={() => formParent.resetFields()}
            width={992}
            centered
            footer={null}
            wrapClassName="product-category-modal"
            forceRender={true}
        >
            <Form
                colon={false}
                form={formParent}
                layout="vertical"
                name="productCategoryEditForm"
                requiredMark={false}
                onFinish={(values) => {
                    setLoading(true);
                    values.invoiceProducts = values.invoiceProducts?.map(product => {
                        if (product?.expiryDate) {
                            product.expiryDate = product.expiryDate.format(formatDateFull);
                        }
                        if (product?.mfgDate) {
                            product.mfgDate = product.mfgDate.format(formatDateFull);
                        }
                        product.shipmentNo = values?.shipmentNo;
                        return product;
                    });
                    values.warehouseId = warehouseId;
                    values.qtyInCarton = 0;
                    values.qtyInTin = 0;
                    values.dataInvoice = 'string';
                    values.invoice = 'string';
                    values.quantityInvoice = 'string';
                    values.code3A = 'string';
                    values.qtyInvoiced = 0;
                    createAPInvoice(values).then(() => {
                        setLoading(false);
                        onCallBack();
                        message.success(lang.createSuccessfully);
                        setIsEditModal(false);
                    })
                        .catch((err) => message.error(err.message), setLoading(false));
                }}
                initialValues={initValues}
            >
                <Row gutter={24} type="flex">
                    <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                        <ProFormText name='company' label={lang.company} placeholder={lang.company} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='branch' label={lang.branch} placeholder={lang.branch} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='shipmentNo' label='ShipmentNo' placeholder='ShipmentNo' />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='invoiceType' label={lang.invoiceType} placeholder={lang.invoiceType} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='physicalInvoiceNo' label={lang.invoiceNo} placeholder={lang.invoiceNo} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='businessPartner' label={lang.seller} placeholder={lang.seller} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='partnerLocation' label={lang.partnerAddress} placeholder={lang.partnerAddress} />
                    </Col>
                    <Col span={8}>
                        <ProFormText name='vendorInvoiceNo' label='VendorInvoiceNo' placeholder='VendorInvoiceNo' />
                    </Col>
                    {/* <Col span={8}>
                        <ProFormText name='poReference' label='PO Reference' placeholder='PO Reference' />
                    </Col> */}
                    <Col span={24}>
                        <Form.List name="invoiceProducts" label={lang.bomList}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }) => (
                                        <Row gutter={[16, 16]} align="middle" key={name}>
                                            <Col span={6}>
                                                <ProFormSelect
                                                    name={[name, 'masterProductId']}
                                                    label={lang.productName}
                                                    options={products?.map(product => ({
                                                        value: product.id,
                                                        label: `${product.nameEN} (${product.shortName}) - ${product.code3A}`,
                                                        key: product.id,
                                                        warehouse: product.warehouseId,
                                                    }))}
                                                    placeholder={lang.productName}
                                                    onChange={(_, rec) => formParent.setFields([{
                                                        name: ['invoiceProducts', name, 'warehouseId'],
                                                        value: rec.warehouse,
                                                    }])}
                                                    showSearch={true}
                                                    optionFilterProp='children'
                                                    filterOption={(input, option) => {
                                                        return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                    }}
                                                    allowClear={false}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                                                    {(form) => {
                                                        const tins = form?.getFieldValue(['invoiceProducts', name, 'quantityTin']);
                                                        return <Form.Item
                                                            name={[name, 'quantityCartton']}
                                                            label={lang.quantityCartton}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                                {
                                                                    // eslint-disable-next-line prefer-promise-reject-errors
                                                                    validator: (_, value) => value === 0 && tins === 0 ? Promise.reject(lang.requiredQuantity) : Promise.resolve(),
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
                                                        </Form.Item>;
                                                    }}
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                                                    {(form) => {
                                                        const cartons = form?.getFieldValue(['invoiceProducts', name, 'quantityCartton']);
                                                        return <Form.Item
                                                            name={[name, 'quantityTin']}
                                                            wrapperCol={24}
                                                            label={lang.quantityTin}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                                {
                                                                    // eslint-disable-next-line prefer-promise-reject-errors
                                                                    validator: (_, value) => value === 0 && cartons === 0 ? Promise.reject(lang.requiredQuantity) : Promise.resolve(),
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                                                        </Form.Item>;
                                                    }}
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    name={[name, 'lot']}
                                                    wrapperCol={24}
                                                    label={'Lot'}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder={'Lot'} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[name, 'invoiceNo']}
                                                    wrapperCol={24}
                                                    label={lang.invoiceNo}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder={lang.invoiceNo} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[name, 'orderNo']}
                                                    wrapperCol={24}
                                                    label={lang.orderNo}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder={lang.orderNo} />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col span={6}>
                                                <Form.Item
                                                    name={[name, 'shipmentNo']}
                                                    wrapperCol={24}
                                                    label={lang.shipment}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder={lang.shipment} />
                                                </Form.Item>
                                            </Col> */}
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[name, 'contractNo']}
                                                    wrapperCol={24}
                                                    label={lang.contractNo}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder={lang.contractNo} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    name={[name, 'poReference']}
                                                    wrapperCol={24}
                                                    label='PO Reference'
                                                >
                                                    <Input style={{ width: '100%' }} placeholder='poReference' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <ProFormDatePicker name={[name, 'mfgDate']} label={lang.mfgDate} placeholder={lang.mfgDate} fieldProps={{
                                                    style: { width: '100%' },
                                                    format: formatDate,
                                                    disabledDate: (date) => date > moment(),
                                                }} />
                                            </Col>
                                            <Col span={4}>
                                                <ProFormDatePicker name={[name, 'expiryDate']} label={lang.expireDate} placeholder={lang.expireDate} fieldProps={{
                                                    style: { width: '100%' },
                                                    format: formatDate,
                                                }} />
                                            </Col>
                                            <Col>
                                                <Button
                                                    danger
                                                    type="text"
                                                    onClick={() => remove(name)}
                                                    icon={<MinusSquareOutlined />}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item wrapperCol={24}>
                                        <Button
                                            type="dashed"
                                            onClick={() => add({ masterProductId: undefined, quantityCartton: 0, quantityTin: 0 })}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            {lang.btnCreate}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <div
                    className="ant-modal-footer"
                    style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
                >
                    <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
                        <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
                            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                                {lang.btnCancel}
                            </Button>
                            {loading === false ? (
                                <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                                    {lang.btnSave}
                                </Button>
                            ) : (
                                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                                    {lang.loading}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal >
    );
};

ApInvoiceCreateForm.propTypes = {
    setIsEditModal: PropTypes.func,
    onCallBack: PropTypes.func,
    isEditModal: PropTypes.bool,
    warehouseId: PropTypes.number,
};

export default ApInvoiceCreateForm;
