import React, { useState } from 'react';
import { Col, Row, Button, Card } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { formatDate, formatDateFull } from 'utils/constants';
import Helper from 'utils/helpers';
import moment from 'moment-timezone';

const ProductPrint = ({ item, onCancel, onCallback }) => {
  const [loading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  return (
    <>
      <Row gutter={16}>
        <Col key={item.id} span={24}>
          <Card bodyStyle={{ padding: 0, textAlign: 'center' }}>
            {Helper.genarateQRcode([item?.code])}
            {Helper.genarateBarcode([item?.code])}
            <div style={{ textAlign: 'center' }}>{`${lang.defaultCode}:${item?.masterProduct?.code3A}`}</div>
            <div style={{ textAlign: 'center' }}>{`${lang.productCode}: ${item?.code}`}</div>
            <div style={{ textAlign: 'center' }}>{`${lang.expireDate}: ${item?.expireDate ? moment(item?.expireDate, formatDateFull).format(formatDate) : ''}`}</div>
            <div style={{ textAlign: 'center' }}>{`${lang.totalQuantity}: ${item?.totalQuantity}`}</div>
            <div style={{ textAlign: 'center' }}>{`${lang.entryDate}: ${item?.recieveDate ? moment(item?.recieveDate, formatDateFull).format(formatDate) : ''}`}</div>
          </Card>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnClose}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

ProductPrint.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default ProductPrint;
