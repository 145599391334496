import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Descriptions } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';
import './style.less';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
import moment from 'moment-timezone';
import { formatDate, formatDateFull } from 'utils/constants';

const CustomerRMAProductForm = ({ items, onCallBack, onCancel, item }) => {
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [formParent] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [locatorsList, setLocatorList] = useState([]);
  const initValues = {
    customerRMAProducts: items?.map(rmaProduct => ({
      id: rmaProduct?.id,
      masterId: rmaProduct?.masterProduct?.id,
      name: `${rmaProduct?.masterProduct?.nameEN}(${rmaProduct?.masterProduct?.shortName}) - ${rmaProduct?.masterProduct?.code3A}`,
      attributeSetInstance: rmaProduct?.attributeSetInstance,
      quantity: rmaProduct?.quantity,
      locator: undefined,
    })),
  };
  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id }).then(value => setLocatorList(value?.data));
  }, [currentWarehouse]);
  return (

    <Form
      colon={false}
      form={formParent}
      layout="vertical"
      name="productCategoryEditForm"
      onFinish={(values) => {
        return onCallBack(values);
      }}
      initialValues={initValues}
    >
      <Row gutter={24} type="flex">
        <Descriptions className="descriptions-class" column={{ xl: 4, md: 3, sm: 2, xs: 1 }}>
          <Descriptions.Item label={'Mã ' + lang.customerReturn}>{item?.documentNo}</Descriptions.Item>
          <Descriptions.Item label={lang.company}>{item?.company}</Descriptions.Item>
          <Descriptions.Item label={lang.branch}>{item?.branch}</Descriptions.Item>
          {/* <Descriptions.Item label={lang.documentNo}>{item?.documentNo}</Descriptions.Item> */}
          <Descriptions.Item label={lang.orderReference}>{item?.orderReference}</Descriptions.Item>
          <Descriptions.Item label={lang.description}>{item?.description}</Descriptions.Item>
          <Descriptions.Item label={lang.targetDocType}>{item?.targetDocType}</Descriptions.Item>
          <Descriptions.Item label={lang.rmaCategory}>{item?.rmaCategory}</Descriptions.Item>
          <Descriptions.Item label={lang.origSalesOrder}>{item?.origSalesOrder}</Descriptions.Item>
          <Descriptions.Item label={lang.origDO}>{item?.origDO}</Descriptions.Item>
          <Descriptions.Item label={lang.returnDate}>{moment(item?.rmaDate, formatDateFull).format(formatDate)}</Descriptions.Item>
          <Descriptions.Item label={lang.DODate}>{moment(item?.DODate, formatDateFull).format(formatDate)}</Descriptions.Item>
          <Descriptions.Item label={lang.businessPartner}>{item?.businessPartner}</Descriptions.Item>
          <Descriptions.Item label={lang.billTo}>{item?.billTo}</Descriptions.Item>
          <Descriptions.Item label={lang.partnerLocation}>{item?.partnerLocation}</Descriptions.Item>
          <Descriptions.Item label={lang.billToLocation}>{item?.billToLocation}</Descriptions.Item>
          <Descriptions.Item label={lang.representative}>{item?.representative}</Descriptions.Item>
          <Descriptions.Item label={lang.businessLine}>{item?.businessLine}</Descriptions.Item>
          <Descriptions.Item label={lang.area}>{item?.warehouse}</Descriptions.Item>
          <Descriptions.Item label={lang.campaign}>{item?.campaign}</Descriptions.Item>
          <Descriptions.Item label={lang.department}>{item?.department}</Descriptions.Item>
        </Descriptions>
        <Col span={24}>
          <Form.List name="customerRMAProducts" label={lang.customerRMAProducts}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <Row gutter={[16, 16]} align="middle" key={name}>
                    <Col span={16}>
                      <ProFormText
                        name={[name, 'name']}
                        label={lang.productName}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: intl.formatMessage({
                        //       id: 'pages.goodIO.message.required-description',
                        //       defaultMessage: 'Please enter goods description!',
                        //     }),
                        //   },
                        // ]}
                        // fieldProps={{
                        //   labelInValue: true
                        // }}
                        placeholder={lang.productName}
                        disabled
                      />
                    </Col>
                    <Col span={8}>
                      <ProFormText
                        name={[name, 'attributeSetInstance']}
                        label={lang.attributeSetInstance}
                        placeholder={lang.attributeSetInstance}
                      />
                    </Col>
                    <Col span={8}>
                      <ProFormText
                        name={[name, 'quantity']}
                        label={lang.quantity}
                        placeholder={lang.quantity}
                      />
                    </Col>
                    <Col span={8}>
                      <ProFormSelect name={[name, 'locator']} label={lang.locator} params={{ warehouseId: currentWarehouse?.id }}
                                     placeholder={lang.locator}
                                     options={locatorsList?.map(locator => {
                          return {
                            value: locator.locator,
                            label: `${locator.locatorName} - ${locator.locator}`,
                          };
                        })}
                                     showSearch
                                     optionFilterProp="children"
                                     filterOption={(input, option) => {
                          return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
              {lang.btnSave}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

CustomerRMAProductForm.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default CustomerRMAProductForm;
