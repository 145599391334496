import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Descriptions, Card, Row, Col, Modal, message, Form } from 'antd';
import './style.less';
import { giftManueuverCommandDetail, giftOrderCMCreate, productGiftOrderUpdate, updateOrderGift } from './production-order.service';
import { formatDate, formatDateFull } from 'utils/constants';//formatDatetime
import moment from 'moment-timezone';
import TableCustom from 'components/TableCustom/table';
// import SuggestLocationForm from './suggest-location.form';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons'; //, DeleteOutlined, ExclamationCircleOutlined
// import { orderUpdateStatus } from 'pages/dashboard/outbound/picking/picking.service';
// import { deleteProductOrder } from 'pages/dashboard/configuration/sale-order/sale-order.service';
import ProducttionGiftInternal from './gift-producttion-order-create-internal.edit';
import _get from 'lodash/get';
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import { warehouseListAll } from 'pages/admin/warehouse/warehouse.service';
import logo from 'assets/logoAKAWMS/akawms.svg';
import {
  ModalForm,
  ProFormTextArea
} from '@ant-design/pro-form';
// import ReceiptProductGiftEditForm from './gift-production-order-product-edit.form';
import ReceiptProductEditForm from './gift-production-order-create-product-edit.form';
// import ReceiptProductEditForm from './gift-production-order-create-product-edit.form';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const GiftManueuverCommandDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingLDD, setLoadingLDD] = useState(false);
  const [loadingPBCH, setLoadingPBCH] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [formCancel] = Form.useForm();
  // const [isSuggestLocationModal, setIsSuggestLocationModal] = useState(false);
  const [saleorder, setSaleorder] = useState();
  const [pageSize, SetPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  // const [deleteProduct, setDeleteProduct] = useState();
  const { currentWarehouse, user } = useSelector((state) => state.auth);
  const isManager = _get(user, 'roles', '').includes('manager');
  const isIdm = _get(user, 'roles', '').includes('idm');
  const isOperator = _get(user, 'roles', '').includes('operator');
  // const isStocker = _get(user, 'roles', '').includes('stocker');
  const [modalCreateInternal, setModalCreateInternal] = useState(false);
  const [checkQuantity, setCheckQuantity] = useState(false);
  const [checkManuever, setCheckManuever] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: setSelectedRowKeys,
  // };

  useEffect(() => {
    setLoading(true);
    warehouseListAll()
      .then(result => {
        setWarehouses(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [currentWarehouse]);

  useEffect(() => {
    setLoading(true);
    giftManueuverCommandDetail(parseInt(id))
      .then(async (result) => {
        setSaleorder(result);
        const data = result.productGiftOrders;
        const quantity = data.reduce((prev, curr) => { prev.quantity += curr.quantity; prev.pickedQuantity += curr.pickedQuantity; return prev; }, {quantity: 0, pickedQuantity: 0});
        if ((quantity?.pickedQuantity === 0)) {
          setCheckQuantity(false);
          setCheckManuever(false);
        } else if ((quantity?.pickedQuantity < quantity?.quantity)) {
          setCheckManuever(true);
          setCheckQuantity(false);
        }
        if ((quantity?.pickedQuantity === quantity?.quantity) ) {
          setCheckQuantity(true);
          setCheckManuever(true);
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [id, checkLoading]);
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: ['bomGift', 'code'],
      key: 'code',
    },
    {
      title: lang.productName,
      dataIndex: ['bomGift', 'name'],
      key: 'productName',
    },
    {
      title: lang.locator,
      key: 'locatorResponse',
      render: record => record?.locatorCode ? record?.locatorCode : ''
    },
    {
      title: lang.quantity,
      key: 'quantity',
      render: rec => rec?.quantity,
    },
    {
      title: lang.pickingquantity,
      key: 'pickedQuantity',
      render: rec => rec?.pickedQuantity,
    },
    {
      title: lang.action,
      align: 'center',
      width: '8%',
      fixed: 'right',
      hideInTable: saleorder?.status === 'EXPORTED' || (saleorder?.status === 'CONFIRM' && !isIdm) || (saleorder?.status === 'CONFIRM1' && !isManager) || isOperator,
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="link"
            size="small"
            disabled={record?.pickedQuantity > 0}
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditModal(true);
              setItem(record);
            }}
          />
          {/* <Button
            type="link"
            size="small"
            disabled={(record?.pickingQuantityCartton === record?.quantityInCarton && record?.pickingQuantityCartton > 0 && record?.quantityInCarton > 0) || 
              (record?.pickingQuantityTin === record?.quantityTin && record?.pickingQuantityTin > 0 && record?.quantityTin > 0)}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: lang.doYouWantToDeleteTheseItems,
                icon: <ExclamationCircleOutlined />,
                onOk: async () => {
                  try {
                    await deleteProductOrder(record.id);
                    message.success(lang.deleteSuccessfully);
                    setDeleteProduct(!deleteProduct);
                    return true;
                  } catch (error) {
                    message.error(error.message);
                    return false;
                  }
                },
                onCancel() {

                },
              });
            }}
          /> */}
        </div>
      ),
    },
  ];

  const extraButton = [
    <Button
      key="btn-cancel"
      type="primary"
      ghost
      onClick={() => {
        navigate(-1)
      }}
    >
      {'Back'}
    </Button>,
    isIdm && <Button
    disabled={saleorder?.status === 'CANCELED'}
    key="btn-export-ldd"
    type="primary"
    // ghost
    loading={loadingLDD}
    onClick={async () => {
      setLoadingLDD(true);
      // eslint-disable-next-line new-cap
      const listProducts = saleorder?.productOrders[0].locatorTo;
      const currWarehouse = warehouses.find(item => item?.id === listProducts?.warehouseId);
      const warehouseTo = warehouses.find(item => item?.id === listProducts?.warehouseToId);
      const dateTime = new Date();
          const date = dateTime.getDate();
          const month = (dateTime.getMonth() + 1);
          const year = (dateTime.getFullYear());

      let content = `
      <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
          <div style="display:flex">
              <div style=" margin-top: 20px; padding: 10px 0; line-height: 1; width: 45%; ">
                <div>
                  <p style="font-weight: bold; font-size: 18px;">CÔNG TY TNHH DINH DƯỠNG 3A (VIỆT NAM)</p>
                  <p>Centec Tower, 72-74 Nguyễn Thị Minh Khai, P.6, Q.3, TP.HCM</p>
                  <p>Tel: (84.8) 3823 8222 - Fax: (84.8) 3823 8777</p>
                </div>  
                <div style="margin-left: 50px; padding-top: 50px">Số LĐĐ: ${saleorder?.code}</div>  
              </div>
              <div style="width: 10%"></div>
            <div style="width: 45%; margin-top: 20px; padding: 10px 0; ">
              <div style="text-align: center">
                <p style="font-weight: bold; font-size: 18px;">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                <p><u>Độc Lập - Tự Do - Hạnh Phúc</u></p>
              </div>
              <div style="margin-top: 50px; text-align:left; margin-left:20px; padding: 4px 5px;width: 45%; font-weight: bold; border: 2px solid #333;">
                <p>Code: P-85-01-F3</p>
                <p>Issue date: 02/02/2016</p>
                <p>No:</p>
              </div>
            </div>
          </div>
          <div style="; margin-top: 30px">
            <div style="width: 100%; line-height: 1.2;">
              <div style="font-size: 25px; font-weight:bold; text-align: center;">LỆNH ĐIỀU ĐỘNG HÀNG HÓA NỘI BỘ</div>
            </div>
          </div>

          <div style="margin-top: 20px">
            <p>- Căn cứ vào nhu cầu hàng hoá.</p>
            <p>- Đại diện Công ty TNHH Dinh Dưỡng 3A (Việt Nam) quyết định điều động một số mặt hàng</p>
            <p>Từ kho: ${currWarehouse?.name}</p>
            <p>Địa chỉ: ${currWarehouse?.address}</p>
            <p>Đến kho: ${warehouseTo?.name}</p>
            <p>Địa chỉ: ${warehouseTo?.address}</p>
          </div>    

          <table style="border: 2px solid #333; width: 100%; border-collapse: collapse;">
            <thead>
              <tr style="text-align:center; font-weight: bold;">
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">Tên hàng hóa</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lượng thùng</td>
                  <td style="width: auto; border: 1px solid #333; padding: 5px;">Số lượng lon/Chai/Pack/Gói</td>
                </tr>
            </thead>
            <tbody>`;
            const listPro = saleorder?.productOrders;
            listPro.forEach((product, index) => {
              return content += `
              <tr>
                <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${index + 1}</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.masterProduct?.nameEN}</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.quantityInCarton}</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product?.quantityTin}</td>
              </tr>`;
            });  
            content += `</tbody>
            <tfoot>
              <tr>
                <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;" colspan="2">Tổng cộng</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityInCarton; return prev; }, 0)}</td>
                <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${saleorder?.productOrders?.reduce((prev, curr) => { prev += curr.quantityTin; return prev; }, 0)}</td>
            </tr>
            </tfoot>
          </table>
          <div style="display: flex;">
            <div style="width: 50%; margin-top: 20px; font-weight: bold; font-size: 16px">
              <p>Ký duyệt</p>
            </div>
            <div style="width: 50%; margin-top: 20px">
              <p>TP.HCM, ngày ${date} tháng ${month} năm ${year} </p>
            </div>
          </div>
        </div>`;
        
      const pdf = new jsPdf('p', 'mm', 'a4');
      const template = document.createElement('div');
        template.innerHTML = content;
        document.body.appendChild(template);
        html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
          pdf.save(`LDD${moment().format('DDMMYYYY')}.pdf`);
        });
        document.body.removeChild(template);
      setLoadingLDD(false);
    }}
  >
    {lang.exportDispatchOrder}
  </Button>,
  isIdm && <Button
  disabled={saleorder?.status === 'CANCELED'}
  key="btn-export-pbch"
  type="primary"
  ghost
  loading={loadingPBCH}
  onClick={async () => {
    setLoadingPBCH(true);
    // eslint-disable-next-line new-cap
    const listProducts = saleorder?.productOrders[0].locatorTo;
    const currWarehouse = warehouses.find(item => item?.id === listProducts?.warehouseId);
    const warehouseTo = warehouses.find(item => item?.id === listProducts?.warehouseToId);
    const dateTime = new Date();
        const date = dateTime.getDate();
        const month = (dateTime.getMonth() + 1);
        const year = (dateTime.getFullYear());
    let content = `
    <div style="display: grid ;line-height: 1;width: 950px; font-size: 16px; font-family: Times New Roman, Times, serif;padding-bottom: 30px; ">
    <div style="display:flex">
      <div style=" margin-top: 20px; line-height: 1; width: 20%;">
        <div style="text-align:center; border-image: initial;">
          <img src="${logo}" style=" height: 30%; width: 100%" />
        </div>
      </div>
      <div style="width: 80%">
        <div>
          <p>Công Ty TNHH Dinh Dưỡng 3A (Việt Nam)</p>
          <p>3A Nutrition (Việt Nam) Co. Ltd.</p>
          <p>No: 72-74 Nguyen Thi Minh Khai St, Ward 6</p>
          <p>Dist 3, Ho Chi Minh City, Viet nam</p>
          <p>Tel: (84.8) 3823 8222</p>
          <p>Fax: (84.8) 3823 8777</p>
        </div>

        <div style="display: flex">
          <div style="text-align: left; line-height: 1.2; width: 60%;">
            <div style="font-size: 25px; font-weight:bold; ">PHIẾU BÁO CHUYỂN HÀNG <br><span style="font-size: 20px; font-weight:bold; ">CHO CHI NHÁNH</span></div>
          </div>
         
          <div style="text-align:left; border: 2px solid #333; width: 20%;">
            <span>Code: P-85-01-F3</span><br>
            <span>Issue date: </span><br>
            <span>No: </span><br>
          </div>
          <div style="width:20%"></div>
        </div>
        <div style="padding-top:30px">
          <p>NGÀY CHUYỂN: Ngày ${date} tháng ${month} năm ${year}</p>
          <p>NƠI CHUYỂN: ${currWarehouse?.address}</p>
          <p>NƠI NHẬN: ${warehouseTo?.address}</p>
        </div>    
      </div>
    </div>

    <table style="border: 1px solid #333; width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="text-align:center; font-weight: bold;">
          <td style="width: auto; border: 1px solid #333; padding: 5px;">STT</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">TÊN HÀNG HÓA</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">MÃ HH</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">QUY CÁCH</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">SỐ LÔ</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">HSD</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">SỐ LƯỢNG (Thùng)</td>
          <td style="width: auto; border: 1px solid #333; padding: 5px;">SỐ LƯỢNG (Lon/gói/pack)</td>
          </tr>
      </thead>
      <tbody>`;
      const listPro = saleorder?.productOrders;
            listPro.forEach((product, index) => {
              return content += `
          <tr>
              <td style="width: auto; border: 1px solid #333; padding: 5px; font-weight: bold; text-align: center;">${1 + index}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct.code3A} - ${product.masterProduct.nameEN}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct.nameEN}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.masterProduct?.CTNSPerPalletImport}T/Pallet</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.lot}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${moment(product.expireDate, formatDateFull).format(formatDate)}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityInCarton}</td>
              <td style="width: auto; border: 1px solid #333; padding: 5px; text-align: center;">${product.quantityTin}</td>
          </tr>`;
        });  
  content += `</tbody>
  </table>

  <div style=" padding-top: 20px;">
    <div style="display: flex; border: 1px solid #333;">
      <div style="width: 50%;border-right: 1px solid #333;">
        <p style="padding-left: 10px; padding-top: 10px; font-weight: bold;">NGƯỜI LẬP PHIẾU</p>
      </div>
      <div style="padding-bottom: 50px;"></div>
      <div style="width: 50%">
        <P style="padding-left: 10px; padding-top: 10px; font-weight: bold;" >LOGISTICS</P>
        <div style="width: 50%; padding-top: 10px; padding-bottom: 50px;">
        </div>
      </div>
    </div>
  </div>
    
</div>`;
      
    const pdf = new jsPdf('p', 'mm', 'a4');
    const template = document.createElement('div');
      template.innerHTML = content;
      document.body.appendChild(template);
      html2canvas(template, { width: 1100, scale: 3 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 15, 10, imgWidth, imgHeight);
        pdf.save(`PBCH${moment().format('DDMMYYYY')}.pdf`);
      });
      document.body.removeChild(template);
    setLoadingPBCH(false);
  }}
>
  {lang.exportDeliveryNotice}
</Button>,
    isIdm && <ModalForm
    title={`${lang.cancelText} ${lang.dispatchOrder.toLowerCase()}`}
      trigger={
        <Button
          key="btn-cancel"
          type="primary"
          ghost
          disabled={saleorder?.status === 'CANCELED' || checkManuever}
        >
          {lang.btnCancel + ' lệnh'}
        </Button>
      }
      onFinish= { async (value) => {
        saleorder.description = value?.description;
        saleorder.status = 'CANCELED';
        delete saleorder.productGiftOrders;
        delete saleorder.locatorTransitCode;
        delete saleorder.locator;
        await updateOrderGift(saleorder)
        message.success(`${lang.cancelOrderSuccess}!`)
        navigate(-1)
      }}
      form={formCancel}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
      }}
      key="btn-cancel-manuever"
    >
      <ProFormTextArea 
       name="description"
       label={lang.description}
       placeholder={lang.description}
      />
    </ModalForm>, 
    <Button
      key="btn-create-branch"
      type="primary"
      onClick={() => setModalCreateInternal(true)}
      disabled={!isIdm || saleorder?.status === 'CANCELED' || checkQuantity || selectedRowKeys?.length === 0}
    >
      {`${lang.btnCreate} ${lang.exportBranch}`}
    </Button>,
    isOperator && saleorder?.status === 'CANCELED' && <ModalForm
    title={`${lang.update} ${lang.dispatchOrder.toLowerCase()}`}
      trigger={
        <Button
          key="btn-confirm"
          type="primary"
          ghost
        >
          {`${lang.update} ${lang.order.toLowerCase()}`}
        </Button>
      }
      onFinish= { async (value) => {
        saleorder.description = value?.description;
        saleorder.status = 'NEW';
        delete saleorder.productGiftOrders;
        delete saleorder.locatorTransitCode;
        delete saleorder.locator;
        await updateOrderGift(saleorder)
        message.success(`${lang.updateOrderSuccess}!`)
        navigate(-1)
      }}
      form={formCancel}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
      }}
      key="btn-confirm-manuever"
    >
      <ProFormTextArea 
       name="description"
       label={lang.description}
       placeholder={lang.description}
      />
    </ModalForm>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/manuever-command',
      breadcrumbName: lang.dispatchOrder,
    },
    {
      path: '/',
      breadcrumbName: lang.dispatchOrderDetail,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.dispatchOrderDetail}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Descriptions column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 3, xs: 2 }} >
          <Descriptions.Item label={lang.dispatchOrderCode}>{saleorder?.documentNo}</Descriptions.Item>
            <Descriptions.Item span={2} label={`${lang.locator} (Transit)`}>{`${saleorder?.locatorTransitCode?.locatorName} - ${saleorder?.locatorTransitCode?.locator}`}</Descriptions.Item>
            <Descriptions.Item label={lang.truckType}>{saleorder?.truckType}</Descriptions.Item>
            <Descriptions.Item label={lang.truckWeight}>{saleorder?.truckWeight}</Descriptions.Item>
            <Descriptions.Item label={lang.movementDate}>{moment(saleorder?.movementDate).format(formatDate)}</Descriptions.Item>
            <Descriptions.Item label={lang.tranferReason}>{saleorder?.tranferReason}</Descriptions.Item>
            <Descriptions.Item label={lang.status}>{
              saleorder?.status === 'NEW' ? lang.new
              : saleorder?.status === 'COMPLETE' ? lang.operationCreated
              : lang.deleted
            }</Descriptions.Item>
            <Descriptions.Item label={lang.description}>{saleorder?.description}</Descriptions.Item>
          </Descriptions>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.productList}</h3></Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={saleorder?.productGiftOrders}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCurrentPage(pagination.current);
          }}
          pagination={{
            showSizeChanger: true,
            total: saleorder?.productGiftOrders?.length,
            onShowSizeChange: (current, size) => {
              SetPageSize(size);
            },
            pageSize: pageSize,
            current: currentPage,
          }}
          scroll={{ x: 467 }}
          rowSelection = {{
            onChange: (_, selectedRows) => {
              setSelectedRowKeys(selectedRows)
            },
            getCheckboxProps: (record) => ({
              disabled: (record?.locatorCode === null)
            })
          }}
        />
      </Layout.Content>
      <Modal
        visible={isEditModal}
        title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
        onCancel={() => setIsEditModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
        wrapClassName="receipt-product-edit-modal"
      >
        {
          <ReceiptProductEditForm
          item={item}
          onCallback={async (value) => {
            console.log(value)
            try {
              setLoading(true)
              if (!(value?.locatorId?.locatorCode)) {
                await productGiftOrderUpdate({ id: value?.id, quantity: value?.quantity, warehouseId: currentWarehouse?.id, locatorCode: value?.locatorId});
              } else {
                await productGiftOrderUpdate({ id: value?.id, quantity: value?.quantity, warehouseId: currentWarehouse?.id, locatorCode: value?.locatorId?.locatorCode});
              }
              giftManueuverCommandDetail(id)
                .then(result => {
                setLoading(true)
                setSaleorder(result);
                setLoading(false);
                })
                .catch((e) => setLoading(false));
              setIsEditModal(false);
              message.success(`${lang.updateSuccessfully}!`);
              setLoading(false);
            } catch (error) {
              message.error(error.message);
              setLoading(false);
            }
          }}
          onCancel={() => setIsEditModal(false)}
          />
        }
      </Modal>

      {/* CREATE EXPORT BRANCH GIFT */}
      <ProducttionGiftInternal
        item={selectedRowKeys}
        onCallBack={async (values) => {
          try {
           const formData = { ...saleorder }
            formData.commandManueverGiftId = parseInt(id);
            formData.locatorTransitCode = saleorder?.locatorTransitCode.locator
            const payload = values?.map(item => {
              return {
                quantity: item.quantity,
                id: item?.id
              };
            });
            delete formData.locatorCode;
            delete formData.id;
            delete formData.group;
            delete formData.productGiftOrders;
             await giftOrderCMCreate({ ...formData, productGiftOrders: payload });
            setSelectedRowKeys([])
            await giftManueuverCommandDetail(parseInt(id))
              .then(result => {
                setLoading(true)
                setSaleorder(result);
                setLoading(false);
              });
            setCheckLoading(!checkLoading);
            setModalCreateInternal(false);
            setLoading(false);
            message.success(lang.createSuccessfully);
          } catch (error) {
            message.error(error.message);
            return false;
          }
        }}
        setIsEditModal={setModalCreateInternal}
        isEditModal={modalCreateInternal}
      />
    </Layout>
  );
};

export default GiftManueuverCommandDetail;
