import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Popconfirm, message, Card, Row, Col, Modal } from 'antd';
import { ModalForm } from '@ant-design/pro-form';
import './location-style.less';
import ProCard from '@ant-design/pro-card';
import { blockDelete, blockList, importExel, shelfDelete, shelfDetail, zoneDelete, zoneList } from './location.service';
import { useSelector } from 'react-redux';
import environment from 'environments/environment';
import Helper from 'utils/helpers';
import ZoneModalForm from './zoneModalForm';
import BlockModalForm from './blockModalForm';
import ShelfModalForm from './shelfModalForm';
import RackModalForm from './rackModalForm';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';

const scrollStyle = {
    overflowX: 'scroll',
    height: '85vh',
    margin: 'auto',
    width: '100%',
    marginBottom: 19,
};
const headeStyleZone = {
    margin: 0,
    border: 0,
    height: 0,
    minHeight: 0,
};
const headStyleBlock = {
    borderBottom: 0,
};

const breadcrumbItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return (last || route.path !== '') ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
    );
};

const Location = () => {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    const lang = getLanguages(t);
    const [listzone, setListzone] = useState([]);
    const [listblock, setListblock] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [visibleBlock, setVisibleBlock] = useState(false);
    const [visibleZone, setVisibleZone] = useState(false);
    const [visibleprintShelf, setVisibleprintShelf] = useState(false);
    const [zoneSelected, setZoneSelected] = useState();
    const [blockSelected, setBlockSelected] = useState();
    const [shelfSelected, setShelfSelected] = useState();
    const [listRacks, setListRacks] = useState();
    const [position, setPosition] = useState();
    const { currentWarehouse } = useSelector((state) => state.auth);
    const [visibleRack, setVisibleRack] = useState(false);
    const [visiblePrintRack, setVisiblePrintRack] = useState(false);
    const [rack, setRack] = useState();
    const [file, setFile] = useState();
    const [uploadModal, setUploadModal] = useState(false);
    const fetchZones = (warehouseId) => {
        zoneList({ warehouseId })
            .then(result => {
                setListzone([...result.data]);
            })
            .catch((e) => message.error(e.message));
    };
    const fetchBlock = (warehouseId) => {
        blockList({ warehouseId })
            .then(result => {
                const dataremp = result.data.reduce((prev, current) => {
                    const positionFind = prev?.find(pre => pre.position === current.position);
                    if (positionFind) {
                        positionFind.blocks.push(current);
                        return prev;
                    }
                    return [...prev, {
                        position: current.position,
                        blocks: [current],
                    }];
                }, []);
                setListblock([...dataremp]);
            })
            .catch((e) => message.error(e.message));
    };

    useEffect(() => {
        fetchZones(currentWarehouse.id);
    }, [currentWarehouse]);
    useEffect(() => {
        fetchBlock(currentWarehouse.id);
    }, [currentWarehouse]);

    const routes = [
        {
            breadcrumbName: 'Home',
        },
        {
            breadcrumbName: lang.configuration,
        },
        {
            breadcrumbName: lang.location,
        },
    ];
    const uploadButton = (
        <div
            className="ButtonCustom"
            onClick={() => {
                setZoneSelected(null);
                setVisibleZone(true);
            }}
        >
            <span>{lang.addZone} +</span>
        </div>
    );

    const handledeleteZone = async (value) => {
        try {
            await zoneDelete(value);
            fetchZones(currentWarehouse.id);
            message.success(lang.zoneAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    const handleDeleteBlock = async (value) => {
        try {
            await blockDelete(value);
            await fetchBlock(currentWarehouse.id);
            message.success(lang.blockAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    const handleDeleteshelf = async (id) => {
        try {
            if (environment.isMockData) {
                await shelfDelete(id).then(result => {
                    setListblock([...result]);
                });
                return true;
            }
            await shelfDelete(id);
            await fetchBlock(currentWarehouse.id);
            message.success(lang.shelfAreDeletedSuccessfully);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };
    const extraButton = [
        <Button
            key="btn-import"
            type="primary"
            onClick={() => {
                setUploadModal(true);
            }}
        >
            {lang.importCSV}
            <PlusOutlined />
        </Button>,
    ];
    return (
        <Layout className="layoutContent">
            <PageHeader
                ghost={false}
                title={lang.location}
                extra={extraButton}
                breadcrumb={{
                    routes,
                    itemRender: breadcrumbItemRender,
                }}
                className="customPageHeader"
            />
            <Layout.Content>
                <ProCard bodyStyle={scrollStyle} >
                    <Row gutter={16} wrap={false}>
                        <Col flex="102px" className="ColLeft">
                            <Row gutter={[16, 32]}>
                                {listzone?.map((zone) => (
                                    <Col key={zone.id} span={24}>
                                        <Card className="CardStyle" style={{ background: '#dee0e0' }}
                                            headStyle={headeStyleZone}
                                            extra={<Popconfirm title={lang.areYouSureToDeleteThisZone}
                                                onConfirm={async () => await handledeleteZone(zone.id)}>
                                                <div className="btnDelete" style={{ right: '-9px' }}>X</div>
                                            </Popconfirm>}
                                            bodyStyle={{ padding: '24px 0px' }}
                                        >
                                            <p style={{ wordWrap: 'break-word' }} onClick={() => { setZoneSelected(zone); setVisibleZone(true); }}>{zone.name}</p>
                                        </Card>
                                    </Col>
                                ))}
                                <Col key="btn-upload-zone">
                                    {uploadButton}
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="auto">
                            {listblock.map((columnPos) => (
                                <Row gutter={[32, 32]} key={columnPos.position} wrap={false} style={{ marginBottom: 32, width: 'max-content' }}>
                                    {/* <Col span={24} /> */}
                                    {columnPos?.blocks.map((block) => (
                                        <Col key={block.id}>
                                            <Card
                                                title={<span style={{ cursor: 'pointer' }} onClick={() => { setVisibleBlock(true); setBlockSelected(block); }}>{block?.name}</span>}
                                                headStyle={headStyleBlock}
                                                bodyStyle={{ padding: '0 12px' }}
                                                className="CardBlock" size="small"
                                                extra={<Popconfirm title={lang.areYouSureToDeleteThisBlock}
                                                    onConfirm={async () => await handleDeleteBlock(block.id)}>
                                                    <div className="btnDelete">X</div>
                                                </Popconfirm>}
                                                style={{ width: 'max-content' }}
                                            >
                                                {
                                                    block?.shelfs?.reduce((prev, curr) => {
                                                        if (prev?.[curr.position - 1]) {
                                                            prev[curr.position - 1] = [...prev[curr.position - 1], curr];
                                                        } else {
                                                            prev = [...prev, [curr]];
                                                        }
                                                        return prev;
                                                    }, [])?.map((shelfs, index) => <Row gutter={[16, 16]} wrap={false} key={index} className="scrollXStyle">
                                                        {shelfs?.map((self) => (
                                                            <Col key={self.id}>
                                                                <Row gutter={[16, 16]} wrap={false}>
                                                                    <Col>
                                                                        <Card
                                                                            style={{ background: '#EFF1F1', borderRadius: 4, marginTop: 5, height: 'calc(100% - 5px)' }}
                                                                            headStyle={headeStyleZone}
                                                                            extra={<Popconfirm title={lang.areYouSureToDeleteThisShelf}
                                                                                onConfirm={async () => await handleDeleteshelf({ shelfId: self.id, blockId: block.id })}>
                                                                                <div className="btnDelete" style={{ right: '-9px' }}>X</div></Popconfirm>}
                                                                            className="CardShelf"
                                                                            onClick={async (e) => {
                                                                                if (e.target.className && e.target.className !== 'btnDelete') {
                                                                                    if (!environment.isMockData) {
                                                                                        const shelfvalue = await shelfDetail(self.id);
                                                                                        setShelfSelected({ ...shelfvalue, block: { ...block } });
                                                                                        setListRacks(shelfvalue?.racks);
                                                                                        setVisible(true);
                                                                                    } else {
                                                                                        setShelfSelected({ ...self, block: { ...block } });
                                                                                        setVisible(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                            bodyStyle={{ padding: 0, height: '100%', display: 'flex', alignItems: 'flex-start' }}
                                                                        >
                                                                            <span className='tempSpan' style={{ wordWrap: 'break-word', width: '100%', alignSelf: 'center' }} >{self?.isDivedShelf ? `${self.name} ${lang.divived}` : self.name}</span>
                                                                        </Card>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card headStyle={headeStyleZone} style={{ borderRadius: 4, marginTop: 5 }} bodyStyle={{ padding: '0 8px', display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
                                                                            {!self?.isDivedShelf ? self?.racks?.reduce((prev, curr, index) => {
                                                                                const finded = prev?.find((_, index) => index === curr.floor - 1);
                                                                                if (finded) {
                                                                                    finded.push({ ...curr, index });
                                                                                }
                                                                                return prev;
                                                                            }, [...Array(self?.floor)]?.map(i => []))?.reverse()?.map((val, idx) => <Row gutter={[16, 16]} key={idx}>
                                                                                {val?.map((rack, stt) => <Col key={rack.index}><div onClick={() => { setRack({ ...rack, shelfId: self.id }); setVisibleRack(true); }} style={{ width: 45, backgroundColor: rack.status === 'ENABLE' ? '#EFF1F1' : 'grey', textAlign: 'center', cursor: 'pointer' }}>{`T${rack?.floor}_${stt + 1 >= 10 ? stt + 1 : '0' + (stt + 1)}`}</div></Col>).sort(() => -1)}
                                                                            </Row>) : self?.racks?.reduce((prev, curr, index) => {
                                                                                const finded = prev?.find((_, index) => index === curr.floor - 1);
                                                                                if (finded) {
                                                                                    const bin = finded?.find((val) => val?.[0]?.bin === curr.bin);
                                                                                    if (bin) {
                                                                                        bin.push({ ...curr });
                                                                                    } else finded.push([{ ...curr }]);
                                                                                }
                                                                                return prev;
                                                                            }, [...Array(self?.floor)]?.map(i => []))?.sort(() => -1)?.map((racks, idx) =>
                                                                                <Row gutter={[16, 16]} key={idx} style={{ paddingLeft: 8 }}>
                                                                                    {
                                                                                        racks?.sort(() => -1).map((rack, ind) => <Row gutter={[16, 16]} key={ind} style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
                                                                                            {rack?.sort((a, b) => b.id - a.id)?.map((r) => {
                                                                                                const arr = r?.code?.split('_');
                                                                                                return <Col key={r.id}>
                                                                                                    <div onClick={() => { setRack({ ...r, shelfId: self.id }); setVisibleRack(true); }} style={{ width: 45, backgroundColor: r.status === 'ENABLE' ? '#EFF1F1' : 'grey', textAlign: 'center', cursor: 'pointer' }}>{[arr[1], arr[2]].join('_')}</div>
                                                                                                </Col>;
                                                                                            })}
                                                                                        </Row>)}
                                                                                </Row>,
                                                                            )}
                                                                            {/* {self?.racks.length % 2 === 0 ? <> <Row gutter={[16, 16]}>
                                                                        {self?.racks?.filter((rack) => rack.id % 2 === 0)?.map((_, index) => <Col key={index}><p style={{ width: 45, backgroundColor: '#EFF1F1', textAlign: 'center' }}>{index * 2 + 1}</p></Col>)}
                                                                    </Row>
                                                                        <Row gutter={[16, 16]}>
                                                                            {self?.racks?.filter((rack) => rack.id % 2 !== 0)?.map((_, index) => <Col key={index}><div style={{ width: 45, backgroundColor: '#EFF1F1', textAlign: 'center' }}>{index * 2 + 2}</div></Col>)}
                                                                        </Row> </> : <> <Row gutter={[16, 16]}>
                                                                            {self?.racks?.filter((rack) => rack.id % 2 !== 0)?.map((_, index) => <Col key={index}><p style={{ width: 45, backgroundColor: '#EFF1F1', textAlign: 'center' }}>{index * 2 + 1}</p></Col>)}
                                                                        </Row>
                                                                        <Row gutter={[16, 16]}>
                                                                            {self?.racks?.filter((rack) => rack.id % 2 === 0)?.map((_, index) => <Col key={index}><div style={{ width: 45, backgroundColor: '#EFF1F1', textAlign: 'center' }}>{index * 2 + 2}</div></Col>)}
                                                                        </Row> </>} */}
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        ))}
                                                        {block.totalShelf > block.shelfs.length ? (
                                                            <Col key="btnadd-shelf">
                                                                <Card
                                                                    className="ButtonShelfAdd"
                                                                    style={{ color: '#2f80ed', lineHeight: 1, border: '1px dashed #2f80ed', borderRadius: 4, marginTop: 5, height: 'calc(100% - 5px)' }}
                                                                    onClick={() => {
                                                                        setShelfSelected({ block: { ...block }, position: index + 1 });
                                                                        setVisible(true);
                                                                    }}
                                                                    bodyStyle={{ padding: 0, height: '100%', display: 'flex', alignItems: 'flex-start' }}
                                                                >
                                                                    <span style={{ width: '100%', alignSelf: 'center' }}>{lang.addShelf} +</span>
                                                                </Card>
                                                            </Col>
                                                        ) : null}
                                                    </Row>)}
                                                {block.totalShelf > block.shelfs.length && <Row gutter={16}>
                                                    <Col key="btnadd-shelf">
                                                        <Card
                                                            className="ButtonShelfAdd"
                                                            style={{ color: '#2f80ed', lineHeight: 1, border: '1px dashed #2f80ed', borderRadius: 4, marginTop: 5, height: 'calc(100% - 5px)' }}
                                                            onClick={() => {
                                                                setShelfSelected({ block: { ...block }, position: block?.shelfs.sort((a, b) => b.position - a.position)[0]?.position + 1 || 1 });
                                                                setVisible(true);
                                                            }}
                                                            bodyStyle={{ padding: 0, height: '100%', display: 'flex', alignItems: 'flex-start' }}
                                                        >
                                                            <span style={{ width: '100%', alignSelf: 'center' }}>{lang.addShelf} +</span>
                                                        </Card>
                                                    </Col>
                                                </Row>}
                                            </Card>
                                        </Col>
                                    ))}
                                    <Col key={`btnadd-block-${columnPos.position}`}>
                                        <Card
                                            className="CardBlockAdd"
                                            size="small"
                                            onClick={() => { setVisibleBlock(true); setPosition(columnPos.position); setBlockSelected(undefined); }}
                                            bodyStyle={{ display: 'grid', height: '100%' }}
                                        >
                                            <span>{lang.addBlock} + </span>
                                        </Card>
                                    </Col>
                                </Row>))
                            }
                            <Row gutter={[16, 32]} key={listblock.length + 1} wrap={false}>
                                <Col flex="382px">
                                    <Card
                                        className="CardBlockAdd"
                                        size="small"
                                        onClick={() => { setVisibleBlock(true); setPosition(listblock.length + 1); setBlockSelected(undefined); }}
                                        bodyStyle={{ display: 'grid', height: '100%' }}
                                    >
                                        <span>{lang.addBlock} + </span>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ProCard>
            </Layout.Content>
            <ZoneModalForm
                item={zoneSelected}
                visible={visibleZone}
                setVisible={setVisibleZone}
                setVisiblePrint={setVisiblePrint}
                fetchZones={fetchZones}
                setZoneSelected={setZoneSelected}
            />
            <BlockModalForm
                item={blockSelected}
                visible={visibleBlock}
                setVisible={setVisibleBlock}
                setVisiblePrint={setVisiblePrint}
                fetchBlock={fetchBlock}
                setBlockSelected={setBlockSelected}
                position={position}
            />

            <ShelfModalForm
                item={shelfSelected}
                visible={visible}
                setVisible={setVisible}
                setVisibleprintShelf={setVisibleprintShelf}
                fetchBlock={fetchBlock}
                setShelfSelected={setShelfSelected}
                setListRacks={setListRacks}
            />
            {/* </ConfigProvider> */}
            <ModalForm
                visible={visiblePrint}
                width="440px"
                submitter={{
                    render: ({ form }) => {
                        return [
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                key="extra-reset"
                                onClick={() => {
                                    setVisiblePrint(false);
                                }}
                            >
                                {lang.btnClose}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    Helper.dowloadCSV([zoneSelected?.code], 'zondeCode');
                                    setVisiblePrint(false);
                                    setVisibleZone(false);
                                }}
                            >
                                {lang.exportCSV}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    var content = document.getElementById('idZone');
                                    var myWindow = window.open('', '');
                                    myWindow.document.write(content.innerHTML);
                                    myWindow.document.close(); //missing code
                                    myWindow.focus();
                                    myWindow.print();

                                    setVisibleprintShelf(false);
                                }}
                            >
                                {lang.print}
                            </Button>,
                        ];
                    },
                }}
                onVisibleChange={setVisiblePrint}
                modalProps={{
                    closable: false,
                    centered: true,
                }}
                className="ModalPrint"
            >
                <Row gutter={16} id="idZone">
                    <Col key={rack?.id} span={24}>
                        <Card bodyStyle={{ padding: 0, textAlign: 'center' }}>
                            {Helper.genarateQRcode([zoneSelected?.code])}
                            {/* <div style={{ marginTop: '20px' }}>
                                {Helper.genarateBarcode([zoneSelected?.code])}
                            </div> */}
                            <div style={{ textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }}>{`${zoneSelected?.code}`}</div>
                        </Card>
                    </Col>
                </Row>

            </ModalForm>
            <ModalForm
                title={lang.print}
                visible={visibleprintShelf}
                width="1122px"
                submitter={{
                    render: ({ form }) => {
                        return [
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                key="extra-reset"
                                onClick={() => {
                                    setVisibleprintShelf(false);
                                    fetchBlock(currentWarehouse.id);
                                }}
                            >
                                {lang.btnCancel}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    Helper.dowloadCSV(listRacks?.map(rack => rack.code), 'dataRackCode');
                                    setVisibleprintShelf(false);
                                    setVisible(false);
                                }}
                            >
                                {lang.exportCSV}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    var content = document.getElementById('idRow');
                                    var myWindow = window.open('', '');
                                    myWindow.document.write(content.innerHTML);
                                    myWindow.document.close(); //missing code
                                    myWindow.focus();
                                    myWindow.print();
                                    setVisibleprintShelf(false);
                                }}
                            >
                                {lang.print}
                            </Button>,
                        ];
                    },
                }}
                onVisibleChange={setVisibleprintShelf}
                modalProps={{
                    closable: false,
                    centered: true,
                }}
            >
                <Row gutter={[16, 16]} className="scrollYStyle" id='idRow'>
                    {listRacks?.map((value) => {
                        // const imageSettings = value?.floor === 1 ? { src: logog3A, height: 20, width: 20, excavate: true } : undefined;
                        return (
                            <Col key={value.id} lg={6} sm={8} xs={12}>
                                <Card style={{ background: '#EFF1F1' }} bodyStyle={{ padding: 12, textAlign: 'center' }}>
                                    {/* <QRCode value={value.code} renderAs={'svg'} style={{ margin: 'auto', width: '100%' }} />
                                    <Barcode value={value.code} height={70} background="#EFF1F1" width={1.9} /> */}
                                    {Helper.genarateQRcode([value.code] /*, imageSettings, 'H'*/)}
                                    {/* {Helper.genarateBarcode([value.code])} */}
                                    <div style={{ textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }}>{`${value.code}`}</div>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </ModalForm>
            <RackModalForm
                item={rack}
                visible={visibleRack}
                setVisible={setVisibleRack}
                fetchBlock={() => fetchBlock(currentWarehouse.id)}
                setVisiblePrintRack={setVisiblePrintRack}
            />
            <ModalForm
                visible={visiblePrintRack}
                width="302px"
                submitter={{
                    render: ({ form }) => {
                        return [
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                key="extra-reset"
                                onClick={() => {
                                    setVisiblePrintRack(false);
                                }}
                            >
                                {lang.btnClose}
                            </Button>,
                            <Button
                                style={{ maxWidth: '130px', height: '32px', width: '24vw' }}
                                type="primary"
                                key="extra-submit"
                                onClick={() => {
                                    var content = document.getElementById('print');
                                    var myWindow = window.open('', '');
                                    myWindow.document.write(content.innerHTML);
                                    myWindow.document.close(); //missing code
                                    myWindow.focus();
                                    myWindow.print();
                                    setVisiblePrintRack(false);
                                }}
                            >
                                {lang.print}
                            </Button>,
                        ];
                    },
                }}
                onVisibleChange={setVisiblePrintRack}
                modalProps={{
                    closable: false,
                    centered: true,
                }}
                className="ModalPrint"
            >
                <Row gutter={16} id='print'>
                    <Col key={rack?.id} span={24}>
                        <Card bodyStyle={{ padding: 0, textAlign: 'center' }}>
                            {Helper.genarateQRcode([rack?.code])}
                            {/* {Helper.genarateBarcode([rack?.code])} */}
                            <div style={{ textAlign: 'center', fontSize: '60px', fontWeight: 'bold' }}>{`${rack?.code}`}</div>
                        </Card>
                    </Col>
                </Row>
            </ModalForm>
            <Modal visible={uploadModal} onCancel={() => { setUploadModal(false); setFile(); }}
                bodyStyle={{ minHeight: 340 }} onOk={async () => {
                    try {
                        if (file) {
                            const formData = new FormData();
                            formData.append('excel', new Blob([file?.originFileObj], { type: file.type }));
                            await importExel(currentWarehouse?.id, formData);
                            setFile();
                        }
                        fetchBlock(currentWarehouse?.id);
                        message.success(lang.importSuccess);
                        setUploadModal(false);
                    } catch (error) {
                        message.error(error.message);
                        setUploadModal(false);
                        setFile();
                    }
                }}
                okText={lang.okText} cancelText={lang.cancelText}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Button onClick={() => Helper.dowloadXLSX([{
                            [lang.shelfTemplateColShelfName]: '',
                            [lang.shelfTemplateColPosition]: '',
                            [lang.shelfTemplateColTotalBin]: '',
                            [lang.shelfTemplateColAverageRate]: '',
                            [lang.shelfTemplateColHighRate]: '',
                            [lang.shelfTemplateColVolume]: '',
                            [lang.shelfTemplateColBlockCode]: '',
                            CategoryId: '',
                            LocatorId: '',
                            [lang.shelfTemplateColTierNumber]: '',
                        }], 'TemplateLocation')}>{lang.dowloadTemplate}</Button>
                    </Col>
                    <Col span={24}>
                        <Dragger onChange={(info) => {
                            setFile(info.file);
                        }}
                            maxCount={1}
                            // defaultFileList={file}
                            showUploadList={true}
                            customRequest={async (options) => {
                                const { onSuccess } = options;
                                onSuccess('OK');
                            }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Dragger>
                    </Col>
                </Row>
            </Modal>
        </Layout>
    );
};

export default Location;
