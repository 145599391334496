import React, { useEffect } from 'react';
import { Form, Col, Row, Button, Modal, InputNumber, Input, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
// import Helper from 'utils/helpers';
// import { masterproductPackingCreate, masterproductPackingUpdate } from './masterproduct-packing.service';
import { ProFormText } from '@ant-design/pro-form';

const ProducttionInternal = ({ item, onCallBack, setIsEditModal, isEditModal }) => {
  const [formParent] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const initValues = {
    internalProduct: item?.map(product => ({
      codeGift: product?.codeGift,
      locatorCode: product?.locatorCode,
      locatorCodeTo: product?.locatorCodeTo,
      warehouseId: product?.warehouseId,
      name: product?.bomGift?.name,
      id: product?.id,
    })),
  };
  useEffect(() => {
    return () => {
      formParent.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModal]);
  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${lang.new} ${lang.branchShipmentOrder}`}
      onCancel={onCancel}
      afterClose={() => {
        formParent.resetFields();
        formParent.setFieldsValue({ internalProduct: undefined });
      }}
      width={876}
      centered
      footer={null}
      wrapClassName="product-category-modal"
      forceRender={true}
    >
      <Form
        colon={false}
        form={formParent}
        layout="vertical"
        name="productCategoryEditForm"
        onFinish={ (values) => {
          const data = values?.internalProduct.filter((item) => (
            item?.quantity > 0
          ));
          console.log(data) 
          onCallBack(data);
        }}
        initialValues={initValues}
      >
        <Row gutter={24} type="flex">
          <Col span={24}>
            <Form.List name="internalProduct" label={lang.bomList}>
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name }) => (
                    <>
                      <Row gutter={[16, 16]} align="middle" key={name} style={{ marginBottom: '5px solid #333' }}>
                        <Col span={8}>
                          <Form.Item
                            name={[name, 'codeGift']}
                            wrapperCol={24}
                            label={lang.defaultCode}
                          >
                            <Input style={{ width: '100%' }} placeholder={lang.defaultCode} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <ProFormText
                            name={[name, 'name']}
                            label={lang.productName}
                            placeholder={lang.productName}
                            disabled
                          />
                        </Col>
                        <Col span={8}>
                          <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
                            {(form) => {
                              // const cartons = Math.floor((item[name]?.quantityTin - item[name]?.pickingQuantityTin) / parseInt(item[name]?.masterProduct?.tinPerCarton)) + item[name]?.quantityInCarton - item[name]?.pickingQuantityCartton;
                              // const tins = form?.getFieldValue(['internalProduct', name, 'quantityTin']);
                              const quatityProduct = item[name]?.quantity - item[name]?.pickedQuantity;
                              return <Form.Item
                                name={[name, 'quantity']}
                                label={`${lang.pickedCanQuantity}(<=${quatityProduct})`}
                                rules= {[
                                  {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    validator: (_, value) => (value === 0) || value > quatityProduct ? Promise.reject('Sai số lượng') : Promise.resolve(),
                                  },
                                ]}
                                initialValue={quatityProduct}
                              >
                                <InputNumber style={{ width: '100%' }} placeholder={lang.quantity} min={0} />
                              </Form.Item>;
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                      {name < item?.length - 1 && <Divider />}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              <Button type="primary" onClick={() => formParent.submit()} style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal >
  );
};

ProducttionInternal.propTypes = {
  item: PropTypes.array,
  setIsEditModal: PropTypes.func,
  onCallBack: PropTypes.func,
  isEditModal: PropTypes.bool,
};

export default ProducttionInternal;
