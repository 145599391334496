import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Button, Card, Row, Col, DatePicker, Form, Select, Modal, message } from 'antd';

import { PlusOutlined, SearchOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { materialCommandManueverList, materialDelete } from './production-order.service';

import './style.less';
import { defaultPage, formatDate, formatDateFull } from 'utils/constants';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import TableCustom from 'components/TableCustom/table';
import { Option } from 'antd/lib/mentions';
import _get from 'lodash/get';

const defaultSort = {
  sortDirection: 'desc',
  sortBy: 'createDate',
};

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const MaterialManueuverCommandList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [items, setItems] = useState([]);

  //Pagination
  const [sortedInfo] = useState(defaultSort);
  const [totalItems, setTotalItems] = useState();

  const { currentWarehouse, user } = useSelector((state) => state.auth);
  // const isManager = _get(user, 'roles', '').includes('manager');
  const isIdm = _get(user, 'roles', '').includes('idm');
  // const isOperator = _get(user, 'roles', '').includes('operator');

  const [params, setParams] = useState({ ...defaultPage, status: isIdm ? 'NEW' : '' });
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    setLoading(true);
    materialCommandManueverList({ ...params, ...sortedInfo, warehouseId: currentWarehouse?.id })
      .then(result => {
        setItems(result.data);
        setTotalItems(result.totalItem);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [params, sortedInfo, currentWarehouse]);
  const columns = [
    {
      title: lang.documentNo,
      key: 'documentNo',
      render: (record) => (<Button type='link' onClick={() => navigate(`${record?.id}`)}>{record?.documentNo}</Button>)
    },
    {
      title: lang.truckType,
      key: 'truckType',
      dataIndex: 'truckType',
    },
    {
      title: lang.truckWeight,
      key: 'truckWeight',
      dataIndex: 'truckWeight',
    },
    {
      title: lang.exportSlipType,
      key: 'orderMaterialType',
      dataIndex: 'orderMaterialType',
      render: val => val === 'EXPORT_BRANCH' ? lang.exportBranch1 : lang.internalExport
    },
    {
      title: lang.movementDate,
      dataIndex: 'createDate',
      key: 'createdate',
      render: (value) => moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.creator,
      dataIndex: 'creatorId',
      key: 'creatorId',
    },
    {
      title: lang.description,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: lang.status,
      dataIndex: 'status',
      key: 'status',
      render: (val) => {
       if (val === 'EXPORTED') {
          return lang.exported
        } else {
          return lang.newCreate
        }
      },
    },
  ];

  const extraButton = [
    isIdm && <Button
      key="btn-complete"
      type="primary"
      onClick={() => {
        navigate('create');
      }}
    >
      {lang.btnCreate}
      <PlusOutlined />
    </Button>,
  ];

  const routes = [
    {
      path: '/dashboard',
      breadcrumbName: 'Home',
    },
    {
      path: '/',
      breadcrumbName: lang.outbound,
    },
    {
      path: '/manuever-command',
      breadcrumbName: lang.materialExport,
    },
  ];

  return (
    <Layout className="layoutContent">
      <PageHeader
        ghost={false}
        title={lang.materialExport}
        extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        //onBack={onBack}
        className="customPageHeader"
      />
      <Layout.Content>
        <Card size="small" className="cardSearch">
          <Form form={form} className="customFormSearch" onFinish={(value) => {
            const cleanValue = pickBy(value, v => v !== undefined && v !== '');

            if (cleanValue?.date) {
              cleanValue.startDate = cleanValue.date[0].startOf('date').format(formatDateFull);
              cleanValue.endDate = cleanValue.date[1].endOf('date').format(formatDateFull);
              delete cleanValue.date;
            }
            setParams({ ...cleanValue, page: 1, limit: params.limit });
          }}>
            <Row gutter={16}>
              <Col xxl={{ span: 6 }} md={8} sm={12} xs={24}>
                <Form.Item label={lang.createDate} name="date" className="customItem">
                  <DatePicker.RangePicker
                    placeholder={[lang.from, lang.to]}
                    style={{ width: '100%' }}
                    format={formatDate}
                  />
                </Form.Item>
              </Col>
              <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item label={lang.status} name="status" >
                  <Select placeholder={lang.status} allowClear>
                    <Option value=''>{lang.all}</Option>
                    <Option key={'NEW'} value={'NEW'}>{lang.newCreate}</Option>
                    <Option key={'EXPORTED'} value={'EXPORTED'}>{lang.exported}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xxl={{ span: 4 }} sm={6} xs={12}>
                <Form.Item name="keyword">
                  <Input placeholder={lang.keyword} allowClear={true} />
                </Form.Item>
              </Col> */}
              <Col>
                <Form.Item>
                  <Button
                    type={'primary'}
                    ghost
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >{lang.btnSearch}</Button>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Card>
        <TableCustom
          title={() => <Row>
            <Col span={12}><h3>{lang.materialExportSlipList}</h3></Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {
                selectedRowKeys?.length > 0 && <Button
                type='primary'
                onClick={() => {
                  Modal.confirm({
                    title: lang.areYouSureDeleteSlip,
                    icon: <ExclamationCircleOutlined />,
                    async onOk() {
                      setLoading(true)
                      await selectedRowKeys?.map( async orderId => {
                        await materialDelete(orderId?.id)
                        materialCommandManueverList({ ...params, ...sortedInfo, orderMaterialType: 'EXPORT_SALE', warehouseId: currentWarehouse?.id })
                        .then(result => {
                          setLoading(true)
                          setItems(result.data);
                          setTotalItems(result.totalItem);
                          setLoading(false);
                        })
                        .catch((e) => setLoading(false));
                      }
                      );
                        message.success(lang.slipDeletedSuccessfully);
                        setSelectedRowKeys([])
                    },
                    onCancel() {}
                  })
                }}
                >
                {lang.btnDelete}
                <DeleteOutlined />
                </Button>
              }
            </Col>
          </Row>}
          rowKey="id"
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          rowSelection={{
            onChange: (_, selectedRows) => {
            setSelectedRowKeys(selectedRows);
          },
            getCheckboxProps: (record) => ({
              disabled: record.status === 'PICKING', // Column configuration not to be checked
              name: record.status,
            }),
          }}
          onChange={(pagination, filters, sorter) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (pagination.pageSize !== params.limit) {
              params.page = 1;
            } else {
              params.page = pagination.current;
            }
            params.limit = pagination.pageSize;
            setParams({ ...params });
          }}
          pagination={{
            showSizeChanger: true,
            total: totalItems,
            pageSize: params.limit,
            current: params.current,
          }}
        />
      </Layout.Content>

    </Layout>
  );
};

export default MaterialManueuverCommandList;
