import React, { useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Modal, Radio } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { ProFormSelect, ProFormDatePicker } from '@ant-design/pro-form';
import { productList } from '../../configuration/product/product.service';
import './style.less';
import { locatorList } from '../product-management/product-management.service';
import { formatDate, formatDateAPi } from 'utils/constants';
import {getAllBomGift , getAllProudctLocator} from './physical-inventory-gift.service'
import _ from 'lodash';
import moment from 'moment-timezone';
const { Option } = Select;

const PhysicalInventoryEditForm = ({ item, documentType, warehouseId, setIsEditModal, onCallback, isOnlyQuantityAllowForUpdate = false, isEditModal, onPrint }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [products, setProducts] = useState([]);
  const [selectedInWarehouse, setselectedInWarehouse] = useState(true);
  const [productFind, setProductFind] = useState();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [locators, setLocators] = useState([]);
  const [locatorFind, setLocatorFind] = useState();
  const [value, setValue] = useState('YES');

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [isEditModal, selectedInWarehouse]);
  useEffect(() => {
    setLoading(true);
    selectedInWarehouse ?
      getAllProudctLocator()
        .then(result => {
          setProducts(result.data);
          setLoading(false);
        })
        .catch((e) => setLoading(false)) 
          :    
         getAllBomGift({ status: 'ENABLE', 
        warehouseId: currentWarehouse?.id })
          .then(result => {
            setProducts(result.data);
            setLoading(false);
          })
          .catch((e) => setLoading(false)) ; 
  }, [currentWarehouse, selectedInWarehouse]);

  useEffect(() => {
    if (selectedInWarehouse && productFind) {
      setLocators([productFind.locator]);
      form.setFieldsValue({ locatorId:productFind.locator.locator,});
    }
  }, [productFind]);

  useEffect(() => {
    if(!selectedInWarehouse){
      locatorList({ warehouseId: currentWarehouse?.id }).then(val => {
        if (!selectedInWarehouse && documentType === 'Stocktake Adjustment') {
          const filterData = val.data.filter(item => item?.area?.warehouseID === 'SPS');
          setLocators(filterData);
        } else {
          setLocators(val.data);
        }
        setLoading(false);
      });
    }
  }, [currentWarehouse, selectedInWarehouse, documentType]);

  const initValues = {
    productCode: item ? item?.id : undefined,
    locatorId: item?.locatorId ? item?.locatorId?.value : undefined,
    lot: item ? item?.lot : undefined,
    expireDate: item ? item?.expireDate : undefined,
    qtyCount: item ? item?.qtyCount : undefined,
    quantityBook: item ? item?.quantityBook : 0,
    qtyScrapped: item ? item?.qtyScrapped : undefined,
    inventoryType: item ? item?.inventoryType : undefined,
    campaign: item ? item?.campaign : undefined,
    printQAcode: value
  };
  const onCancel = () => {
    setIsEditModal(false);
  };
  return (
    <Modal
      visible={isEditModal}
      title={`${item ? lang.btnEdit : lang.btnAdd} ${lang.product}`}
      onCancel={onCancel}
      width={980}
      centered
      footer={null}
      wrapClassName="receipt-product-edit-modal"
      afterClose={() => {
        form.resetFields();
      }}
      forceRender={true}
    >
      <Form
        colon={false}
        form={form}
        layout="vertical"
        name="receiptProductEditForm"
        onFinish={(values) => {
          values.expireDate = moment(values.expireDate, formatDateAPi).format(formatDateAPi)
          setLoading(true);
          onCallback({ 
            ...values,
            ...productFind,
            ...locatorFind,
            products
          });
          setLoading(false);
        }}
        initialValues={initValues}
      >
          <Row gutter={[16, 16]}>
            <Col lg={{ span: 16 }} xs={{ span: 24 }}>
              <Form.Item name="productCode" label={lang.product}
                rules={[
                  {
                    required: true,
                    message: lang.requiredProduct,
                  },
                ]}
              >
                <Select
                  placeholder={lang.placeholderSelectProduct}
                  allowClear
                  onChange={(value) => {
                    if(!value){
                      form.setFieldsValue({qtyScrapped: 0 });
                      form.setFieldsValue({ quantityBook: 0 });
                      return;
                    }
                    const itemFind = products.find(product => product.id === value);
                    if(selectedInWarehouse){
                    }
                    setProductFind(itemFind);
                    form.setFieldsValue({qtyScrapped: 0 });
                    
                    form.setFieldsValue({ quantityBook: itemFind?.quantityForSale ? itemFind?.quantityForSale: 0 });

                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // return true;
                    return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  }
                >
                  {products?.map(item => (
                    selectedInWarehouse ?<Option key={item.id} value={item.id}>{item?.bomGift?.code} - {item?.bomGift?.name} - {item?.locator?.locator} </Option>
                    :
                    <Option key={item.id} value={item.id}>{item?.code} - {item?.name} </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <ProFormSelect style={{ fontWeight: 600 }}
                name='locatorId'
                label={lang.locator}
                disabled  = {selectedInWarehouse}
                rules={[
                  {
                    required: true,
                    message: lang.requiredLocator,
                  },
                ]}
                options={locators?.filter(locator => locator.warehouseId === currentWarehouse.id)?.map(locator => ({
                  label: `${locator.locatorName} - ${locator.locator}`,
                  value: locator.id,
                  warehouseId: locator.warehouseId,
                  id: locator.id,
                }))}
                placeholder={lang.locator}
                fieldProps={{
                  labelInValue: true,
                  onChange: async value => {
                   let locator = locators.filter((item) => item.id === value.id);
                    setLocatorFind(locator);
                                         console.log(locatorFind)

                    // if (productFind && !_.isEmpty(value)) {
                    //   const locator = await getAllProudctLocator({ masterProductId: productFind.id, locatorCode: value?.value });
                    //   if (locator?.data) {
                    //     setLocatorFind(locator?.data);
                    //   } else setLocatorFind();
                    // } else setLocatorFind();
                    // form.setFieldsValue({ lot: undefined, expireDate: undefined, qtyCount: 0 });
                  },
                }}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // return true;
                  return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              />
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              {
                selectedInWarehouse  ? 
                <Form.Item
                name="qtyScrapped"
                label={'QtyScrapped'}
                rules={[
                  {
                    required: true,
                    message: lang.requiredQuantity,
                    validator: (_, value) => {
                      if (value === 0) {
                        return Promise.reject(new Error(lang.requiredQuantity));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  style={{ width: '100%' }}
                  placeholder={lang.placeholderQuantity}
                  onChange={val => {
                    form.setFieldsValue({ quantityBook: productFind.quantityForSale - val });
                  }}
                />
              </Form.Item>
              : <Form.Item
              name="qtyScrapped"
              label={'QtyScrapped'}
              rules={[
                {
                  required: true,
                  message: lang.requiredQuantity,
                },
              ]}
            >
              <InputNumber
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                style={{ width: '100%' }}
                placeholder={lang.placeholderQuantity}
                onChange={val => {
                  form.setFieldsValue({ quantityBook: - val });
                }}
              />
            </Form.Item>
              }
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Form.Item
                name="quantityBook"
                label={lang.quantityBook}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value < 0) {
                        return Promise.reject(new Error(lang.quantityBookIsNegative));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} disabled />
            </Form.Item>
              
            </Col>

            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item name="selectedInWarehouse" label={lang.selectProduct}>
                <Radio.Group name='selectedInWarehouse' defaultValue={selectedInWarehouse} onChange = {(val) => {setselectedInWarehouse(val.target.value)} }>
                    <Radio value = {true} >{lang.inStock}</Radio>
                    <Radio value= {false} >{lang.outOfStock}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <Form.Item name="printQAcode" label={lang.printQrCode}>
                <Radio.Group 
                name='selectedQAcode'
                onChange={(e) => setValue(e.target.value)}
                defaultValue={value}
                >
                  <Radio value='YES'>{lang.yes}</Radio>
                  <Radio value='NO'>{lang.no}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>    
        <div
          className="ant-modal-footer"
          style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
        >
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
                {lang.btnCancel}
              </Button>
              {loading === false ? (
                <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                  {lang.btnSave}
                </Button>
              ) : (
                <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                  {lang.loading}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

PhysicalInventoryEditForm.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  productsSelected: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.object,
  warehouseId: PropTypes.number,
  setIsEditModal: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  isEditModal: PropTypes.bool,
  kind: PropTypes.string,
};

export default PhysicalInventoryEditForm;
