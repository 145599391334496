import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
import { ProFormSelect } from '@ant-design/pro-form';
import _ from 'lodash';
import { productList } from 'pages/dashboard/configuration/product/product.service';
import { getAllProudctLocator } from './production-order.service';
import { locatorList } from 'pages/dashboard/inventory/product-management/product-management.service';
import moment from 'moment-timezone';
import { formatDateFull } from 'utils/constants';
import { masterproductPackingFindOne } from 'pages/dashboard/configuration/masterproduct-packing/masterproduct-packing.service';
// import { bomList } from 'pages/dashboard/configuration/masster-products-bom-gift/master-products-bom-gift.service';

const { Option } = Select;

const ReceiptProductEditForm = ({ itemVas, onCancel, onCallback, items, channel}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  const [locatorFind, setLocatorFind] = useState();
  const [locators, setLocators] = useState();
  const [quantityChange, setQuantityChange] = useState(0);
  const [dataVas, setDataVas] = useState([]);

  // console.log(item?.id);
  useEffect(() => {
    setLoading(true);
    productList({ status: 'ENABLE', isAvailableQauntity: true, warehouseId: currentWarehouse?.id })
      .then(result => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));

  }, [currentWarehouse]);

  useEffect(() => {
    if (itemVas) {
      setProductFind(itemVas?.masterProduct);
      getAllProudctLocator({ masterProductId: itemVas?.masterProduct?.id, locatorCode: itemVas?.locator?.locator }).then(res => {
        setLocatorFind(res?.data);
      });
    }
  }, [itemVas]);

  useEffect(() => {
    locatorList({ warehouseId: currentWarehouse?.id, isAvailable: true, masterProductId: productFind?.id })
      .then(val => {
        setLocators(val.data);
        setLoading(false);
    });
  }, [currentWarehouse, productFind]);

  useEffect(() => {
    if (itemVas?.masterProduct) {
      setProductFind(itemVas?.masterProduct);
    }
  }, [form, itemVas]);

  useEffect(() => {
    masterproductPackingFindOne({ masterProductId: productFind?.id, warehouseId: currentWarehouse?.id })
        .then(dataPack => {
          if (dataPack) {
            setDataVas(dataPack);
          }
        })
        .catch((e) => {
          message.error(e);
          setLoading(false)
        });
  }, [productFind, currentWarehouse, itemVas])

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        try {
          if (values.quantityInCarton === 0 && values.quantity === 0) {
            throw new Error(`${lang.quantityCartonAndTin} ${lang.isRequire}`);
          }
          setLoading(true);
          if(typeof values.locatorId === 'string'){
            const finded = locators?.find(locator=>locator?.locator === values.locatorId);
            values.locatorId= {
              label: `${finded?.locatorName} - ${finded?.locator}`,
              value: finded?.locator,
              warehouseId: finded?.warehouseId,
              id: finded?.id,
            }
          }
          const listBomproduction = dataVas?.map(item => {
            return {
              bomProductionId: item?.bomProduction?.id,
              quantity: item?.using * (productFind?.tinPerCarton * values?.quantityInCarton + values?.quantity),
              warehouseId: currentWarehouse?.id
            }
          })
          values.bomProductionIds = listBomproduction;
          onCallback({
            ...productFind,
            ...values,
            indx: itemVas?.indx || items?.length + 1,
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: itemVas ? itemVas?.masterProduct?.id : undefined,
        quantityInCarton: itemVas ? itemVas?.quantityInCarton : 0,
        quantity: itemVas ? itemVas?.quantityTin : 0,
        locatorId: itemVas ? itemVas?.locator?.locator : undefined,
        lot: itemVas ? itemVas?.lot : undefined,
        expireDate: itemVas ? itemVas?.expireDate : undefined,
        shelfLife: itemVas ? moment(itemVas?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (itemVas?.masterProduct?.shelfLifeMonths * 30)?.toFixed(2) : undefined
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product?.id === value);
                setProductFind(itemFind);
                form.setFieldsValue({ locatorId: undefined, lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0, shelfLife: undefined });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
            >
              {products?.map(item => (
                <Option key={item?.id} value={item?.id}>{item.code3A} - {item.shortName} - {item.nameEN}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name='locatorId'
            label={lang.locator}
            rules={[
              {
                required: true,
                message: lang.requiredLocator,
              },
            ]}
            options={(dataVas?.length > 0) ? locators?.filter(locator => locator?.warehouseId === currentWarehouse?.id && !locator?.warehouseToId)?.map(locator => ({
              label: `${locator?.locatorName} - ${locator?.locator}`,
              value: locator?.locator,
              warehouseId: locator?.warehouseId,
              id: locator?.id,
            })) : undefined}
            placeholder={lang.locator}
            fieldProps={{
              labelInValue: true,
              onChange: async value => {
                if (productFind && !_.isEmpty(value)) {
                  const locator = await getAllProudctLocator({ masterProductId: productFind?.id, locatorCode: value?.value, shelfLife: channel?.shelfLife });
                  // console.log(locator)
                  if (locator?.data) {
                    setLocatorFind(locator?.data);
                  } else setLocatorFind();
                } else setLocatorFind();
                form.setFieldsValue({ lot: undefined, expireDate: undefined, quantityInCarton: 0, quantity: 0, shelfLife: undefined });
              },
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              // return true;
              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            showSearch
          />
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="lot"
            label={lang.lot}
            rules={[
              {
                required: true,
                message: lang.requiredLot,
              },
            ]}
          >
            <Select
              placeholder={lang.lot}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              }
              onChange={(val) => {
                const find = locatorFind?.find(value => value.lot === val);
                const shelfLife = moment(find?.expireDate, formatDateFull).diff(moment().startOf('date'), 'day') * 100 / (find?.masterProduct?.shelfLifeMonths * 30);
                const shelfLifeFixed = shelfLife.toFixed(2);
                form.setFieldsValue({ expireDate: find?.expireDate, quantityInCarton: 0, quantity: 0, shelfLife: shelfLifeFixed });
              }}
            >
              {locatorFind?.reduce((prev, curr) => {
                // Nếu lot trc đó bằng lot hiện tại mà không xảy ra thì lấy cả 2 lot. TH này do locator có 2 lot
                if (!prev.some(lot => lot === curr.lot)) { // ...prev tức là nếu locator này có 2 lot nó sẽ giải thêm vào
                  return [...prev, curr.lot];
                }
                return prev;
              }, [])?.map(item => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => <Form.Item
              name="expireDate"
              label={lang.expireDate}
              rules={[
                {
                  required: true,
                  message: lang.requiredExpirationDate,
                },
              ]}
            >
              <Select
                placeholder={lang.expireDate}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  // return true;
                  return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                }
                onChange={() => form.setFieldsValue({ quantityInCarton: 0, quantity: 0 })}
              >
                {locatorFind?.filter(val => val.lot === getFieldValue('lot'))?.map(item => (
                  <Option key={item.expireDate} value={item.expireDate}>{item.expireDate}</Option>
                ))}
              </Select>
            </Form.Item>}
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name='shelfLife' label={'Shelf Life(%)'}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              if (locatorFind) {
                let findProductVas = dataVas.map(item => item?.bomProduction);
                let totalQuantity = locatorFind?.reduce((prev, cur) => prev + cur.totalQuantityCarttonForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantity = locatorFind?.find(locator => locator?.lot === form.getFieldValue('lot'))?.totalQuantityCarttonForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantity = locatorFind?.find(locator => form.getFieldValue('lot') === locator?.lot && locator?.expireDate === form.getFieldValue('expireDate'))?.totalQuantityCarttonForSale;
                }
                if (items) {
                  totalQuantity -= items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                } else totalQuantity += itemVas?.quantityInCarton;
                return <Form.Item
                  name="quantityInCarton"
                  label={totalQuantity > 0 ? `${lang.totalQuantityInCarton}(<=${totalQuantity})` : `${'Số lượng thùng khả dụng'}(=${totalQuantity})`}
                  rules={[
                    {                
                      validator: async (_, value) => {
                        let nameVas = [];
                          await findProductVas?.map((bomPro, index) => {
                            if (productFind?.tinPerCarton * value * dataVas[index]?.using > bomPro?.stockAvailableForSale) {
                              nameVas.push(bomPro?.name)
                            }
                            return true;
                          })
                          return value ? (
                            nameVas?.length > 0 ?  Promise.reject(`${nameVas.toString()} không đủ`) :
                            value <= totalQuantity ? Promise.resolve() : 
                            Promise.reject(lang.lessThanStockCarton)
                          ) :
                           Promise.resolve()
                      }
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantity === 0} style={{ width: '100%' }} min={0} placeholder={lang.placeholderQuantity} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantityInCarton"
                label={lang.totalQuantityInCarton}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={productFind?.capacity} placeholder={'Số lượng thùng khả dụng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item shouldUpdate>
            {(form) => {
              if (locatorFind) {
                let findProductVas = dataVas.map(item => item?.bomProduction);
                let totalQuantityTin = locatorFind?.reduce((prev, cur) => prev + cur.totalQuantityForSale, 0);
                if (form.getFieldValue('lot')) {
                  totalQuantityTin = locatorFind?.find(locator => locator?.lot === form.getFieldValue('lot'))?.totalQuantityForSale;
                }
                if (form.getFieldValue('expireDate')) {
                  totalQuantityTin = locatorFind?.find(locator => form.getFieldValue('lot') === locator?.lot && locator?.expireDate === form.getFieldValue('expireDate'))?.totalQuantityForSale;
                }
                if (items) {
                  const cartons = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantityInCarton, 0);
                  const tins = items?.filter(item => item.locatorId.value === form.getFieldValue('locatorId')?.value && form.getFieldValue('lot') === item.lot &&
                    form.getFieldValue('expireDate') === item.expireDate && item.code === productFind?.code)?.reduce((prev, curr) => prev + curr.quantity, 0);
                  totalQuantityTin -= (form.getFieldValue('quantityInCarton') + cartons) * parseInt(productFind.tinPerCarton) - tins;
                } else {
                  totalQuantityTin += itemVas?.quantityInCarton * parseInt(productFind.tinPerCarton) + itemVas?.quantityTin;
                  totalQuantityTin -= form.getFieldValue('quantityInCarton') * parseInt(productFind.tinPerCarton);
                }
                return <Form.Item
                  name="quantity"
                  label={totalQuantityTin > 0 ? `${lang.total}(<=${lang.totalQuantityInTin})` : `${lang.totalQuantityInTin}(=${totalQuantityTin})`}
                  rules={[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => {
                        let nameVas = [];
                           findProductVas.forEach((bomPro, index) => {
                            if (value + (productFind?.tinPerCarton * form.getFieldValue('quantityInCarton') * dataVas[index]?.using) > bomPro?.stockAvailableForSale) {
                              nameVas.push(bomPro?.name)
                            }
                            return true;
                          })
                          return value ? (
                            nameVas?.length > 0 ?  Promise.reject(`${nameVas.toString()} không đủ`) :
                            value <= totalQuantityTin ? Promise.resolve() : 
                            Promise.reject(lang.lessThanStockTin)
                          ) :
                           Promise.resolve()
                      }
                    },
                  ]}
                >
                  <InputNumber disabled={totalQuantityTin === 0} style={{ width: '100%' }} min={0} placeholder={'Số lượng lon khả dụng'} onChange={(val) => setQuantityChange(val)} />
                </Form.Item>;
              }
              return <Form.Item
                name="quantity"
                label={lang.totalQuantityInTin}
              >
                <InputNumber style={{ width: '100%' }} min={0} max={productFind?.capacity} placeholder={'Số lượng lon khả dụng'} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>

        <Col lg={{ span: 24 }} xs={{ span: 8 }}>
          <Row>
            <h3>{lang.rawMaterialsAndSuppliesInfo}</h3>
          </Row>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item shouldUpdate>
              {(form) => {
                if (form.getFieldValue('productCode') > 0) {
                  let quantityChangeVas = 0;
                  if (quantityChange === 0)  {
                     quantityChangeVas = 0
                  } else if (quantityChange - itemVas?.quantityTin > 0 ) {
                     quantityChangeVas = quantityChange - itemVas?.quantityTin;
                  } else if (quantityChange - itemVas?.quantityTin < 0) {
                     quantityChangeVas = (quantityChange - itemVas?.quantityTin);
                  }
                  return dataVas?.length > 0 ? dataVas.map((item) => {
                    const cartons = form.getFieldValue('quantityInCarton');
                    const tins = form.getFieldValue('quantity');
                    const totalStockAvailableForSale = item?.bomProduction?.stockAvailableForSale;
                    const totalQuantityTinTake = cartons > 0 ? (cartons * productFind?.tinPerCarton * item?.using) + tins : tins * item?.using;
                    // const StockAvailableForSale = totalStockAvailableForSale - totalQuantityTinTake;
                    //Tính toán để sd lại SL khả dụng của BomProduction khi có SP thứ 2 trở đi
                    // if (StockAvailableForSale > 0) {
                    //   if (sessionStorage) {
                    //     sessionStorage.setItem('StockAvailableForSale', StockAvailableForSale)
                    //     sessionStorage.setItem('idBomProduction', item?.bomProduction?.id)
                    //     sessionStorage.setItem('nameBomProduction', item?.bomProduction?.name)
                    //   }
                    //   // TODO
                    // }
                    // console.log(quantityChange)
                    // console.log(quantityChange - itemVas?.quantityTin >= 0 ? quantityChange - itemVas?.quantityTin : -(quantityChange - itemVas?.quantityTin) )
                    // console.log(quantityChange * item?.using)
                    // console.log( totalStockAvailableForSale - (quantityChange * item?.using))
                    return (
                      <div>{item?.bomProduction?.name} - <span>Using: {item?.using}</span> - <span>Available: { 
                        cartons > 0 || tins > 0 ? 
                          totalStockAvailableForSale - totalQuantityTinTake > 0 ? 
                          itemVas ? totalStockAvailableForSale - (quantityChangeVas * item?.using) : 
                            totalStockAvailableForSale - totalQuantityTinTake : 
                          0 : 
                        totalStockAvailableForSale}
                        </span> - <span>{`${lang.quantityNeeded}: ${totalQuantityTinTake}`}</span></div>
                    )
                  }) : <div style={{color: 'red'}}>{lang.productNotConfiguredYet}</div>
                }
              }}
            </Form.Item>
          </Col>
        </Col>
        
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  isOnlyQuantityAllowForUpdate: PropTypes.bool,
  items: PropTypes.array,
  orderType: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
