import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLanguages } from 'utils/lang';

import { suggestLocationByOrder } from './export-branch-gift.service';

import './style.less';
import TableCustom from 'components/TableCustom/table';

const tableScrollHeightArea = window.innerHeight - 200; // px

const SuggestLocationForm = ({ item, onCancel, productOrders, productPicked, locatorFind }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { currentWarehouse } = useSelector((state) => state.auth);
  const lang = getLanguages(t);
  const [productOrder, setProductOrder] = useState([]);
  const [items, setItems] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columnsBookingOrders = [
    {
      title: lang.defaultCode,
      dataIndex: 'codeGift',
      key: 'productCode',
    },
    {
      title: lang.productName,
      key: 'name',
      dataIndex: ['bomGift', 'name']
    },
    {
      title: lang.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: `${lang.pickingquantity}`,
      key: 'pickedQuantity',
      dataIndex: 'pickedQuantity',
    },
    {
      title: lang.locator,
      dataIndex: 'locatorCode',
      key: 'locator',
      // render: val => `${val?.locatorName} - ${val?.locator}`,
    },
  ];
  useEffect(() => {
    setLoading(true);
    if (productPicked?.length > 0) {
      const listProductOrder = productOrders?.map(product => {
        const productFill = productPicked?.filter(productpick => productpick?.masterProduct?.code3A === product?.masterProduct?.code3A);
        const total = productFill?.reduce((prev, curr) => {
          prev.tins += curr.quantityTin;
          prev.cartton += curr.quantityCartton;
          return prev;
        }, { tins: 0, cartton: 0 });
        product.pickedQuantityCartton = total.cartton;
        product.pickedQuantityTin = total.tins;
        return product;
      });
      setProductOrder(listProductOrder);
      Promise.all(listProductOrder?.map(async (product) => {
        const { masterProduct, quantityInCarton, quantityTin, warehouseId, pickedQuantityCartton, pickedQuantityTin, code, orderId } = product;
        const reponse = await suggestLocationByOrder({ masterProductId: masterProduct.id, quantityCarton: quantityInCarton - pickedQuantityCartton, quantityTin: quantityTin - pickedQuantityTin, warehouseId, orderId, screenLock: true, locatorId: product?.locator?.id });
        const result = reponse?.data?.map(productSuggest => ({ ...productSuggest, codeProductOrder: code }));
        return Promise.resolve(result);
      })).then((results) => {
        const ctx = results?.reduce((prev, current) => {
          return prev.concat(current);
        }, []);
        setItems([...ctx]);
        // setSelectedRowKeys([...ctx?.map(ct => ct.id)]);
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setProductOrder(productOrders);
      Promise.all(productOrders?.map(async (product) => {
        if (product?.pickedQuantity < product?.quantity) {
          const locatorId = locatorFind?.find(locator => locator?.locator === product?.locatorCode)?.id;
          const reponse = await suggestLocationByOrder({ sortBy: product.id, sortDirection: 'desc', warehouseId: currentWarehouse?.id, bomGiftId: product.bomGift?.id, quantity: product.quantity, locatorId: locatorId  });
          const result = reponse?.data?.map(productSuggest => ({ ...productSuggest, codeProductOrder: product.document }));
          return Promise.resolve(result);
        } else {
          return Promise.resolve([]);
        }
      })).then((results) => {
        const ctx = results?.reduce((prev, current) => {
          return prev.concat(current);
        }, []);
        setItems([...ctx]);
        // setSelectedRowKeys([...ctx?.map(ct => ct.id)]);
        setLoading(false);
      }).catch(() => setLoading(false));
    }
  }, [productOrders, item, productPicked, currentWarehouse, locatorFind]);
  const columns = [
    {
      title: lang.defaultCode,
      key: 'productCode',
      dataIndex: ['bomGift', 'code'],
      fixed: true,
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'productCode',
      fixed: true,
    },
    {
      title: lang.productName,
      key: 'productName',
      dataIndex: ['bomGift', 'name'],
    },
    {
      title: lang.block,
      key: 'block',
      render: record => record?.rack?.shelf?.block?.name,
    },
    {
      title: lang.shelf,
      key: 'shelf',
      render: record => record?.rack?.shelf?.name,
    },
    {
      title: lang.rack,
      dataIndex: 'rack',
      key: 'rack',
      render: value => value?.code,
    },
    {
      title: lang.quantity,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    // {
    //   title: `${lang.quantity} có thể lấy`,
    //   // dataIndex: 'quantityCartonSubmit',
    //   key: 'quantityCarttonLock',
    //   // render: rec => rec?.quantity - rec?.quantityCartonSubmit,
    // },
    {
      title: lang.locator,
      dataIndex: 'locator',
      key: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
  ];

  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      initialValues={{}}
    >
      <Row gutter={24} >
        <Col>
          <h3>{`${lang.orderCode}`}</h3>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 12 }}>
          <h3 style={{ marginBottom: '10px', fontWeight: 'bold' }}>{item?.map(ite => ite.documentNo)}</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 6 }} xs={{ span: 12 }}>
          <h3>{lang.orderedProductList}</h3>
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        loading={loading}
        bordered
        columns={columnsBookingOrders}
        dataSource={productOrder}
        scroll={{ x: 1300, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
       <Row>
        <Col lg={{ span: 6 }} xs={{ span: 12 }}>
          <h3>{lang.suggestedProductList}</h3>
        </Col>
      </Row>
      <TableCustom
        rowKey="id"
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: (key) => {
        //     setSelectedRowKeys(key);
        //   },
        // }}
        loading={loading}
        bordered
        columns={columns}
        dataSource={items}
        scroll={{ x: 1600, y: tableScrollHeightArea }}
        pagination={{
          showSizeChanger: true,
        }}
      />
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {/* {loading === false ? (
              <Button type="primary" htmlType='submit' style={{ fontWeight: 'bold' }}>
                {lang.print}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )} */}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

SuggestLocationForm.propTypes = {
  item: PropTypes.array,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  productOrders: PropTypes.array,
  productPicked: PropTypes.array,
  locatorFind: PropTypes.array,
};

export default SuggestLocationForm;
