import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getLanguages } from 'utils/lang';
import { useTranslation } from 'react-i18next';
import { Layout, PageHeader, Row, Col, Select, message, Checkbox, Input, Tag } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import ScanLocationForm from 'components/ScanLocationForm/scan-location.form';
import ScanProductForm from 'components/ScanProductForm/scan-product.form';

import './style.less';
import { scanInboundControlCompleted, scanReturnCompleted, scanStoringCompleted, scanVasCompleted, setTypeScan } from 'providers/InboundProvider/actions';
import { productUpdates } from '../storing/storing.service';
import { productList } from '../product.service';
import moment from 'moment-timezone';
import { formatDateFull, formatDate } from 'utils/constants';
import TableCustom from 'components/TableCustom/table';
import { productGiffGetAll, productGiftUpdates } from './scan.service';
// import { recommendRack } from './scan.service';

const breadcrumbItemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return (last || route.path !== '') ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const InboundControlScan = () => {
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const dispatch = useDispatch();
  const { scanInboundControlForProductCodes, scanInboundControlForLocationCode, scanStoringForProductCodes, scanVasForProductCodes, typeScan, scanReturnForProductCodes } = useSelector((state) => state.inbound);
  // const { scanReallocationProductCode } = useSelector((state) => state.inventory);
  const [typeVas, setTypeVas] = useState('VAS');
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [rackRec, setRackRec] = useState();
  const inputRef = useRef(null);
  const inputRefPro = useRef(null);
  const [items, setItems] = useState([]);
  const [type, setType] = useState('PROD');
  useEffect(() => {
    if (scanReturnForProductCodes.length > 0) {
      if (scanReturnForProductCodes?.[0]?.startsWith('GIFT')) {
        setType('GIFT');
        productGiftUpdates({
          codes: scanReturnForProductCodes,
          status: 'TEMPORARY',
          warehouseId: currentWarehouse.id,
        })
          .then(result => {
            dispatch(scanReturnCompleted());
            setItems(result);
          })
          .catch((e) => { message.error(e.message); dispatch(scanReturnCompleted()); });
      } else {
        setType('PROD');
        productUpdates({
          codes: scanReturnForProductCodes,
          status: 'TEMPORARY',
          warehouseId: currentWarehouse.id,
        })
          .then(result => {
            dispatch(scanReturnCompleted());
            setItems(result);
          })
          .catch((e) => { message.error(e.message); dispatch(scanReturnCompleted()); });
      }
    } else if (scanStoringForProductCodes.length > 0) {
      productUpdates({
        codes: scanStoringForProductCodes,
        status: 'MOVING',
        warehouseId: currentWarehouse.id,
      })
        .then(result => {
          dispatch(scanStoringCompleted());
          setItems(result);
        })
        .catch((e) => { message.error(e.message); dispatch(scanStoringCompleted()); });
    } else if (scanInboundControlForLocationCode.length > 0 && scanInboundControlForProductCodes.length > 0) {
      if (scanInboundControlForProductCodes?.[0]?.startsWith('GIFT')) {
        productGiftUpdates({
          codes: scanInboundControlForProductCodes,
          rackCode: scanInboundControlForLocationCode[0],
          status: 'STORED',
          warehouseId: currentWarehouse.id,
        })
          .then(result => {
            dispatch(scanInboundControlCompleted());
            setItems(result);
          })
          .catch((e) => {
            message.error(e.message);
            dispatch(scanInboundControlCompleted());
          });
          return inputRefPro?.current?.focus({
            preventScroll: true,
          });
      } else {
        productUpdates({
          codes: scanInboundControlForProductCodes,
          rackCode: scanInboundControlForLocationCode[0],
          status: 'STORED',
          warehouseId: currentWarehouse.id,
        })
          .then(result => {
            dispatch(scanInboundControlCompleted());
            setItems(result);
          })
          .catch((e) => { message.error(e.message); dispatch(scanInboundControlCompleted()); });
        return inputRefPro?.current?.focus({
          preventScroll: true,
        });
      }
    } else if (scanVasForProductCodes.length > 0 && typeVas) {
      setType('PROD');
      const data = { codes: scanVasForProductCodes, warehouseId: currentWarehouse.id };
      // console.log(typeVas)
      // if (typeVas.length > 0) {
      //   if (typeVas.length === 2) {
      //     data.type = 3;
      //     data.status = 'PACK';
      //   } else {
      //     if (typeVas[0] === 'VAS') {
      //       data.type = 1;
      //       data.status = 'VAS';
      //     } else {
      //       data.type = 2;
      //       data.status = 'PACK';
      //     }
      //   }
      // }
      data.type = 1;
      data.status = 'VAS';
      productUpdates(data)
        .then(result => {
          dispatch(scanVasCompleted());
          setItems(result);
        })
        .catch((e) => { message.error(e.message); dispatch(scanVasCompleted()); });
    }
  }, [scanStoringForProductCodes, scanInboundControlForLocationCode, scanReturnForProductCodes, scanInboundControlForProductCodes, dispatch, lang.productName, scanVasForProductCodes, typeVas, currentWarehouse,]);
  useEffect(() => {
    if (scanInboundControlForProductCodes.length > 0) {
      if (scanInboundControlForProductCodes?.[0]?.startsWith('GIFT')) {
        setType('GIFT');
        productGiffGetAll({ warehouseId: currentWarehouse?.id, code: scanInboundControlForProductCodes[0] }).then(rs => {
          setRackRec(rs?.data[0]?.rack?.code);
        });
      } else {
        setType('PROD');
        productList({ warehouseId: currentWarehouse?.id, keyword: scanInboundControlForProductCodes[0], multipleStatus: 'TEMPORARY,STORED' }).then(rs => {
          setRackRec(rs?.data[0]?.rack?.code);
        });
      }
    }
  }, [scanInboundControlForProductCodes, currentWarehouse]);
  // const extraButton = [
  //   <Button
  //     key="btn-cancel"
  //     type="primary"
  //     ghost
  //     onClick={() => {
  //       if (typeScan === 'SCAN_RETURN') {
  //         dispatch(scanReturnCompleted());
  //       } else if (typeScan === 'STORING') {
  //         dispatch(scanStoringCompleted());
  //       } else if (typeScan === 'INBOUND_CONTROL') {
  //         dispatch(scanInboundControlCompleted());
  //       } else if (typeScan === 'VAS') {
  //         dispatch(scanVasCompleted());
  //       } else {
  //         dispatch(scanReallocationProduct([]));
  //       }
  //       navigate(-1);
  //     }}
  //   >
  //     {lang.btnCancel}
  //   </Button>,
  //   <Button
  //     key="btn-complete"
  //     disabled={(scanInboundControlForLocationCode.length === 0 || scanInboundControlForProductCodes.length === 0) && scanVasForProductCodes.length === 0 &&
  //       scanReallocationProductCode.length === 0 && scanStoringForProductCodes.length === 0 && scanReturnForProductCodes.length === 0
  //     }
  //     type="primary"
  //     onClick={() => {
  //       if (typeScan === 'SCAN_RETURN') {
  //         navigate('dashboard/inbound/temporary-inbound/scan-return-product/complete', { state: { prevUrl: document.location.pathname } });
  //       } else if (typeScan === 'STORING') {
  //         navigate('/dashboard/inbound/storing/storing-scan/complete', { state: { prevUrl: document.location.pathname } });
  //       } else if (typeScan === 'INBOUND_CONTROL') {
  //         navigate('/dashboard/inbound/inbound-control/inbound-control-scan/complete', { state: { prevUrl: document.location.pathname } });
  //       } else if (typeScan === 'VAS') {
  //         navigate('/dashboard/inbound/vas/vas-scan/complete', { state: { prevUrl: document.location.pathname } });
  //       } else {
  //         navigate('/dashboard/inventory/reallocation/scancode/editlocation', { state: { prevUrl: document.location.pathname } });
  //       }
  //     }}
  //   >
  //     {lang.btnSubmit}
  //     <LoginOutlined />
  //   </Button>,
  // ];

  const routes = [
    {
      path: '/dashboard/inbound',
      breadcrumbName: lang.inbound,
    },
    {
      path: '/dashboard/inbound/scan',
      breadcrumbName: lang.scan,
    },
  ];
  const columnsGift = [
    {
      title: lang.giftCode,
      key: 'giftCode',
      fixed: 'left',
      width: '5%',
      render: (record) => record?.bomGift?.code,
    },
    {
      title: 'QR code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: lang.giftName,
      key: 'giftName',
      render: (record) => record?.bomGift?.name,
    },
    {
      title: 'ContractRefNo',
      key: 'contractRefNo',
      dataIndex: 'contractRefNo',
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.quantity,
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: lang.locator,
      key: 'locator',
      dataIndex: 'locator',
      render: val => `${val?.locatorName} - ${val?.locator}`,
    },
    // {
    //   title: lang.block + '(Gợi ý)',
    //   key: 'block',
    //   dataIndex: ['rack', 'shelf', 'block', 'name'],
    // },
    // {
    //   title: lang.shelf + '(Gợi ý)',
    //   key: 'shelf',
    //   dataIndex: ['rack', 'shelf', 'name'],
    // },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      render: value => value?.code,
    },
  ];
  const columns = [
    {
      title: lang.defaultCode,
      dataIndex: 'masterProduct',
      key: 'barcode',
      // width: '8%',
      render: value => value?.code3A,
    },
    {
      title: lang.shortname,
      dataIndex: ['masterProduct', 'shortName'],
      key: 'shortName',
    },
    {
      title: lang.productName,
      dataIndex: 'masterProduct',
      key: 'name',
      width: '12%',
      render: value => value?.nameEN,
    },
    {
      title: lang.quantityCartton,
      key: 'quantityCartton',
      dataIndex: 'quantityCartton',
    },
    {
      title: lang.quantityTin,
      key: 'quantityTin',
      dataIndex: 'quantityTin',
    },
    {
      title: lang.totalQuantityTins,
      key: 'totalQuantity',
      dataIndex: 'totalQuantity',
      // width: '6%',
    },
    {
      title: lang.productCode,
      dataIndex: 'code',
      key: 'code',
      // width: '8%',
    },
    {
      title: lang.mfgDate,
      dataIndex: 'mfgDate',
      key: 'mfgDate',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.expireDate,
      dataIndex: 'expireDate',
      key: 'expireDate',
      // width: '8%',
      render: value => value && moment(value, formatDateFull).format(formatDate),
    },
    {
      title: lang.vansCode,
      dataIndex: 'container',
      key: 'container',
    },
    {
      title: lang.shipmentNo,
      dataIndex: 'shipment',
      key: 'shipment',
    },
    {
      title: lang.contractNo,
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: lang.locator,
      key: 'locator',
      // dataIndex: ['locator', 'locatorName'],
      render: val => val?.locator?.locatorName + '-' + val?.locator?.locator,
    },
    {
      title: lang.zone,
      key: 'zone',
      dataIndex: ['zone', 'name'],
    },
    {
      title: lang.rack,
      key: 'rack',
      dataIndex: 'rack',
      // width: '6%',
      render: value => value?.code,
    },
    {
      title: lang.description,
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: lang.UOM,
      key: 'UOM',
      dataIndex: 'masterProduct',
      // width: '5%',
      render: value => value?.UOM_VN,
    },
    {
      title: lang.status,
      key: 'status',
      dataIndex: 'status',
      // width: '5%',
      render: (record) => {
        return (
          <Tag>{lang[record]}</Tag>
        );
      },
    },
  ];
  return (
    <Layout className="inbound-control-scan layoutContent">
      <PageHeader
        ghost={false}
        title={lang.scan}
        // extra={extraButton}
        breadcrumb={{
          routes,
          itemRender: breadcrumbItemRender,
        }}
        className="customPageHeader"
      />
      <Layout.Content>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Select style={{ width: '30%' }} defaultValue={typeScan} options={[
              {
                value: 'SCAN_RETURN',
                label: lang.temporaryInbound,
              },
              // {
              //   value: 'STORING',
              //   label: lang.storing,
              // },
              {
                value: 'INBOUND_CONTROL',
                label: lang.inboundControl,
              },
              {
                value: 'VAS',
                label: lang.Vas,
              },
            ]}
              onChange={val => { dispatch(setTypeScan(val)); setItems([]); }} />
            {typeScan === 'VAS' && <Checkbox.Group
              layout="horizontal"
              options={['VAS']}
              onChange={(values) => setTypeVas(values)}
              style={{ marginLeft: 10 }}
              defaultValue={typeVas}
            />}
            {typeScan === 'INBOUND_CONTROL' && <Input style={{ width: '20%', marginLeft: 10 }} value={rackRec} placeholder='Vị trí gợi ý' />}
          </Col>
          <Col sm={24} lg={typeScan === 'INBOUND_CONTROL' ? 12 : 24} xs={24}>
            <ScanProductForm type={typeScan} inputRef={inputRef} inputRefPro={inputRefPro} />
          </Col>
          {typeScan === 'INBOUND_CONTROL' && <Col sm={24} lg={12} xs={24}>
            <ScanLocationForm type={typeScan} inputRef={inputRef} />
          </Col>}
          <Col span={24}>
            <TableCustom
              title={() => <Row>
                <Col span={12}><h3>{type === 'GIFT' ? 'Danh sách quà' : lang.productList}</h3></Col>
              </Row>}
              rowKey="id"
              //rowSelection={rowSelection}
              bordered
              columns={type === 'GIFT' ? columnsGift : columns}
              dataSource={items}
              pagination={false}
              scroll={{ x: 2600 }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default InboundControlScan;
