import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Select, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import './style.less';
import { useSelector } from 'react-redux';
import { bomList } from 'pages/dashboard/inventory/inventorymaterial/bom.service';

import { defaultPage } from 'utils/constants';

const { Option } = Select;


const ReceiptProductEditForm = ({ item, onCancel, onCallback, items, type }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const [params] = useState({ ...defaultPage });
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [productFind, setProductFind] = useState();
  const [sortedInfo] = useState({
    sortDirection: 'desc',
    sortBy: 'createDate',
  });
  useEffect(() => {
    setLoading(true);
    bomList({ ...params, ...sortedInfo, warehouseId: currentWarehouse.id, isAvailable: true })
      .then(result => {
        setProducts(result.data);
        if (item) {
          setProductFind(result.data.find(pro => pro?.code === item?.bomProduction?.code))
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }, [sortedInfo, currentWarehouse, params, item]);
  return (
    <Form
      colon={false}
      form={form}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={(values) => {
        try {
          if (values.quantity === 0) {
            throw new Error(lang.quantityIsRequired);
          }
          setLoading(true);
          onCallback({
            ...productFind,
            ...values,
            type,
            id: item?.id,
            indx: item?.indx || items?.length + 1,
          });
        } catch (error) {
          message.error(error.message);
        }
      }}
      initialValues={{
        productCode: item ? item?.bomProduction?.name : undefined,
        quantity: item ? item?.quantity : 0,
        dvt: item?.DVT ? item?.DVT : productFind?.DVT

      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item name="productCode" label={lang.product}
            rules={[
              {
                required: true,
                message: lang.requiredProduct,
              },
            ]}
          >
            <Select
              disabled={item}
              placeholder={lang.placeholderSelectProduct}
              onChange={(value) => {
                const itemFind = products.find(product => product.id === value);
                setProductFind(itemFind);
                // setCheckLocator(true)
                form.setFieldsValue({quantity: 0 });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                // return true;
                return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {products?.map(item => (
                <Option key={item.id} value={item.id}>{item.name} - {item.code}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Form.Item
          name="dvt"
          label={`Đơn vị tính: ${productFind?.DVT ? productFind?.DVT : ''}`}
        >
          <Input type='text' style={{ width: '100%' }} placeholder={'Đơn vị tính'} />
        </Form.Item>
        </Col> */}
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Form.Item
          name="quantity"
          label={`${lang.availableQuantity} (<= ${productFind?.stockAvailableForSale > 0 ? productFind?.stockAvailableForSale : 0})`}
          rules={[
            {
              // eslint-disable-next-line prefer-promise-reject-errors
              validator: (_, value) => value ? (value <= productFind?.stockAvailableForSale ? Promise.resolve() : Promise.reject(lang.lessThanStockQuantity)) : Promise.resolve(),
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={0} placeholder={lang.quantity} />
        </Form.Item>
        </Col>
      </Row>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            {loading === false ? (
              <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
                {lang.btnSave}
              </Button>
            ) : (
              <Button type="primary" loading style={{ fontWeight: 'bold' }}>
                {lang.loading}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ReceiptProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
  type: PropTypes.string,
  locators: PropTypes.array,
  channel: PropTypes.object,
};

export default ReceiptProductEditForm;
