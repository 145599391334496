import React, {useState } from 'react';
import { Form, Input, Col, Row, Button, InputNumber } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { useSelector } from 'react-redux';

import './style.less';
import { ProFormSelect } from '@ant-design/pro-form';
import { getAllProudctLocator } from './production-order.service';
import { productList } from 'pages/dashboard/inventory/inventory-management/product.service';

const ProductEditForm = ({ item, onCancel, onCallback}) => {
  // console.log(item)
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lang = getLanguages(t);
  const { currentWarehouse } = useSelector((state) => state.auth);
  const [locatorSelected, setLocatorSeleted] = useState('')
  // const [locatorFind, setLocatorFind] = useState([])

  return (
    <Form
      colon={false}
      form={form}
      requiredMark={false}
      layout="vertical"
      name="receiptProductEditForm"
      onFinish={async (values) => {
        values.index = item?.index
        onCallback(values);
      }}
      initialValues={{
        code3A: item?.code3A ? item?.code3A : '',
        name: item?.name ? item?.name : '',
        shortName: item?.shortName ? item.shortName : '',
        locatorId: item?.locatorId ? item?.locatorId?.label : undefined,
        lot: item?.lot ? item?.lot?.lot : undefined,
        expireDate: item?.expireDate ? item?.expireDate : undefined,
        quantityInCarton: item?.quantityInCarton ? item?.quantityInCarton : undefined,
        quantityTin: item?.quantityTin ? item?.quantityTin : undefined,
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="code3A" label={lang.defaultCode}>
            <Input placeholder={lang.defaultCode} disabled={true} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name='shortName'
            label={lang.shortname}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            label={lang.productName}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name='locatorId'
            label={lang.locator}
            rules={[
              {
                required: true,
                message: lang.requiredLocator,
              },
            ]}
            params={{code3A: item?.code3A}}
            request={async (params) => {
            const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
            let listProductLocator = listProductInventory?.data?.find(item => item?.code3A === params?.code3A)?.masterProductsLocators;
            // console.log(listProductLocator)
            const listProductSelectedMulti = []; 
            for (let i = 0; i < listProductLocator.length; i++) { 
              let obj = listProductLocator[i];
              const { totalQuantityCarttonForSale, totalQuantityForSale, locators } = obj;
              const foundObj = listProductSelectedMulti.find((item) => (item?.locators?.id === locators?.id ));
              
              if (foundObj) {
                foundObj.totalQuantityCarttonForSale += totalQuantityCarttonForSale; 
                foundObj.totalQuantityForSale += totalQuantityForSale;
              } else { 
                listProductSelectedMulti.push(listProductLocator[i]);
              }
            }

            return listProductSelectedMulti?.filter(proItem => proItem?.locators?.warehouseId === currentWarehouse?.id && proItem?.locators?.warehouseToId === null)?.map(locator => ({
              label: `${locator?.locators?.locator} - ${locator?.locators?.locatorName}`,
              value: locator?.locators?.locator,
              id: locator?.id,
              code3A: params?.code3A,
              totalQuantityCarttonForSale: locator?.totalQuantityCarttonForSale,
              totalQuantityForSale: locator?.totalQuantityForSale,
              totalQuantityTinForSale: locator?.totalQuantityTinForSale
            }))
            }}
            placeholder={lang.locator}
            fieldProps={{
              labelInValue: true,
              onChange: async (value) => {
                setLocatorSeleted(value?.value)
                // console.log(value)
                // const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
                // const masterProductId = listProductInventory?.data?.find(item => item?.code3A === value?.code3A)?.id;
                // const locator = await getAllProudctLocator({ masterProductId: masterProductId, locatorCode: value?.value });
                
                // if (locator?.data) {
                //   setLocatorFind(locator?.data)
                // } else {
                //   setLocatorFind([])
                // }
                form.setFieldsValue({ lot: undefined, expireDate: undefined})
              },
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              // return true;
              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            showSearch
          />
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <ProFormSelect style={{ fontWeight: 600 }}
            name="lot"
            label={lang.lot}
            rules={[
              {
                required: true,
                message: lang.requiredLot,
              },
            ]}
            params={{ 
              code3A: item?.code3A, 
              locatorCode: locatorSelected,
            }}
            request={ async (params) => {
              const {locatorCode} = params;
              const listProductInventory = await productList({ warehouseId: currentWarehouse?.id, isAvailableQauntity: true});
              const masterProductId = listProductInventory?.data?.find(item => item?.code3A === params?.code3A)?.id;
              const locator = await getAllProudctLocator({ masterProductId: masterProductId, locatorCode });
              return locator?.data.map(locator => ({
                label: locator?.lot,
                value: locator?.lot,
                ...locator
              }));
            }}
            placeholder={lang.lot}
            fieldProps={{
              labelInValue: true,
              onChange: val => {
                form.setFieldsValue({ expireDate: val?.expireDate})
              },
            }}
            optionFilterProp="children"
            filterOption={(input, option) => {
              // return true;
              return option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            showSearch
          />
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            // name="expireDate"
            name="expireDate"
            label={lang.expireDate}
            rules={[
              {
                required: true,
                message: lang.requiredExpirationDate,
              },
            ]}
          >
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item shouldUpdate
            wrapperCol={{ span: 24 }} 
            style={{ marginBottom: 0 }}>
            {(form) => {
              const locatorSelect = item?.locatorId?.value;
              if (locatorSelect) {
                let dataQuantity = form.getFieldValue('locatorId');
                // console.log(form.getFieldValue('lot'))
                const cartons = item?.carttonFromOperator;
                let totalQuantity = dataQuantity?.totalQuantityCarttonForSale;
                
                if (form.getFieldValue('lot')?.lot) {
                  dataQuantity = form.getFieldValue('lot');
                  totalQuantity = dataQuantity?.totalQuantityCarttonForSale;
                } else {
                  totalQuantity = item?.locatorId?.totalQuantityCarttonForSale
                }
                // if (form.getFieldValue('expireDate')) {
                //   totalQuantity = locatorFind?.find(locator => form.getFieldValue(['internalProduct', name, 'lot'])?.lot === locator.lot && locator.expireDate === form.getFieldValue(['internalProduct', name, 'expireDate']))?.totalQuantityCarttonForSale;
                // }
                return <Form.Item
                  name='quantityInCarton'
                  label={`${lang.quantityCartton} ${lang.pickedCan}(<=${totalQuantity >= 0 ? totalQuantity : 0}) - ${lang.quantityCartton} ${lang.toCoordination}: ${cartons}`}
                  rules= {[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => 
                      // value > cartons ? Promise.reject('SL lấy phải nhỏ hơn SL tối đa') : 
                      value > totalQuantity ? Promise.reject(lang.quantityInCartonLessThanTotal) : 
                      Promise.resolve(),
                    },
                  ]}
                  initialValue={totalQuantity >= 0 ? totalQuantity : cartons}
                >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                </Form.Item>;
              }
              const cartons = item?.carttonFromOperator;
              return <Form.Item
                name='quantityInCarton'
                label={`${`${lang.quantityCartton} ${lang.pickedNeed}`}(<=${cartons})`}
                rules= {[
                  {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    validator: (_, value) => value > cartons ? Promise.reject(lang.quantityInCartonLessThanTotal) : Promise.resolve(),
                  },
                ]}
                initialValue={cartons}
              >
                <InputNumber style={{ width: '100%' }} placeholder={lang.quantityCartton} min={0} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item shouldUpdate wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
            {(form) => {
              const locatorSelect = item?.locatorId?.value;
              if (locatorSelect) {
                const tins = item?.tinFromOperator;
                let dataQuantity = form.getFieldValue('locatorId');
                let totalQuantityTin = dataQuantity?.totalQuantityForSale ;

                if (form.getFieldValue('lot')?.lot) {
                  dataQuantity = form.getFieldValue('lot');
                  totalQuantityTin = dataQuantity?.totalQuantityForSale;
                } else {
                  totalQuantityTin = item?.locatorId?.totalQuantityForSale
                }
                // if (form.getFieldValue(['internalProduct', name, 'expireDate'])) {
                //   totalQuantityTin = locatorFind?.find(locator => form.getFieldValue(['internalProduct', name, 'lot'])?.lot === locator.lot && locator.expireDate === form.getFieldValue(['internalProduct', name, 'expireDate']))?.totalQuantityForSale ;
                // }
                return <Form.Item
                  name='quantityTin'
                  label={`${lang.quantityTin} ${lang.pick}'}(<=${totalQuantityTin >= 0 ? totalQuantityTin : 0}) - ${lang.quantityTin} ${lang.toCoordination}: ${tins}`}
                  rules= {[
                    {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      validator: (_, value) => 
                      // value > cartons ? Promise.reject('SL lấy phải nhỏ hơn SL tối đa') : 
                      value > totalQuantityTin ? Promise.reject(lang.quantityInCartonLessThanTotal) : 
                      Promise.resolve(),
                    },
                  ]}
                  initialValue={item?.quantityTin}
                >
                  <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
                </Form.Item>;
              }
              const cartons = form?.getFieldValue('quantityInCarton');
              const tins = item?.quantityTin - item?.pickingQuantityTin + (item?.quantityInCarton - item?.pickingQuantityCartton - cartons) * parseInt(item?.masterProduct?.tinPerCarton);
              return <Form.Item
                name='quantityTin'
                wrapperCol={24}
                label={`${lang.quantityInTin} ${lang.pickedNeed}(<=${tins > 0 ? tins : 0})`}
                rules={(cartons > 0 || tins > 0) && [
                  {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    validator: (_, value) =>  value > tins ? Promise.reject(lang.quantityInCartonLessThanTotal) : Promise.resolve(),
                  },
                ]}
                initialValue={tins}
              >
                <InputNumber style={{ width: '100%' }} placeholder={lang.quantityTin} min={0} />
              </Form.Item>;
            }}
          </Form.Item>
        </Col>
      </Row>
      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel} style={{ fontWeight: 'bold' }}>
              {lang.btnCancel}
            </Button>
            <Button type="primary" htmlType="submit" style={{ fontWeight: 'bold' }}>
              {lang.btnSave}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

ProductEditForm.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onCallback: PropTypes.func,
};

export default ProductEditForm;
